import { signInConstansts } from "../Constants/loginConstant";
import { doctorConstants } from "../Constants/doctorConstants";
const initialState = {
  myPatient: [],
  allMedicines: [],
  allTests: [],
  myBoards: [],
};
export const doctorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case doctorConstants.MY_PATIENTS:
      return { ...state, myPatient: payload };
    case doctorConstants.ALL_MEDICINES:
      return { ...state, allMedicines: payload };
    case doctorConstants.MY_BOARDS:
      return { ...state, myBoards: payload };
    case doctorConstants.ALL_TESTS:
      return { ...state, allTests: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

import React from "react";
import "./teamcard.css";
const Teamcard = (props) => {
  return (

    <div className="project-image">
      <div className="magnific-imgs">
        <img src={props.imgSrc} alt="Team Member" style={{ width: '170px', maxWidth: '170px' }} />
        <h3 className="text-center mt-3 px-2" style={{ whiteSpace: 'pre-wrap' }}>{props.cardHeading} </h3>
        <span className="designation px-2 " style={{ whiteSpace: 'pre-wrap' }}>{props.description}</span>
      </div>
    </div>
  );
};

export default Teamcard;

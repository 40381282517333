import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import { patientPrescriptions } from "../../Store/Actions/patientActions";

const PrescriptionProfile = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const prescriptions = useSelector((state) => state?.patient?.prescriptions);

  useEffect(() => {
    const apiData = {
      id: user?.id,
    };

    dispatch(patientPrescriptions(apiData));
  }, [user?.id, dispatch]);

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{ t("dashboards.alldashboardkeys.tablekeys.myprescription")}</h4>
        </div>
      </div>

      <div className="row">
        <BootstrapCustomTable
          columns={[
            {
              formatter: (cell, row) => {
                return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
              },
              text: t("dashboards.alldashboardkeys.tablekeys.date"),
            },
            { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
            { dataField: "diagnosis", text: t("dashboards.alldashboardkeys.tablekeys.diagnoses") },
            {
              formatter: (cell, row) => {
                return (
                  <>
                    <DashboardBtnList
                      label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                      className="order-pay-btn"
                      onClick={() =>
                        props?.history?.push(
                          "/patient-prescription-details",
                          row
                        )
                      }
                    />
                  </>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={prescriptions}
        />
      </div>
    </div>
  );
};

export default PrescriptionProfile;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UploadReportModal from "../../Components/LayOuts/modals/uploadReportModal/UploadReportModal";
// import { useDispatch } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import {
  changeResportStatus,
  getTestResports,
} from "../../Store/Actions/labAdminActions";
import { pharmacyPay } from "../../Store/Actions/pharmacyActions";
import { comaSeperated } from "../../Utils/functions";
import ProfileTable from "../patientDashboard/ProfileTable";
import Loader from "../../Components/ReUseableComponents/Loader";

const ViewTestDetails = (props) => {
  const { t } = useTranslation("common");
  // let viewData = props.location.state;
  const item = { key: "fromPrescription" };
  const loader = useSelector((state) => state?.logIn?.loader);
  const [loading, setLoading] = useState(false);

  const [openuploadModal, setopenuploadModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [viewData, setviewData] = useState(props.location.state);
  const dispatch = useDispatch();

  // useEffect(async () => {
  //   setLoading(true);
  //   let res = await dispatch(getTestResports());
  //   setLoading(false);
  // }, [dispatch]);
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.testdetails")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                if (viewData?.from === "administrator") {
                  props?.history?.push("/administrator", item);
                } else {
                  props?.history?.push("/lab-administrator", item);
                }
              }}
            >
              <i className="fas fa-arrow-left"></i>{" "}
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3  pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <h5>{t("dashboards.alldashboardkeys.tablekeys.patientdetail")}</h5>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{viewData?.ordered_by?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{viewData?.ordered_by?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientnumber")}
                </span>
                <p>{viewData?.ordered_by?.phone_number}</p>
              </li>
            </ul>
            {loading ? (
              <Loader />
            ) : (
              <div>
                {viewData?.tests?.length > 0 ? (
                  <>
                    <ProfileTable
                      tableHead={[
                        t("dashboards.alldashboardkeys.tablekeys.name"),
                        t("dashboards.alldashboardkeys.tablekeys.testid"),
                        t("dashboards.alldashboardkeys.tablekeys.quantity"),
                        t("dashboards.alldashboardkeys.tablekeys.price"),
                        t("dashboards.alldashboardkeys.tablekeys.action"),
                      ]}
                      data={viewData?.tests?.map((item, index) => {
                        return [
                          item?.test?.name,
                          item?.test?.test_id,
                          item?.quantity,
                          item?.test?.price,
                          <>
                            {item?.test?.report?.status?.name === "pending" &&
                            (viewData?.paid_status?.name === "paid" ||
                              viewData?.paid_status?.name === "partial") ? (
                              <DashboardBtnList
                                label={t(
                                  "dashboards.alldashboardkeys.tablekeys.collectsample"
                                )}
                                className="order-pay-btn"
                                onClick={async () => {
                                  const data = await dispatch(
                                    changeResportStatus(
                                      {
                                        report_ids: [item?.test?.report?.id],
                                        status: {
                                          id: 2,
                                          name: "sample collected",
                                        },
                                      },
                                      viewData?.id
                                    )
                                  );
                                  let testArray = [...viewData?.tests];
                                  testArray[index] = {
                                    ...viewData?.tests[index],
                                    test: {
                                      ...viewData?.tests[index].test,
                                      report: data[0],
                                    },
                                  };

                                  setviewData({
                                    ...viewData,
                                    tests: testArray,
                                  });
                                }}
                              />
                            ) : item?.test?.report?.status?.name ===
                                "sample collected" &&
                              (viewData?.paid_status?.name === "paid" ||
                                viewData?.paid_status?.name === "partial") ? (
                              <DashboardBtnList
                                label={t(
                                  "dashboards.alldashboardkeys.tablekeys.submitsample"
                                )}
                                className="order-pay-btn"
                                onClick={async () => {
                                  const data = await dispatch(
                                    changeResportStatus(
                                      {
                                        report_ids: [item?.test?.report?.id],
                                        status: {
                                          id: 3,
                                          name: "sample submitted",
                                        },
                                      },
                                      viewData?.id
                                    )
                                  );
                                  let testArray = [...viewData?.tests];
                                  testArray[index] = {
                                    ...viewData?.tests[index],
                                    test: {
                                      ...viewData?.tests[index].test,
                                      report: data[0],
                                    },
                                  };

                                  setviewData({
                                    ...viewData,
                                    tests: testArray,
                                  });

                                  // props?.history?.push("/lab-administrator", item);
                                }}
                              />
                            ) : item?.test?.report?.status?.name ===
                                "sample submitted" &&
                              (viewData?.paid_status?.name === "paid" ||
                                viewData?.paid_status?.name === "partial") ? (
                              <div
                                className="btn btn-block order-pay-btn text-white"
                                onClick={() => {
                                  setSelectedReport(item?.test);
                                  setopenuploadModal(true);
                                }}
                              >
                                {t(
                                  "dashboards.alldashboardkeys.tablekeys.submitreport"
                                )}
                              </div>
                            ) : item?.test?.report?.status?.name ===
                                "report uploaded" &&
                              (viewData?.paid_status?.name === "paid" ||
                                viewData?.paid_status?.name === "partial") ? (
                              <a
                                className="btn btn-block order-pay-btn text-white"
                                href={item?.test?.report?.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t(
                                  "dashboards.alldashboardkeys.tablekeys.viewreport"
                                )}
                              </a>
                            ) : (
                              ""
                            )}
                          </>,
                        ];
                      })}
                    />
                  </>
                ) : (
                  <div className="text-center">
                    {" "}
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.noprescribedtest"
                    )}
                  </div>
                )}
              </div>
            )}
            {viewData?.paid_status?.name == "partial" ? (
              <>
                <ul style={{ float: "right" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {viewData?.total_amount}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {viewData?.paid_amount}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.remainingamount"
                      )}{" "}
                      :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {viewData?.remaining_amount}
                    </span>
                  </li>
                  {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}
                </ul>
              </>
            ) : viewData?.paid_status?.name == "paid" ? (
              <ul style={{ float: "right" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {viewData?.total_amount}
                  </span>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :{" "}
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {viewData?.paid_amount}
                  </span>
                </li>
              </ul>
            ) : viewData?.paid_status?.name == "unpaid" ? (
              <>
                <ul style={{ float: "right" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {viewData?.total_amount}
                    </span>
                  </li>

                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.installmentamount"
                      )}{" "}
                      :{" "}
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {viewData?.installments_amount}
                    </span>
                  </li>
                </ul>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <UploadReportModal
        show={openuploadModal}
        onHide={() => setopenuploadModal(false)}
        viewData={selectedReport}
        {...props}
      />
    </div>
  );
};

export default ViewTestDetails;

// <div className="d-flex justify-content-between">
// <div className="col-lg-8">
//   <h5>
//     {t("dashboards.alldashboardkeys.tablekeys.testdetails")}
//   </h5>
// </div>
// <div className="col-lg04">
//   {viewData?.tests?.length > 0 &&
//     viewData?.paid_status?.name === "unpaid" ? (
//     <>
//       {loader ? <Loader
//         height={20}
//         width={20}
//         type="Oval"
//         color="#1a4774"
//       /> :
//         <><DashboardBtnList
//           label={`${t("dashboards.alldashboardkeys.tablekeys.paybtn")} (${comaSeperated(viewData?.total_amount)}) `}
//           className="order-pay-btn"
//           onClick={async () => {
//             setLoading(true)
//             const data = await dispatch(
//               pharmacyPay({ id: viewData?.id, partial_payment: false }, true, viewData?.id)
//             );
//             setLoading(false)

//             if (data) {
//               props?.history?.push("/lab-administrator", item);

//             }

//           }}
//         />
//           <DashboardBtnList
//             label={`${t("dashboards.alldashboardkeys.tablekeys.partialpay")} (${comaSeperated(viewData?.installments_amount)}) `}
//             className="order-pay-btn mt-1 mb-1 w-100"
//             onClick={async () => {
//               const data = await dispatch(
//                 pharmacyPay({ id: viewData?.id, partial_payment: true }, true, viewData?.id)
//               );
//               if (data) {
//                 props?.history?.push("/lab-administrator", item);

//               }

//             }}
//           />
//         </>}

//     </>
//   ) : viewData?.paid_status?.name === "partial" ?
//     loader ? <Loader
//       height={20}
//       width={20}
//       type="Oval"
//       color="#1a4774"
//     /> : <DashboardBtnList
//       label={`${t("dashboards.alldashboardkeys.tablekeys.paybtn")} (${comaSeperated(viewData?.remaining_amount)}) `}

//       className="order-pay-btn w-100 mb-1"
//       onClick={async () => {
//         const data = await dispatch(
//           pharmacyPay({ id: viewData?.id, partial_payment: false }, true, viewData?.id)
//         );
//         if (data) {
//           props?.history?.push("/lab-administrator", item);

//         }
//         // if (viewData?.from) {
//         //   setviewData({ ...data, from: "administrator" });
//         // }
//         // else {
//         //   setviewData(data);
//         // }

//       }}
//     />
//     : null}
// </div>
// </div>

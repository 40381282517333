import React from "react";
const BannerWithText = (props) => {
  return (
    <>
      <div>
        <div
          className="jumbotron text-white jumbotron-image shadow"
          style={{
            backgroundImage: ` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)), url(${props?.imgSrc})`,
            objectFit: "contain",
            height: "390px",
            backgroundSize: "100% 390px",
          }}
        >
          <div className="mt-5 pt-5">
            <h1 style={{fontSize:'24px'}} className="mb-2 text-center banerWithTextHeading">
              {props?.heading}
            </h1>
            <p className="mb-4 text-center banerWithTextSubHeading">
              {" "}
              {props?.subHeading}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerWithText;

import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, logoutUser } from "../../../../Store/Actions/loginActions";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = (props) => {
  const {t} = useTranslation("common")
  const user = useSelector((state) => state?.logIn?.user);
  const loading = useSelector((state) => state?.logIn?.loader);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data?.oldpass === data?.newpass) {
      toast.error("Cannot Have New Password Same as Old Password");
    } else {
      const apiData = {
        id: user?.id,
        old_password: data?.oldpass,
        new_password: data?.newpass,
      };
      const res = await dispatch(changePassword(apiData));
      if (res === "true") {
        props.onHide();
        dispatch(logoutUser())
        props.history.push('/')
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="">{t("dashboards.alldashboardkeys.tablekeys.changepassword")}</span>
          </Modal.Title>
          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="">
              ×
            </span>
            <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.oldpassword")}</span>
                  <input
                    className="input-login-modal"
                    type="password"
                    {...register("oldpass", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.oldpass?.message ? (
                    <div className="text-error">{errors?.oldpass?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.newpassword")}</span>
                  <input
                    className="input-login-modal"
                    type="password"
                    {...register("newpass", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.newpass?.message ? (
                    <div className="text-error">{errors?.newpass?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-success mt-1  btn-block"
                disabled={loading}
              >
                {loading ? (
                  <Loader
                    height={20}
                    width={20}
                    type="ThreeDots"
                    color="black"
                  />
                ) : (
                  t("dashboards.alldashboardkeys.tablekeys.changepassword")
                )}
              </button>
              {/* <input
                type="submit"
                value={"Chnage Password"}
                className="btn btnSquare btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              /> */}
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;

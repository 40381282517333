import React from 'react'

const CardHandler = (
    {
        label,
        className,
        bntStyle,
        onClick,
        icon,
        carddoc
      }
) => {
    return (
        <>
        <div
     className={`${className} p-2 btn `}
     style={bntStyle}
     onClick={onClick}
   >
      {label}
   </div>
       </>
 
    )
}

export default CardHandler

import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCmscomponent } from "../../../../Store/Actions/cmsAction";
import { updateModalState } from "../../../../Store/Actions/staticActions";
import { filterComponentData } from "../../../../Utils/functions";
import HeaderBtn from "../../../ReUseableComponents/HeaderBtn";
import AddAppointments from "../../modals/addAppointment/AddAppointments";
import SignUpModal from "../../modals/signUpModal/SignUpModal";
import "./fixedRight.css";

const FixedRight = (props) => {
  const lang = useSelector((state) => state?.cms?.language);

  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const componetcms = useSelector((state) => state.cms.components);

  useEffect(() => {
    dispatch(getCmscomponent());
    // eslint-disable-next-line
  }, []);
  const siderfixed = filterComponentData(
    componetcms,
    "sidefixed_component",
    lang
  );
  const fixed_right_labels = filterComponentData(
    componetcms,
    "fixed_right_labels",
    lang
  );

  const [signUpModal, setSignUpModal] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);

  const userRole = useSelector((state) => state?.logIn?.userRole);

  const appointmentHandler = () => {
    if (!user) {
      setSignUpModal(true);
    } else if (user && userRole === "patient") {
      dispatch(updateModalState(true));
      props?.history?.push("/patient-dashboard", { key: "fixedRight" });
      // setAddAppointment(true);
    } else if (user && userRole !== "patient") {
      toast.error(t("fixedRight.modalsuccesstoast"));
    }
  };
  return (
    <div className="row no-gutters right-main d-none d-lg-block">
      <div className="right-item right-hover-active">
        <div className="emergency-data-refrence">
          <i className="fas fa-map-marker-alt right-icon"></i>
          <p className="right-para">
            {fixed_right_labels?.location
              ? fixed_right_labels?.location
              : t("fixedRight.location")}
          </p>
        </div>
        <div className="detail-right-div-map text-left">
          <div className="">
            <div className="google-map">
              <iframe
                // src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.7717004281494!2d90.37404911498146!3d23.75551928458687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b95aa54c30fd%3A0x252f9c01448a39f1!2sDMFR%20Molecular%20Lab%20%26%20Diagnostics!5e0!3m2!1sen!2sin!4v1615553867806!5m2!1sen!2sin"}
                src={
                  siderfixed[0]?.maplink
                    ? siderfixed[0]?.maplink
                    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.7717004281494!2d90.37404911498146!3d23.75551928458687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b95aa54c30fd%3A0x252f9c01448a39f1!2sDMFR%20Molecular%20Lab%20%26%20Diagnostics!5e0!3m2!1sen!2sin!4v1615553867806!5m2!1sen!2sin"
                }
                width="100%"
                height="100%"
                style={{ border: "0", padding: "10px" }}
                allowFullScreen
                loading="lazy"
                title="google Map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="right-item right-hover-active">
        <div className="emergency-data-refrence">
          <i className="far fa-clock right-icon"></i>
          <p className="right-para">
            {fixed_right_labels?.workingtime
              ? fixed_right_labels?.workingtime
              : t("fixedRight.workingtime")}
          </p>
        </div>
        <div className="detail-right-div-emergency text-left">
          <div className="px-5 py-4">
            <div className="d-flex flex-row">
              <i
                className="far fa-clock mt-1 mr-2"
                style={{ fontSize: "20px", color: "#1a4774" }}
              ></i>
              <h5 className="gray-headin">
                {siderfixed[0]?.worktimehead
                  ? siderfixed[0]?.worktimehead
                  : t("fixedRight.workingtime1")}
              </h5>
            </div>
            <div className="py-2 d-flex justify-content-between">
              <span>
                {siderfixed[0]?.worktimehead
                  ? siderfixed[0]?.whead
                  : t("fixedRight.everyday")}
              </span>
              <span>
                {siderfixed[0]?.worktimehead
                  ? siderfixed[0]?.time
                  : "08:00 AM - 11:00 PM"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="right-item" onClick={appointmentHandler}>
        <i className="fas fa-address-book right-icon"></i>
        <p className="right-para">
          {fixed_right_labels?.appoitment
            ? fixed_right_labels?.appoitment
            : t("fixedRight.requestform")}
        </p>
      </div>
      <div className="right-item right-hover-active">
        <div className="emergency-data-refrence">
          <i className="fas fa-phone-alt right-icon"></i>
          <p className="right-para">
            {fixed_right_labels?.energency
              ? fixed_right_labels?.energency
              : t("fixedRight.emergencycase")}
          </p>
        </div>
        <div className="detail-right-div-emergency text-left">
          <div className="px-5 py-4">
            <h5 className="gray-headin">
              {fixed_right_labels?.energency
                ? fixed_right_labels?.energency
                : t("fixedRight.emergencycase1")}
            </h5>
            <div className="py-2">
              <i
                className="fas fa-phone-alt mr-2"
                style={{ color: "#1a4774" }}
              ></i>
              <a className="light-Color-Para" href={"tel:09606213233"}>
                {siderfixed[0]?.number ? siderfixed[0]?.number : "09606213233"}
              </a>
            </div>
            <div className="py-2 ">
              <i
                className="fas fa-envelope mr-2"
                style={{ color: "#1a4774" }}
              ></i>
              <a href="mailto:info@mdxdmfr.com" className="light-Color-Para">
                {siderfixed[0]?.email
                  ? siderfixed[0]?.email
                  : "info@mdxdmfr.com"}
              </a>
            </div>
            <div className="btn-position-emergency-call pt-4">
              <a
                href={"tel:09606213233"}
                className="headerBtn-red"
                style={{
                  fontWeight: 400,
                  textDecoration: "none",
                  marginRight: "5px",
                }}
              >
                {siderfixed[0]?.callbtn
                  ? siderfixed[0]?.callbtn
                  : t("fixedRight.callbtntext")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <SignUpModal
        show={signUpModal}
        onHide={() => setSignUpModal(!signUpModal)}
        {...props}
      />
      {/* <AddAppointments
          show={addAppointment}
          onHide={() => setAddAppointment(!addAppointment)}
          {...props}
        /> */}
    </div>
  );
};

export default FixedRight;

import { signInConstansts } from "../Constants/loginConstant";
const initialState = {
  showSignUpModal: false,
  user: null,
  error: false,
  loader: false,
  emailModal: false,
  phoneModal: false,
  userRole: "",
  addPhoneModal: false,
  userPhoneNum: "",
};
export const logInReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case signInConstansts.SIGN_IN:
      return { ...state, user: payload };
    case signInConstansts.SET_LOADER:
      return { ...state, loader: payload };
    case signInConstansts.EMAIL_MODAL:
      return { ...state, emailModal: payload };
    case signInConstansts.ADD_PHONE_MODAL:
      return { ...state, addPhoneModal: payload };
    case signInConstansts.USER_TYPE:
      return { ...state, userRole: payload };
    case signInConstansts.PHONE_MODAL:
      return { ...state, phoneModal: payload };
    case signInConstansts.GET_USER_FROM_LOCAL:
      return { ...state, user: payload };
    case signInConstansts.EMAIL_VERIFY:
      return { ...state, user: payload };
    case signInConstansts.Number_VERIFY:
      return { ...state, user: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    case signInConstansts.SET_ERROR:
      return { ...state, error: payload };
    case signInConstansts.GOOGLE_USER_LOGIN:
      return { ...state, user: payload };
    case signInConstansts.USER_PHONE_NUMBER:
      return { ...state, userPhoneNum: payload };
    default:
      return state;
  }
};

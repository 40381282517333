import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { emptyString } from "../../Utils/functions";
// import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";

const PastAppointments = (props) => {
  const { t, i18n } = useTranslation("common")
  const loader = useSelector((state) => state?.logIn?.loader);
  const appointment = useSelector((state) => state?.patient?.appointments);

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">

            {t("dashboards.doctordashboard.appointment.todayappointment.mainheading3")}

          </h4>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.date"),
              },
              { dataField: "patient_details.name", text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.patientname") },
              { dataField: "condition.name", text: "Condition" },

              // {
              //   formatter: (cell, row, index) => {
              //     return (
              //       <span>
              //         {emptyString(row.description) ? "N/A" : row.description}
              //       </span>
              //     );
              //   },
              //   text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.description"),
              // },
              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#1a4774' }}>{row?.status?.name == "booked" ? "CANCELLED":row?.status?.name?.toUpperCase()}</span>;
                },
                text: "Status",
              },
              {
                formatter: (cell, row) => {
                  return (
                    row?.status?.name == "booked" ? <div className="row">
                      <div className="col ">
                        <button
                          className="dashboardBtnList-item-disbaled w-100"
                          disabled
                        >View</button>
                      </div>
                    </div> :
                      <>
                        <div className="row">
                          <div className="col ">
                            <DashboardBtnList
                              label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.view")}
                              className="order-pay-btn"
                              onClick={() => {
                                props.history.push("/appointment-details", { row, from: "past" });
                              }}
                            />
                          </div>
                        </div>
                      </>
                  );
                },
                text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.action"),
              },
            ]}
            tabledata={appointment?.past?.length > 0 ? appointment?.past : []}
          />
        )}
      </div>
    </div>
  );
};

export default PastAppointments;

import { signInConstansts } from "../Constants/loginConstant";
import { pharmacyConstants } from "../Constants/pharmacyConstants";

const initialState = {
  medicines: [],
  orders: [],
  selectedMedi: {},
  deliveryStaff: [],
};
export const pharmacyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case pharmacyConstants.GET_ALL_MEDICINES:
      return { ...state, medicines: payload };
    case pharmacyConstants.GET_ALL_ORDERS:
      return { ...state, orders: payload };
    case pharmacyConstants.SET_SELECTED_MEDI:
      return { ...state, selectedMedi: payload };
    case pharmacyConstants.GET_ALL_DELIVERYSTAAF:
      return { ...state, deliveryStaff: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

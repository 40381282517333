import React, { useEffect } from "react";
import apiServices from "../requestHandler";

const ChatIframe = (props) => {
  const { user_id, appointment_id, board, name } = props

  useEffect(() => {
    if (board) {
      apiServices.sendChatNotificationDoctorsBoard({
        user_id: user_id,
        board_id: appointment_id,
      })
    } else {
      apiServices.sendChatNotificationAppointment({
        user_id: user_id,
        appointment_id: appointment_id,
      })
    }
  }, [user_id, appointment_id, board, name])

  return (
    <div className="row " style={{ height: '90vh' }}>
      <div className="container center-childrens ">
        <div className="col-lg-10 col-md-12 containerWithShadow center-childrens py-3 p-0" style={{ height: '80vh' }}>
          <iframe
            className="chat-frame mt-5"
            // src={`http://localhost:3001/chat/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`}
            src={`https://mdxdmfr.com/chat/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`}
            // src={`https://ripple-chat-app-frontend.herokuapp.com/chat?name=${user_id}&room=${appointment_id}&friendlyName=${name}`}
            // src={`https://ripple-chat-app-frontend.herokuapp.com/?channel_id=${appointment_id}&user_id=${user_id}&doctors_board=${board}`}
            title="chatIframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ChatIframe;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const BootstrapCustomTable = (props) => {
  const limit = props?.tabledata?.length;
  const sorted = props?.defaultsort;
  const { SearchBar } = Search;
  return (
    <>
      <div className="p-3 py-1 w-100  mb-5 containerWithShadow">
        <ToolkitProvider
          keyField="id"
          data={props?.tabledata}
          columns={props?.columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable

                {...props.baseProps}
                defaultSorted={sorted}
                noDataIndication={() => <div className="text-center pt-4"><h3 style={{ color: 'lightgray' }}>No Data To Show</h3></div>}
                pagination={limit > 10 ? paginationFactory() : null}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default BootstrapCustomTable;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import { fetchStaticData } from "../../Store/Actions/staticActions";
import AppointmentProfile from "./AppointmentProfile";
import MyDoctors from "./MyDoctors";
import OrderProfile from "./OrderProfile";
import PatientMedicalRecords from "./PatientMedicalRecords";
import PatientProfile from "./PatientProfile";
import PaymentProfile from "./PaymentProfile";
import PrescriptionProfile from "./PrescriptionProfile";
import PurchasedPackages from "./PurchasedPackages";
import ReportsProfile from "./ReportsProfile";
const PatientDashboard = (props) => {
  const { t, i18n } = useTranslation("common");
  const requestAppointment = useSelector(
    (state) => state.static.requestAppointment
  );
  const [appointmentModal, setAppointmentModal] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);
  // const userRole = useSelector((state) => state?.logIn?.userRole);

  const [selectedTab, setSelectedTab] = useState(
    t("dashboards.alldashboardkeys.tablekeys.profile")
  );
  const dispatch = useDispatch();
  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  const FromView = props?.location?.state?.key;
  useEffect(() => {
    if (FromView === "fixedRight" && requestAppointment) {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.myappointment"));
    }
    if (FromView === "FromViewAppointment") {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.myappointment"));
    } else if (FromView === "fromPrescription") {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.myprescription"));
    } else if (FromView === "fromOrderDetails") {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.myorder"));
    } else if (FromView === "doctorDetails") {
      setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.mydoctor"));
    }
    dispatch(fetchStaticData());
  }, [FromView, user, requestAppointment]);
  return (
    <>
      <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
      <div style={{ backgroundColor: "#f9f9f9" }} className="pb-5">
        <DashBoardMainHading
          title={t("dashboards.alldashboardkeys.patientmainheading1")}
        />
        <div className="container">
          {[
            t("dashboards.alldashboardkeys.tablekeys.profile"),
            t("dashboards.alldashboardkeys.tablekeys.myappointment"),
            t("dashboards.alldashboardkeys.tablekeys.myorder"),
            t("dashboards.alldashboardkeys.tablekeys.myprescription"),
            t("dashboards.alldashboardkeys.tablekeys.mypayment"),
            t("dashboards.alldashboardkeys.tablekeys.myreport"),
            t("dashboards.alldashboardkeys.tablekeys.mydoctor"),
            t("dashboards.alldashboardkeys.tablekeys.pakages"),
            "My Medical Record",
          ].map((item, index) => (
            <div
              className="d-flex d-inline-flex col-xl-2 col-lg-3 col-md-6  mt-2 px-0 pr-3 "
              key={index + 1}
              onClick={() => tabHandler(item)}
            >
              <DashboardBtnList
                label={item}
                labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                className={
                  selectedTab === item
                    ? "dashboardBtnList-item-active fontsizecus py-2"
                    : "default-color-and-hover fontsizecus py-2"
                }
              />
            </div>
          ))}
          {selectedTab ===
          t("dashboards.alldashboardkeys.tablekeys.profile") ? (
            <PatientProfile />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.myappointment") ? (
            <AppointmentProfile
              setAppointmentModal={setAppointmentModal}
              appointmentModal={appointmentModal}
              FromView={FromView}
              {...props}
            />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.myorder") ? (
            <OrderProfile {...props} />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.myprescription") ? (
            <PrescriptionProfile {...props} />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.mypayment") ? (
            <PaymentProfile />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.myreport") ? (
            <ReportsProfile />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.mydoctor") ? (
            <MyDoctors {...props} />
          ) : selectedTab ===
            t("dashboards.alldashboardkeys.tablekeys.pakages") ? (
            <PurchasedPackages {...props} />
          ) : selectedTab === "My Medical Record" ? (
            <PatientMedicalRecords {...props} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PatientDashboard;

/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SlotsModal from "../../Components/LayOuts/modals/SlotModal/SlotsModal";
import ActionBtn from '../../Components/ReUseableComponents/ActionBtn'
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import {
  acceptAppointmentAction,
  getdoctorRescheduleRequest,
  rejectAppointmentAction,
} from "../../Store/Actions/administratorActions";

const AdministratorDoctorReschedule = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const doctorRequests = useSelector(
    (state) => state?.administrator?.doctorRequests
  );


  // const reversedata = doctorRequests?.reverse()
  // console.log(doctorRequests,"reversedata");
  useEffect(() => {
    dispatch(getdoctorRescheduleRequest());
    // eslint-disable-next-line
  }, []);

  let [show, setShow] = useState(false);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const [currentSelectid, setCurrentSelectid] = useState("");
  const [currentSelectedrequstid, setCurrentSelectedrequstid] = useState("");
  const handleChange = (value, item) => {
    if (value === "Approve") {
      setCurrentSelectid(item?.requested_by?.id);
      setCurrentSelectedrequstid(item?.id);
      showModal();
    } else if (value === "Decline") {
      dispatch(
        rejectAppointmentAction({
          id: item?.id,
          status: { id: 3, name: "rejected" },
        })
      )
    }
    // props.history.push('',item)
  };
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.allrequest")}</h4>
        </div>
      </div>

      <div className="row">
        <BootstrapCustomTable
          columns={[
            {
              formatter: (cell, row, index) => {
                return <span>{index + 1}</span>;
              },
              text: "Sr.",
            },
            { dataField: "description", text: t("dashboards.alldashboardkeys.tablekeys.reason") },

            { dataField: "requested_by.name", text: t("dashboards.alldashboardkeys.tablekeys.requestbyname") },
            { dataField: "requested_by.email_address", text: t("dashboards.alldashboardkeys.tablekeys.requestbyemail") },
            {
              formatter: (cell, row, index) => {
                return <span style={{ color: row?.status?.name === 'approved' ? 'green' : 'red' }}>{row?.status?.name?.toUpperCase()}</span>
              },
              text: t("dashboards.alldashboardkeys.tablekeys.status"),
            },
            {
              formatter: (cell, row) => {
                if (row.status.name === "pending") {

                  return (
                    <>
                      {/* <DashboardBtnList
                          label={"Approve"}
                          className="order-pay-btn"
                        /> */}
                      <div className="container">

                        <div className="row">

                          <ActionBtn
                            options={[
                              t("dashboards.alldashboardkeys.tablekeys.approve"),
                              t("dashboards.alldashboardkeys.tablekeys.decline")
                            ]}
                            handleChange={handleChange}
                            data={row}

                          />




                        </div>
                      </div>

                      {/* <DashboardBtnList
                          label={"Decline"}
                          className="appointment-reschdule-btn"
                        /> */}
                    </>
                  );

                }
                else {

                  return (
                    <>
                    </>
                  );

                }

              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={doctorRequests}
          defaultsort={[
            { dataField: "status.name", order: 'desc' }
          ]}

        />
      </div>

      <SlotsModal
        show={show}
        onHide={hideModal}
        data={currentSelectid}
        data1={currentSelectedrequstid}
        {...props}
      ></SlotsModal>
    </div>
  );
};

export default AdministratorDoctorReschedule;

import React from "react";
import { Modal } from "react-bootstrap";

const HomeVIdeoModal = (props) => {
  const videolink = props?.data?.link
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="popUpModal"
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#476b8f",
            padding: "5px",
            borderBottom: "none",
          }}
        >
          <iframe
            src={videolink ? videolink : "https://drive.google.com/file/d/15Q7XCvwvHC-V8Kqh3UJEQIsfbCshkg5L/preview"}
            width={740}
            height={380}
            allow={"autoplay"}
            title={"home-video"}
          ></iframe>
        </Modal.Header>
        {/* <Modal.Body  >
        </Modal.Body> */}
      </Modal>
    </>
  );
};

export default HomeVIdeoModal;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import {
    pharmacyOrderDispatch,
    pharmacyPay,
} from "../../Store/Actions/pharmacyActions";
import DetailTable from "../../Dashboards/patientDashboard/ProfileTable";
// import orderImage from "../../statics/ewmimages/patient-assets/sell-selling.png";
import AssignDeliveryBoy from "../../Components/LayOuts/modals/assignRiderModal/AssignRiderModal";
import { comaSeperated } from "../../Utils/functions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
const PatientOrderDetail = (props) => {
    const { t } = useTranslation("common");

    const [show, setShow] = useState(false);
    const loader = useSelector((state) => state?.logIn?.loader);

    const showModal = () => setShow(!show);
    const [currentOrder, setCurrentOrder] = useState(null);

    const viewData = props.location.state;
    const item = { key: "fromOrderDetails" };
    const [tabledata, settabledata] = useState([]);
    const dispatch = useDispatch();
    const finalDataArray = (viewData) => {
        let dataArray = [];
        viewData?.medicines?.map((item) =>
            dataArray.push({
                type: "medicines",
                price: `${item?.medicine?.sales_price} BDT`,
                name: item?.medicine?.name,
                quantity: item?.medicine?.quantity,
            })
        );
        viewData?.packages?.map((item) =>
            dataArray.push({ type: "packages", price: item?.package?.price, name: item?.package?.name, quantity: item?.package?.tests?.length })
        );
        viewData?.tests?.map((item) =>
            dataArray.push({ type: "tests", price: item?.test?.price, name: item?.test?.name, quantity: item?.quantity })
        );
        dataArray.push({
            type: "",
            name: "",
            price: (
                <>
                    <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.alldashboardkeys.tablekeys.grandtotal")}
                    </span>
                    : <span>{viewData?.total_amount}</span>
                    {" bdt"}
                </>
            ),
        });
        settabledata(dataArray);
    };
    const getTableData = useCallback(() => {
        finalDataArray(viewData);
    }, [viewData]);
    useEffect(() => {
        getTableData();
    }, [getTableData]);
    const pharmacyPartialPay = async () => {
        let res = await dispatch(
            pharmacyPay({ id: viewData?.id, partial_payment: true })
        );
        if (res) {
            props.history.push("/administrator");
        }
    };
    const pharmacyFullPay = async () => {
        let res = await dispatch(
            pharmacyPay({ id: viewData?.id, partial_payment: false })
        );
        if (res) {
            props.history.push("/administrator");
        }
    };
    return (
        <>
            <div style={{ backgroundColor: "#f9f9f9" }}>
                <DashBoardMainHading
                    title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
                // image={orderImage}
                />
                <div className="container ">
                    <div className="row center-childrens">
                        <div
                            className="col-xl-8 col-md-8 col-12 px-0 mb-3
          "
                        >
                            <div
                                className="btn edit-patient-btn "
                                style={{ width: "150px" }}
                                onClick={() => {
                                    props.history.push("/administrator", item);
                                }}
                            >
                                <i className="fas fa-arrow-left"></i>
                                {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                            </div>
                        </div>
                    </div>
                    <div className="row center-childrens mt-3 pb-5">
                        <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                            <div className="d-flex justify-content-between">
                                <h5>
                                    {t("dashboards.alldashboardkeys.tablekeys.customerdetail")}
                                </h5>
                                <p
                                    style={{
                                        color:
                                            viewData?.paid_status?.name === "unpaid"
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                    Status: {viewData?.paid_status?.name?.toUpperCase()}
                                    <br />
                                    <span
                                        style={{
                                            color:
                                                viewData?.delivery_status?.name !== "dispatched"
                                                    ? "red"
                                                    : "green",
                                        }}
                                    >
                                        <span style={{ color: "black" }}>
                                            {t("dashboards.alldashboardkeys.tablekeys.deliverystatus")}:
                                        </span>{" "}
                                        {viewData?.delivery_status?.name?.toUpperCase()}
                                    </span>
                                    <br />
                                    <span


                                    >

                                        <span style={{ color: "black" }}>

                                            {t("dashboards.alldashboardkeys.tablekeys.assignmentstatus")}:

                                        </span>{" "}

                                        <span style={{ color: viewData?.is_assigned ? 'green' : 'red' }}>{viewData?.is_assigned ? viewData?.delivery_boy?.name?.toUpperCase() : "NOT ASSIGNED"}</span>

                                    </span>

                                    <br />
                                </p>
                            </div>
                            <ul style={{ listStyle: "none" }}>
                                <li>
                                    <span
                                        className="light-Color-Para"
                                        style={{ fontSize: "12px", fontWeight: "500" }}
                                    >
                                        {t("dashboards.alldashboardkeys.tablekeys.customername")}
                                    </span>
                                    <p>{viewData?.ordered_by?.name}</p>
                                </li>
                                <li>
                                    <span
                                        className="light-Color-Para"
                                        style={{ fontSize: "12px", fontWeight: "500" }}
                                    >
                                        {t("dashboards.alldashboardkeys.tablekeys.customernumber")}
                                    </span>
                                    <p>{viewData?.ordered_by?.phone_number}</p>
                                </li>
                                <li>
                                    <span
                                        className="light-Color-Para"
                                        style={{ fontSize: "12px", fontWeight: "500" }}
                                    >
                                        {t("dashboards.alldashboardkeys.tablekeys.customeremail")}
                                    </span>
                                    <p>{viewData?.ordered_by?.email_address}</p>
                                </li>
                            </ul>
                            <h5>{t("dashboards.alldashboardkeys.tablekeys.orderdetail")}</h5>
                            {

                                <DetailTable
                                    tableHead={[
                                        t("dashboards.alldashboardkeys.tablekeys.type"),
                                        t("dashboards.alldashboardkeys.tablekeys.name"),
                                        t("dashboards.alldashboardkeys.tablekeys.quantity"),
                                        t("dashboards.alldashboardkeys.tablekeys.price"),
                                    ]}
                                    data={tabledata?.map((item) => {
                                        return [
                                            item?.type,
                                            item?.name,
                                            item?.quantity,
                                            item?.price,
                                        ];
                                    })}
                                />
                            }
                            {viewData?.paid_status?.name == "partial" ? (
                                <>
                                    <ul style={{ float: "right", listStyle: "none" }}>
                                        <li>
                                            <span
                                                className=""
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#787878",
                                                }}
                                            >
                                                {t("dashboards.alldashboardkeys.tablekeys.totalamount")}
                                                :
                                            </span>

                                            <span style={{ fontWeight: "bold" }}>
                                                {comaSeperated(viewData?.total_amount)}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className="light-Color-Para"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#787878",
                                                }}
                                            >
                                                {t("dashboards.alldashboardkeys.tablekeys.paidamount")}
                                                :
                                            </span>

                                            <span style={{ fontWeight: "bold" }}>
                                                {comaSeperated(viewData?.paid_amount)}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className="light-Color-Para"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#787878",
                                                }}
                                            >
                                                {t(
                                                    "dashboards.alldashboardkeys.tablekeys.remainingamount"
                                                )}
                                                :
                                            </span>

                                            <span style={{ fontWeight: "bold" }}>
                                                {comaSeperated(viewData?.remaining_amount)}
                                            </span>
                                        </li>
                                        {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}
                                    </ul>
                                </>
                            ) : viewData?.paid_status?.name == "paid" ? (
                                <ul style={{ float: "right", listStyle: "none" }}>
                                    <li>
                                        <span
                                            className="light-Color-Para"
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#787878",
                                            }}
                                        >
                                            {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :
                                        </span>

                                        <span style={{ fontWeight: "bold" }}>
                                            {comaSeperated(viewData?.total_amount)}
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            className="light-Color-Para"
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#787878",
                                            }}
                                        >
                                            {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :
                                        </span>

                                        <span style={{ fontWeight: "bold" }}>
                                            {comaSeperated(viewData?.paid_amount)}
                                        </span>
                                    </li>
                                </ul>
                            ) : viewData?.paid_status?.name == "unpaid" ? (
                                <>
                                    <ul style={{ float: "right", listStyle: "none" }}>
                                        <li>
                                            <span
                                                className="light-Color-Para"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#787878",
                                                }}
                                            >
                                                {t("dashboards.alldashboardkeys.tablekeys.totalamount")}
                                                :
                                            </span>

                                            <span style={{ fontWeight: "bold" }}>
                                                {comaSeperated(viewData?.total_amount)}
                                            </span>
                                        </li>

                                        <li>
                                            <span
                                                className="light-Color-Para"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#787878",
                                                }}
                                            >
                                                {t(
                                                    "dashboards.alldashboardkeys.tablekeys.installmentamount"
                                                )}
                                                :
                                            </span>

                                            <span style={{ fontWeight: "bold" }}>
                                                {comaSeperated(viewData?.installments_amount)}
                                            </span>
                                        </li>
                                    </ul>
                                </>
                            ) : null}
                            <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end">
                                {viewData?.paid_status?.name == "unpaid" && viewData?.delivery_status?.name != "cancelled" ? (
                                    loader ? (
                                        <Loader
                                            height={20}
                                            width={20}
                                            type="Oval"
                                            color="#970165"
                                        />
                                    ) : (
                                        <>
                                            <div
                                                className="btn order-pay-btn mt-3 mr-1"
                                                style={{ color: "#fff", cursor: "pointer" }}
                                                onClick={pharmacyFullPay}
                                            >
                                                {`${"Pay"}  (${comaSeperated(viewData?.total_amount)}) `}
                                            </div>
                                            <div
                                                className="btn order-pay-btn mt-3 mr-1"
                                                style={{ color: "#fff", cursor: "pointer" ,display:viewData?.type == "test" || viewData?.type == "medicine" ? 'none':'block' }}
                                                onClick={pharmacyPartialPay}
                                            >
                                                {`${"Partial Pay"}  (${comaSeperated(viewData?.installments_amount)}) `}
                                            </div>
                                        </>
                                    )
                                ) : viewData?.paid_status?.name == "paid" &&
                                    viewData?.delivery_status?.name == "processing" ? (
                                    <></>
                                ) : viewData?.paid_status?.name == "partial" ? (
                                    loader ? (
                                        <Loader
                                            height={20}
                                            width={20}
                                            type="Oval"
                                            color="#970165"
                                        />
                                    ) : (
                                        <div
                                            className="btn order-pay-btn mt-3 mr-1"
                                            style={{ color: "#fff" }}
                                            onClick={pharmacyFullPay}
                                        >
                                            {`${"Pay"}  (${comaSeperated(viewData?.remaining_amount)}) `}
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AssignDeliveryBoy
                show={show}
                handleClose={showModal}
                data={viewData}
                history={props?.history}
            />
        </>
    );
};

export default PatientOrderDetail;
// (viewData?.delivery_status?.name === "confirmed" ||
//                   viewData?.delivery_status?.name === "processing") &&

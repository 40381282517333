import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import { getadministratorPatients } from "../../Store/Actions/administratorActions";

const AdministrationPatientsList = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const administratorPatients = useSelector(
    (state) => state?.administrator?.patients
  );

  useEffect(() => {
    dispatch(getadministratorPatients());
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.allpatients")}</h4>
        </div>
      </div>

      <div className="row">
        <BootstrapCustomTable
          columns={[
            {
              formatter: (cell, row, index) => {
                return <span>{index + 1}</span>;
              },
              text: "Sr.",
            },
            { dataField: "patient_id", text: "Id" },


            { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.patientname") },
            { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.emailaddress") },


            { dataField: "gender.name", text: t("dashboards.alldashboardkeys.tablekeys.gender") },




            {
              formatter: (cell, row) => {
                return (
                  <>
                    <div className="row">
                      <div className="col ">
                        <DashboardBtnList
                          label={t("dashboards.alldashboardkeys.tablekeys.managereports")}
                          className="order-pay-btn"
                          onClick={() => {
                            props?.history?.push("/manage-reports", row);
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              },
              text: t("dashboards.alldashboardkeys.tablekeys.action"),
            },
          ]}
          tabledata={administratorPatients}
        />
      </div>
    </div>
  );
};

export default AdministrationPatientsList;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddInstallmentAmount from "../../Components/LayOuts/modals/installmentAmonutModal/AddInstallmentAmount";
import ReturnRequest from "../../Components/LayOuts/modals/returnRequestModal/ReturnRequest";
import UploadReportModal from "../../Components/LayOuts/modals/uploadReportModal/UploadReportModal";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
// import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import {
  // changeResportStatus,
  getTestResports,
} from "../../Store/Actions/labAdminActions";
import { generatePayment } from "../../Store/Actions/patientActions";
import { fetchStaticData } from "../../Store/Actions/staticActions";
import { checkPaidOrUnpaid, comaSeperated } from "../../Utils/functions";
// import { pharmacyPay } from "../../Store/Actions/pharmacyActions";
import ProfileTable from "./ProfileTable";

const ViewOrderDetails = (props) => {
  const { t } = useTranslation("common");
  const [viewData, setviewData] = useState(props.location.state);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState({})

  const [show, setShow] = useState(false)
  const [showInstallmentModal, setShowInstallmentModal] = useState(false)


  const item = { key: "fromOrderDetails" };
  const [openuploadModal, setopenuploadModal] = useState(false);
  // const [selectedReport, setSelectedReport] = useState("");
  const loader = useSelector((state) => state?.patient?.paymentLoader);
  const { staticData } = useSelector((state) => state?.static);
  const selectedReport = "";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestResports());
    dispatch(fetchStaticData())
  }, [dispatch]);

  const paymentHandler = async (data) => {
    let apiData = {
      id: data?.id,
      type: "order",
      partial_payment: false
    };
    const res = await dispatch(generatePayment(apiData))
    if (res == false) {
      return;
    }
    window.open(res);
    window.focus();
  };
  const payByCash = async (data) => {
    let apiData = {
      id: data?.id,
      type: "order",
      partial_payment: false
    };
    const res = await dispatch(generatePayment(apiData))
    props.history.push('/patient-dashboard', { key: 'fromOrderDetails' })
  };
    
  const partialPaymentHandler = async (data) => {
    let test = 0
    if (staticData?.max_installments == data?.no_of_installments) {
      test = staticData?.max_installments
    } else {
      test = staticData?.max_installments - 1

    }

    if (data?.paid_status?.name == "unpaid") {

      let apiData = {
        id: data?.id,
        type: "order",
        partial_payment: true
      };
      const res = await dispatch(generatePayment(apiData))
      if (res == false) {
        return;
      }
      window.open(res);
      window.focus();
      props.history.push("/patient-dashboard")

    } else if (data?.paid_status?.name == "partial" && data?.no_of_installments != test) {
      setShowInstallmentModal(true)

    } else if (data?.no_of_installments == test) {
      let apiData = {
        id: data?.id,
        type: "order",
        partial_payment: true
      };
      const res = await dispatch(generatePayment(apiData))
      if (res == false) {
        return;
      }
      window.open(res);

      window.focus();
      props.history.push("/patient-dashboard")

    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading
          title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
        />
        <div className="container">
          <div className="row center-childrens">
            <div className="col-xl-8 col-md-8 col-12">
              <div
                className="btn edit-patient-btn"
                style={{ width: "150px" }}
                onClick={() => {
                  props?.history?.push("/patient-dashboard", item);
                }}
              >
                <i className="fas fa-arrow-left"></i>
                {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
              </div>
            </div>
          </div>
          <div className="row center-childrens mt-3  pb-5">
            <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
              <div className="d-flex justify-content-between">
                <h5>
                  {t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
                </h5>
                <div>

                  <div>
                    <p>
                      {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :{" "}
                      <strong
                        style={{
                          color:
                            checkPaidOrUnpaid(viewData?.paid_status?.name)
                        }}
                      >
                        {viewData?.paid_status?.name?.toUpperCase()}
                      </strong>
                    </p>
                  </div>
                  {viewData?.paid_status?.name === "unpaid" && viewData?.delivery_status?.name != "cancelled" ? (
                    loader ? <Loader type="Oval" height="40px" width="20px" className="text-center" color="green" /> :
                      <>
                        <DashboardBtnList
                          label={t("dashboards.alldashboardkeys.tablekeys.paybtn")}
                          className="order-pay-btn"
                          onClick={() => paymentHandler(viewData)}
                        />
                        <DashboardBtnList
                          label={
                            "Pay By Cash"
                          }
                          className="order-pay-btn mt-1"
                          onClick={() => payByCash(viewData)}
                        />
                        {
                          viewData?.type == "package" ?
                            <DashboardBtnList

                              // label={`${viewData?.no_of_installments} Partial Pay`}
                              label={t("dashboards.alldashboardkeys.tablekeys.partialpay")}
                              className="order-pay-btn mt-1"
                              onClick={() => {

                                partialPaymentHandler(viewData)
                              }}
                            /> : null
                        }
                      </>
                  ) : viewData?.paid_status?.name == "partial" && viewData?.delivery_status?.name != "cancelled" ?
                    loader ? <Loader type="Oval" height="40px" width="20px" className="text-center" color="green" /> :
                      viewData?.type == "package" && staticData.max_installments !== "undefined" ? <DashboardBtnList
                        // label={`Partial Pay`}
                        label={viewData.no_of_installments + 1 >= staticData.max_installments ? "Pay" : "Partial Pay"}
                        className="order-pay-btn mt-1"
                        onClick={() => {

                          partialPaymentHandler(viewData)
                        }}
                      /> :
                        <>
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.paybtn")}
                            className="order-pay-btn"
                            onClick={() => paymentHandler(viewData)}
                          />
                          <DashboardBtnList
                            label={
                              "Pay By Cash"
                            }
                            className="order-pay-btn mt-1"
                            onClick={() => payByCash(viewData)}
                          />
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.receipt")}
                            className="order-pay-btn mt-1"
                            onClick={() => {
                              window.open(viewData?.receipt_url, "_blank")

                            }}
                          />

                        </>
                    :
                    viewData?.receipt_url == "" ? null :
                      <div className="col-lg-12">

                        <DashboardBtnList
                          label={t("dashboards.alldashboardkeys.tablekeys.receipt")}
                          className="order-pay-btn"
                          href=""
                          onClick={() => {
                            window.open(viewData?.receipt_url, "_blank");
                            window.focus();
                          }


                          }

                        />
                      </div>
                  }
                </div>
              </div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                  </span>
                  <p>{viewData?.ordered_by?.name}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                  </span>
                  <p>{viewData?.ordered_by?.email_address}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.patientnumber")}
                  </span>
                  <p>{viewData?.ordered_by?.phone_number}</p>
                </li>
              </ul>
              <h5 className="mt-4">
                {" "}
                {t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
              </h5>
              <ul>
                {" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.ordernumber")}
                  </span>
                  <p>{viewData?.order_id}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.deliverystatus")}
                  </span>
                  <p>{viewData?.delivery_status?.name}</p>
                </li>
              </ul>
              {viewData?.tests?.length > 0 ? (
                <>
                  <h5>
                    {" "}
                    {t("dashboards.alldashboardkeys.tablekeys.testdetails")}
                  </h5>
                  <ProfileTable
                    tableHead={[

                      t("dashboards.alldashboardkeys.tablekeys.name")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.testid")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.quantity")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.price")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.action")

                    ]}
                    data={viewData?.tests?.map((item) => {
                      return [
                        item?.test?.name,
                        item?.test?.test_id,
                        item?.quantity,
                        item?.test?.price,
                        // eslint-disable-next-line
                        <a
                          className={
                            item?.test?.report?.status?.name === "report uploaded" && item?.test?.report?.url != ""
                              ? "btn btn-block order-pay-btn text-white"
                              : "btn btn-block btn-light  "
                          }
                          href={
                            item?.test?.report?.status?.name === "report uploaded" && item?.test?.report?.url != ""
                              ? item?.test?.report?.url
                              : null
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("dashboards.alldashboardkeys.tablekeys.viewreport")}

                        </a>,
                      ];
                    })}
                  />
                </>
              ) : null}
              {viewData?.medicines?.length > 0 ? (
                <>
                  <h5>{t("dashboards.alldashboardkeys.tablekeys.medicinedetail")}</h5>
                  <ProfileTable
                    tableHead={[
                      t("dashboards.alldashboardkeys.tablekeys.name")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.medicineid")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.quantity")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.price"),
                      viewData?.delivery_status?.name == "delivered" ? "Action" : "Action"

                    ]}
                    data={viewData?.medicines?.map((item) => {
                      return [
                        item?.medicine?.name,
                        item?.medicine?.medicine_id,
                        item?.medicine?.quantity,

                        item?.medicine?.sales_price,
                        viewData?.delivery_status?.name == "delivered" ? <Button
                          className="btn headerBtn-red w-100"
                          onClick={() => {
                            setData({
                              order_id: viewData?.id,
                              medicine_id: item?.medicine?.id

                            })
                            setShow(!show)

                          }}
                        >
                          {"Return"}
                        </Button> : null
                      ];
                    })}
                  />
                </>
              ) : null}
              {viewData?.packages?.length > 0 ? (
                <>
                  <h5>Package Details</h5>
                  <ProfileTable
                    tableHead={[
                      t("dashboards.alldashboardkeys.tablekeys.name"),
                      t("dashboards.alldashboardkeys.tablekeys.test")
                      ,
                      t("dashboards.alldashboardkeys.tablekeys.price")
                    ]}
                    data={viewData?.packages?.map((item) => {
                      return [
                        item?.package?.name,
                        item?.package?.tests?.map((item) => (
                          <li>{item?.name}</li>
                        )),
                        item?.package?.price,
                      ];
                    })}
                  />
                </>
              ) : null}

              {viewData?.paid_status?.name == "partial" ?
                <>
                  <ul style={{ float: 'right' }}>
                    <li>
                      <span className="light-Color-Para"
                        style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                      <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>
                    <li>
                      <span className="light-Color-Para"
                        style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.paidamount")} : </span>

                      <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.paid_amount)}</span></li>
                    <li>
                      <span className="light-Color-Para"
                        style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.remainingamount")} : </span>

                      <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.remaining_amount)}</span></li>
                    {/* <li>
                    <span className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }} >Installment Amount : </span>

                    <span style={{ fontWeight: 'bold' }}>{viewData?.installments_amount}</span></li> */}
                  </ul>

                </>
                : viewData?.paid_status?.name == "paid" ?
                  <ul style={{ float: 'right' }}>
                    <li>
                      <span className="light-Color-Para"
                        style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                      <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>
                    <li>
                      <span className="light-Color-Para"
                        style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.paidamount")} : </span>

                      <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.paid_amount)}</span></li>
                  </ul>
                  : viewData?.paid_status?.name == "unpaid" ?
                    <>
                      <ul style={{ float: 'right' }}>
                        <li>
                          <span className="light-Color-Para"
                            style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.totalamount")} : </span>

                          <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.total_amount)}</span></li>

                        {/* <li>
                          <span className="light-Color-Para"
                            style={{ fontSize: "16px", fontWeight: "500" }} >{t("dashboards.alldashboardkeys.tablekeys.installmentamount")} : </span>

                          <span style={{ fontWeight: 'bold' }}>{comaSeperated(viewData?.installments_amount)}</span></li>


 */}

                      </ul>

                    </>
                    : null}
            </div>
          </div>
        </div>
        {/* <UploadReportModal
        show={openuploadModal}
        onHide={() => setopenuploadModal(false)}
        viewData={selectedReport}
        {...props}
      /> */}
        <ReturnRequest
          show={show}
          orderData={data}
          onHide={() => setShow(false)}
          {...props}
        />
        <AddInstallmentAmount
          show={showInstallmentModal}
          orderData={viewData}
          onHide={() => setShowInstallmentModal(false)}
          {...props}
        />
      </div>
    </>
  );
};

export default ViewOrderDetails;
//  line # 176  viewData?.receipt_url == "" ? <DashboardBtnList
// label={t("dashboards.alldashboardkeys.tablekeys.paybtn")}
// className="order-pay-btn"
// onClick={() => paymentHandler(viewData)}
// /> :
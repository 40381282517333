import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";
const DeliveryPolicy = () => {
   const componetcms = useSelector((state) => state?.cms?.components);
   const lang = useSelector((state) => state?.cms?.language);
   const delivery_policy = filterComponentData(
    componetcms,
    "delivery_policy",
    lang
    
  );
  
  return (<>
    <Helmet>
      <title>Delivery Policy - DMFR Molecular Lab and Diagnostics</title>
    </Helmet>
    <div className="container" id="setpripol1">
      <div className="row">

       {delivery_policy.length<1?(<><div className="col-12 my-3">
       
            <h1
              className="text-center"
              style={{ color: "#1a4774", fontWeight: "600" }}
            >
              Delivery Policy
            </h1>
            <div className="text-start">
              <h1
                style={{ color: "#1a4774", fontWeight: "600" }}>
                Deliveries
              </h1>
            </div>
            <div className="text-start">

              <p> All items<span style={{fontWeight:700}}>&nbsp;(Medicine or Health related product)&nbsp;</span>are available
                for delivery within Dhaka Metropolitan City area only for a flat
                rate of Tk.100, except when special offers apply. We do not
                deliver any product beyond the Dhaka Metropolitan City area.</p>



              <p> Deliveries are dispatched, at the latest, on the next working day
                after the order has been placed, and you should receive your
                online order within two to five working days. Over 90% of our
                orders are delivered within two days of being shipped</p>



              <p>Once your order has been shipped you will be emailed notifying
                you of this. You can check the status of your order in your account
                area when you are logged in</p>



              <p>    Your order will be sent through Local courier. If you want to track
                your order after it’s been shipped, please feel free to contact
                them directly at <span style={{fontWeight:800,color:"#2dd16a"}}>&nbsp;09606213233 or 10649.</span> If you supplied your
                mobile number or email address during user registration or the
                checkout process, they will contact you by text or email on the
                morning of delivery anyway.</p>



              <p>In the unlikely event that an item <span style={{fontWeight:700}}>(Medicine or Health related
                product) </span>you have ordered is out of stock, we will notify you
                immediately to inform you of any possible delays.Please check
                the contents of your package on arrival. Should any items be
                damaged during shipment, it is important that you notify upon
                opening the package. Also, please keep all packaging materials
                from the shipment
              </p>


              <p>Please note that while we will do our best to deliver all orders as
                promptly as possible, we can’t guarantee on time delivery during
                Festival days (Eid-ul-Fitr and Eid-ul-Azha).Deliveries over the
                mentioned holiday time may be delayed due to local courier
                closures.
              </p>
            </div>




          </div>
        
        <div>
          <div className="text-start">
            <h1

              style={{ color: "#1a4774", fontWeight: "600" }}
            >
              Contact Details
            </h1>
          </div>
          <div className="text-start">
            <p>DMFR Molecular Lab & Diagnostics BD Ltd.<br/>
            <span>Telephone:<span style={{  fontWeight: "600" }}>09606213233 or 10649</span></span><br/>
            <span>Email:&nbsp;<a href="info@mdxdmfr.com">info@mdxdmfr.com</a></span></p>
          </div>
          <div className="text-start">
            <h1 style={{ color: "#1a4774", fontWeight: "600" }}>Payments</h1></div>
          <div className="text-start"><p>We accept Debit, Visa and MasterCard payments. Beside this we
            accept Mobile Banking and Net banking Payment also. All our
            payments are securely processed through Payment gateway</p></div>
        </div>
        </>):(<div id="cms-content">
        <div className="container">
          <h1 className="text-center mt-2" style={{ color: "#1a4774", fontWeight: "600" }}>Delivery Policy</h1>
        </div>
        <div
         dangerouslySetInnerHTML={{
                __html: delivery_policy?.description,
              }}>

        </div>
        </div>)}
      </div>
    </div>

  </>)
}
export default DeliveryPolicy;
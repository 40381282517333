import React from "react";

const BlueHeading = ({ heading , newcolor}) => {
  return (
    <div>
      <div className="col-12 text-center">
        <h1
          style={{
            fontWeight: "700",
            fontSize: "24px",
            marginBottom: "40px",
            color: newcolor || "#1a4774",
          }}
        >
          {heading}
        </h1>
      </div>
    </div>
  );
};

export default BlueHeading;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import Loader from "react-loader-spinner";
import {
  // getChatToken,
  videoCallToken,
} from "../../Store/Actions/staticActions";
import ProfileTable from "./ProfileTable";
import ChatIframe from "../../services/twillioChat/ChatIframe";
import { generatePayment } from "../../Store/Actions/patientActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { capitalize } from "twilio-video/es5/util";
const ViewAppointment = (props) => {
  // const viewData = props.location.state;
  const [viewData, setViewData] = useState(
    props.location.state.row ? props.location.state.row : props.location.state
  );
  const from = props.location.state?.from;
  const [check, setCheck] = useState(false);
  const { t, i18n } = useTranslation("common");

  const [loadingVideo, setloadingVideo] = useState(false);
  // const [loadingChat, setloadingChat] = useState(false);
  const loader = useSelector((state) => state?.patient?.paymentLoader);

  const [showchat, setshowchat] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);
  const item = { key: "FromViewAppointment" };
  const dispatch = useDispatch();
  const paymentHandler = async (data) => {
    let apiData = {
      id: data?.id,
      type: "appointment",
      partial_payment: false,
    };
    const res = await dispatch(generatePayment(apiData));
    if (res == false) {
      return;
    }
    window.open(res);
    window.focus();
  };
  const payByCash = async (data) => {
    let apiData = {
      id: data?.id,
      type: "appointment",
      partial_payment: false,
    };
    const res = await dispatch(generatePayment(apiData));
    props.history.push("/patient-dashboard");
  };

  const callHandler = async () => {
    setloadingVideo(true);
    const res = await dispatch(videoCallToken(false, { id: viewData?.id }));
    if (res === "true") {
      setloadingVideo(false);
      const win = window.open(`/call/${viewData?.id}`, "_blank");
      win.focus();
    } else {
      setloadingVideo(false);
    }
  };

  const chatHandler = async () => {
    setshowchat(true);
  };

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}
      />
      {showchat ? (
        <>
          <div className="container ">
            <div className="row ">
              <div className="col-xl-8 col-md-8 col-12 mx-5">
                <div
                  className="btn edit-patient-btn "
                  style={{ width: "150px" }}
                  onClick={() => {
                    setshowchat(false);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>
            <ChatIframe
              user_id={user?.id}
              appointment_id={viewData?.id}
              board={false}
              name={user?.name}
            />
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="row center-childrens">
              <div className="col-xl-8 col-md-8 col-12 ">
                <div
                  className="btn edit-patient-btn"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/patient-dashboard", item);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>{" "}
                  {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                </div>
              </div>
            </div>
            <div className="row center-childrens mt-3 pb-5">
              <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
                <div className="d-flex justify-content-between">
                  <h5>
                    {t("dashboards.alldashboardkeys.tablekeys.doctordetail")}
                  </h5>
                  <span>
                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")} :{" "}
                    <strong
                      style={{
                        color:
                          viewData?.status?.name === "booked" ? "red" : "green",
                      }}
                    >
                      {viewData?.status?.name === "booked" ? "UNPAID" : "PAID"}
                    </strong>
                  </span>
                </div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.datantime")}
                    </span>
                    <p>
                      {viewData?.date} @ {viewData?.slot?.time?.from} -{" "}
                      {viewData?.slot?.time?.to}{" "}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                    </span>
                    <p>{viewData?.doctor_details?.name}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                    </span>
                    <p>{viewData?.doctor_details?.email_address}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {"Department"}
                    </span>
                    <p>
                      {viewData?.doctor_details?.departments?.length < 1
                        ? "None"
                        : viewData?.doctor_details?.departments.map((item) => {
                            return <p>{item}</p>;
                          })}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      Condition
                    </span>
                    <p>{viewData?.condition?.name}</p>
                  </li>{" "}
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.speciality")}
                    </span>
                    <p>
                      {viewData?.doctor_details?.speciality.length < 1
                        ? "None"
                        : viewData?.doctor_details?.speciality.map(
                            (item, idx) => {
                             
                              return (
                                <span>
                                  {idx ==
                                  viewData?.doctor_details?.speciality?.length -
                                    1
                                    ? `${capitalize(item?.name)} `
                                    : `${capitalize(item?.name)}, `}
                                </span>
                              );
                            }
                          )}
                    </p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.consultationtype"
                      )}
                    </span>
                    <p>{viewData?.consultation_type?.name}</p>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      {"Description"}
                    </span>
                    <p>{viewData?.description}</p>
                  </li>
                  {/* <li>
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      value={check}
                      onChange={() => {
                        setCheck(!check);
                      }}
                    />
                    &nbsp;Partial{" "}

                    <br />
                    <br />
                  </li> */}
                </ul>
                {viewData?.status?.name == "booked" ? (
                  <div className="row">
                    <div className="col">
                      {viewData?.consultation_type?.name == "walk-in" ? (
                        loader ? (
                          <Loader
                            type="Oval"
                            height="40px"
                            width="20px"
                            className="text-center"
                            color="green"
                          />
                        ) : (
                          <div
                            className="row"
                            style={{
                              display:
                                from == "PastAppointment" &&
                                viewData?.status?.name == "booked"
                                  ? "none"
                                  : "",
                            }}
                          >
                            <div className="col-6">
                              <DashboardBtnList
                                label={
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? "Cancelled"
                                    : t("Pay By Cash")
                                }
                                className="order-pay-btn"
                                onClick={() => {
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? toast.error("Appointment is Cancelled")
                                    : payByCash(viewData);
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <DashboardBtnList
                                label={
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? "Cancelled"
                                    : t(
                                        "dashboards.alldashboardkeys.tablekeys.paybtn"
                                      )
                                }
                                className="order-pay-btn"
                                onClick={() => {
                                  from == "PastAppointment" &&
                                  viewData?.status?.name == "booked"
                                    ? toast.error("Appointment is Cancelled")
                                    : paymentHandler(viewData);
                                }}
                              />
                            </div>
                          </div>
                        )
                      ) : loader ? (
                        <Loader
                          type="Oval"
                          height="40px"
                          width="20px"
                          className="text-center"
                          color="green"
                        />
                      ) : (
                        <DashboardBtnList
                          label={
                            from == "PastAppointment" &&
                            viewData?.status?.name == "booked"
                              ? "Cancelled"
                              : t(
                                  "dashboards.alldashboardkeys.tablekeys.paybtn"
                                )
                          }
                          className="order-pay-btn"
                          onClick={() => {
                            from == "PastAppointment" &&
                            viewData?.status?.name == "booked"
                              ? toast.error("Appointment is Cancelled")
                              : paymentHandler(viewData);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : null}

                <div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    {from === "TodayAppointment" &&
                    viewData?.status?.name == "confirmed" ? (
                      <div
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={callHandler}
                      >
                        {loadingVideo ? (
                          <Loader
                            height={20}
                            width={20}
                            type="ThreeDots"
                            color="#fff"
                          />
                        ) : (
                          t("dashboards.alldashboardkeys.tablekeys.callbtn")
                        )}
                      </div>
                    ) : null}
                    {(from === "TodayAppointment" &&
                      viewData?.status?.name == "confirmed") ||
                    ((from === "PastAppointment" ||
                      from === "TodayAppointment" ||
                      from == "upcoming") &&
                      viewData?.status?.name == "completed") ||
                    (from === "PastAppointment" &&
                      viewData?.status?.name == "confirmed") ? (
                      <div
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={chatHandler}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.chatbtn")}
                      </div>
                    ) : null}
                  </div>

                  {viewData?.prescription?.id ? (
                    <div>
                      <h5>
                        {t(
                          "dashboards.alldashboardkeys.tablekeys.appointmentdetail"
                        )}
                      </h5>
                      <div>
                        <div className="w-100 containerWithShadow p-2 mb-4 mt-4">
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.diagnoses"
                            )}
                          </span>
                          <p className="ml-2 mt-2">
                            {viewData?.prescription?.diagnosis}
                          </p>
                        </div>
                      </div>

                      {viewData?.prescription?.tests?.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.testdetails"
                            )}
                          </span>
                          <ProfileTable
                            tableHead={[
                              "ID",
                              t("dashboards.alldashboardkeys.tablekeys.name"),
                              t("dashboards.alldashboardkeys.tablekeys.price"),
                              t("dashboards.alldashboardkeys.tablekeys.action"),
                            ]}
                            data={viewData?.prescription?.tests?.map((item) => {
                              return [
                                item?.test_id,
                                item?.name,
                                item?.price, // eslint-disable-next-line
                                <a
                                  className={
                                    item?.report?.status?.name ===
                                    "report uploaded"
                                      ? "btn btn-block order-pay-btn text-white"
                                      : "btn btn-block btn-light  "
                                  }
                                  href={
                                    item?.report?.status?.name ===
                                    "report uploaded"
                                      ? item?.report?.url
                                      : null
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t(
                                    "dashboards.alldashboardkeys.tablekeys.viewreport"
                                  )}
                                </a>,
                              ];
                            })}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {viewData?.prescription?.medicines.length > 0 ? (
                        <>
                          <span
                            className="light-Color-Para ml-2"
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.medicinedetails"
                            )}
                          </span>
                          <ProfileTable
                            tableHead={[
                              t("dashboards.alldashboardkeys.tablekeys.name"),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.duration"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.morningdose"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.eveningdose"
                              ),
                              t(
                                "dashboards.alldashboardkeys.tablekeys.nightdose"
                              ),
                            ]}
                            data={viewData?.prescription?.medicines?.map(
                              (item) => {
                                return [
                                  item?.medicine?.name,
                                  item?.duration,
                                  item?.dose[0],
                                  item?.dose[1],
                                  item?.dose[2],
                                ];
                              }
                            )}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {viewData?.recommended_doctors?.length > 0 ? (
                    <>
                      <span
                        className="light-Color-Para ml-2"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        {"Recomended Doctors"}
                      </span>
                      <ProfileTable
                        tableHead={["S.No", "Name"]}
                        data={viewData?.recommended_doctors?.map(
                          (item, index) => {
                            return [index, item?.name];
                          }
                        )}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewAppointment;

import { get, patch, post, put } from "./HttpProvider";
import featureConstants from "./features-constants";

const SERVICE_URLS = {
  // service URL's (API End-Points)
  login: "user/login",
  staticData: "static/all",
  registerPatient: "patient/create",
  logout: "user/logout",
  verifyEmail: "user/verify-email",
  verifyNumber: "user/verify-phone",
  updatePatient: "patient/update",
  patientAppointments: "my-appointments",
  resendPhoneOtp: "user/resend-otp/phone",
  sendPhoneOtp: "user/send-otp/phone",

  resendemailOtp: "user/resend-otp/email",
  forgotPassword: "user/forgot-password",
  resetPassword: "user/reset-password ",
  getDepartment: "department/read",
  getDoctorByDepartment: "patient/read/doctor/by/dept",
  // Read Slot
  getSlotByDateAndDoctor: "doctor/available-slots",
  addAppointment: "appointment/create",
  payAppointment: "appointment/confirmed",
  getCategory: "category/read",
  getTestData: "test/read",
  reschedule: "appointment/reschedule",
  getMyDoctors: "patient/my-doctors",
  getMyPatient: "doctor/my-patients",
  getadministratorPatients: "patient/read",
  getadministratorDoctor: "doctor/read",
  patientPrescriptions: "my-prescriptions",
  getAllMedicine: "medicine/read",
  getAllTests: "test/read",
  addprescription: "prescription/create",
  removerDoctotByAdmin: "administrator/request/removal-user",
  getAllMedicines: "medicine/read",
  addMedicine: "medicine/create",
  updateMedicine: "medicine/update",
  getAllOrders: "order/read",
  pharmacyPay: "order/payment",
  pharmacyOrderDispatch: "order/update/delivery-status",
  patientBookOrder: "order/create",
  AdministratorAddDoctorSlots: "doctor/timings-slots/add",
  rescheduleDoctorAppointment: "doctor/request/reschedule/appointment",
  doctorUpdate: "staff/update-info",
  getPackages: "package/read",
  readTestReport: "test-report/read",
  getPurchasedPackages: "/patient/read/package",
  changeResportStatus: "test-report/status/update",
  getTestResports: "test-report/read",
  uploadTestReport: "user/upload/test-report",
  createDoctorBoard: "doctor/doctors-board/create",
  getdoctorRescheduleRequest: "request/read",
  videoCallToken: "user/video-access/appointment",
  videoCallTokenBoard: "user/video-access/doctors_board",
  getallNotification: "my-notifications",
  getChatToken: "chat-room/create/appointment",
  joinChatTokenBoard: "chat-room/create/doctors_board",
  joinChatToken: "chat-room/create/appointment",
  myPayment: "my-payments",
  myDoctorBoard: "doctor/my-boards",
  updateDoctorBoard: "doctor/doctors-board/update",
  reqApproval: "request/approval",
  seeAllNotification: "notification/change/seen",
  deliveryStaff: "delivery/read",
  assignStaff: "order/assign/delivery-boy",
  conatctUse: "send/email",

  readComponent: "cms/components/read",
  readLang: "cms/languages/read",

  readTarget: "target/read",
  readbde: "business_development_executive/read",
  assignbdetarget: "target/request",
  updateBdeTarget: "target/update",
  gatewayUrl: "payment/generate",
  changePassword: "user/change-password",
  chatNotificationDoctorsBoard: "/doctors_board/chat/notification",
  chatNotificationAppointment: "/appointment/chat/notification",

  googlesignin: "/patient/login/google",
  googlesignup: "/user/signup/google",

  facebooksignin: "/patient/login/facebook",
  facebooksignup: "/user/signup/facebook",

  // changes

  cancelappointment: "appointment/cancelled",

  cancelorder: "order/cancel",

  returnreq: "create/return/request",

  readreturnreq: "read/return/request",

  updatereturnreq: "update/return/request",

  getTodaysAppoinment: "appointments/today",

  getPastAppoinment: "appointments/past",

  getUpcomingAppoinments: "appointments/upcoming",
  returnreqpicture: "upload/file/super-admin/1",
  myMedicalRecords: "medical-record/read",

  readPartialRequest: "request/read",
  updatePartialRequest: "request/update",
  cancelReq: "request/approval",
  deleteMedicalRecord: "medical-record/change/active",
  closeboard: "doctor/doctors-board/patient/recommend",
  publicDoctor: "public/doctors",
  notifyEmail: "walk-in-user/create",
};
const getPublicDoctor = () =>
  get(SERVICE_URLS.publicDoctor, { feature: featureConstants.static });

const login = (data) =>
  post(SERVICE_URLS.login, data, { feature: featureConstants.login });
const notifyEmail = (data) =>
  post(SERVICE_URLS.notifyEmail, data, { feature: featureConstants.login });

const logout = () =>
  post(SERVICE_URLS.logout, {}, { feature: featureConstants.login });

const forgotPassword = (data) =>
  post(SERVICE_URLS.forgotPassword, data, { feature: featureConstants.static });
const resetPassword = (data) =>
  patch(SERVICE_URLS.resetPassword, data, {
    feature: featureConstants.static,
  });

const getStaticData = () =>
  get(SERVICE_URLS.staticData, { feature: featureConstants.static });
const getCategory = () =>
  post(SERVICE_URLS.getCategory, {}, { feature: featureConstants.static });
const getTestData = (data) =>
  post(SERVICE_URLS.getTestData, data, { feature: featureConstants.static });
const registerPatient = (data) =>
  post(SERVICE_URLS.registerPatient, data, {
    feature: featureConstants.static,
  });
const verifyEmail = (data) => {
  return post(SERVICE_URLS.verifyEmail, data, {
    feature: featureConstants.login,
  });
};
const verifyNumber = (data) =>
  post(SERVICE_URLS.verifyNumber, data, { feature: featureConstants.login });
const updatePatient = (data) =>
  put(SERVICE_URLS.updatePatient, data, { feature: featureConstants.static });
const patientAppointments = (data) =>
  post(SERVICE_URLS.patientAppointments, data, {
    feature: featureConstants.static,
  });
const patientPrescriptions = (data) =>
  post(SERVICE_URLS.patientPrescriptions, data, {
    feature: featureConstants.static,
  });
const resendPhoneOtp = (data) =>
  post(SERVICE_URLS.resendPhoneOtp, data, {
    feature: featureConstants.static,
  });
const sendPhoneOtp = (data) =>
  post(SERVICE_URLS.sendPhoneOtp, data, {
    feature: featureConstants.static,
  });
const resendemailOtp = (data) =>
  post(SERVICE_URLS.resendemailOtp, data, {
    feature: featureConstants.static,
  });
const getDepartment = () =>
  post(
    SERVICE_URLS.getDepartment,
    {},
    {
      feature: featureConstants.static,
    }
  );
const getDoctorByDepartment = (data) =>
  post(SERVICE_URLS.getDoctorByDepartment, data, {
    feature: featureConstants.static,
  });
const getSlotByDateAndDoctor = (data) =>
  post(SERVICE_URLS.getSlotByDateAndDoctor, data, {
    feature: featureConstants.static,
  });
const addAppointment = (data) =>
  post(SERVICE_URLS.addAppointment, data, {
    feature: featureConstants.static,
  });
const reschedule = (data) =>
  put(SERVICE_URLS.reschedule, data, {
    feature: featureConstants.static,
  });
const getMyDoctors = (data) =>
  post(SERVICE_URLS.getMyDoctors, data, { feature: featureConstants.static });
const getMyPatient = (data) =>
  post(SERVICE_URLS.getMyPatient, data, { feature: featureConstants.static });

const getadministratorPatients = (data) =>
  post(SERVICE_URLS.getadministratorPatients, data, {
    feature: featureConstants.static,
  });

const getadministratorDoctor = (data) =>
  post(SERVICE_URLS.getadministratorDoctor, data, {
    feature: featureConstants.static,
  });
const getAllMedicine = () =>
  post(SERVICE_URLS.getAllMedicine, {}, { feature: featureConstants.static });
const getAllTests = () =>
  post(SERVICE_URLS.getAllTests, {}, { feature: featureConstants.static });
const addprescription = (data) =>
  post(SERVICE_URLS.addprescription, data, {
    feature: featureConstants.static,
  });
const removerDoctotByAdmin = (data) =>
  post(SERVICE_URLS.removerDoctotByAdmin, data, {
    feature: featureConstants.static,
  });
const getAllMedicines = () =>
  post(SERVICE_URLS.getAllMedicines, {}, { feature: featureConstants.static });
const addMedicine = (data) =>
  post(SERVICE_URLS.addMedicine, data, { feature: featureConstants.static });

const updateMedicine = (data) =>
  put(SERVICE_URLS.updateMedicine, data, { feature: featureConstants.static });
const getAllOrders = () =>
  post(SERVICE_URLS.getAllOrders, {}, { feature: featureConstants.static });
const getOrdersByEmail = (data) =>
  post(SERVICE_URLS.getAllOrders, data, { feature: featureConstants.static });

const pharmacyPay = (data) =>
  patch(SERVICE_URLS.pharmacyPay, data, { feature: featureConstants.static });
const pharmacyOrderDispatch = (data) =>
  put(SERVICE_URLS.pharmacyOrderDispatch, data, {
    feature: featureConstants.static,
  });
const patientBookOrder = (data) =>
  post(SERVICE_URLS.patientBookOrder, data, {
    feature: featureConstants.static,
  });
const AdministratorAddDoctorSlots = (data) =>
  put(SERVICE_URLS.AdministratorAddDoctorSlots, data, {
    feature: featureConstants.static,
  });
const rescheduleDoctorAppointment = (data) =>
  post(SERVICE_URLS.rescheduleDoctorAppointment, data, {
    feature: featureConstants.static,
  });
const doctorUpdate = (data) =>
  put(SERVICE_URLS.doctorUpdate, data, { feature: featureConstants.static });
const payAppointment = (data) =>
  put(SERVICE_URLS.payAppointment, data, { feature: featureConstants.static });
const getPackages = (data) =>
  post(SERVICE_URLS.getPackages, data, { feature: featureConstants.static });
const readTestReport = (data) =>
  post(SERVICE_URLS.readTestReport, data, { feature: featureConstants.static });
const getPurchasedPackages = (data) =>
  post(SERVICE_URLS.getPurchasedPackages, data, {
    feature: featureConstants.static,
  });
const changeResportStatus = (data) =>
  patch(SERVICE_URLS.changeResportStatus, data, {
    feature: featureConstants.static,
  });
const getTestResports = (data) =>
  post(SERVICE_URLS.getTestResports, data, {
    feature: featureConstants.static,
  });
const uploadTestReport = (data) =>
  post(SERVICE_URLS.uploadTestReport, data, {
    feature: featureConstants.static,
  });
const createDoctorBoard = (data) =>
  post(SERVICE_URLS.createDoctorBoard, data, {
    feature: featureConstants.static,
  });
const getdoctorRescheduleRequest = (data) =>
  post(SERVICE_URLS.getdoctorRescheduleRequest, data, {
    feature: featureConstants.static,
  });
const videoCallToken = (data) =>
  post(SERVICE_URLS.videoCallToken, data, {
    feature: featureConstants.static,
  });
const videoCallTokenBoard = (data) =>
  post(SERVICE_URLS.videoCallTokenBoard, data, {
    feature: featureConstants.static,
  });
const getallNotification = () =>
  get(
    SERVICE_URLS.getallNotification,
    {},
    {
      feature: featureConstants.static,
    }
  );
const getChatToken = (data) =>
  post(SERVICE_URLS.getChatToken, data, {
    feature: featureConstants.static,
  });
const joinChatToken = (data) =>
  post(SERVICE_URLS.joinChatToken, data, {
    feature: featureConstants.static,
  });
const joinChatTokenBoard = (data) =>
  post(SERVICE_URLS.joinChatTokenBoard, data, {
    feature: featureConstants.static,
  });
const myPayment = (data) =>
  get(
    SERVICE_URLS.myPayment,
    {},
    {
      feature: featureConstants.static,
    }
  );
const myDoctorBoard = (data) =>
  post(SERVICE_URLS.myDoctorBoard, data, {
    feature: featureConstants.static,
  });
const updateDoctorBoard = (data) =>
  patch(SERVICE_URLS.updateDoctorBoard, data, {
    feature: featureConstants.static,
  });
const seeAllNotification = (data) =>
  put(SERVICE_URLS.seeAllNotification, data, {
    feature: featureConstants.static,
  });
const requestApproval = (data) =>
  put(SERVICE_URLS.reqApproval, data, { feature: featureConstants.static });
const getAllDeliveryStaff = (data) =>
  post(
    SERVICE_URLS.deliveryStaff,
    {},
    {
      feature: featureConstants.static,
    }
  );
const assignDeliveryStaff = (data) =>
  patch(SERVICE_URLS.assignStaff, data, {
    feature: featureConstants.static,
  });
const conatctUse = (data) =>
  post(SERVICE_URLS.conatctUse, data, {
    feature: featureConstants.static,
  });
const readTarget = () =>
  get(SERVICE_URLS.readTarget, {
    feature: featureConstants.static,
  });
const readBDE = () =>
  post(
    SERVICE_URLS.readbde,
    {},
    {
      feature: featureConstants.static,
    }
  );
const AssignBDETarget = (data) =>
  post(SERVICE_URLS.assignbdetarget, data, {
    feature: featureConstants.static,
  });
const UpdateBDETarget = (data) =>
  put(SERVICE_URLS.updateBdeTarget, data, {
    feature: featureConstants.static,
  });
const generateGatewayUrl = (data) =>
  post(SERVICE_URLS.gatewayUrl, data, {
    feature: featureConstants.static,
  });
const changePassword = (data) =>
  patch(SERVICE_URLS.changePassword, data, {
    feature: featureConstants.static,
  });
const sendChatNotificationDoctorsBoard = (data) =>
  post(SERVICE_URLS.chatNotificationDoctorsBoard, data, {
    feature: featureConstants.static,
  });

const sendChatNotificationAppointment = (data) =>
  post(SERVICE_URLS.chatNotificationAppointment, data, {
    feature: featureConstants.static,
  });

const ReadComponent = () =>
  get(SERVICE_URLS.readComponent, {}, { feature: featureConstants.static });
const ReadLang = () =>
  get(SERVICE_URLS.readLang, {}, { feature: featureConstants.static });

//Google Sign in
const googlesignin = (data) =>
  post(SERVICE_URLS.googlesignin, data, {
    feature: featureConstants.static,
  });
const googlesignup = (data) =>
  post(SERVICE_URLS.googlesignup, data, {
    feature: featureConstants.static,
  });
const facebooksignin = (data) =>
  post(SERVICE_URLS.facebooksignin, data, {
    feature: featureConstants.static,
  });
const facebooksignup = (data) =>
  post(SERVICE_URLS.facebooksignup, data, {
    feature: featureConstants.static,
  });

// changes

const cancellAppointment = (data) =>
  patch(SERVICE_URLS.cancelappointment, data, {
    feature: featureConstants.static,
  });

const cancellOrder = (data) =>
  patch(SERVICE_URLS.cancelorder, data, {
    feature: featureConstants.static,
  });

const returnRequest = (data) =>
  post(SERVICE_URLS.returnreq, data, {
    feature: featureConstants.static,
  });

const readreturnRequest = () =>
  get(
    SERVICE_URLS.readreturnreq,
    {},
    {
      feature: featureConstants.static,
    }
  );

const updatereturnRequest = (data) =>
  put(SERVICE_URLS.updatereturnreq, data, {
    feature: featureConstants.static,
  });

const getTodaysAppoinments = async () =>
  post(
    SERVICE_URLS.getTodaysAppoinment,
    {},
    {
      feature: featureConstants.static,
    }
  );

const getPastAppoinments = async () =>
  post(
    SERVICE_URLS.getPastAppoinment,
    {},
    {
      feature: featureConstants.static,
    }
  );

const getUpcomingAppoinments = async () =>
  post(
    SERVICE_URLS.getUpcomingAppoinments,
    {},
    {
      feature: featureConstants.static,
    }
  );
const uploadReturnRequestPicture = (data) =>
  post(SERVICE_URLS.returnreqpicture, data, {
    feature: featureConstants.static,
  });

const myMedicalRecords = (data) =>
  get(SERVICE_URLS.myMedicalRecords, data, {
    feature: featureConstants.static,
  });
const readPartialPaymentRequest = () =>
  post(
    SERVICE_URLS.readPartialRequest,
    {},
    {
      feature: featureConstants.static,
    }
  );
const updatePartialPaymentRequest = (data) =>
  put(SERVICE_URLS.cancelReq, data, {
    feature: featureConstants.static,
  });

const deleteMedicalRecordReq = (data) =>
  patch(SERVICE_URLS.deleteMedicalRecord, data, {
    feature: featureConstants.login,
  });
const closeDoctorBoard = (data) =>
  post(SERVICE_URLS.closeboard, data, {
    feature: featureConstants.static,
  });

const apiServices = {
  // define variables
  deleteMedicalRecordReq,
  facebooksignin,
  facebooksignup,
  ReadLang,
  changePassword,
  login,
  getStaticData,
  resendPhoneOtp,
  registerPatient,
  logout,
  forgotPassword,
  verifyEmail,
  verifyNumber,
  updatePatient,
  patientAppointments,
  resendemailOtp,
  resetPassword,
  getDepartment,
  getDoctorByDepartment,
  getSlotByDateAndDoctor,
  addAppointment,
  getCategory,
  getTestData,
  reschedule,
  getMyDoctors,
  getMyPatient,
  getadministratorPatients,
  getadministratorDoctor,
  patientPrescriptions,
  getAllMedicine,
  getAllTests,
  addprescription,
  removerDoctotByAdmin,
  getAllMedicines,
  addMedicine,
  updateMedicine,
  getAllOrders,
  pharmacyPay,
  pharmacyOrderDispatch,
  patientBookOrder,
  AdministratorAddDoctorSlots,
  rescheduleDoctorAppointment,
  doctorUpdate,
  payAppointment,
  getPackages,
  readTestReport,
  getPurchasedPackages,
  changeResportStatus,
  getTestResports,
  uploadTestReport,
  createDoctorBoard,
  getdoctorRescheduleRequest,
  videoCallToken,
  getallNotification,
  getChatToken,
  joinChatToken,
  myPayment,
  myDoctorBoard,
  requestApproval,
  getOrdersByEmail,
  videoCallTokenBoard,
  joinChatTokenBoard,
  seeAllNotification,
  getAllDeliveryStaff,
  assignDeliveryStaff,
  conatctUse,
  readTarget,
  readBDE,
  AssignBDETarget,
  UpdateBDETarget,
  generateGatewayUrl,
  updateDoctorBoard,
  ReadComponent,
  sendChatNotificationDoctorsBoard,
  sendChatNotificationAppointment,

  // Google Sign In
  googlesignin,
  googlesignup,
  sendPhoneOtp,

  // changes

  cancellAppointment,

  cancellOrder,

  returnRequest,

  readreturnRequest,

  updatereturnRequest,

  getTodaysAppoinments,

  getPastAppoinments,

  getUpcomingAppoinments,
  uploadReturnRequestPicture,
  myMedicalRecords,
  readPartialPaymentRequest,
  updatePartialPaymentRequest,
  closeDoctorBoard,
  getPublicDoctor,
  notifyEmail,
};

export default apiServices;

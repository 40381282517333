import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    addAppointment,
    getAllDepartment,
    getDoctorByDepartment,
    getSlotByDateAndDoctor,
} from "../../../../Store/Actions/patientActions";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { disablePastDate } from "../../../../Utils/functions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { fetchStaticData } from "../../../../Store/Actions/staticActions";

const AddAppointmentWeb = (props) => {
    const { t } = useTranslation("common");
    const new_date = new Date()
    const { doctorId } = props
    const departments = useSelector((state) => state?.patient?.departments);
    const doctors = useSelector((state) => state?.patient?.doctorsByDepartment);
    const slots = useSelector((state) => state?.patient?.slotsByDate);
    const staticData = useSelector((state) => state?.static?.staticData);
    const loader = useSelector((state) => state?.logIn?.loader);

    const user = useSelector((state) => state?.logIn?.user);
    const dispatch = useDispatch();
    const [mydate, setmyDate] = useState("");

    const [singleSelections, setSingleSelections] = useState([]);
    useEffect(() => {
        // dispatch(fetchStaticData())
        setSlot([])
    }, [])

    const [doctor, setDoctor] = useState([]);
    const [slot, setSlot] = useState([]);
    const changeHanler = (selected) => {

        if (selected.length > 0) {
            const apiData = {
                department_id: selected[0]?.department_id,
            };
            dispatch(getDoctorByDepartment(apiData));
        } else {
            setDoctor([])
        }
    };
    const changeDoctorHanler = async (doctorId, date) => {
        if (doctorId == "" || doctorId?.length > 0 || date != "") {

            let today = new Date();
            let time = today.getHours() + ":" + today.getMinutes();
            const apiData = {
                id: doctorId,
                date: date,
                time: time,
                timestamp: moment(new_date)
                    .add(moment(new_date).utcOffset(), 'minutes')
                    .utc()
                    .unix(),
            };
            let res = await dispatch(getSlotByDateAndDoctor(apiData));
            if (!res) setSlot([])
        } else {
            setSlot([])
        }
    };
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (departments === undefined || departments?.length < 1) {
        }
    }, [dispatch, departments]);

    const onSubmit = async (data) => {
        const condition = staticData?.appointment_patient_condition?.filter(
            (item) => item.name === data.condition
        );
        const consultation = staticData?.appointment_consultation_type?.filter(
            (item) => item.name === data.consultation
        );
        const apiData = {
            city: data.city,
            condition: condition[0] || [],
            consultation_type: consultation[0] || [],
            date: mydate,
            slot_id: slot[0]?.slot_id,
            description: data.description,
            doctor_id: doctorId,
            patient_id: user?.id,
        };
        dispatch(addAppointment(apiData, { id: user?.id }));

        props.onHide();
        props.history.push('/patient-dashboard')
        reset();
    };
    const date = watch("date", props.date);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("dashboards.alldashboardkeys.tablekeys.addappointmentbtn")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row px-3">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                            <div
                                className="row"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div className="col-lg-6">
                                    <span className="label-name-login">
                                        {" "}
                                        {t(
                                            "dashboards.alldashboardkeys.tablekeys.city"
                                        )}
                                    </span>
                                    <input
                                        className="input-login-modal"
                                        type="text"
                                        {...register("city", {
                                            required: {
                                                value: true,
                                                message: "this field is required field",
                                            },
                                        })}
                                    />
                                    {errors?.city?.message ? (
                                        <div className="text-error">{errors?.city?.message}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t(
                                        "dashboards.alldashboardkeys.tablekeys.condition"
                                    )}</span>
                                    <Form.Control
                                        as="select"
                                        className="input-login-modal"
                                        custom
                                        {...register("condition", {})}
                                    >
                                        {staticData?.appointment_patient_condition?.map(
                                            (item, index) => (
                                                <option value={item?.name} key={index + 1}>
                                                    {item?.name}
                                                </option>
                                            )
                                        )}
                                    </Form.Control>
                                    {errors?.condition?.message ? (
                                        <div className="text-error">
                                            {errors?.condition?.message}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-lg-6">
                                    <span className="label-name-login">{t(
                                        "dashboards.alldashboardkeys.tablekeys.consultationtype"
                                    )}</span>
                                    <Form.Control
                                        as="select"
                                        className="input-login-modal"
                                        custom
                                        {...register("consultation", {})}
                                    >
                                        {staticData?.appointment_consultation_type?.map(
                                            (item, index) => (
                                                <option value={item?.name} key={index + 1}>
                                                    {item?.name}
                                                </option>
                                            )
                                        )}
                                    </Form.Control>
                                    {errors?.consultation?.message ? (
                                        <div className="text-error">
                                            {errors?.consultation?.message}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t(
                                        "dashboards.alldashboardkeys.tablekeys.date"
                                    )}</span>
                                    <input
                                        className="input-login-modal"
                                        type="date"
                                        min={disablePastDate()}
                                        onChange={(e) => {
                                            setmyDate(e.target.value)
                                            changeDoctorHanler(doctorId, e.target.value)
                                        }}
                                    // {...register("date", {
                                    //   required: {
                                    //     value: true,
                                    //     message: "this field is required field",
                                    //   },
                                    // })}
                                    />
                                    {errors?.date?.message ? (
                                        <div className="text-error">{errors?.date?.message}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-lg-12">
                                    <span className="label-name-login">{t(
                                        "dashboards.alldashboardkeys.tablekeys.slots"
                                    )}</span>
                                    <Form.Group>
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="duration"
                                            onChange={(selected) => {
                                                setSlot(selected);
                                                // changeDoctorHanler(selected, mydate);

                                            }}
                                            options={slots}
                                            placeholder=""
                                            selected={slot}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-12">
                                    <span className="label-name-login">{t(
                                        "dashboards.alldashboardkeys.tablekeys.description"
                                    )}</span>
                                    <textarea
                                        className="input-login-modal"
                                        style={{ minHeight: "100px" }}
                                        type="text"
                                        {...register("description", {
                                            required: {
                                                value: false,
                                                message: "this field is required field",
                                            },
                                        })}
                                    />
                                    {errors?.description?.message ? (
                                        <div className="text-error">
                                            {errors?.description?.message}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>{" "}
                            </div>
                            <input
                                type="submit"
                                value={t(
                                    "dashboards.alldashboardkeys.tablekeys.addappointmentbtn"
                                )}
                                className="headerBtn-red btn-block mx-auto"
                                style={{ width: "85%", textAlign: "center" }}
                            />
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddAppointmentWeb;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import TestLabAdministrator from "./TestLabAdministrator";

const LabAdministrator = (props) => {
  // const FromSlots = props?.location?.state?.key;
  const {t,i18n} = useTranslation("common")
  const [selectedTab, setSelectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.test"));
  const userRole = useSelector((state) => state?.logIn?.userRole);
  

  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  useEffect(() => {
    if(userRole !== "laboratory_admin"){
      props?.history?.push("/")
    }
    tabHandler(t("dashboards.alldashboardkeys.tablekeys.test"))
    // eslint-disable-next-line
  }, [])





  return (
    <>
      <Helmet>
        <title>
        {t("dashboards.alldashboardkeys.maintitle")}
        </title>
      </Helmet>
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.labadminmainheading1")} />
      <div className="container">
        {[t("dashboards.alldashboardkeys.tablekeys.test")].map((item, index) => (
          <div
            className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
            key={index + 1}
            onClick={() => tabHandler(item)}
          >
            <DashboardBtnList
              label={item}
              labelStyle={selectedTab === item ? { color: "#fff" } : ""}
              className={
                selectedTab === item
                  ? "dashboardBtnList-item-active py-2"
                  : "default-color-and-hover py-2"
              }
            />
          </div>
        ))}
        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.test") ? (
          <>
            <div className="pb-5">
              <TestLabAdministrator {...props}/>
            </div>
          </>
        ) : null}
      </div>
    </div>
    </>
  );
};

export default LabAdministrator;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import AdministrationDoctorsList from "./AdministrationDoctorsList";
import AdministrationPatientsList from "./AdministrationPatientsList";
import AdministrationProfile from "./AdministrationProfile";
import AdministratorDoctorReschedule from "./AdministratorDoctorReschedule";
import { Helmet } from "react-helmet";
import AssignTarget from "./AssignTarget";
import { useTranslation } from "react-i18next";
import PatientOrders from "./Order";
import ReturnRequest from "./ReturnRequest";
import AdministratorDashboardAppointmentContainer from "./AdministratorDashboardAppointmentContainer";
import AdministratorPartialPaymentReq from "./AdministratorPartialPaymentReq";

const Administration = (props) => {
  const { t } = useTranslation("common");
  const FromSlots = props?.location?.state?.key;
  const [selectedTab, setSelectedTab] = useState(
    FromSlots === "addSlot" ? t("dashboards.alldashboardkeys.tablekeys.doctortab") : t("dashboards.alldashboardkeys.tablekeys.profiletab")
  );
  const userRole = useSelector((state) => state?.logIn?.userRole);
  useEffect(() => {
    // if (userRole !== "administrator") {
    //   props?.history?.push("/");
    // }
    // eslint-disable-next-line
  }, []);
  const tabHandler = (item) => {
    setSelectedTab(item);
  };

  return (
    <>
      <Helmet>
        <title>{t("dashboards.alldashboardkeys.maintitle")}</title>
      </Helmet>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading
          title={t(
            "dashboards.alldashboardkeys.administrationmainheading1"
          )}
        />
        <div className="container">
          {[
            t("dashboards.alldashboardkeys.tablekeys.profiletab"),
            t("dashboards.alldashboardkeys.tablekeys.doctortab"),
            t("dashboards.alldashboardkeys.tablekeys.patienttab"),
            t("dashboards.alldashboardkeys.tablekeys.reschedulerequest"),
            t("dashboards.alldashboardkeys.tablekeys.targettab")
            , t("dashboards.alldashboardkeys.tablekeys.orders"),
            "Request",
            "Appointment",
            "Partial Payment Request"

          ].map((item, index) => (
            <div
              className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
              key={index + 1}
              onClick={() => tabHandler(item)}
            >
              <DashboardBtnList
                label={item}
                labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                className={
                  selectedTab === item
                    ? "dashboardBtnList-item-active py-2"
                    : "default-color-and-hover py-2"
                }
              />
            </div>
          ))}

          {selectedTab === t("dashboards.alldashboardkeys.tablekeys.profiletab") ? (
            <>
              <div className="pb-5">
                <AdministrationProfile />
              </div>
            </>
          ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.doctortab") ? (
            <>
              <AdministrationDoctorsList {...props} />
            </>
          ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.patienttab") ? (
            <>
              <AdministrationPatientsList {...props} />
            </>
          ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.reschedulerequest") ? (
            <>
              <AdministratorDoctorReschedule />
            </>
          ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.targettab") ? (
            <AssignTarget {...props} />
          ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.orders") ?
            <PatientOrders {...props} />
            : selectedTab === "Request" ? <ReturnRequest {...props} />
              : selectedTab == "Appointment" ? <AdministratorDashboardAppointmentContainer {...props} />
                : selectedTab == "Partial Payment Request" ? <AdministratorPartialPaymentReq {...props} /> : null}
        </div>
      </div>
    </>
  );
};

export default Administration;

import React from 'react'
import { Helmet } from 'react-helmet'

const Pharmacogenetics = () => {
    return (
        <>
         <Helmet>
        <title>
        Pharmacogenetics - DMFR Molecular Lab and Diagnostics
        </title>
      </Helmet>
            Pharmacogenetics
        </>
    )
}

export default Pharmacogenetics

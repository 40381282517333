import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import AboutImg from "../../Statics/Images/banner/about.jpg";
import videoposter from "../../Statics/Images/video-poster.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import AboutLeftImg from "../../Statics/Images/about-page-bg-1.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const componetcms = useSelector((state) => state.cms.components);

  const lang = useSelector((state) => state?.cms?.language);
  const about = filterComponentData(componetcms, "aboutus", lang);
  const philosophy = filterComponentData(
    componetcms,
    "philosophyaboutus",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);
  const { t, i18n } = useTranslation("common");
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[4]?.sublink1 ?banner[4]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[4]?.sublink2 ?banner[4]?.sublink2:"About Us",
      subDash: ">",
      subLink: "/about-us",
    },
    {
      subLinkName: banner[4]?.sublink3 ?banner[4]?.sublink3:"About Us",
      subDash: ">",
      subLink: "/about-us",
    },
    
   
  ];
  return (
    <>
      <Helmet>
        <title>{t("about.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[4]?.image ? banner[4]?.image : AboutImg})`}
        heading={banner[4]?.heading ? banner[4]?.heading : "About Us"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <TextAndImageSection
              imgSrc={about?.image ? about?.image : videoposter}
              subHeading={
                about?.subHeading
                  ? about?.subHeading
                  : t("about.textrev.subHeading")
              }
              headingFirst={
                about?.headfirst
                  ? about?.headfirst
                  : t("about.textrev.headfirst")
              }
              headingSecond={
                about?.headsecond
                  ? about?.headsecond
                  : t("about.textrev.headsecond")
              }
              discription={
                <>
                  <p
                    style={{
                      fontFamily: "Work Sans', sans-serif",
                      lineHeight: "25px",
                      marginTop: "20px",
                    }}
                    className="light-Color-Para"
                  >
                    {about?.desc1
                      ? about?.desc1
                      : t("about.textrev.discriptiontxt1")}
                  </p>
                  <p
                    style={{
                      fontFamily: "Work Sans', sans-serif",
                      lineHeight: "25px",
                    }}
                    className="light-Color-Para"
                  >
                    {about?.desc2
                      ? about?.desc2
                      : t("about.textrev.discriptiontxt2")}
                  </p>
                  <p
                    style={{
                      fontFamily: "Work Sans', sans-serif",
                      lineHeight: "25px",
                    }}
                    className="light-Color-Para"
                  >
                    {about?.desc3
                      ? about?.desc3
                      : t("about.textrev.discriptiontxt3")}
                  </p>
                </>
              }
              reverse={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TextAndImageSection
              // imgSrc={AboutLeftImg}
              imgHtml={
                <>
                  <div className="about-thumb-wrap">
                    <div className="about-thumb">
                      <img
                        src={
                          philosophy?.image ? philosophy?.image : AboutLeftImg
                        }
                        alt="Diagnose Tool"
                      />
                    </div>
                    <div className="about-content">
                      <div className="content">
                        <h1 style={{ fontSize: "24px" }} className="title">
                          <span className="counter">
                            {philosophy?.yearsofexp
                              ? philosophy?.yearsofexp
                              : 2}
                          </span>{" "}
                          +
                        </h1>
                        <p>
                          {philosophy?.txt1
                            ? philosophy?.txt1
                            : t("about.textrev1.txt1")}
                        </p>
                      </div>
                      <div className="dots">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </>
              }
              subHeading={
                philosophy?.subheading
                  ? philosophy?.subheading
                  : t("about.textrev1.subHeading")
              }
              headingFirst={
                philosophy?.headfirst
                  ? philosophy?.headfirst
                  : t("about.textrev1.headfirst")
              }
              headingSecond={
                philosophy?.secondheading
                  ? philosophy?.secondheading
                  : t("about.textrev1.headsecond")
              }
              discription={
                <>
                  <p
                    style={{
                      fontFamily: "Work Sans', sans-serif",
                      lineHeight: "25px",
                      marginTop: "20px",
                    }}
                    className="light-Color-Para"
                  >
                    {philosophy?.discriptiontxt1
                      ? philosophy?.discriptiontxt1
                      : t("about.textrev1.discriptiontxt1")}
                  </p>
                  <div className="row2">
                    <div className="col-12 d-flex flex-row py-2">
                      <div className="col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt2
                          ? philosophy?.discriptiontxt2
                          : t("about.textrev1.discriptiontxt2")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className="col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt3
                          ? philosophy?.discriptiontxt3
                          : t("about.textrev1.discriptiontxt3")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className="col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para py-2">
                        {philosophy?.discriptiontxt4
                          ? philosophy?.discriptiontxt4
                          : t("about.textrev1.discriptiontxt4")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className=" col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt5
                          ? philosophy?.discriptiontxt5
                          : t("about.textrev1.discriptiontxt5")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className="col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt6
                          ? philosophy?.discriptiontxt6
                          : t("about.textrev1.discriptiontxt6")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className="col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt7
                          ? philosophy?.discriptiontxt7
                          : t("about.textrev1.discriptiontxt7")}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row py-2">
                      <div className=" col-1 aboutUsCheckIcon ">
                        <i className="fas fa-check"></i>
                      </div>
                      <span className="col-11 light-Color-Para">
                        {philosophy?.discriptiontxt8
                          ? philosophy?.discriptiontxt8
                          : t("about.textrev1.discriptiontxt8")}
                      </span>
                    </div>
                  </div>
                </>
              }
              reverse={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

import React from 'react'
import { Helmet } from 'react-helmet'

const Hematology = () => {
    return (
        <>
                <Helmet>
        <title>
        Hematology - DMFR Molecular Lab and Diagnostics </title>
      </Helmet>
            Hematology
        </>
    )
}

export default Hematology

import React, { useState } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import covidTeamBaner from "../../Statics/Images/internal-banner/covid-team.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import CardHandler from "../ReUseableComponents/CardHandler";
import Teamcard from "../LayOuts/Cards/teamcardinnerhandler/teamcard";
import { Helmet } from "react-helmet";

import covidTeamImg1 from "../../Statics/Images/covid-team/1.jpg";
import covidTeamImg2 from "../../Statics/Images/covid-team/2.jpg";
import covidTeamImg3 from "../../Statics/Images/doctors/no_image.jpg";
import covidTeamImg4 from "../../Statics/Images/covid-team/4.jpg";
import covidTeamImg5 from "../../Statics/Images/covid-team/5.jpg";
import covidTeamImg6 from "../../Statics/Images/covid-team/6.jpg";

import { useTranslation } from "react-i18next";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";
import { useEffect } from "react";


const CovidTeamInnerDetails = (props) => {


  const { t, i18n } = useTranslation("common")

  const dispatch = useDispatch();
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language)
  const CovidTeamDetail = filterComponentData(componetcms, 'covid_team_card_detail', lang)
  const [buttonState, setButtonState] = useState([])

  const covidTeamtab = [
    {
      doc1: {
        img: covidTeamImg1,
        mainheading: t("covidteaminner.covidteaminnerdata.doc1.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc1.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc1.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc1.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc2: {
        img: covidTeamImg2,
        mainheading: t("covidteaminner.covidteaminnerdata.doc2.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc2.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc2.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc2.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc3: {
        img: covidTeamImg3,
        mainheading: t("covidteaminner.covidteaminnerdata.doc3.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc3.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc3.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc3.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc4: {
        img: covidTeamImg4,
        mainheading: t("covidteaminner.covidteaminnerdata.doc4.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc4.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc4.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc4.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc5: {
        img: covidTeamImg5,
        mainheading: t("covidteaminner.covidteaminnerdata.doc5.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc5.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc5.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc5.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc6: {
        img: covidTeamImg6,
        mainheading: t("covidteaminner.covidteaminnerdata.doc6.head"),
        mainsub: t("covidteaminner.covidteaminnerdata.doc6.headsub"),
        mainsub1: t("covidteaminner.covidteaminnerdata.doc6.headsub1"),
        discription:
          t("covidteaminner.covidteaminnerdata.doc6.description"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
    },
  ];



  const viewData = props?.location?.state;

  const [selectedTab1, setSelectedTab1] = useState(
    viewData ? viewData : "drkaniz"
  );
  const [selecteddata, setSelecteddata] = useState(
      viewData === "drkaniz"
      ? covidTeamtab[0].doc1

      : viewData === "drtasnim"
      ? covidTeamtab[0].doc2

      : viewData === "drAmirul"
      ? covidTeamtab[0].doc3

      : viewData === "drAsish"
      ? covidTeamtab[0].doc4

      : viewData === "drmdmaruf"
      ? covidTeamtab[0].doc5

      : viewData === "drmahmuda"
      ? covidTeamtab[0].doc6

      : covidTeamtab[0].doc1

  );
  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };


  const buttonname = ["drkaniz", "drtasnim", "drAmirul","drAsish","drmdmaruf","drmahmuda"];
  const tabHandlerTest = (item) => {
    setSelecteddata([{ ...item }])
    setSelectedTab1(item?.mhead);
  };
  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === "drkaniz") {
      tabledataHandler(covidTeamtab[0].doc1);
    } else if (item === "drtasnim") {
      tabledataHandler(covidTeamtab[0].doc2);
    } 
    else if (item === "drAmirul") {
      tabledataHandler(covidTeamtab[0].doc3);
    }
    else if (item === "drAsish") {
      tabledataHandler(covidTeamtab[0].doc4);
    }
    else if (item === "drmdmaruf") {
      tabledataHandler(covidTeamtab[0].doc5);
    }
    else if (item === "drmahmuda") {
      tabledataHandler(covidTeamtab[0].doc6);
    }
  };



  const functFilter = () => {
    return CovidTeamDetail?.filter((item) => {
      if (item?.mhead?.toLowerCase() == viewData?.toLowerCase()) {
        return item
      }
    })
  }
  const populateButtonArray = () => {
    let test = []
    CovidTeamDetail?.map((item) => {
      test?.push(item?.mhead)
    })
    setButtonState(test)
  }
  // const [selecteddata, setSelecteddata] = useState(functFilter());
  useEffect(() => {
    populateButtonArray()
    setSelecteddata(CovidTeamDetail?.length < 1 ? selecteddata : functFilter()[0] ? functFilter() : covidTeamtab[0] );
    // eslint-disable-next-line
  }, [lang,CovidTeamDetail?.length]);



  return (
    <>

      <Helmet>
        <title>
          {t("covidteaminner.maintitle")}</title>
      </Helmet>
      <BannerImg imgSrc={covidTeamBaner} />
      <br />



      <TextAndImageSection
        imgSrc={selecteddata[0]?.image ? selecteddata[0]?.image : selecteddata?.img}
        imgStyle={{ marginTop: "20px" }}
        imgCol="col-lg-3"
        textCol="col-lg-9"
        headingFirst={selecteddata[0]?.mhead ? selecteddata[0]?.mhead : selecteddata?.mainheading}
        discription={
          <>
            <h5
              style={{ textTransform: "capitalize" }}
              className="light-Color-Para mt-4"
            >
              {selecteddata[0]?.headsub ? selecteddata[0]?.headsub :selecteddata?.mainsub}
            </h5>
            <h5
              style={{ textTransform: "capitalize" }}
              className="light-Color-Para mb-4"
            >
              {selecteddata[0]?.headsub1 ? selecteddata[0]?.headsub1 : selecteddata?.mainsub1 }

            </h5>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {selecteddata[0]?.description ? selecteddata[0]?.description : selecteddata?.discription}
            </p>
            <div classNameName="socialmediasIconCovidTeam">
              <span className="light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkFacebook ? selecteddata[0]?.linkFacebook : selecteddata?.linkFacebook}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i className="fab fa-facebook-square" style={{ fontSize: "19px" }}></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkTwitter ? selecteddata[0]?.linkTwitter : selecteddata?.linkTwitter}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i className="fab fa-linkedin" style={{ fontSize: "19px" }}></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkInsta ? selecteddata[0]?.linkInsta : selecteddata?.linkInsta}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i className="fab fa-twitter-square" style={{ fontSize: "19px" }}></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkLinkedIn ? selecteddata[0]?.linkLinkedIn : selecteddata?.linkLinkedIn}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i className="fab fa-dribbble-square" style={{ fontSize: "19px" }}></i>
                </a>
              </span>
            </div>
          </>
        }
        reverse={true}
      />
      <div className="container mt-5">
        <div className="row mb-5">
          {CovidTeamDetail.length < 1 ?
          
<div className="container mt-5">
        <div className="row mb-5">
          {buttonname.map((item, index) => (
            <div
              className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6"
              key={index + 1}
              onClick={() => {tabHandler(item)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }}
            >
              <CardHandler
                label={
                  item === "drkaniz" ? (
                    <>
                     <Teamcard
                        imgSrc={covidTeamtab[0]?.doc1?.img}
                        cardHeading={covidTeamtab[0]?.doc1?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc1?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  ) : item === "drtasnim" ? (
                    <>
                    <Teamcard
                        imgSrc={covidTeamtab[0]?.doc2?.img}
                        cardHeading={covidTeamtab[0]?.doc2?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc2?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  )
                   : item === "drAmirul" ? (
                    <>
                     <Teamcard
                        imgSrc={covidTeamtab[0]?.doc3?.img}
                        cardHeading={covidTeamtab[0]?.doc3?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc3?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  )
                   : item === "drAsish" ? (
                    <>
                     <Teamcard
                        imgSrc={covidTeamtab[0]?.doc4?.img}
                        cardHeading={covidTeamtab[0]?.doc4?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc4?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  )
                   : item === "drmdmaruf" ? (
                    <>
                     <Teamcard
                        imgSrc={covidTeamtab[0]?.doc5?.img}
                        cardHeading={covidTeamtab[0]?.doc5?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc5?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  )
                   : item === "drmahmuda" ? (
                    <>
                    <Teamcard
                        imgSrc={covidTeamtab[0]?.doc6?.img}
                        cardHeading={covidTeamtab[0]?.doc6?.mainheading}
                        btnshow={true}
                        description={
                          <>
                            {covidTeamtab[0]?.doc6?.mainsub}
                            
                          </>
                        }
                      />
                    </>
                  )
                   : (
                    ""
                  )
                }
                className={
                  selectedTab1 === item
                    ? "dashboardBtnList-item-doc-active p-0"
                    : "default-color-and-hover-doc p-0"
                }
                bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
              />
            </div>
          ))}
        </div>
      </div>

          :
          CovidTeamDetail?.map((item, index) => (
            <div
              className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6"
              key={index + 1}
              onClick={() => {
                tabHandlerTest(item)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }}
            >
              <CardHandler
                label={
                  item?.mhead?.toLowerCase() == buttonState[index]?.toLowerCase() ? (
                    <>
                      <Teamcard
                        imgSrc={item?.image}
                        cardHeading={item?.mhead}
                        btnshow={true}
                        description={
                          <>
                            {item?.headsub}

                          </>
                        }
                      />
                    </>
                  ) : (
                              ""
                            )
                }
                className={
                  selectedTab1?.toLowerCase() === item?.mhead?.toLowerCase()
                    ? "dashboardBtnList-item-doc-active p-0"
                    : "default-color-and-hover-doc p-0"
                }
                bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CovidTeamInnerDetails;

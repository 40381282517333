import React, { useEffect, useState } from "react";

import { Form, Modal } from "react-bootstrap";

import { Typeahead } from "react-bootstrap-typeahead";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import { cancelAppointmentAdmin, cancelOrderAdmin } from "../../../../Store/Actions/administratorActions";
import { generatePayment } from "../../../../Store/Actions/patientActions";

import { getAllOrders } from "../../../../Store/Actions/pharmacyActions";

export default function AddInstallmentAmount(props) {

    const { t } = useTranslation("common")
    const { orderData } = props
    const dispatch = useDispatch()

    const user = useSelector((state) => state?.logIn?.user);


    const {

        register,

        handleSubmit,

        formState: { errors },

    } = useForm();

    const onSubmit = async (data) => {


        if (Number(data?.amount) < 1) {

            toast.error("Please Enter Valid Amount")

            return;

        }
        let apiData = {
            id: orderData?.id ? orderData?.id : orderData?.order?.id,
            type: "order",
            partial_payment: true,
            installment: Number(data?.amount)
        };
        const res = await dispatch(generatePayment(apiData))
        if (!res) {
            return;
        }
        props.onHide()
        window.open(res);
        window.focus();


        props.history.push("/patient-dashboard")


    };

    return (

        <>

            <Modal

                {...props}

                size="md"

                aria-labelledby="contained-modal-title-vcenter"

                centered

            >

                <Modal.Header className="dashboardBtnList-item-active">

                    <Modal.Title id="contained-modal-title-vcenter">

                        <span className="text-white">{"Installment Amount"}</span>

                    </Modal.Title>

                    <button type="button" class="close" onClick={props?.onHide}>

                        <span aria-hidden="true" className="text-white">

                            ×

                        </span>

                        <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>

                    </button>

                </Modal.Header>

                <Modal.Body>

                    <div className="row px-3">
                        <span style={{ fontWeight: 'bold' }}>Insatallment # {orderData?.no_of_installments ? orderData?.no_of_installments + 1 : orderData?.order?.no_of_installments + 1}</span>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100">

                            <div

                                className="row"

                                style={{ display: "flex", justifyContent: "center" }}

                            >

                                <div className="col-lg-12">

                                    <span className="label-name-login">{"Enter Amount"}</span>

                                    <Form.Group>

                                        <input

                                            className="input-login-modal"

                                            type="number"
                                            step="any"

                                            {...register("amount", {

                                                required: {

                                                    value: true,

                                                    message: "this field is required field",

                                                },

                                            })}

                                        />

                                        {/* {mediError ? (

                                    <div className="text-error">{mediError}</div>

                                ) : (

                                    ""

                                )} */}

                                    </Form.Group>

                                </div>




                                <input

                                    type="submit"

                                    value={"Submit"}

                                    className="headerBtn-red btn-block mx-auto"

                                    style={{ width: "85%", textAlign: "center", cursor: "pointer" }}

                                />

                            </div>

                        </form>

                    </div>

                </Modal.Body>

            </Modal >

        </>

    )

}
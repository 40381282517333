import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import ChatIframe from "../../services/twillioChat/ChatIframe";
import { CloseBoardStatus, myDoctorBoard, updateBoardStatus } from "../../Store/Actions/docotorActions";
import {
  videoCallToken,
} from "../../Store/Actions/staticActions";
import ProfileTable from "../patientDashboard/ProfileTable";
import RecomendedDoctorBoardModal from "../../Components/LayOuts/modals/doctorBoardModal/AddRecomendedDoctors";
import DashboardBtn from "../../Components/ReUseableComponents/DashboardBtn";

const BoardDetails = (props) => {
  const { t, i18n } = useTranslation("common");
  const [toggle, setToggle] = useState(false);
  const [doctorModal, setdoctorModal] = useState(false);

  const viewData = props.location.state;
  const [doctorList, setdoctorList] = useState([]);

  // eslint-disable-next-line
  let doctors = viewData?.doctors?.map((item) => {
    return item?.name;
  });
  const doctorAddHandler = (item) => {
    setdoctorList([...doctorList, ...item]);
  };
  // eslint-disable-next-line
  doctors = doctors.join(" | ");
  const user = useSelector((state) => state?.logIn?.user);
  const loader = useSelector((state) => state?.logIn?.loader);

  const item = { key: "FromBoardDetails" };
  const dispatch = useDispatch();
  const callHandler = () => {
    dispatch(videoCallToken(true, { id: viewData?.id }));
    const win = window.open(`/call/${viewData?.id}`, "_blank");
    win.focus();
  };
  const uniqueDoctor = doctorList.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      doctorList.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  const closeBoard = async () => {
    const ids = uniqueDoctor?.map((item) => item?.id);

    const apiData = {
      board_id: viewData?.id,
      // status: { id: 2, name: "closed" }
      doctor_ids: ids,

    }
    const res = await dispatch(CloseBoardStatus(apiData))
    if (res) {
      dispatch(myDoctorBoard({id:user?.id}));

      toast.success("Successfully Closed", "Success")
      props.history.push("doctor-dashboard")
    }
  }
  const openBoard = async () => {
    const apiData = {
      board_id: viewData?.id,
      status: { id: 1, name: "open" }
    }
    const res = await dispatch(updateBoardStatus(apiData))
    if (res) {
      dispatch(myDoctorBoard({id:user?.id}));

      toast.success("Successfully Opened", "Success")

      props.history.push("doctor-dashboard")
    }
  }
  return (
    <>
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.doctordashboard.myboards.boarddetail")}
      />
      {toggle ? (
        <>
          <div className="row center-childrens">
            <div className="col-xl-8 col-md-8 col-12 ">
              <div
                className="btn edit-patient-btn"
                style={{ width: "150px" }}
                onClick={() => {
                  // setshowchat(false);
                  setToggle(false);
                }}
              >
                <i className="fas fa-arrow-left"></i>{" "}
                {t(
                  "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.backbtn"
                )}
              </div>
            </div>
          </div>
          <ChatIframe
            user_id={user?.id}
            appointment_id={viewData?.id}
            board={true}
            name={user?.name}
          />
        </>
      ) : (
        <div className="container">
          <div className="row center-childrens">
            <div className="col-xl-8 col-md-8 col-12 ">
              <div
                className="btn edit-patient-btn"
                style={{ width: "150px" }}
                onClick={() => {
                  props.history.push("/doctor-dashboard", item);
                }}
              >
                <i className="fas fa-arrow-left"></i>{" "}
                {t(
                  "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.backbtn"
                )}
              </div>
            </div>
          </div>
          <div className="row center-childrens mt-3 pb-5">
            <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
              <div className="d-flex justify-content-between">
                <h5>
                  {t("dashboards.doctordashboard.mypatients.mainheading1")}
                </h5>
                <span>
                  {`${t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.paymentstatus"
                  )} :`}
                  <strong
                    style={{
                      color:
                        viewData?.status?.name === "booked" ? "red" : "green",
                    }}
                  >
                    {viewData?.status?.name === "booked" ? "UNPAID" : "PAID"}
                  </strong>
                </span>
              </div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.doctordashboard.mypatients.patientname")}
                  </span>
                  <p>{viewData?.appointment?.patient_details?.name}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.doctordashboard.mypatients.patientemail")}
                  </span>
                  <p>{viewData?.appointment?.patient_details?.email_address}</p>
                </li>{" "}
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.doctordashboard.mypatients.patientage")}
                  </span>
                  <p>{viewData?.appointment?.patient_details?.age}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {t("dashboards.doctordashboard.mypatients.description")}
                  </span>
                  <p>{viewData?.appointment?.description}</p>
                </li>
              </ul>
              {viewData?.from === "New Boards" ? (
                <div
                  className="btn-group w-100 "
                  role="group"
                  aria-label="Basic example"
                >
                  <div
                    className="btn btn-success ml-auto"
                    style={{ float: "right" }}
                    onClick={callHandler}
                  >
                    {t("dashboards.doctordashboard.mypatients.joincall")}
                  </div>
                  <div
                    className="btn btn-success"
                    style={{ float: "right" }}
                    onClick={() => {
                      // dispatch(joinChatToken(true,{ id: viewData?.id }));
                      // dispatch(selectedAppointment(viewData?.appointment));
                      // const win = window.open(`/chat/${viewData?.id}/${user?.id}/${doctors}/true`, "_blank");
                      // win.focus();
                      setToggle(true);
                    }}
                  >
                    {t("dashboards.doctordashboard.mypatients.joinchat")}
                  </div>
                </div>
              ) : null}

              {viewData?.doctors?.length > 0 ? (
                <div>
                  <h5>
                    {t("dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.doctorlist")}
                  </h5>
                  <ProfileTable
                    tableHead={[
                    t("dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.name")
                    ,
                    t("dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.speciality")
                    ,
                    t("dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.email")
                    ,
                    t("dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.department")
                  ]}
                    data={viewData?.doctors?.map((item) => {
                      return [
                        item?.name,
                        item?.speciality?.map((item,idx) => <li key={idx}>{item?.name}</li>),
                        item?.email_address,
                        item?.departments?.map((_item, index) => {
                          return <li key={index}>{_item?.name}</li>;
                        }),
                      ];
                    })}
                  />
                </div>
              ) : (
                ""
              )}
               {doctorList?.length > 0 ? (
                  <div>
                    <h5>Recomended Doctors</h5>
                    <ProfileTable
                      tableHead={["Name", "Speciality", "Email", "Action"]}
                      data={uniqueDoctor?.map((item, index) => {
                        return [
                          item?.name,
                          item?.speciality?.map((_item, index) => {
                            return (
                              <li
                                style={{
                                  listStyle:
                                    item?.speciality?.length > 1 ? "" : "none",
                                }}
                                key={index}
                              >
                                {_item?.name}
                              </li>
                            );
                          }),
                          item?.email_address,

                          <>
                            {" "}
                            <div className="row">
                              <div className="col">
                                <button
                                  onClick={async () => {
                                    const test = [...uniqueDoctor];
                                    test.splice(index, 1);
                                    await setdoctorList(test);
                                  }}
                                  className="btn btn-primary"
                                  style={{backgroundColor:'#e92125'}}
                                >Remove</button>
                              </div>
                            </div>
                          </>,
                        ];
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}
               {viewData?.from == "New Boards" ? (
                  loader ? (
                    <Loader
                      height={20}
                      width={20}
                      type="Oval"
                      color="#1a4774"
                    />
                  ) : viewData?.is_owner ? (
                    <div className="row">
                      <div className="col-2">
                        <button className="btn btn-primary" style={{backgroundColor:'#1a4774',border:'none'}} onClick={closeBoard}>
                          Close Board
                        </button>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary" style={{backgroundColor:'#1a4774',border:'none'}}
                          onClick={() => setdoctorModal(true)}
                        >
                          Add Doctor
                        </button>
                      </div>
                    </div>
                  ) : null
                ) : loader ? (
                  <Loader height={20} width={20} type="Oval" color="#1a4774" />
                ) : viewData?.is_owner ? (
                  <button className="btn btn-primary" style={{backgroundColor:'#1a4774',border:'none'}} onClick={openBoard}>
                    Open Board
                  </button>
                ) : null}

            </div>
          </div>
        </div>
      )}
    </div>
     <RecomendedDoctorBoardModal
     show={doctorModal}
     onHide={() => setdoctorModal(!doctorModal)}
     setdoctorList={doctorAddHandler}
     doctor={viewData?.doctors}
     {...props}
   />
   </>
  );
};

export default BoardDetails;
{/* <button className="btn btn-primary" style={{backgroundColor:'#1a4774',border:'none'}} onClick={closeBoard}>Close Board</button> : */}

import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { patientBookOrder } from "../../../../Store/Actions/patientActions";
import { addToCart } from "../../../../Store/Actions/staticActions";
import Loader from "../../../ReUseableComponents/Loader";
// import imgSrc from "../../../../Statics/packages/1.png";
const ViewPackageDetails = (props) => {
  const data = props.data;
  const { t } = useTranslation("common");
  const user = useSelector((state) => state?.logIn?.user);
  const userRole = useSelector((state) => state?.logIn?.userRole);

  const dispatch = useDispatch()
  const [walkin, setwalkin] = useState(false)
  const [homedelivery, sethomedelivery] = useState(false)
  const cartHandler = () => {
    let cartData = [];
    const prevcartData = JSON.parse(localStorage.getItem("cart"));
    if (prevcartData !== null && prevcartData?.length > 0) {
      cartData = [...prevcartData, data];
      dispatch(addToCart(cartData?.length));
      localStorage.setItem("cart", JSON.stringify(cartData));
    } else {
      dispatch(addToCart(1));

      localStorage.setItem("cart", JSON.stringify([data]));
    }
    toast.info(t("dashboards.alldashboardkeys.tablekeys.pakagesaddedincart"));
    props?.onHide();
  };
  const [loader, setloader] = useState(true);

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        setloader(!loader);
      }, 3000);
    }
  }, [loader]);
  const paybycash = async () => {
    if (!user) {
      toast.warn("Please Login As A Patient")
      setTimeout(() => {

        window.location.replace('/login')
      }, 1000);
    } else if (userRole === "patient") {
      const apiData = {
        id: user?.id,
        delivery_type: { id: 2, name: "delivery" },
        packages: [{ package: data?.id, quantity: 1 }]

      };
      const response = await dispatch(patientBookOrder(apiData, false));
      if (!response) {
        return
      }
      props?.onHide();

      props?.history.push('patient-dashboard', { key: "fromOrderDetails" })
    } else {
      // pleaseloginaspatient
      toast.error("Please Login As A Patient");
    }
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.details")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "400px",
            overflowY: "scroll",
            // paddingTop: "484px",
          }}
          className="testScroll"
        >
          {loader ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-start flex-column ">
              <img src={data?.display_picture} alt="details" />

              {/* <div
                className="btn btn-block btn-danger"
                onClick={() => {
                  // props?.history?.push("/cart");
                  cartHandler();
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.addtocart")}
              </div>
               */}
              <div className="row mt-2">
                <div className="col-6">
                  <label style={{ fontWeight: '600', fontSize: '19px' }} className='mx-2'>Home Collection</label>
                  <input type="checkbox"
                    disabled={walkin}
                    onChange={(e) => sethomedelivery(e.target.checked)}
                  />
                </div>
                <div className="col-6">
                  <label style={{ fontWeight: '600', fontSize: '19px' }} className='mx-2'>Walk-in</label>
                  <input type="checkbox"
                    disabled={homedelivery}

                    onChange={(e) => setwalkin(e.target.checked)}
                  />
                </div>
              </div>
              {
                walkin ? <div className="row mt-2">
                  <div className="col-6"> <div
                    className="btn btn-block btn-danger"
                    onClick={() => {
                      // props?.history?.push("/cart");
                      paybycash();

                    }}
                  >
                    Pay By Cash
                  </div></div>
                  <div className="col-6"> <div
                    className="btn btn-block btn-danger"
                    onClick={() => {
                      // props?.history?.push("/cart");
                      cartHandler();
                    }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.addtocart")}
                  </div></div>
                </div> : !walkin && homedelivery ? <div className="row mt-2">

                  <div className="col-12"> <div
                    className="btn btn-block btn-danger"
                    onClick={() => {
                      // props?.history?.push("/cart");
                      cartHandler();
                    }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.addtocart")}
                  </div></div>
                </div> : null
              }
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewPackageDetails;

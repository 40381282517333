import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import FooterComponentsTable from "../ReUseableComponents/FooterComponentsTable";
import moliculler from "../../Statics/Images/internal-banner/moliculler.jpg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import { useDispatch, useSelector } from "react-redux";
import { getTestData } from "../../Store/Actions/staticActions";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const Moleculardiagnostics = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const testData = useSelector((state) => state?.static?.testData);
const id =  props?.location?.state?.data?.id

const name = props?.location?.state?.data?.name

  useEffect(() => {
    const apiData = {
      category_id: id,
      status: {
        id: 1,
        name: "active",
      },
    };
    dispatch(getTestData(apiData));
    window.scroll(0,0)
    // eslint-disable-next-line
  }, [dispatch,id]);
  return (
    <>
    
     <Helmet>
        <title>
       {name} - {t("testcatalog.maintitle")}
        </title>
      </Helmet>
    <div className="mb-5">
      <BannerImg imgSrc={moliculler} />
      <div className="mt-5">
        <BlueHeading heading={props?.location?.state?.data?.name} newcolor={"#444"} />
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <FooterComponentsTable
              tableHeaders={[
                t("testcatalog.s1no"),
                t("testcatalog.examname"),
                t("testcatalog.activestatus"),
                t("testcatalog.priceinBDT"),
              ]}
              tableBodyData={testData?.map((item, index) => {
                return [index, item?.name, item?.status?.name, item?.price];
              })}
            />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Moleculardiagnostics;

import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import radiology from "../../Statics/Images/banner/radiology.jpg";
import radimg1 from "../../Statics/Images/radimg1.jpg";
import radimg2 from "../../Statics/Images/radimg2.jpg";
import radimg3 from "../../Statics/Images/radimg3.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const Radiologyimaging = () => {
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCmscomponent());
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const radiologyCom = filterComponentData(componetcms, "radiology", lang);
  const radiologyHeader = filterComponentData(
    componetcms,
    "radiologyheader",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[11]?.sublink1 ? banner[11]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[11]?.sublink2 ? banner[11]?.sublink2 : "Services",
      subDash: ">",
      subLink: "/radiology-imaging-services",
    },
    {
      subLinkName: banner[11]?.sublink3
        ? banner[11]?.sublink3
        : "Radiology & Imaging",
      subDash: ">",
      subLink: "/radiology-imaging-services",
    },
  ];
  const { t, i18n } = useTranslation("common");
  return (
    <>
      <Helmet>
        <title>{t("radiologyandimaging.maintitle")}</title>
      </Helmet>
      <div className="mb-5">
        {/* img banner */}
        <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[11]?.image ? banner[11]?.image : radiology
          })`}
          heading={
            banner[11]?.heading ? banner[11]?.heading : "Radiology And Imaging"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
        {/* img and text section */}
        <HeadingWithBottomBar
          heading={
            radiologyHeader?.mainheading
              ? radiologyHeader?.mainheading
              : t("radiologyandimaging.mainheading1")
          }
          discription={
            <>
              <div>
                {radiologyHeader?.headingsub
                  ? radiologyHeader?.headingsub
                  : t("radiologyandimaging.mainheadingsub")}
              </div>
              <div>
                {radiologyHeader?.headingsub2
                  ? radiologyHeader?.headingsub2
                  : t("radiologyandimaging.mainheadingsub1")}
              </div>
            </>
          }
        />
        {radiologyCom?.length < 1 ? (
          <>
            <div className="">
              <TextAndImageSection
                imgSrc={radimg1}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("radiologyandimaging.textrev.headfirst")}
                headingSecond={t("radiologyandimaging.textrev.headsecond")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("radiologyandimaging.textrev.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>
            <div className="">
              <TextAndImageSection
                imgSrc={radimg2}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("radiologyandimaging.textrev1.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("radiologyandimaging.textrev1.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={true}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={radimg3}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("radiologyandimaging.textrev2.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("radiologyandimaging.textrev2.discriptiontxt1")}
                      <br />
                      {t("radiologyandimaging.textrev2.discriptiontxt2")}
                      <br />
                      {t("radiologyandimaging.textrev2.discriptiontxt3")}
                      <br />
                      {t("radiologyandimaging.textrev2.discriptiontxt4")}
                      <br />{" "}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>
          </>
        ) : (
          radiologyCom?.map((item, index) => (
            <div className="">
              <TextAndImageSection
                imgSrc={item?.image}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={item?.heading}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                      {/* {item?.text} */}
                      {/* <br />
                        {item?.desc2}
                        <br />
                        {item?.desc3}
                        <br />
                        {item?.desc4} */}
                      <br />{" "}
                    </p>
                  </>
                }
                reverse={index % 2 == 1 ? true : false}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Radiologyimaging;

import React from 'react'
import { Helmet } from 'react-helmet'

const Flowcytometry = () => {
    return (
        <>
          <Helmet>
        <title>
        Flowcytometry - DMFR Molecular Lab and Diagnostics
        </title>
      </Helmet>
            Flowcytometry
        </>
    )
}

export default Flowcytometry

import React, { useEffect, useState } from "react";
import Video from "twilio-video";
import Room from "../../services/twillio/Room";
const VideoCall = ({ roomName, token, handleLogout }) => {
  const [room, setRoom] = useState(null);
  // eslint-disable-next-line
  const [participants, setParticipants] = useState([]);

  const roomToken = localStorage.getItem("roomToken") || "";
  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    Video.connect(roomToken, {
      name: "roomName",
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });
    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
    // eslint-disable-next-line
  }, [roomName, token]);
  return <>{room ? <Room room={room} /> : ""}</>;
};

export default VideoCall;

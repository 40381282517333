import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "../Store/Actions/loginActions";
import { isJSON } from "./auth.util";

const PrivateRoute = ({ component: Component, routelink, ...rest }) => {
  const dispatch = useDispatch()
  const getUserTypeFromLocal = localStorage.getItem("userRole");
  const getUserFromLocal = localStorage.getItem("user");
  const user1 = useSelector((state) => state.logIn.user)
  const userRole1 = useSelector((state) => state.logIn.userRole)
  useEffect(async () => {

    await dispatch(getUser())
  }, [dispatch])

  let user = isJSON(!user1 ? getUserFromLocal : user1)
  let userRole = isJSON(userRole1 == '' ? getUserTypeFromLocal : userRole1)


  const { t } = useTranslation("common")
  return (
    <Route
      {...rest}
      render={(props) =>
        user && routelink === userRole ? (
          <Component {...props} />
        ) : (
          <>
            {toast.error(t("toastmessages.unauthorizedaccess"))}
            <Redirect to="/" />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;

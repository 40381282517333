import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AssignRiderModal from "../../Components/LayOuts/modals/assignRiderModal/AssignRiderModal";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import { pharmacyPay, cancelOrder } from "../../Store/Actions/pharmacyActions";
import { comaSeperated } from "../../Utils/functions";
import ProfileTable from "../patientDashboard/ProfileTable";
import { validateFieldsNatively } from "@hookform/resolvers";

const PaharmacyOrderDetail = (props) => {
  const { t } = useTranslation("common");

  const viewData = props.location.state;
  const item = { key: "fromOrderDetails" };
  const [loading, setLoading] = useState(false);
  const [assignOrder, setassignOrder] = useState(false);
  const dispatch = useDispatch();

  const pharmacyPartialPay = async () => {
    setLoading(true);
    let res = await dispatch(
      pharmacyPay({ id: viewData?.id, partial_payment: true })
    );
    setLoading(false);
    if (res) {
      props.history.push("/pharmacy", item);
    }
  };
  const pharmacyFullPay = async () => {
    let res = await dispatch(
      pharmacyPay({ id: viewData?.id, partial_payment: false })
    );
    if (res) {
      props.history.push("/pharmacy", item);
    }
  };
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-12 col-md-12 col-12 pb-5">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/pharmacy", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens  pb-5">
          <div className="col-xl-12 col-md-12 col-12 containerWithShadow p-4">
            <div className="row d-flex justify-content-between pl-2 pr-5">
              <h5>
                {t("dashboards.alldashboardkeys.tablekeys.customerdetail")}
              </h5>
              <p>
                <span
                  style={{
                    color:
                      viewData?.paid_status?.name === "unpaid"
                        ? "red"
                        : "green",
                  }}
                >
                  <span style={{ color: "black" }}>
                    {t("dashboards.alldashboardkeys.tablekeys.paymentstatus")}:
                  </span>

                  {viewData?.paid_status?.name}
                </span>
                <br />
                <span
                  style={{
                    color:
                      viewData?.delivery_status?.name !== "dispatched"
                        ? "red"
                        : "green",
                  }}
                >
                  <span style={{ color: "black" }}>
                    {t("dashboards.alldashboardkeys.tablekeys.deliverystatus")}:
                  </span>

                  {viewData?.delivery_status?.name?.toUpperCase()}
                </span>
                <br />
                <span>
                  <span style={{ color: "black" }}>
                    {t(
                      "dashboards.alldashboardkeys.tablekeys.assignmentstatus"
                    )}

                    :
                  </span>

            
                  {!viewData.is_assigned ? <span style={{ color: viewData?.is_assigned ? "green" : "red" }}>Un Assigned</span> : <span style={{ color: viewData?.is_assigned ? "green" : "red" }}>{viewData?.delivery_boy?.name?.toUpperCase()}</span>}

                </span>
              </p>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.customername")}
                </span>
                <p>{viewData?.ordered_by?.name}</p>
              </li>

              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.customernumber")}
                </span>
                <p>{viewData?.ordered_by?.phone_number}</p>
              </li>

              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.customeremail")}
                </span>
                <p>{viewData?.ordered_by?.email_address}</p>
              </li>
            </ul>
            <h5>{t("dashboards.alldashboardkeys.tablekeys.orderdetail")}</h5>
            <li>
              <span
                className="light-Color-Para"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.orderid")}
              </span>
              <p>{viewData?.order_id}</p>
            </li>
            {viewData?.medicines?.length > 0 ? (
              <ProfileTable
                tableHead={[
                  t("dashboards.alldashboardkeys.tablekeys.type"),
                  t("dashboards.alldashboardkeys.tablekeys.name"),
                  t("dashboards.alldashboardkeys.tablekeys.quantity"),
                  t("dashboards.alldashboardkeys.tablekeys.price"),
                ]}
                data={viewData?.medicines?.map((item) => {
                  item = { ...item?.medicine };

                  return [
                    item?.medicine_type,
                    item?.name,
                    item?.quantity,
                    ,
                    item?.sales_price,
                  ];
                })}
              />
            ) : null}
            {viewData?.medicines?.length > 0 ? (
              <>
                <div
                  className="w-100 d-flex justify-content-end "
                  style={{ marginTop: "-40px" }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <strong>
                      {t("dashboards.alldashboardkeys.tablekeys.grandtotal")} :
                    </strong>
                    {comaSeperated(viewData?.total_amount, "AYA.?")}
                  </div>
                </div>
              </>
            ) : null}
            {viewData?.paid_status?.name == "partial" ? (
              <>
                <ul style={{ float: "right" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.total_amount)}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.paid_amount)}
                    </span>
                  </li>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.remainingamount"
                      )}{" "}
                      :
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.remaining_amount)}
                    </span>
                  </li>
                </ul>
              </>
            ) : viewData?.paid_status?.name == "paid" ? (
              <ul style={{ float: "right" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {comaSeperated(viewData?.total_amount)}
                  </span>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {t("dashboards.alldashboardkeys.tablekeys.paidamount")} :
                  </span>

                  <span style={{ fontWeight: "bold" }}>
                    {comaSeperated(viewData?.paid_amount)}
                  </span>
                </li>
              </ul>
            ) : viewData?.paid_status?.name == "unpaid" ? (
              <>
                <ul style={{ float: "right" }}>
                  <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t("dashboards.alldashboardkeys.tablekeys.totalamount")} :
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.total_amount)}
                    </span>
                  </li>

                  {/* <li>
                    <span
                      className="light-Color-Para"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.installmentamount"
                      )}{" "}
                      :
                    </span>

                    <span style={{ fontWeight: "bold" }}>
                      {comaSeperated(viewData?.installments_amount)}
                    </span>
                  </li> */}
                </ul>
              </>
            ) : null}

            <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end">
              {viewData?.paid_status?.name === "unpaid" ? (
                <></>
              ) : viewData?.delivery_status?.name === "processing" &&
                viewData?.paid_status?.name === "paid" ? (
                <div
                  className="btn appointment-reschdule-btn"
                  style={{ color: "#fff" }}
                  onClick={() => {
                    setassignOrder(true);
                    // dispatch(
                    //   pharmacyOrderDispatch({
                    //     id: viewData?.id,
                    //     delivery_status: { id: 3, name: "dispatched" },
                    //   })
                    // );
                    // props.history.push("/pharmacy", item);
                  }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.dispatch")}
                </div>
              ) : viewData?.paid_status?.name === "partial" ? (
                <></>
              ) : null}
            </div>

            <div className="col-xl-12 col-md-12 col-12  d-flex justify-content-end mt-2">

              {

                !viewData?.is_returned && viewData?.delivery_status?.name == "cancelled" ? <button

                  className="btn headerBtn-red "

                  onClick={async () => {

                    let res = await dispatch(cancelOrder({ order_id: viewData?.id, is_returned: true }))

                    if (res) {

                      props.history.push('/pharmacy', { key: 'fromOrderDetails' })

                    }

                  }}

                >Mark Return</button> : null

              }

            </div>

          </div>
        </div>
      </div>
      <AssignRiderModal
        viewData={viewData}
        show={assignOrder}
        onHide={() => setassignOrder(false)}
        {...props}
      />
    </div>
  );
};

export default PaharmacyOrderDetail;


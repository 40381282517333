import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AssignBdeTargetAction, readBdeAction } from "../../../../Store/Actions/administratorActions";
import { disablePastDate } from "../../../../Utils/functions";
export default function AddTarget(props) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch()
    const allMedicines = useSelector((state) => state?.administrator?.bde);
    // eslint-disable-next-line 
    const loading = useSelector((state) => state?.logIn?.loader);

    const [bdeSelection, setbdeSelection] = useState(null)
    useEffect(() => {
        dispatch(readBdeAction())
    }, [dispatch])
    const {
        register,
        handleSubmit,
        // eslint-disable-next-line 
        formState: { errors }, reset
    } = useForm();
    const onSubmit = async (data) => {
        if (bdeSelection?.length === 0) {

            toast.error(t("dashboards.alldashboardkeys.tablekeys.pleaseselectbdetoassign"), "Error")
            // setmediError("This Is A Required Field");
        } else {
            const bdeData = {
                id: bdeSelection[0]?.id,
                amount: Number(data?.amount),
                number_of_packages: Number(data?.packages),
                number_of_tests: Number(data?.test),
                number_of_doctor_visits: Number(data?.doctor),
                start_date: moment(data?.startdate).unix(),
                end_date: moment(data?.enddate).unix()
            };


            let res = await dispatch(AssignBdeTargetAction(bdeData))
            if (res) {

                toast.success(t("dashboards.alldashboardkeys.tablekeys.successfullyassignedtarget"), "Success")
                setbdeSelection(null)
                reset()
                props.onHide();

            } else {

            }
            // props.setBdeTarget([...props?.bdeTarget, bdeData]);
        }
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("dashboards.alldashboardkeys.tablekeys.addtargets")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row px-3">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                            <div
                                className="row"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div className="col-lg-12">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.selectbde")}</span>
                                    <Form.Group>
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="name"
                                            onChange={(selected) => {
                                                setbdeSelection(selected);
                                            }}
                                            options={allMedicines}
                                            placeholder=""
                                            selected={bdeSelection}
                                        />
                                        {/* {mediError ? (
                                            <div className="text-error">{mediError}</div>
                                        ) : (
                                            ""
                                        )} */}
                                    </Form.Group>
                                </div>

                            </div>
                            <div
                                className="row"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enterammount")}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="number"
                                            {...register("amount", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enternumberofpakages")}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="number"
                                            {...register("packages", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>

                            </div>
                            <div className="row"
                                style={{ display: "flex", justifyContent: "center" }}

                            >
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.entertest")}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="number"
                                            {...register("test", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enternumberofdetails")}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="number"
                                            {...register("doctor", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>

                            </div>
                            <div className="row"
                                style={{ display: "flex", justifyContent: "center" }}

                            >
                                <div className="col-lg-6">
                                    <span className="label-name-login">{"Start Date"}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="date"
                                            min={disablePastDate()}
                                            {...register("startdate", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enterenddate")}</span>
                                    <Form.Group>
                                        <input
                                            className="input-login-modal"
                                            type="date"
                                            min={disablePastDate()}

                                            {...register("enddate", {
                                                required: {
                                                    value: true,
                                                    message: "this field is required field",
                                                },
                                            })}
                                        />

                                    </Form.Group>
                                </div>
                            </div>
                            <input
                                type="submit"
                                value={t("dashboards.alldashboardkeys.tablekeys.addtargets")}
                                className="headerBtn-red btn-block mx-auto"
                                style={{ width: "85%", textAlign: "center" }}
                            />
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UploadReportModal from "../../Components/LayOuts/modals/uploadMedicalModal/UploadReportModal";
import ActionBtn from "../../Components/ReUseableComponents/ActionBtn";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtn from "../../Components/ReUseableComponents/DashboardBtn";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { deleteMedicalRecordAction, myMedicalRecords } from "../../Store/Actions/patientActions";
const PatientMedicalRecords = (props) => {
  const { t } = useTranslation("common")
  const [selectedTab, setselectedTab] = useState("Prescription");


  const loader = useSelector((state) => state?.patient?.patientLoader);
  const user = useSelector((state) => state?.logIn?.user);
  const medicalRecords = useSelector((state) => state?.patient?.medicalRecords);
  const [tableData, settableData] = useState([]);
  const [uploadModal, setuploadModal] = useState({ show: false, from: "" });
  const dispatch = useDispatch();
  let tableDataFormatPrescription = [];
  medicalRecords?.prescriptions?.map((item, index) =>
    tableDataFormatPrescription.push({
      sr: index,
      medical_record_id: item?.medical_record_id,
      created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
      prescription_url: item?.prescription_url,
      id:item?.id
    })
  );
  let tableDataFormatReports = [];
  medicalRecords?.reports?.map((item, index) =>
    tableDataFormatReports.push({
      sr: index,
      medical_record_id: item?.medical_record_id,
      created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
      report_url: item?.report_url,
      id:item?.id

    })
  );
  useEffect(() => {
    dispatch(myMedicalRecords({ id: user?.id }));
    settableData(tableDataFormatPrescription);
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    setselectedTab("Prescription");
    settableData(tableDataFormatPrescription);

  }, [medicalRecords])
  const tabHandler = (item) => {
    setselectedTab(item);
    if (item === "Prescription") {
      settableData(tableDataFormatPrescription);
    } else {

      settableData(tableDataFormatReports);
    }
  };
  const clickHandler = () => {
    if (selectedTab === "Prescription") {
      setuploadModal({
        show: true, from:
          "Prescription"
      });
    } else if (selectedTab === "Report") {
      setuploadModal({ show: true, from: "Report" });
    }
  };
  const handleChange = async(value, data) => {
    if(value == "View"){

      if (selectedTab === "Prescription") {
        window.open(data?.prescription_url);
        window.focus();
      }
      if (selectedTab === "Report") {
        window.open(data?.report_url);
        window.focus();
      }
    }else if(value == "Delete"){
      let res =await dispatch(deleteMedicalRecordAction({medical_record_id:data?.id,is_active:false}))
      if(res) dispatch(myMedicalRecords({ id: user?.id }));
    }
  };
  // t("dashboards.alldashboardkeys.tablekeys.reports")
  return (
    <>
      <div className="w-100">
        <div className=" mt-3">
          <h4 className="patient-dashboard-heading">{"Medical Records"}</h4>
        </div>
        <div className="row px-3 mb-3">
          {["Prescription", "Report"]?.map((item, index) => (
            <div
              className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
              key={index + 1}
              onClick={() => tabHandler(item)}
            >
              <DashboardBtnList
                label={item}
                labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                className={
                  selectedTab === item
                    ? "dashboardBtnList-item-active py-2"
                    : "default-color-and-hover py-2"
                }
              />
            </div>
          ))}
        </div>
        {loader ? (
          <Loader />
        ) : (
          <>
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{index + 1}</span>;
                  },
                  dataField: "sr.",
                  text: "Sr.",
                },
                { dataField: "medical_record_id", text: "Record Id" },
                { dataField: "created_at", text: t("dashboards.alldashboardkeys.tablekeys.date") },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <ActionBtn
                        options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"),"Delete"]}
                        handleChange={handleChange}
                        data={row}
                      />

                    );
                  },
                },
              ]}
              tabledata={tableData || []}
            />
          </>
        )}
        <div className="row">
          <div className="col-2">
            <div className=" p-2 text-white text-center mb-5" onClick={clickHandler} style={{ background: '#1a4774', cursor: "pointer" }}>
              {selectedTab === "Prescription" ? "Add Prescription" : "Add Report"}
            </div>
          </div>
        </div>
        <UploadReportModal
          show={uploadModal.show}
          onHide={() => setuploadModal(false)}
          viewData={uploadModal.from}
          {...props}
        />
      </div>
    </>
  );
};

export default PatientMedicalRecords;

import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { saveUser, setUserRole, setToken } from "../../Utils/auth.util";
import { signInConstansts } from "../Constants/loginConstant";
import { signUpConstansts } from "../Constants/signUpConstants";
import {
  emailModalToggle,
  phoneModalToggle,
  resendemailOtp,
  resendPhoneOtp,
  sendPhoneOtp,
} from "./loginActions";

export const toggleSignUpModal = (toogleValue) => {
  return {
    type: signUpConstansts.SHOW_MODAL,
    payload: toogleValue,
  };
};
export const registerUser = (data) => async (dispatch) => {
  try {
    const response = await apiServices.registerPatient(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });

      // console.log("ROLE", response?.data?.response_data);
      // dispatch({
      //   type: signInConstansts.USER_TYPE,
      //   payload: response?.data?.response_data?.session_token_type,
      // });
      saveUser(response?.data?.response_data?.user);
      // setUserRole(response?.data?.response_data?.session_token_type);
      if (response?.data?.response_data?.user?.email_address_is_verified) {
        dispatch(
          resendPhoneOtp({ id: response?.data?.response_data?.user?.id })
        );
        dispatch(phoneModalToggle(true));
      } else {
        dispatch(
          resendemailOtp({ id: response?.data?.response_data?.user?.id })
        );
        dispatch(emailModalToggle(true));
      }

      return "true";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};

export const registerUserGoogle = (data) => async (dispatch) => {
  try {
    const response = await apiServices.googlesignup(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      if (response?.data?.response_data?.user?.phone_number_is_verified) {
        dispatch(
          sendPhoneOtp({
            id: response?.data?.response_data?.user?.id,
            phone_number: response?.data?.response_data?.user?.phone_number,
          })
        );
        dispatch(phoneModalToggle(true));
      }
      return response?.data?.response_data?.user;
    } else if (response?.data?.response_code == 8001) {
      return "exist";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};
export const registerUserFaceBook = (data) => async (dispatch) => {
  try {
    const response = await apiServices.facebooksignup(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      if (response?.data?.response_data?.user?.phone_number_is_verified) {
        dispatch(
          sendPhoneOtp({
            id: response?.data?.response_data?.user?.id,
            phone_number: response?.data?.response_data?.user?.phone_number,
          })
        );
        dispatch(phoneModalToggle(true));
      }
      return response?.data?.response_data?.user;
    } else if (response?.data?.response_code == 8001) {
      return "exist";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};

/*eslint-disable*/

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DashboardBtn from "../../Components/ReUseableComponents/DashboardBtn";

import LoderContainer from "../../Components/ReUseableComponents/Loader";

import { useTranslation } from "react-i18next";

import { readPastAppointmentAction, readTodaysAppointmentAction, readUpcomingAppointmentAction } from "../../Store/Actions/administratorActions";

import AdministratorTodayAppointment from "./AdministratorTodayAppointment";

import AdministratorPastAppointment from "./AdministratorPastAppointment";

import AdministratorUpcomingAppointment from "./AdministratorUpcomingAppointment";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";


const AdministratorDashboardAppointmentContainer = (props) => {

    const { t } = useTranslation("common")

    const [selectedTab, setselectedTab] = useState(t("dashboards.alldashboardkeys.tablekeys.today"));

    const loader = useSelector((state) => state?.administrator?.administrator_loader);


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(readTodaysAppointmentAction())

        dispatch(readPastAppointmentAction())

        dispatch(readUpcomingAppointmentAction())


    }, []);

    const tabHandler = (item) => {

        setselectedTab(item);

    };

    return (

        <div className="container">

            {loader ? (

                <LoderContainer />

            ) : (

                <>

                    <div className="row">

                        {[t("dashboards.alldashboardkeys.tablekeys.today"), t("dashboards.alldashboardkeys.tablekeys.upcoming"), t("dashboards.alldashboardkeys.tablekeys.past")]?.map((item, index) => (

<div
className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
key={index + 1}
onClick={() => tabHandler(item)}
>
<DashboardBtnList
  label={item}
  labelStyle={selectedTab === item ? { color: "#fff" } : ""}
  className={
    selectedTab === item
      ? "dashboardBtnList-item-active py-2"
      : "default-color-and-hover py-2"
  }
/>
</div>

                        ))}

                    </div>

                    <div className="row">

                        {/* t("dashboards.alldashboardkeys.tablekeys.past") */}

                        {selectedTab === t("dashboards.alldashboardkeys.tablekeys.today") ? (

                            <AdministratorTodayAppointment {...props} />

                        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.upcoming") ? (

                            <AdministratorUpcomingAppointment {...props} />

                        ) : selectedTab === t("dashboards.alldashboardkeys.tablekeys.past") ? (

                            <AdministratorPastAppointment {...props} />

                        ) : null}

                    </div>

                </>

            )}

        </div>

    );

};


export default AdministratorDashboardAppointmentContainer;
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./notfound.css";
const NotFound = (props) => {
  const {t} = useTranslation("common")
  return (
    <>
     <Helmet>
        <title>
        {t("404.maintitle")}
        </title>
      </Helmet>
    <div className="notFoundContainer">
      <div className="c">
        <div className="_404">{t("404.404")}</div>
        <hr />
        <div className="_1">{t("404.thepage")}</div>
        <div className="_2">{t("404.wasnotfound")}</div>
        <Link className="btn bg-light" to="/" style={{color:'black'}}>
             {t("404.backtohome")}
        </Link>
      </div>
    </div>
    </>
  );
};
export default NotFound;

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AddTarget from '../../Components/LayOuts/modals/addTargetModal/addTarget'
import EditTarget from '../../Components/LayOuts/modals/addTargetModal/editTarget'
import BootstrapCustomTable from '../../Components/ReUseableComponents/BootstrapCustomTable'
import DashboardBtnList from '../../Components/ReUseableComponents/DashboardBtnList'
import Loader from '../../Components/ReUseableComponents/Loader'
import { readTargetAction } from '../../Store/Actions/administratorActions'

export default function AssignTarget(props) {
    const {t} = useTranslation("common")
    const [targetModal, setTargetModal] = useState(false)
    const [targetModalEdit, setTargetModalEdit] = useState(false)
    const target = useSelector(state => state?.administrator?.target)

    const [bdeTarget, setBdeTarget] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(readTargetAction())
    }, [dispatch])
    const loading = useSelector(state => state?.administrator?.loading)
    // const test = () => {
    //     setTargetModal(true)
    // }
    return (
        <>
        <div>
            <div className="row">
                <div className="col-6">
                    <div className="p-3">
                        <span className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.alltargets")}</span>
                    </div>
                </div>
                <div className="col-6 text-right mt-2">
                    <DashboardBtnList
                        label={t("dashboards.alldashboardkeys.tablekeys.addtargets")}
                        className="order-pay-btn w-50"
                         onClick={
                            () => setTargetModal(true)
                         }   
                    />
                </div>

                </div>
                {loading ?
                    <Loader /> : <div className="row">
                        <BootstrapCustomTable
                            columns={[
                                {
                                    formatter: (cell, row, index) => {
                                        return <span>{index + 1}</span>;
                                    },
                                    text: "Sr.",
                                },
                                { dataField: "target_id", text: t("dashboards.alldashboardkeys.tablekeys.targetid") },

                                { dataField: "assigned_to.name", text: t("dashboards.alldashboardkeys.tablekeys.assigntoname") },
                                { dataField: "amount", text: t("dashboards.alldashboardkeys.tablekeys.ammount") },
                                {
                                    formatter: (cell, row, index) => {
                                        return <span style={{ color: row?.status?.name === 'assigned' ? 'green' : row?.status?.name === 'pending' ? '#1a4774 ':'red' }}>{row?.status?.name?.toUpperCase()}</span>
                                    },
                                    text: t("dashboards.alldashboardkeys.tablekeys.status"),
                                },

                                {
                                    formatter: (cell, row) => {
                                        return (
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <DashboardBtnList
                                                            label={t("dashboards.alldashboardkeys.tablekeys.viewdetail")}
                                                            className="order-pay-btn"
                                                            onClick={() => {
                                                                props?.history?.push("/target-details", row);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <DashboardBtnList
                                                            label={t("dashboards.alldashboardkeys.tablekeys.editTarget")}
                                                            className="order-pay-btn"
                                                            onClick={() => {
                                                                // props?.history?.push("/target-details", row);
                                                                setTargetModalEdit(true)
                                                                setBdeTarget(row)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    },
                                    text: t("dashboards.alldashboardkeys.tablekeys.action"),
                                },
                            ]}
                            tabledata={target || []}
                        />
                    </div>
                }

            </div>
            <AddTarget
                show={targetModal}
                onHide={() => setTargetModal(false)}

            />
            <EditTarget
                show={targetModalEdit}
                onHide={() => setTargetModalEdit(false)}
                setBdeTarget={setBdeTarget}
                bdeTarget={bdeTarget}

            />
        </>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import img1 from "../../../Statics/Images/Slider/1.jpg";
import img2 from "../../../Statics/Images/Slider/2.jpg";
import img3 from "../../../Statics/Images/Slider/3.jpg";
import img4 from "../../../Statics/Images/Slider/4.jpg";
import img5 from "../../../Statics/Images/Slider/5.jpg";
import img6 from "../../../Statics/Images/Slider/6.jpg";
import img7 from "../../../Statics/Images/Slider/7.jpg";
import useMediaQuery from "../../ReUseableComponents/useMediaQueryHook";
import "./carousal.css";

const Carousal = (props) => {
  // eslint-disable-next-line 
  const { t, i18n } = useTranslation("common");


  const data = props.data


  const sliderData = [
    {
      img: img3,
      id: "img3",
      heading: t("home.mainslider.headingsl1"),
      description: t("home.mainslider.descriptionsl1"),
      description1: t("home.mainslider.description1sl1"),
    },
    {
      img: img2,
      id: "img2",
      heading: t("home.mainslider.headingsl2"),
      description: t("home.mainslider.descriptionsl2"),
      description1: t("home.mainslider.description1sl2"),
    },

    {
      img: img4,
      id: "img4",
      heading: t("home.mainslider.headingsl3"),
      description: t("home.mainslider.descriptionsl3"),
    },
    {
      img: img6,
      id: "img6",
      heading: t("home.mainslider.headingsl4"),
      description: t("home.mainslider.descriptionsl4"),
    },
    {
      img: img1,
      id: "img1",
      heading: t("home.mainslider.headingsl5"),
      description: t("home.mainslider.descriptionsl5"),
    },

    {
      img: img7,
      id: "img7",
      heading: t("home.mainslider.headingsl6"),
      description: t("home.mainslider.descriptionsl6"),
    },
    {
      img: img5,
      id: "img5",
      heading: t("home.mainslider.headingsl7"),
      description: t("home.mainslider.descriptionsl7"),
    },
  ];

  const autoInProperties = {
    indicators: true,
    arrows: false,
    speed: 3000,
    autoplaySpeed: 5000,
    lazyLoad: 'progressive',
    transitionDuration: 3000,
    infinite: true,
    autoplay: true,
    dots: true,
    fade: true,
    cssEase: 'linear',
    loop: true,
  };

  const isMacDesktop = useMediaQuery('(max-width: 768px)');

  return (
    <div >
      <Slider {...autoInProperties}>
        {data?.length < 1 ? sliderData?.map((each, index) => (
          <div key={index} style={{ width: "100%", height: "100%" }}>
            <div className="slider-wrapper ">
              <div className="slider" >
                <img
                  alt="Main Slider"
                  className="img-fluid"
                  style={{ objectFit: 'cover', width: '100%', height: '550px' }}
                  src={each?.img}
                />
              </div>
              <div className="container cen">

                <div className="text-left justify-content-center slider-overlay" style={{ width: '90%' }}>
                  <div className="caption mb-5">

                    <div className="d-flex align-baseline typography_Heading">
                      <div>
                        <div className="">


                          <span className="mb-2 fadeInDown"
                            style={{ fontSize: isMacDesktop ? '32px' : '' }}
                          >{each?.heading}</span >

                        </div>
                      </div>

                    </div>
                    <div className="typograpy_title d-none d-md-block fadeInUp">
                      {/* <p className={`mb-0`}> {each?.heading}</p> */}
                      <p className={`mb-0`}> {each?.description}</p>
                      <p className={`mb-0`}> {each?.description1}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        )) : data?.map((each, index) => (
          <div key={index} style={{ width: "100%", height: "100%" }}>
            <div className="slider-wrapper ">
              <div className="slider" >
                <img
                  alt="Main Slider"
                  className="img-fluid"
                  style={{ objectFit: 'cover', width: '100%', height: '550px' }}
                  src={each?.image}
                />
              </div>
              <div className="container cen">

                <div className="text-left justify-content-center slider-overlay" style={{ width: '90%' }}>
                  <div className="caption mb-5">

                    <div className="d-flex align-baseline typography_Heading">
                      <div>
                        <div className="">


                          <span className="mb-2 fadeInDown"
                            style={{ fontSize: isMacDesktop ? '32px' : '' }}
                          >{each?.heading}</span >

                        </div>
                      </div>

                    </div>
                    <div className="typograpy_title d-none d-md-block fadeInUp">
                      {/* <p className={`mb-0`}> {each?.heading}</p> */}
                      <p className={`mb-0`}> {each?.text1}</p>
                      <p className={`mb-0`}> {each?.text2}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousal;

import * as firebase from "firebase/app";
// import { getMessaging } from "firebase/messaging/sw";
import * as test  from "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDJaANUaQbwqdsz9l482rULaRXVCbuKHf4",
  authDomain: "dmfr-c6a08.firebaseapp.com",
  projectId: "dmfr-c6a08",
  storageBucket: "dmfr-c6a08.appspot.com",
  messagingSenderId: "22007544673",
  appId: "1:22007544673:web:a5075dfc23e2d606c8a320",
  measurementId: "G-6FT4F50R79"
});
const messaging = test.getMessaging(initializedFirebaseApp);
export { messaging , test};

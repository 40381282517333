import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removerDoctotByAdmin } from "../../../../Store/Actions/administratorActions";
import { deleteMedicine } from "../../../../Store/Actions/pharmacyActions";
import DashboardBtnList from "../../../ReUseableComponents/DashboardBtnList";

const MedicineAlertModal = (props) => {
    const {t} = useTranslation("common")
    const dispatch = useDispatch();
    const deleteHandler = () => {
        const apiData = {
            id: props?.data?.id,
            status: { id: 2, name: "inactive" },
        };
        dispatch(deleteMedicine(apiData));
        props.onHide()
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="dashboardBtnList-item-active" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.confirmation")}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("dashboards.alldashboardkeys.tablekeys.areyousureyouwanttodeletemed")}</p>
                <div className="row">
                    <div className="col-6">
                        <DashboardBtnList
                            label={"Remove "}
                            className="appointment-reschdule-btn"
                            onClick={deleteHandler}
                        />
                    </div>
                    <div className="col-6">
                        <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.cancel")}
                            className="order-pay-btn"
                            onClick={props.onHide}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MedicineAlertModal;

import React from "react";
const HeaderBtn = (props) => {
  return (
    <button
      style={props.style}
      className={`${props.className} pb-2`}
      
      onClick={props.onClick}
    >
      <span>{props.icon}</span>
      <span style={props.labelStyle} className={"hvr-icon-forward"}>
        {props.label}
        {props.cart ? (
          <i className="fas fa-shopping-cart hvr-icon ml-2"></i>
        ) : (
          <i
            className="fas fa-arrow-right hvr-icon ml-2"
            style={{ fontSize: "12px" }}
          ></i>
        )}
      </span>
    </button>
  );
};

export default HeaderBtn;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProfileTable from "../../../../Dashboards/patientDashboard/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import { patientBookOrder } from "../../../../Store/Actions/patientActions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const BookOrderModal = (props) => {
  const { t } = useTranslation("common");
  const { viewData, type, onHide, prescriptionId } = props;
  const [tableData, settableData] = useState([viewData]);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  useEffect(() => {
    settableData(viewData);
  }, [viewData]);
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.bookorder")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflowY: "scroll" }}>
            {type === "medicine" && tableData?.length > 0 ? (
              <ProfileTable
                tableHead={[
                  t("dashboards.alldashboardkeys.tablekeys.name"),

                  t("dashboards.alldashboardkeys.tablekeys.testid"),

                  t("dashboards.alldashboardkeys.tablekeys.price"),
                  "Quantity",
                  t("dashboards.alldashboardkeys.tablekeys.action"),
                ]}
                data={tableData?.map((item, index) => {
                  return [
                    item?.medicine?.name,
                    item?.medicine?.medicine_id,
                    item?.medicine?.sales_price,
                    <input
                      className="input-login-modal"
                      defaultValue={tableData[index]?.quantity}
                      // value={count}
                      type="number"
                      onChange={(e) =>
                        // settableData({ tableData,quantity: Number(e.target.value) })
                        (item.quantity = Number(e.target.value))
                      }
                    />,
                    <span
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      className="ml-4"
                      onClick={() => {
                        const test = [...tableData];
                        test.splice(index, 1);
                        settableData(test);
                        if (test.length === 0) {
                          onHide();
                        }
                      }}
                    >
                      x
                    </span>,
                  ];
                })}
              />
            ) : null}
            {type === "test" && tableData?.length > 0 ? (
              <ProfileTable
                tableHead={[
                  t("dashboards.alldashboardkeys.tablekeys.name"),

                  t("dashboards.alldashboardkeys.tablekeys.testid"),

                  t("dashboards.alldashboardkeys.tablekeys.price"),
                  t("dashboards.alldashboardkeys.tablekeys.action"),
                ]}
                data={tableData?.map((item, index) => {
                  return [
                    item?.test_id,
                    item?.name,
                    item?.price,

                    <span
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      className="ml-4"
                      onClick={() => {
                        const test = [...tableData];
                        test.splice(index, 1);
                        settableData(test);
                        if (test.length === 0) {
                          onHide();
                        }
                      }}
                    >
                      x
                    </span>,
                  ];
                })}
              />
            ) : null}
            <div
              className="btn btn-block btn-danger"
              onClick={async () => {
                if (!loading) {
                  setloading(true);
                  if (type === "medicine") {
                    const res = await dispatch(
                      patientBookOrder(
                        {
                          id: user?.id,
                          delivery_type: { id: 2, name: "delivery" },
                          medicines: tableData?.map((item, index) => {
                            return {
                              medicine: item?.medicine?.id,
                              quantity: Number(tableData[index]?.quantity),
                            };
                          }),
                        },
                        false
                      )
                    );
                    setloading(false);

                    if (!res) {
                      onHide();
                    } else {
                      props?.history?.push("/Order-details", res);
                    }
                  } else if (type == "test") {
                    const res = await dispatch(
                      patientBookOrder(
                        {
                          id: user?.id,
                          delivery_type: { id: 2, name: "delivery" },
                          tests: tableData?.map((item) => {
                            return {
                              test: item?.id,
                              quantity: 1,
                            };
                          }),
                          prescription_id: prescriptionId,
                        },
                        false
                      )
                    );
                    setloading(false);
                    if (!res) {
                      onHide();
                    } else {
                      props?.history?.push("/Order-details", res);
                    }
                  }
                }
              }}
            >
              {loading ? (
                <Loader height={20} width={20} type="ThreeDots" color="#fff" />
              ) : (
                t("dashboards.alldashboardkeys.tablekeys.placeorder")
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookOrderModal;

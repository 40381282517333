import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { getMyDoctors } from "../../Store/Actions/patientActions";

const MyDoctors = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const myDoctors = useSelector((state) => state?.patient?.myDoctors);

  const loader = useSelector((state) => state?.logIn?.loader);
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    dispatch(getMyDoctors(apiData));

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.mydoctor")}</h4>
        </div>
      </div>
      <div className="row">
        {/* <ProfileTable
          tableHead={["Name", "Phone", "Email"]}
          data={myDoctors?.map((item) => {
            return [item?.name, item?.phone_number, item?.email_address];
          })}
        /> */}
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.name") },
              { dataField: "phone_number", text: t("dashboards.alldashboardkeys.tablekeys.phone") },
              { dataField: "email_address", text: t("dashboards.alldashboardkeys.tablekeys.email") },
              {
                formatter: (cell, row) => {
                  return (
                    <>
                      <DashboardBtnList
                        label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                        className="order-pay-btn"
                        onClick={() =>
                          props?.history?.push("/patient-doctot-details", row)
                        }
                      />
                    </>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={myDoctors}
          />
        )}
      </div>
    </div>
  );
};

export default MyDoctors;

import axios from "axios";
import i18next from "i18next";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import {
  isJSON,
  logout,
  saveUser,
  setToken,
  setUserRole,
} from "../../Utils/auth.util";
import { signInConstansts } from "../Constants/loginConstant";

export const loginUser = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.login(data);
    if (
      response?.data?.response_code === 200 &&
      response?.data?.response_data?.session_token_type !== "super_admin"
    ) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return response?.data?.response_data?.session_token_type;
    } else if (response?.data?.response_code === 1000) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      if (
        response?.data?.response_data?.session_token_type === "patient" &&
        !response?.data?.response_data?.user?.email_address_is_verified
      ) {
        // alert("Email Not Verified");
        dispatch({
          type: signInConstansts.EMAIL_MODAL,
          payload: true,
        });
        dispatch({
          type: signInConstansts.SIGN_IN,
          payload: response?.data?.response_data?.user,
        });
        dispatch({
          type: signInConstansts.USER_TYPE,
          payload: response?.data?.response_data?.session_token_type,
        });
      }
      if (
        response?.data?.response_data?.user?.email_address_is_verified &&
        !response?.data?.response_data?.user?.phone_number_is_verified
      ) {
        dispatch({
          type: signInConstansts.SET_LOADER,
          payload: false,
        });
        dispatch(
          resendPhoneOtp({ id: response?.data?.response_data?.user?.id })
        );
        dispatch({
          type: signInConstansts.PHONE_MODAL,
          payload: true,
        });
        dispatch({
          type: signInConstansts.USER_TYPE,
          payload: response?.data?.response_data?.session_token_type,
        });
        dispatch({
          type: signInConstansts.SIGN_IN,
          payload: response?.data?.response_data?.user,
        });
      }

      return "error";
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });

      if (response?.data?.response_data?.session_token_type === "super_admin") {
        toast.error("Invalid username/password!");
        dispatch({
          type: signInConstansts.SET_ERROR,
          payload: "Invalid username/password!",
        });
      } else {
        toast.error(response?.data?.response_message);
        dispatch({
          type: signInConstansts.SET_ERROR,
          payload: response?.data?.response_message,
        });
      }

      return "error";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const forgotPassword = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.forgotPassword(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.emailhasbeensenttoemail"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {}
};
export const resetPassword = (data) => async (dispatch) => {
  try {
    const response = await apiServices.resetPassword(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.successfullpasswordreset"));
      return "success";
    } else {
      toast.error(response?.response_message);
      return "fail";
    }
  } catch (error) {
    toast.error(error);
  }
};

export const googleSignIn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    const response = await apiServices.googlesignin(data);
    localStorage.setItem("response", JSON.stringify(response));
    if (
      response?.data?.response_code === 200 &&
      response?.data?.response_data?.session_token_type == "patient"
    ) {
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.GOOGLE_USER_LOGIN,
        payload: response?.data?.response_data?.user,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_PHONE_NUMBER,
        payload: response?.data?.response_data?.user?.phone_number,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return [
        response?.data?.response_data?.session_token_type,
        response?.data?.response_data?.user,
      ];
    } else if (response?.data?.response_code == 7002) {
      return "invalid";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    toast.error(error);
  }
};

export const facebookSignIn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    const response = await apiServices.facebooksignin(data);
    localStorage.setItem("response", JSON.stringify(response));
    if (
      response?.data?.response_code === 200 &&
      response?.data?.response_data?.session_token_type == "patient"
    ) {
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.GOOGLE_USER_LOGIN,
        payload: response?.data?.response_data?.user,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });

      dispatch({
        type: signInConstansts.SET_ERROR,
        payload: null,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.data?.response_data?.user,
      });
      dispatch({
        type: signInConstansts.USER_PHONE_NUMBER,
        payload: response?.data?.response_data?.user?.phone_number,
      });
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      toast.info(i18next.t("common:toastmessages.loginSuccess"));
      return [
        response?.data?.response_data?.session_token_type,
        response?.data?.response_data?.user,
      ];
    } else if (response?.data?.response_code == 7002) {
      toast.error("Invalid User Name or Password");

      return "invalid";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getUser = () => async (dispatch) => {
  const getUserFromLocal = localStorage.getItem("user");
  let user = getUserFromLocal;
  const getUserTypeFromLocal = localStorage.getItem("userRole");
  let userRole = getUserTypeFromLocal;
  let user1 = isJSON(user);
  let userRole1 = isJSON(userRole);

  dispatch({
    type: signInConstansts.GET_USER_FROM_LOCAL,
    payload: user1,
  });
  dispatch({
    type: signInConstansts.USER_TYPE,
    payload: userRole1,
  });
};
export const setUser = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SIGN_IN,
    payload: data,
  });
  saveUser(data);
};
export const emailModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.EMAIL_MODAL,
    payload: data,
  });
};
export const addPhoneModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.ADD_PHONE_MODAL,
    payload: data,
  });
};
export const phoneModalToggle = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.PHONE_MODAL,
    payload: data,
  });
};

export const logoutUser = () => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    await apiServices.logout();
    logout();
    dispatch({
      type: signInConstansts.LOG_OUT_USER,
      payload: null,
    });
    toast.info(i18next.t("common:toastmessages.userlogout"));
  } catch (error) {
    console.log("Login error", error);
  }
};

export const verifyEmail = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.verifyEmail(data);
    if (response?.data?.response_code === 200) {
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      dispatch({
        type: signInConstansts.EMAIL_VERIFY,
        payload: response?.data?.response_data?.user,
      });
      dispatch(resendPhoneOtp({ id: response?.data?.response_data?.user?.id }));
      toast.info(i18next.t("common:toastmessages.emailverified"));
      return true;
    } else {
      toast.error(response?.data?.response_message);
      return false;
    }
  } catch (error) {
    console.log("EMAIL_VERIFY ERROR", error);
  }
};
export const verifyNumber = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.verifyNumber(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.USER_TYPE,
        payload: response?.data?.response_data?.session_token_type,
      });
      setToken({
        key: response?.data?.response_data?.session_token,
        type: response?.data?.response_data?.session_token_type,
      });
      saveUser(response?.data?.response_data?.user);
      setUserRole(response?.data?.response_data?.session_token_type);
      dispatch({
        type: signInConstansts.Number_VERIFY,
        payload: response?.data?.response_data?.user,
      });
      toast.info(i18next.t("common:toastmessages.phoneverified"));
      return "success";
    } else {
      toast.error(response?.data?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const resendPhoneOtp = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.resendPhoneOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.phoneotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};
export const sendPhoneOtp = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.sendPhoneOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.phoneotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};
export const resendemailOtp = (data) => async (dispatch) => {
  try {
    const response = await apiServices.resendemailOtp(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.emailotpresend"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("verify Number error", error);
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.changePassword(data);
    if (response?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.success("Password changed successfully");
      return "true";
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message);
      return "fail";
    }
  } catch (error) {
    console.log(error);
  }
};

export const notifyEmail = (data) => async (dispatch) => {
  // const {t} = useTranslation("common")

  try {
    const response = await apiServices.notifyEmail(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.notifyemail"));
      return true;
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("notification email error", error);
  }
};

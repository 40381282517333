import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img1 from "../../Statics/Images/news/1.png";
import { filterComponentData } from "../../Utils/functions";
import { useTranslation } from "react-i18next";

const SocialMediaInnerPage = (props) => {
  const { t, i18n } = useTranslation("common");

  const [newsdetailpage, setNewsData] = useState(props.location.state);
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);
  const newspage = filterComponentData(
    componetcms,
    "news_and_media_heading",
    lang
  );
  const newssocialmediacard = filterComponentData(
    componetcms,
    "new_social_media_post_cards",
    lang
  );

  return (
    <>
      <Helmet>
        <title>News Details - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <div className="container title-wrap text-center">
        <div className="breadcrumb-content my-0">
          <a
            href="/news#news"
            className="mr-2 font-weight-bold"
            style={{
              color:
                window.location?.hash === "#social_media" ||
                window.location?.hash === ""
                  ? "black"
                  : "red",
            }}
          >
            {newspage?.news ? newspage?.news : t("news.tabheading2")}
          </a>
          <span style={{ color: "#0e5684" }}>|</span>
          <a
            href="/news#social_media"
            style={{
              color:
                window.location?.hash === "#social_media" ||
                window.location?.hash === ""
                  ? "red"
                  : "black",
            }}
            className="ml-2 font-weight-bold"
          >
            {newspage?.socialmedia
              ? newspage?.socialmedia
              : t("news.tabheading1")}
          </a>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-8 ">
            <img
              src={newsdetailpage?.image ? newsdetailpage?.image : img1}
              alt="news"
              width="70%"
            />
            <div style={{ fontSize: "13px" }} className="mt-2">
              <span>
                <i className="fa fa-user" aria-hidden="true"></i> &nbsp;&nbsp;
                {newsdetailpage?.company ? newsdetailpage?.company : "DMFR"}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <i className="fas fa-calendar-alt"></i> &nbsp;&nbsp;{" "}
                {newsdetailpage?.date ? (
                  newsdetailpage?.date
                ) : (
                  <>4 August 2019</>
                )}
              </span>
            </div>
            <h5
              className="mt-3 ntitles"
              style={{
                textTransform: "none",
                marginBottom: "10px",
                color: "#0e5684",
              }}
            >
              {newsdetailpage?.heading}
            </h5>
            <div>
              <span className="blue-heading" style={{ fontSize: "18px" }}>
                {newsdetailpage?.text ? (
                  newsdetailpage?.text
                ) : (
                  <>
                    DMFR Molecular Lab & Diagnostics,
                    <span className="banglafont">
                      BD‡iv‡ci weL¨vZ ¯^v¯’¨ c«wZôvb
                    </span>{" "}
                    EW Villa Medica Bangladesh and
                    <span className="banglafont">
                      Gi mn‡hvwMZvq 5B AvM÷ 2019 Bs ‡_‡K gvme¨vcx webvg~‡j¨
                      ডেঙ্গু ‡ivM cix¶v I civgk© শুরু K‡i‡Q| GB Kvh©µ‡gi AvIZvq
                      c«wZw`b mKvj 8Uv ‡_‡K ivZ 8Uv পর্যন্ত wWGgGdAvi gwjKyjvi
                      j¨ve এণ্ড WvqMbw÷Km&amp;, bvfvbv wbDevwi প্লেস, 4/1/G (7g
                      Zjv) ‡mvenvbevM, wgicyi ‡ivW, XvKv-1207 G webvg~‡j¨ ডেঙ্গু
                      ‡ivMxi cix¶v I civgk© Pj‡Q|
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="w-100">
              <h5
                style={{
                  textTransform: "none",
                  borderBottom: "1px #ccc solid",
                  paddingBottom: "12px",
                  marginBottom: "12px",
                }}
              >
                <Link to="/news" style={{ color: "red " }}>
                  {"Recent Post"}
                </Link>
              </h5>
            </div>
            {newssocialmediacard?.map((item) => (
              <>
                <div
                  className=" row w-100 pb-5"
                  style={{ cursor: "pointer" }}
                  onClick={() => setNewsData(item)}
                >
                  <div className="col-4" style={{ background: "#f9f9f9" }}>
                    <img src={item?.image} width={100} height={80} alt="news" />
                  </div>
                  <div className="col-8" style={{ background: "#f9f9f9" }}>
                    <h6 className="blue-heading">{item?.heading}</h6>
                    <span style={{ fontSize: "13px" }}>
                      <i className="fas fa-calendar-alt"></i> &nbsp;&nbsp;
                      {item?.date}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaInnerPage;

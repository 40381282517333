import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../Utils/functions";
import "./pakagesFemale.css";
const PakagesFemale = (props) => {
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);
  const packages_view_more_button = filterComponentData(
    componetcms,
    "packages_view_more_button",
    lang
  );
  const { t } = useTranslation("common");
  return (
    <>
      <div className="pakage-female-box pt-4 px-2 pb-1">
        <div className="pkg-female-name text-center text-white">
          <h6 style={{ fontWeight: "bold" }}>{props.head}</h6>
        </div>
        <div className="pkg-female-price text-center text-white">
          <p style={{ fontWeight: "bold" }}>{props.price}</p>
        </div>
      </div>
      <div className="text-center mt-3">
        <span
          style={{
            color: "#e92125",
            fontWeight: "bold",
            fontSize: "14px",
            cursor: "pointer",
          }}
          className="hvr-icon-forward"
          onClick={props?.onClick}
        >
          {packages_view_more_button?.label
            ? packages_view_more_button?.label
            : t("femalepakages.viewmore")}{" "}
          <i
            className="fas fa-arrow-right hvr-icon"
            style={{ fontSize: "10px", paddingBottom: "5px" }}
          ></i>
        </span>
      </div>
    </>
  );
};

export default PakagesFemale;

import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { resetPassword } from "../../Store/Actions/loginActions";
const ResetPassword = (props) => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data.password === data.confirmPassword) {
      const apiData = {
        id: props?.match?.params?.id,
        token: props?.match?.params?.token,
        new_password: data.password,
      };
      const success = await dispatch(resetPassword(apiData));

      if (success === "success") {
        props.history.push("/");
      }
    } else {
      toast.error(t("toastmessages.pleaseprovidesamepassword"));
    }
  };
  return (
    <>
     <Helmet>
        <title>
        {t("dashboards.alldashboardkeys.tablekeys.maintitleresetpassword")}
        </title>
      </Helmet>
    <div className="row mb-5 mt-4">
      <div className="container d-flex justify-content-center mt-5 mb-5">
        <div className="col-lg-4">
          <div className="card" style={{ width: "22rem" }}>
            <div className="card-body">
              <h5 className="modal-title"> {t("dashboards.alldashboardkeys.tablekeys.resetpassword")}</h5>
              <div className="row px-3 mb-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mt-4">
                    <div className="col-12">
                      <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.password")}</span>
                      <input
                        className="input-login-modal"
                        type="password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.password?.message ? (
                        <div className="text-error">
                          {errors?.password?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.confirmpassword")}</span>
                      <input
                        className="input-login-modal"
                        type="password"
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "this field is required field",
                          },
                        })}
                      />
                      {errors?.confirmPassword?.message ? (
                        <div className="text-error">
                          {errors?.confirmPassword?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={t("dashboards.alldashboardkeys.tablekeys.resetpassword")}
                    className="headerBtn-red btn-block mx-auto"
                    style={{ width: "85%", textAlign: "center" }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { myDoctorBoard } from "../../Store/Actions/docotorActions";
import moment from 'moment'
const MyBoard = (props) => {
  const { t, i18n } = useTranslation("common")
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.logIn?.loader);
  const user = useSelector((state) => state?.logIn?.user);
  const myBoards = useSelector((state) => state?.doctor?.myBoards);
  const [dataTable, setdataTable] = useState(myBoards?.past);
  const [selectedBoards, setSelectedBoards] = useState(t("dashboards.doctordashboard.myboards.pastboard"));
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    dispatch(myDoctorBoard(apiData));
    setdataTable(myBoards?.past);
    setSelectedBoards(t("dashboards.doctordashboard.myboards.pastboard"));


    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setdataTable(myBoards?.past);
    setSelectedBoards(t("dashboards.doctordashboard.myboards.pastboard"));
    // eslint-disable-next-line
  }, [myBoards]);
  
  const tableDataHandler = (item) => {
    // setSelectedBoards(item)
    if (item === t("dashboards.doctordashboard.myboards.pastboard")) {
      setdataTable(myBoards?.past);
    } else if (item === t("dashboards.doctordashboard.myboards.newboard")) {
      setdataTable(myBoards?.today);
    }
  };
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">My Board</h4>
        </div>
      </div>
      <div className="row mb-4">
        <div className="container">
          {[t("dashboards.doctordashboard.myboards.pastboard"), t("dashboards.doctordashboard.myboards.newboard")].map((item, index) => (
            <div
              className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3"
              key={index + 1}
              onClick={() => {
                setSelectedBoards(item);
                tableDataHandler(item);
              }}
            >
              <DashboardBtnList
                label={item}
                labelStyle={selectedBoards === item ? { color: "#fff" } : ""}
                className={
                  selectedBoards === item
                    ? "dashboardBtnList-item-active py-2"
                    : "default-color-and-hover py-2"
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "board_id", text: t("dashboards.doctordashboard.myboards.tablekeys.boardid") },
              { dataField: "created_by.name", text: t("dashboards.doctordashboard.myboards.tablekeys.createdby") },
              {
                formatter: (cell, row) => {
                  return <span>{moment.unix(row.created_at).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.doctordashboard.myboards.tablekeys.createdat"),
              },

              {
                formatter: (cell, row) => {
                  return row?.doctors?.map((item) => {
                    return <li>{item?.name}</li>;
                  });
                },
                text: t("dashboards.doctordashboard.myboards.tablekeys.doctors"),
              },

              {
                formatter: (cell, row) => {
                  return (
                    <>
                      <DashboardBtnList
                        label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.viewdetail")}
                        className="order-pay-btn"
                        onClick={() => {
                          const item = { ...row, from: selectedBoards };
                          props?.history?.push("/boards-details", item);
                        }}
                      />
                    </>
                  );
                },
                text: t("dashboards.doctordashboard.myboards.tablekeys.action"),
              },
            ]}
            tabledata={dataTable?.length > 0 ? dataTable : []}
          />
        )}
      </div>
    </div>
  );
};

export default MyBoard;

import { combineReducers } from "redux";
import { staticReducer } from "./staticReducer";
import { signUpReducer } from "./signUpReducer";
import { logInReducer } from "./loginReducer";
import { patientReducer } from "./patientReducer";
import { doctorReducer } from "./doctorReducer";
import { administratorReducer } from "./administratorReducer";
import { pharmacyReducer } from "./pharmacyReducer";
import { labAdminReducer } from "./labAdminReducer";
import { cmsReducer } from "./cmsReducers";

const reducers = combineReducers({
  static: staticReducer,
  signUp: signUpReducer,
  logIn: logInReducer,
  patient: patientReducer,
  doctor: doctorReducer,
  administrator: administratorReducer,
  pharmacy: pharmacyReducer,
  labAdmin : labAdminReducer,
  cms:cmsReducer,
});
export default reducers;

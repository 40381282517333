import React from 'react'
import { Helmet } from 'react-helmet'

const Biochemistryimmunology = () => {
    return (
        <>
         <Helmet>
        <title>Bio Chemistry Immunology - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
            Biochemistryimmunology
        </>
    )
}

export default Biochemistryimmunology

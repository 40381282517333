import React, { useState } from "react";
import { Helmet } from "react-helmet";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
import DashboardBtnList from "../ReUseableComponents/DashboardBtnList";
import collectionathome from "../../Statics/Images/banner/collectionathome.jpg";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";

const Gallery = () => {
  //import All images from Client folder from static/images/clients
  // Note to add any image in client slider add image in client folder and be cautious for image size
  function importAll(data) {
    let images = {};
    data.keys().map((item, index) => {
      images[item.replace("./", "")] = data(item);
      return "";
    });
    return images;
  }
  const componetcms = useSelector((state) => state.cms.components);

  const lang = useSelector((state) => state?.cms?.language)

  const banner = filterComponentData(componetcms, "banners", lang);


  const images = importAll(
    require.context("../../Statics/Images/gallery", false, /\.(png|jpe?g|svg)$/)
  );
  const imagesData = Object.keys(images);

  const [selectedTab2, setSelectedTab2] = useState("Pictures");


  const tabHandler2 = (item) => {
    setSelectedTab2(item);
  };
  const buttonname3 = ["Pictures", "Videos"];
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[19]?.sublink1 ? banner[19]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[19]?.sublink2 ? banner[19]?.sublink2 : "News & Media",
      subDash: ">",
      subLink: "/gallery",
    },
    {
      subLinkName: banner[19]?.sublink3
        ? banner[19]?.sublink3
        : "Gallery",
      subDash: ">",
      subLink: "/gallery",
    },
  ];
  return (
    <>
            <Helmet>
        <title>
        Gallery - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[19]?.image ? banner[19]?.image : collectionathome
          })`}
          heading={
            banner[19]?.heading ? banner[19]?.heading : "Gallery"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
      <div className="container">
        <div className="row ">
          <div className="col-12 text-center">
            {buttonname3.map((item, index) => (
              <div
                className="d-flex d-inline-flex my-3 "
                key={index + 1}
                onClick={() => tabHandler2(item)}
              >
                <DashboardBtnList
                  label={item}
                  bntStyle={{
                    borderRadius:
                      index === 0
                        ? "50px 0px 0px 50px"
                        : index === buttonname3.length - 1
                        ? "0px 50px 50px 0px"
                        : "",
                  }}
                  className={
                    selectedTab2 === item
                      ? "dashboardBtnList-itemgallery-active px-4 py-2"
                      : "default-color-and-hovergallery px-4 py-2"
                  }
                />
              </div>
            ))}
          </div>
        </div>
        {selectedTab2 === "Pictures" ? (
          <div className="row">
            {imagesData?.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                  <a data-fancybox="gallery" href={images[item].default}>
                    <img
                      width="350px"
                      height="250px"
                      alt="gallery"
                      src={images[item].default}
                    />
                  </a>
                  
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4 my-4">
              <iframe
                width="300"
                height="280"
                src="https://www.youtube.com/embed/j3zo0u1JMX0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;

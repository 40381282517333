import React from "react";
import { Modal } from "react-bootstrap";

const HomePageLocationModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="popUpModal"
      >
        <Modal.Header closeButton style={{background:'#fff'}}>
        <div style={{ width: "600px" }}>
            <iframe
              // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3642.563566618186!2d89.06934151498766!3d24.081668984427118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fea15b22db9edb%3A0x8c4abf8b7ccc6a14!2sDMFR%20Molecular%20Lab%20%26%20Diagnostic!5e0!3m2!1sen!2sin!4v1626701150985!5m2!1sen!2sin"
              src={props?.location}
              width="100%"
              height="350px"
              style={{ border: "10px solid #fff" }}
              allowfullscreen=""
              loading="lazy"
              title="titiel"
            ></iframe>
          </div>
        </Modal.Header>
        {/* <Modal.Body  style={{background:'#fff'}}>
     
        </Modal.Body> */}
      </Modal>
    </>
  );
};

export default HomePageLocationModal;

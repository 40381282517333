import React from "react";
import { Link } from "react-router-dom";

const BannerImageWithText = (props) => {
  return (
    <>
      <div className="mb-5">
        <div
          className="text-white"
          style={{
            top:'0',
            backgroundImage: props?.backimg,
            height: props?.height,
            backgroundRepeat: 'no-repeat !important',
            backgroundSize: "cover",
            backgroundPosition:props?.backposit,
            borderRadius:"0px"

          }}
        >
          <div className={`container ${props?.conmarpad}`} style={props?.container_style}>
            <h1
              className="Typograpy_Text_h1"
              style={{ fontSize: props.fontsize }}
            >
              {props?.heading}
            </h1>
            
            <p className="mt-2 Typograpy_Text_h4">
              {props?.LinksBan.map((ob, index) => {
                return (
                  <>
                    <Link
                      className="linkhov"
                      style={{ textDecoration: "none", color: "#fff" }}
                      to={ob?.subLink}
                    >
                      <span className="me-1">{ob?.subLinkName} </span>
                    </Link>
                    <span className="me-1">{ob?.subDash}</span>
                  </>
                );
              })}
              {props?.subHeading}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerImageWithText;

import { React } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AdministratorAddDoctorSlots } from "../../../../Store/Actions/administratorActions";

const selectDay = [
  { label: "monday", value: "monday", key: 1 },
  { label: "tuesday", value: "tuesday", key: 2 },
  { label: "wednesday", value: "wednesday", key: 3 },
  { label: "thursday", value: "thursday", key: 4 },
  { label: "friday", value: "friday", key: 5 },
  { label: "saturday", value: "saturday", key: 6 },
  { label: "sunday", value: "sunday", key: 7 },
];
const selectWorkingSlot = [
  { label: "10:00", value: "10:00", key: 1 },
  { label: "15:00", value: "15:00", key: 1 },

  { label: "20:00", value: "20:00", key: 2 },
  { label: "25:00", value: "25:00", key: 1 },

  { label: "30:00", value: "30:00", key: 3 },
  { label: "35:00", value: "35:00", key: 3 },

  { label: "40:00", value: "40:00", key: 4 },
  { label: "45:00", value: "45:00", key: 3 },

  { label: "50:00", value: "50:00", key: 5 },
];
const selectBreakSlot = [
  { label: "05:00", value: "05:00", key: 1 },
  { label: "10:00", value: "10:00", key: 2 },
  { label: "15:00", value: "15:00", key: 3 },
];
const AddSlotsModal = (props) => {
  const { t } = useTranslation("common")

  const item = { key: "addSlot" };
  function createSlots(slotConfig) {
    const {
      configSlotHours,
      configSlotMinutes,
      configSlotPreparation,
      timeArr,
    } = slotConfig;
    // timeArr.push({from:`${props?.viewData?.timings?.from}` ,to:`${props?.viewData?.timings?.to}`})
    let defaultDate = new Date().toISOString().substring(0, 10);
    let slotsArray = [];
    let _timeArrStartTime;
    let _timeArrEndTime;
    let _tempSlotStartTime;
    let _endSlot;
    let _startSlot;

    for (var i = 0; i < timeArr.length; i++) {
      _timeArrStartTime = new Date(
        defaultDate + " " + timeArr[i]?.from
      )?.getTime();
      _timeArrEndTime = new Date(defaultDate + " " + timeArr[i]?.to)?.getTime();
      _tempSlotStartTime = _timeArrStartTime;

      while (
        new Date(_tempSlotStartTime)?.getTime() <
        new Date(_timeArrEndTime)?.getTime()
      ) {
        _endSlot = new Date(_tempSlotStartTime);
        _startSlot = new Date(_tempSlotStartTime);

        _tempSlotStartTime = _endSlot?.setHours(
          parseInt(_endSlot?.getHours()) + parseInt(configSlotHours)
        );
        _tempSlotStartTime = _endSlot?.setMinutes(
          parseInt(_endSlot?.getMinutes()) + parseInt(configSlotMinutes)
        );

        if (
          new Date(_tempSlotStartTime)?.getTime() <=
          new Date(_timeArrEndTime)?.getTime()
        ) {
          slotsArray.push({
            from: new Date(_startSlot).toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            }),
            to: _endSlot.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            }),
          });
        }

        _tempSlotStartTime = _endSlot?.setMinutes(
          _endSlot?.getMinutes() + parseInt(configSlotPreparation)
        );
      }
    }

    if (slotsArray.length === 0) {
      toast.error(t("toastmessages.pleaseentervaliddetails"));
      return [];
    }
    let lastSlotValueTo = Object.values(slotsArray?.at(-1))[1];
    let endtimeOfDoctor = timeArr?.at(-1)?.to;
    let test1 = endtimeOfDoctor?.split(":");
    let test2 = lastSlotValueTo?.split(":");
    if (Number(test1[1]) < Number(test2[1])) {
      let test3 = configSlotPreparation?.split(":");
      let test4 = lastSlotValueTo?.split(":");
      let breakPlusSlotValue = Number(test4[1]) + Number(test3[0]);
      if (breakPlusSlotValue === 60) {
        let one = "01";
        // eslint-disable-next-line
        let a = Number(test4[0]) + Number(one);
      } else {
        let test5 = test4[0] + ":" + breakPlusSlotValue;
        slotsArray.push({ from: test5, to: endtimeOfDoctor });
      }
    } else {
      let diff = test1[1] - test2[1];
      let test3 = configSlotPreparation?.split(":");
      let test4 = lastSlotValueTo?.split(":");
      let breakPlusSlotValue = Number(test4[1]) + Number(test3[0]);
      let test5 = test4[0] + ":" + breakPlusSlotValue;
      if (diff > test3[0]) {
        slotsArray.push({ from: test5, to: endtimeOfDoctor });
      }
    }
    return slotsArray;
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let slotsA = [];

    // let timeToSplit = data?.to?.split(":");
    // let timeFromSplit = data?.from?.split(":");
    let workingValue = data?.duration?.split(":");
    let breakValue = data?.break?.split(":");
    if (workingValue === undefined && breakValue === undefined) {
      return;
    }


    let w = Number(workingValue[0]);
    var slotConfig = {
      configSlotHours: "00",
      configSlotMinutes: `${w}`,
      configSlotPreparation: `${data?.break}`,
      timeArr: [
        { from: `${data?.from}`, to: `${data?.to}` },
        //  {"startTime":"16:00", "endTime":"20:00"}
      ],
    };
    slotsA = createSlots(slotConfig);
    // slotConfig?.timeArr.push(props?.viewData?.timings)
    if (slotsA.length > 0) {
      let from = slotConfig?.timeArr[0]?.from;
      let to = slotConfig?.timeArr[0]?.to;
      // slotConfig.timeArr.push()
      const apiData = {
        id: props?.viewData?.id,
        timings: { [data?.day]: { from: from, to: to } },
        // timings:{[data?.day]: slotConfig?.timeArr},

        slots: { [data?.day]: slotsA },
      };
      dispatch(
        AdministratorAddDoctorSlots({
          ...apiData,
          timings: { ...props?.viewData?.timings, ...apiData?.timings },
        })
      );
      props.onHide();
      props?.history?.push("/administrator", item);
      // console.log("item", item);
    }

  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.addslot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div className="row">
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.selectday")}</span>
                  <Form.Control
                    as="select"
                    className="input-login-modal"
                    defaultValue={selectDay[0]}
                    custom
                    {...register("day", {})}
                  >
                    {selectDay?.map((item, index) => (
                      <option value={item?.label} key={index + 1}>
                        {item?.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.day?.message ? (
                    <div className="text-error">{errors?.day?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.duration")}</span>
                  <Form.Control
                    as="select"
                    className="input-login-modal"
                    defaultValue={selectWorkingSlot[0]}
                    custom
                    {...register("duration", {})}
                  >
                    {selectWorkingSlot?.map((item, index) => (
                      <option value={item?.label} key={index + 1}>
                        {item?.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.duration?.message ? (
                    <div className="text-error">
                      {errors?.duration?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.break")}</span>
                  <Form.Control
                    as="select"
                    className="input-login-modal"
                    defaultValue={selectBreakSlot[0]}
                    custom
                    {...register("break", {})}
                  >
                    {selectBreakSlot?.map((item, index) => (
                      <option value={item?.label} key={index + 1}>
                        {item?.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.break?.message ? (
                    <div className="text-error">{errors?.break?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.from")}</span>
                  <input
                    className="input-login-modal"
                    type="time"
                    {...register("from", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.from?.message ? (
                    <div className="text-error">{errors?.from?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.to")}</span>
                  <input
                    className="input-login-modal"
                    type="time"
                    {...register("to", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.to?.message ? (
                    <div className="text-error">{errors?.to?.message}</div>
                  ) : (
                    ""
                  )}
                </div>

                <input
                  type="submit"
                  value={t("dashboards.alldashboardkeys.tablekeys.addslot")}
                  className="headerBtn-red btn-block mx-auto"
                  // onClick={}
                  style={{ width: "85%", textAlign: "center" }}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSlotsModal;

import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { BASEURL } from "../../../../services/HttpProvider";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UploadReportModal = (props) => {
  const { t } = useTranslation("common");
  const [file, setFile] = useState();
  const [loading, setloading] = useState(false);

  const userRole = useSelector((state) => state?.logIn?.userRole);

  const uploadFile = async (event) => {
    event.preventDefault();

    if (!loading) {
      setloading(true);
      let formdata = new FormData();
      const authToken = JSON.parse(localStorage.getItem("token"));
      if (file === undefined || file === null) {
        toast.error(t("toastmessages.pleaseuploadreport"));
        return;
      } else {
        formdata.append("report", file[0]);
        formdata.append("id", props?.viewData?.report?.id);
        let res = await axios.post(
          `${BASEURL}user/upload/test-report`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-session-key": authToken.key,
              "x-session-type": authToken.type,
            },
          }
        );
        if (res?.data?.response_code === 200) {
          toast.success(t("toastmessages.reportuploadedsuccess"));
          if (userRole === "administrator") {
            props.history.push("/administrator");
          } else if (
            userRole === "laboratory-admin" ||
            userRole === "laboratory_admin"
          ) {
            props.history.push("/lab-administrator");
          }
          // props?.history?.push("/lab-administrator");
          setloading(false);

          props?.onHide();
        } else {
          setloading(false);

          toast.success(res?.data?.response_message);
        }
      }
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <input
              type="file"
              className="form-control"
              name="upload_file"
              onChange={(e) => {
                setFile(e.target.files);
              }}
            />
            <div className="w-100 d-flex justify-content-center">
              <div
                className="btn btn-success mt-3"
                onClick={(e) => {
                  uploadFile(e);
                }}
              >
                {loading ? (
                  <Loader
                    height={20}
                    width={20}
                    type="ThreeDots"
                    color="#fff"
                  />
                ) : (
                  t("dashboards.alldashboardkeys.tablekeys.upload")
                )}
              </div>
              <div
                className="btn btn-danger mt-3 ml-3"
                onClick={() => {
                  props?.onHide();
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.cancel")}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <UploadFIle viewData={props?.viewData} /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadReportModal;

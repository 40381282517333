import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import { BASEURL } from "../../services/HttpProvider";
import logo from "../../Statics/Images/logo.png";
import { doctorUpdate } from "../../Store/Actions/docotorActions";
import { setUser } from "../../Store/Actions/loginActions";
import { fetchStaticData } from "../../Store/Actions/staticActions";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../Utils/functions";
import countryList from "react-select-country-list";

const DoctorProfile = () => {
  const { t, i18n } = useTranslation("common");
  const user = useSelector((state) => state?.logIn?.user);
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const staticData = useSelector((state) => state?.static?.staticData);
  const [loader, setLoader] = useState(false);
  const [alternatePhone, setAlternatePhone] = useState(
    user?.alternate_phone_number
  );

  const [editProfile, setEditProfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  // const error = useSelector((state) => state?.logIn?.error);

  useEffect(() => {
    dispatch(fetchStaticData());
  }, [dispatch]);
  let schema = yup.object().shape({
    // eslint-disable-next-line
    //  nicPassport : yup.string().length(14).required(false)
  });

  useEffect(() => {
    dispatch(fetchStaticData());
  }, [dispatch]);
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fileSelectHandler = (e) => {
    const file = e.target.files;
    setSelectedFile(file[0]);
  };
  const onSubmit = (data) => {
    const apiData = {
      id: user?.id,
      name: data?.name,
      address: {
        house_number: data?.housenumber || "-",
        villa_or_apartment: "-",
        area: "-",
        street: data?.street || "-",
        city: data?.city || "-",
        country: data?.country || "-",
        pobox: "-",
      },
      gender:
        data?.gender === "male"
          ? { id: 1, name: "male" }
          : data?.gender === "female"
          ? { id: 2, name: "female" }
          : { id: 3, name: "other" },
      // date_of_birth: "1998-08-05",
      nationality: JSON.parse(data?.nationality),

      alternate_phone_number:
        "+" + alternatePhone ? alternatePhone : user?.alternate_phone_number,
      nic_passport: data?.nicPassport || "--",
    };
    dispatch(doctorUpdate(apiData));

    setEditProfile(false);
  };

  const uploadHandler = async () => {
    let formdata = new FormData();
    const authToken = JSON.parse(localStorage.getItem("token"));
    if (
      selectedFile === undefined ||
      selectedFile === null ||
      selectedFile === ""
    ) {
      setLoader(true);

      toast.error(t("toastmessages.pleaseselectimage"));
      setLoader(false);

      return;
    } else {
      setLoader(true);

      formdata.append("display_picture", selectedFile);
      formdata.append("id", user?.id);

      let res = await axios.post(
        `${BASEURL}upload/user/display-picture`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-session-key": authToken.key,
            "x-session-type": authToken.type,
          },
        }
      );
      if (res?.data?.response_code === 200) {
        dispatch(setUser(res?.data?.response_data?.user));
        toast.success(res?.data?.response_message);
        setLoader(false);

        setSelectedFile("");
      } else {
        toast.error(res?.data?.response_message);
        setLoader(false);
      }
      setLoader(false);
    }
  };
  return (
    <div class="container rounded bg-white mt-5 mb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="row">
          <div class="col-md-3 border-right">
            <div className="d-flex flex-column align-items-center text-center py-3">
              <img
                src={
                  user?.display_picture === "" ? logo : user?.display_picture
                }
                alt=""
                className="img-fluid"
                style={{ borderRadius: "50%" }}
              />
            </div>
            {selectedFile ? (
              <>
                <span>{selectedFile?.name}</span>
                &nbsp;
                <span
                  style={{
                    fontSize: "16px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedFile("")}
                  className="btn btn-danger px-4 mr-1"
                >
                  x
                </span>
                <span className="btn btn-primary" onClick={uploadHandler}>
                  {loader ? (
                    <Loader
                      height={20}
                      width={20}
                      type="ThreeDots"
                      color="#fff"
                    />
                  ) : (
                    "Upload"
                  )}
                </span>
              </>
            ) : (
              <input
                style={{ width: "100%" }}
                type="file"
                id="avatar"
                name="avatar"
                accept="image/png, image/jpeg"
                onChange={fileSelectHandler}
              />
            )}
          </div>
          <div class="col-md-9 border-right">
            <div class="p-3 py-5">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="text-right">
                  {t("dashboards.doctordashboard.docprofile.head")}
                </h4>
              </div>
              <div class="row mt-2">
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field1")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          className="input-login-modal"
                          defaultValue={user?.name}
                          style={{ color: "black" }}
                          {...register("name", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.name?.message ? (
                          <div className="text-error">
                            {errors?.name?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field2")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          className="input-login-modal"
                          type="email"
                          disabled
                          style={{ color: "black" }}
                          defaultValue={user?.email_address}
                          {...register("email", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.email?.message ? (
                          <div className="text-error">
                            {errors?.email?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.email_address}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field3")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <PhoneInput
                          country={"bd"}
                          value={user?.phone_number}
                          disabled
                        />
                      </>
                    ) : (
                      <span className="mb-2">{user?.phone_number}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field4")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={user?.address?.street}
                          {...register("street", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.street?.message ? (
                          <div className="text-error">
                            {errors?.street?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.address?.street}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field5")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={user?.address?.city}
                          {...register("city", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.city?.message ? (
                          <div className="text-error">
                            {errors?.city?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.address?.city}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field6")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <Form.Control
                          as="select"
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={user?.address?.country}
                          custom
                          {...register("country", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        >
                          {options?.map((item, index) => (
                            <option value={item?.label}>{item?.label}</option>
                          ))}
                        </Form.Control>
                        {errors?.country?.message ? (
                          <div className="text-error">
                            {errors?.country?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.address?.country}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field7")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <PhoneInput
                          // country={'bd'}
                          value={user?.alternate_phone_number}
                          onChange={(phone) => setAlternatePhone(phone)}
                        />
                        {errors?.alternatePhone?.message ? (
                          <div className="text-error">
                            {errors?.alternatePhone?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">
                        {user?.alternate_phone_number}
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="patient-dashboard-text-field-label">
                    <span style={{ fontWeight: "bold" }}>
                      {t("dashboards.doctordashboard.docprofile.field8")}
                    </span>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <Form.Control
                          as="select"
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={JSON.stringify(user?.nationality)}
                          custom
                          {...register("nationality", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        >
                          {staticData?.user_nationality?.map((item, index) => (
                            <option value={JSON.stringify(item)}>
                              {item?.name}
                            </option>
                          ))}
                        </Form.Control>

                        {errors?.country?.message ? (
                          <div className="text-error">
                            {errors?.country?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">
                        {capitalize(user?.nationality?.name)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div className="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field9")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <Form.Control
                          as="select"
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={user?.gender?.name}
                          custom
                          {...register("gender", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        >
                          {/* <option value="male">male</option> */}
                          {staticData?.gender?.map((item, index) => (
                            <option value={item?.name}>{item?.name}</option>
                          ))}
                        </Form.Control>

                        {errors?.gender?.message ? (
                          <div className="text-error">
                            {errors?.gender?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">
                        {capitalize(user?.gender?.name)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="patient-dashboard-text-field-container">
                    <div className="patient-dashboard-text-field-label">
                      <span style={{ fontWeight: "bold" }}>
                        {t("dashboards.doctordashboard.docprofile.field10")}
                      </span>
                    </div>
                  </div>
                  <div className="patient-dashboard-text-field-value">
                    {editProfile ? (
                      <>
                        <input
                          style={{ color: "black" }}
                          className="input-login-modal"
                          defaultValue={user?.nic_passport}
                          type="number"
                          {...register("nicPassport", {
                            required: {
                              value: false,
                              message: "this field is required field",
                            },
                          })}
                        />
                        {errors?.nicPassport?.message ? (
                          <div className="text-error">
                            {errors?.nicPassport?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <span className="mb-2">{user?.nic_passport}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div className="row my-2">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  {editProfile ? (
                    <input
                      type="submit"
                      value={t(
                        "dashboards.doctordashboard.docprofile.updatebtnname"
                      )}
                      className="btn edit-patient-btn"
                      // onClick={() => setEditProfile(false)}
                    />
                  ) : (
                    <DashboardBtnList
                      label={t(
                        "dashboards.doctordashboard.docprofile.editbtnname"
                      )}
                      className="edit-patient-btn"
                      onClick={() => setEditProfile(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DoctorProfile;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { rescheduleDoctorAppointment } from "../../../../Store/Actions/docotorActions";

const DoctorAppointmentReschedule = (props) => {
  const {t} = useTranslation("common")
  const [reson, setreson] = useState("");
  const dispatch = useDispatch();
  const clickHandler = () => {
    if (reson === "") {
      toast.error(t("toastmessages.pleaseprovideareason"));
    } else {
      const apiData = {
        id: props?.data?.id,
        description: reson,
        
      };
      dispatch(rescheduleDoctorAppointment(apiData));
      props?.onHide();
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("dashboards.alldashboardkeys.tablekeys.reschedulerequest")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> {t("dashboards.alldashboardkeys.tablekeys.pleaseprovidereason")}</p>
          <textarea
            value={reson}
            className="w-100 p-3"
            style={{ maxHeight: "100px", minHeight: "100px" }}
            onChange={(e) => setreson(e.target.value)}
          />
          <input
            type="submit"
            value={t("dashboards.alldashboardkeys.tablekeys.rescheduleappointment")}
            className="headerBtn-red btn-block mx-auto p-4"
            onClick={clickHandler}
            style={{ width: "85%", textAlign: "center" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DoctorAppointmentReschedule;

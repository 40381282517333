import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddMedicinesPrescriptionModal = (props) => {
  const { t, i18n } = useTranslation("common");
  const allMedicines = useSelector((state) => state?.doctor?.allMedicines);
  const [mediSelection, setMediSelection] = useState([]);
  const [mediError, setmediError] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },reset
  } = useForm();
  const onSubmit = async (data) => {
    if (mediSelection.length === 0) {
      setmediError("This Is A Required Field");
    } else {
      
        const medicineData = {
          id: mediSelection[0]?.id,
          dose: [
            Number(data?.morningDose),
            Number(data?.afternoonDosage),
            Number(data?.nightDosage),
          ],
          duration: Number(data?.duration),
          name: mediSelection[0]?.name,
        };
        props.setSelectedMedi([...props?.selectedMedi, medicineData]);

        props.onHide();
        reset();

    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t(
              "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addmedicine"
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.selectedmedicine"
                    )}
                  </span>
                  <Form.Group>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(selected) => {
                        setMediSelection(selected);
                      }}
                      options={allMedicines}
                      placeholder=""
                    // selected={mediSelection}
                    />
                    {mediError ? (
                      <div className="text-error">{mediError}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.morningdose"
                    )}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      {...register("morningDose", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.morningDose?.message ? (
                      <div className="text-error">
                        {errors?.morningDose?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.afternoondose"
                    )}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      {...register("afternoonDosage", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.afternoonDosage?.message ? (
                      <div className="text-error">
                        {errors?.afternoonDosage?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <span className="label-name-login">
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.nightdose"
                    )}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={0}
                      {...register("nightDosage", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.nightDosage?.message ? (
                      <div className="text-error">
                        {errors?.nightDosage?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.durdose"
                    )}
                  </span>
                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      min={1}

                      {...register("duration", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.duration?.message ? (
                      <div className="text-error">
                        {errors?.duration?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </div>
              <input
                type="submit"
                value={t(
                  "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addmedicine"
                )}
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMedicinesPrescriptionModal;

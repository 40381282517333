import React from 'react'

const BannerImg = (props) => {
    return (
        <div>
            <img src={props.imgSrc} alt="bannerimg"   className="img-fluid"/>
        </div>
    )
}

export default BannerImg

import React, { useEffect, useState } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import covidTeamBaner from "../../Statics/Images/internal-banner/covid-team.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import AddAppointmentWeb from "../LayOuts/modals/addAppointment/AddAppointmentWeb";
import {
  consultantTab,
  OnlineconsultantImg1,
  OnlineconsultantImg3,
  OnlineconsultantImg6,
  OnlineconsultantImg7,
  OnlineconsultantImg8,
  OnlineconsultantImg9,
  OnlineconsultantImg10,
  OnlineconsultantImg11,
  OnlineconsultantImg12,
  OnlineconsultantImg13,
  OnlineconsultantImg14,
  OnlineconsultantImg15,
  OnlineconsultantImg16,
  OnlineconsultantImg17,
  OnlineconsultantImg18,
} from "../LayOuts/handlercardData/handlercarddata";
import CardHandler from "../ReUseableComponents/CardHandler";
import Teamcard from "../LayOuts/Cards/teamcardinnerhandler/teamcard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SignUpModal from "../LayOuts/modals/signUpModal/SignUpModal";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import { toast } from "react-toastify";
import AddAppointments from "../LayOuts/modals/addAppointment/AddAppointments";
import {
  fetchPublicDoctor,
  fetchStaticData,
} from "../../Store/Actions/staticActions";
import LoginModal from "../LayOuts/modals/loginModal/LoginModal";
import Loader from "../ReUseableComponents/Loader";
// import HeaderBtn from "../ReUseableComponents/HeaderBtn";

const OnlineConsultantDetailPage = (props) => {
  const [signUpModal, setSignUpModal] = useState(false);
  const [logInModal, setLogInModal] = useState(false);
  const user = useSelector((state) => state?.logIn?.user);
  const loading = useSelector((state) => state?.static?.loader);

  const userRole = useSelector((state) => state?.logIn?.userRole);
  const [buttonState, setButtonState] = useState([]);
  const viewData = props?.location?.state;
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [appointmentModalRef, setAppointmentModalRef] = useState(false);

  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state?.cms?.language);
  useEffect(() => {
    dispatch(fetchPublicDoctor());
    // eslint-disable-next-line
  }, []);
  const { publicDoctor } = useSelector((state) => state.static);

  const consultantDetailCom = filterComponentData(
    componetcms,
    "onlineconsultationdetailpage",
    lang
  );
  const functFilter = () => {
    return publicDoctor?.filter((item) => {
      if (item?.name?.toLowerCase() == viewData?.defaultname?.toLowerCase()) {
        return item;
      }
    });
  };
  const populateButtonArray = () => {
    let test = [];
    publicDoctor?.map((item) => {
      test?.push(item?.name);
    });
    setButtonState(test);
  };

  // About Our Doctors TabHandler Data
  const consultantTab = [
    {
      doc1: {
        img: OnlineconsultantImg16,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant1.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant1.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant1.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant1.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant1.detaillist3"
          ),
        ],
      },
      doc2: {
        img: OnlineconsultantImg15,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant2.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant2.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant2.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant2.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant2.detaillist3"
          ),
        ],
      },
      doc3: {
        img: OnlineconsultantImg13,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant3.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant3.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant3.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant3.detaillist2"
          ),
        ],
      },
      doc4: {
        img: OnlineconsultantImg6,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant4.detaillist4"
          ),
        ],
      },
      doc5: {
        img: OnlineconsultantImg17,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant5.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant5.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant5.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant5.detaillist2"
          ),
        ],
      },
      doc6: {
        img: OnlineconsultantImg18,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant6.detaillist4"
          ),
        ],
      },
      doc7: {
        img: OnlineconsultantImg14,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant7.detaillist4"
          ),
        ],
      },
      doc8: {
        img: OnlineconsultantImg8,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant8.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant8.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant8.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant8.detaillist2"
          ),
        ],
      },
      doc9: {
        img: OnlineconsultantImg7,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant9.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant9.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant9.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant9.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant9.detaillist3"
          ),
        ],
      },
      doc10: {
        img: OnlineconsultantImg9,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist4"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist5"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant10.detaillist6"
          ),
        ],
      },
      doc11: {
        img: OnlineconsultantImg10,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant11.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant11.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant11.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant11.detaillist2"
          ),
        ],
      },
      doc12: {
        img: OnlineconsultantImg1,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist4"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist5"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant12.detaillist6"
          ),
        ],
      },
      doc13: {
        img: OnlineconsultantImg12,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist3"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist4"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist5"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant13.detaillist6"
          ),
        ],
      },
      doc14: {
        img: OnlineconsultantImg11,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant14.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant14.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant14.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant14.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant14.detaillist3"
          ),
        ],
      },
      doc15: {
        img: OnlineconsultantImg3,
        mainheading: t(
          "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant15.head"
        ),
        mainsub: [
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant15.detaillist0"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant15.detaillist1"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant15.detaillist2"
          ),
          t(
            "onlineconsultantsinnerdetail.onlineconsultantsdetaildata.consultant15.detaillist3"
          ),
        ],
      },
    },
  ];

  const appointmentHandler = () => {
    if (!user) {
      setLogInModal(true);
    } else if (user && userRole === "patient") {
      if (selecteddata?.id) {
        setAppointmentModal(true);
      } else {
        setAppointmentModalRef(true);
      }
    } else if (user && userRole !== "patient") {
      toast.error(t("fixedRight.modalsuccesstoast"));
    }
  };

  const [selectedTab1, setSelectedTab1] = useState(viewData?.name);

  // const [selecteddata, setSelecteddata] = useState(functFilter());

  const [selecteddata, setSelecteddata] = useState(viewData);

  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };

  const tabHandler = (item) => {
    setSelectedTab1(item);
    if (item === "drma") {
      tabledataHandler(consultantTab[0].doc1);
    } else if (item === "drmdmah") {
      tabledataHandler(consultantTab[0].doc2);
    } else if (item === "drSaroat") {
      tabledataHandler(consultantTab[0].doc3);
    } else if (item === "drmahbub") {
      tabledataHandler(consultantTab[0].doc4);
    } else if (item === "drshahjahan") {
      tabledataHandler(consultantTab[0].doc5);
    } else if (item === "drjhangir") {
      tabledataHandler(consultantTab[0].doc6);
    } else if (item === "drchoudary") {
      tabledataHandler(consultantTab[0].doc7);
    } else if (item === "drmahammadwahed") {
      tabledataHandler(consultantTab[0].doc8);
    } else if (item === "drmaria") {
      tabledataHandler(consultantTab[0].doc9);
    } else if (item === "drmahibburrahman") {
      tabledataHandler(consultantTab[0].doc10);
    } else if (item === "drnarwana") {
      tabledataHandler(consultantTab[0].doc11);
    } else if (item === "drchowdhurymuhammad") {
      tabledataHandler(consultantTab[0].doc12);
    } else if (item === "drsaima") {
      tabledataHandler(consultantTab[0].doc13);
    } else if (item === "drnuruzzaman") {
      tabledataHandler(consultantTab[0].doc14);
    } else if (item === "drfaizun") {
      tabledataHandler(consultantTab[0].doc15);
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchStaticData());
    }
    populateButtonArray();
    // setSelecteddata(publicDoctor[0])
    // eslint-disable-next-line
  }, [lang, publicDoctor?.length]);

  const buttonname = [
    "drma",
    "drmdmah",
    "drSaroat",
    "drmahbub",
    "drshahjahan",
    "drjhangir",
    "drchoudary",
    "drmahammadwahed",
    "drmaria",
    "drmahibburrahman",
    "drnarwana",
    "drchowdhurymuhammad",
    "drsaima",
    "drnuruzzaman",
    "drfaizun",
  ];
  const tabHandlerTest = (item) => {
    setSelecteddata({ ...item });
    setSelectedTab1(item?.name);
  };

  const itemToForm = (item) => {
    if (item === undefined) {
      return "";
    }
    return item;
  };

  return (
    <>
      <Helmet>
        <title>{t("onlineconsultantsinnerdetail.maintitle")}</title>
      </Helmet>
      <BannerImg imgSrc={covidTeamBaner} />
      <br />

      <TextAndImageSection
        imgSrc={selecteddata?.display_picture}
        imgStyle={{ marginTop: "20px", width: "100%", height: "100%" }}
        imgCol="col-lg-4"
        textCol="col-lg-8"
        headingFirst={selecteddata?.name}
        discription={
          <>
            <p
              style={{ textTransform: "capitalize" }}
              className="light-Color-Para mt-4"
            >
              <div>
                <div>{selecteddata?.designation}</div>
                <div>
                  {selecteddata?.speciality?.map((item, idx) => (
                    <span>
                      {idx === 0 ? null : ", "}
                      {item?.name}
                    </span>
                  ))}
                </div>
                <div>
                  {selecteddata?.departments?.map((item, idx) => (
                    <span>
                      {idx === 0 ? null : ", "}
                      {item?.name}
                    </span>
                  ))}
                </div>
              </div>
            </p>
            <button
              onClick={appointmentHandler}
              className="headerBtn-red "
              style={{ marginRight: "5px" }}
            >
              Book Appointment
            </button>
          </>
        }
        reverse={true}
      />

      <div className="container mt-5">
        <div className="row mb-5">
          {loading ? (
            <Loader />
          ) : (
            publicDoctor?.map((item, index) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6"
                key={index + 1}
                onClick={() => {
                  tabHandlerTest(item);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <CardHandler
                  label={
                    item?.name?.toLowerCase() ==
                    buttonState[index]?.toLowerCase() ? (
                      <>
                        <Teamcard
                          imgSrc={item?.display_picture}
                          cardHeading={item?.name}
                          btnshow={true}
                          description={
                            <div>
                              <div>{item?.designation}</div>
                              <div>
                                {item?.speciality?.map((item) => (
                                  <span>{item?.name}</span>
                                ))}
                              </div>
                              <div>
                                {item?.departments?.map((item) => (
                                  <span>{item?.name}</span>
                                ))}
                              </div>
                            </div>
                          }
                        />
                      </>
                    ) : (
                      ""
                    )
                  }
                  className={
                    selectedTab1?.toLowerCase() === item?.name?.toLowerCase()
                      ? "dashboardBtnList-item-doc-active p-0"
                      : "default-color-and-hover-doc p-0"
                  }
                  bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                />
              </div>
            ))
          )}
        </div>
      </div>
      <SignUpModal
        show={signUpModal}
        onHide={() => setSignUpModal(!signUpModal)}
        {...props}
      />
      <LoginModal
        show={logInModal}
        onHide={() => setLogInModal(!logInModal)}
        showSignUpModal={() => setSignUpModal(true)}
        {...props}
      />
      <AddAppointmentWeb
        show={appointmentModal}
        onHide={() => setAppointmentModal(!appointmentModal)}
        doctorId={selecteddata?.id}
        {...props}
      />
      <AddAppointments
        show={appointmentModalRef}
        onHide={() => setAppointmentModalRef(!appointmentModalRef)}
        doctorId={selecteddata?.id}
        {...props}
      />
    </>
  );
};

export default OnlineConsultantDetailPage;

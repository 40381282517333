import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import missionBanner from "../../Statics/Images/banner/mission_vision-banner.jpg";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";
import missionicon from "../../Statics/Images/mission.png";
import missionBottomIcon from "../../Statics/Images/shape.png";
import missionBottomIcon2 from "../../Statics/Images/vision.png";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const OurMission = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const componetcms = useSelector((state) => state.cms.components);

  const lang = useSelector((state) => state?.cms?.language);
  const about = filterComponentData(componetcms, "missionandvision", lang);
  const banner = filterComponentData(componetcms, "banners", lang);
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[6]?.sublink1 ?banner[6]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[6]?.sublink2 ?banner[6]?.sublink2:"About Us",
      subDash: ">",
      subLink: "/our-mission",
    },
    {
      subLinkName: banner[6]?.sublink3 ?banner[6]?.sublink3:"Mission and Vision",
      subDash: ">",
      subLink: "/our-mission",
    },
  ]
  const { t, i18n } = useTranslation("common");
  return (
    <>
      <Helmet>
        <title>{t("vision-and-mission.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[6]?.image ? banner[6]?.image : missionBanner})`}
        heading={banner[6]?.heading ? banner[6]?.heading : "Mission And Vision"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <HeadingWithBottomBar
        heading={about?.heading ? about?.heading : t("vision-and-mission.head")}
      />
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-lg-6">
            <div className="ourMissionContainer">
              <div className="ouMissioniconContainer d-flex justify-content-center">
                <img
                  src={about?.image ? about?.image : missionicon}
                  alt="mission icon"
                  className="ourMissionIcon"
                />
              </div>
              <div className="missionContentContainer">
                <h3 className="missionTitle text-center gray-headin">
                  {about?.heading1
                    ? about?.heading1
                    : t("vision-and-mission.head1")}
                </h3>
                <div className="ourmissionBottomImg">
                  <img src={missionBottomIcon} alt="mission bottom icon" />
                </div>
                <p className="text-center light-Color-Para mt-4">
                  {about?.text1 ? about?.text1 : t("vision-and-mission.text1")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ourMissionContainer">
              <div className="ouMissioniconContainer d-flex justify-content-center">
                <img
                  src={about?.image2 ? about?.image2 : missionBottomIcon2}
                  alt=""
                  className="ourMissionIcon"
                />
              </div>
              <div className="missionContentContainer">
                <h3 className="missionTitle text-center gray-headin">
                  {" "}
                  {about?.heading2
                    ? about?.heading2
                    : t("vision-and-mission.head2")}
                </h3>
                <div className="ourmissionBottomImg">
                  <img src={missionBottomIcon} alt="mission bottom icon" />
                </div>
                <p className="text-center light-Color-Para mt-4">
                  {about?.text2 ? about?.text2 : t("vision-and-mission.text2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMission;

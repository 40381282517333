import React from "react";

const HeadingWithBottomBar = (props) => {
  return (
    <div className="text-center mt-5" style={props.margStyle}>
        
      <h1 className="sub-heading">{props.subHeading}</h1>
      <h1 style={{ color: "#444",fontWeight: "600" }}>{props.heading}</h1>
      <div className="h-decor"></div>
      <p
        className="max-600 mt-4"
        style={{ color: "#787878", fontWeight: "400" }}
      >
        {props.discription}
      </p>
    </div>
  );
};

export default HeadingWithBottomBar;

import { signInConstansts } from "../Constants/loginConstant";
import { administratorConstants } from "../Constants/administratorConstants";
const initialState = {
  patients: [],
  doctor: [],
  doctorRequests: [],
  rescheduleRequest: [],
  orders: [],
  target: [],
  bde: [],
  targetCreate: {},
  request: [],

  today: [],

  past: [],

  upcoming: [],
  partialPaymentReq: []
};
export const administratorReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case administratorConstants.PATIENTS:
      return { ...state, patients: payload };
    case administratorConstants.DOCTOR:
      return { ...state, doctor: payload };
    case administratorConstants.DOCTOR_REQUESTS:
      return { ...state, doctorRequests: payload };
    case administratorConstants.UPDATE_RESCHEDULE_REQUEST:
      return { ...state, rescheduleRequest: payload };
    case administratorConstants.ORDER_BY_EMAIL:
      return { ...state, orders: payload };
    case administratorConstants.READ_TARGET:
      return { ...state, target: payload };
    case administratorConstants.READ_BDE:
      return { ...state, bde: payload };
    case administratorConstants.ASSIGN_TARGET:
      return { ...state, targetCreate: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    case administratorConstants.RETURN_REQUEST:
      return { ...state, request: payload };

    case administratorConstants.TODAYS_APPOINTMENT:
      return { ...state, today: payload };

    case administratorConstants.UPCOMING_APPOINTMENT:
      return { ...state, upcoming: payload };

    case administratorConstants.PAST_APPOINTMENT:
      return { ...state, past: payload };
    case administratorConstants.PARTIAL_PAYEMNT_REQUEST:
      return { ...state, partialPaymentReq: payload };
    default:
      return state;
  }
};

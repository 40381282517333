import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../Components/LayOuts/modals/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { emptyString } from "../../Utils/functions";

const UpComingAppointments = (props) => {
  const loader = useSelector((state) => state?.logIn?.loader);
  const appointment = useSelector((state) => state?.patient?.appointments);

  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const { t, i18n } = useTranslation("common")
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.doctordashboard.appointment.todayappointment.mainheading2")}</h4>
        </div>
      </div>

      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.date"),
              },
              { dataField: "patient_details.name", text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.patientname") },
              // {
              //   formatter: (cell, row, index) => {
              //     return (
              //       <span>
              //         {emptyString(row.description) ? "N/A" : row.description}
              //       </span>
              //     );
              //   },
              //   text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.description"),
              // },
              { dataField: "condition.name", text: "Condition" },

              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#1a4774' }}>{row?.status?.name?.toUpperCase()}</span>;
                },
                text: "Status",
              },
              {

                formatter: (cell, row) => {
                  return (
                    row?.status?.name == "booked" ? <div className="container">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 mb-2 px-2">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                            className="order-pay-btn"
                            onClick={() => {
                              props.history.push("/appointment-details", { row, from: "upcoming" });

                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 px-2">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.reschedule")}
                            className="appointment-reschdule-btn"
                            onClick={() => {
                              setrescheduleModal({ show: true, data: row })

                            }}
                          />
                        </div>
                      </div>
                    </div> : row?.status?.name == "confirmed" ? <div className="container">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 mb-2 px-2">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                            className="order-pay-btn"
                            onClick={() => {
                              props.history.push("/appointment-details", { row, from: "upcoming" });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 px-2">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.reschedule")}
                            className="appointment-reschdule-btn"
                            onClick={() => {
                              setrescheduleModal({ show: true, data: row })

                            }}
                          />
                        </div>
                      </div>
                    </div> : <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 mb-2 px-2">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                            className="order-pay-btn"
                            onClick={() => {
                              props.history.push("/appointment-details", { row, from: "upcoming" });

                            }}
                          />
                        </div>

                      </div>
                    </div>
                  );
                },
                text: t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.action"),
              },
            ]}
            tabledata={
              appointment?.upcoming?.length > 0 ? appointment?.upcoming : []
            }
          />
        )}
      </div>
      <DoctorAppointmentReschedule
        show={rescheduleModal?.show}
        data={rescheduleModal?.data}
        onHide={() => setrescheduleModal({ show: false, data: {} })}
        {...props}
      />
    </div>
  );
};

export default UpComingAppointments;

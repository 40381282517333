import React from "react";
import loaderGif from "../../Statics/Images/loader.gif"
const Loader = () => {
  return (
    <div className="w-100  d-flex justify-content-center mb-5">
      <img src={loaderGif} alt="loader" />
    </div>
  );
};

export default Loader;

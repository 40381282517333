import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import Loader from "../../Components/ReUseableComponents/Loader";
import { getPurchasedPackages } from "../../Store/Actions/patientActions";

const PurchasedPackages = (props) => {
  const purchasedPackages = useSelector(
    (state) => state?.patient?.purchasedPackages
  );
  const user = useSelector((state) => state?.logIn?.user);
  const loader = useSelector((state) => state?.logIn?.loader);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPurchasedPackages({ id: user?.id }));
    // eslint-disable-next-line
  }, [dispatch]);
  const {t} = useTranslation("common")
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <div className="d-flex justify-content-between">
            <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.pakages")}</h4>
            <span
              className="btn btn-success p-2"
              style={{ height: "fit-content" }}
              onClick={() => props?.history?.push("/packages-male")}
            >
             {t("dashboards.alldashboardkeys.tablekeys.purchasenewpakagesbtn")}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.name")
            },

              {
                formatter: (cell, row) => {
                  return (
                    <>
                      {row?.tests?.map((item) => (
                        <li>{item?.name}</li>
                      ))}
                    </>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.test")
              },
              { dataField: "age_group.name", text: t("dashboards.alldashboardkeys.tablekeys.agegroup") },
              { dataField: "price", text:  t("dashboards.alldashboardkeys.tablekeys.price") },
            ]}
            tabledata={purchasedPackages}
          />
        )}
      </div>
    </div>
  );
};

export default PurchasedPackages;

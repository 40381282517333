import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import PharmacyMedicines from "./PharmacyMedicines";
import PharmacyOrders from "./PharmacyOrders";

const Pharmacy = (props) => {
  const {t} = useTranslation("common")

  const [selectedTab, setSelectedTab] = useState( t(
                "dashboards.alldashboardkeys.tablekeys.medicines"
        ));
  let FromView = props?.location?.state?.key;
  // const userRole = useSelector((state) => state?.logIn?.userRole);

  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  useEffect(() => {
    if (FromView === "fromOrderDetails") {
      setSelectedTab( t(
          "dashboards.alldashboardkeys.tablekeys.orders"
        ));
    }
    // eslint-disable-next-line
  }, [FromView]);
  return (
    <>
      <Helmet>
        <title>
        {t(
                "dashboards.alldashboardkeys.maintitle"
        )}
        </title>
      </Helmet>
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t(
                "dashboards.alldashboardkeys.pharmacymainheading1"
        )} />
      <div className="container">
        {[
        t(
                "dashboards.alldashboardkeys.tablekeys.medicines"
        ), 
        t(
          "dashboards.alldashboardkeys.tablekeys.orders"
        )
      ].map((item, index) => (
          <div
            className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
            key={index + 1}
            onClick={() => tabHandler(item)}
          >
            <DashboardBtnList
              label={item}
              labelStyle={selectedTab === item ? { color: "#fff" } : ""}
              className={
                selectedTab === item
                  ? "dashboardBtnList-item-active py-2"
                  : "default-color-and-hover py-2"
              }
            />
          </div>
        ))}

        {selectedTab ===  t(
                "dashboards.alldashboardkeys.tablekeys.medicines"
        ) ? (
          <>
            <div className="pb-5">
              <PharmacyMedicines />
            </div>
          </>
        ) : selectedTab ===  t(
          "dashboards.alldashboardkeys.tablekeys.orders"
        ) ? (
          <>
            <div className="pb-5">
              <PharmacyOrders {...props} />
            </div>
          </>
        ) : null}
      </div>
    </div>
    </>
  );
};

export default Pharmacy;

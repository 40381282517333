import React, { useEffect, useState } from "react";

import { Form, Modal } from "react-bootstrap";

import { Typeahead } from "react-bootstrap-typeahead";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import {
  cancelAppointmentAdmin,
  cancelOrderAdmin,
  readPastAppointmentAction,
  readTodaysAppointmentAction,
  readUpcomingAppointmentAction,
} from "../../../../Store/Actions/administratorActions";

import { getAllOrders } from "../../../../Store/Actions/pharmacyActions";

export default function RefundModal(props) {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const user = useSelector((state) => state?.logIn?.user);

  const {
    register,

    handleSubmit,

    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (Number(data?.amount) < 1) {
      toast.error("Please Enter Valid Amount");

      return;
    }

    if (props.order) {
      if (
        props?.id?.delivery_status?.name == "processing" &&
        props?.id?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrderAdmin({
            order_id: props?.id?.id,
            is_returned: true,
            refunded_amount: Number(data?.amount),
          })
        );

        if (res) dispatch(getAllOrders({ id: user?.id }));

        props.onHide();
      } else if (
        props?.id?.delivery_status?.name == "processing" &&
        props?.id?.paid_status?.name == "unpaid"
      ) {
        let res = await dispatch(
          cancelOrderAdmin({
            order_id: props?.id?.id,
            is_returned: true,
            refunded_amount: Number(data?.amount),
          })
        );

        if (res) dispatch(getAllOrders({ id: user?.id }));

        props.onHide();
      } else if (
        props?.id?.delivery_status?.name == "delivered" &&
        props?.id?.paid_status?.name == "unpaid"
      ) {
        let res = await dispatch(
          cancelOrderAdmin({
            order_id: props?.id?.id,
            is_returned: false,
            refunded_amount: Number(data?.amount),
          })
        );

        if (res) dispatch(getAllOrders({ id: user?.id }));

        props.onHide();
      } else if (
        props?.id?.delivery_status?.name == "delivered" &&
        props?.id?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrderAdmin({
            order_id: props?.id?.id,
            is_returned: false,
            refunded_amount: Number(data?.amount),
          })
        );

        if (res) dispatch(getAllOrders({ id: user?.id }));

        props.onHide();
      } else if (
        props?.id?.delivery_status?.name == "dispatched" &&
        props?.id?.paid_status?.name == "paid"
      ) {
        let res = await dispatch(
          cancelOrderAdmin({
            order_id: props?.id?.id,
            is_returned: false,
            refunded_amount: Number(data?.amount),
          })
        );

        if (res) dispatch(getAllOrders({ id: user?.id }));

        props.onHide();
      } else {
      }
    } else {
      let apiData = {
        refunded_amount: Number(data?.amount),

        appointment_id: props?.id,
      };

      let res = await dispatch(cancelAppointmentAdmin(apiData));

      if (res) {
        dispatch(readTodaysAppointmentAction());
        dispatch(readUpcomingAppointmentAction());
        dispatch(readPastAppointmentAction());

        toast.success("Successfully Cancelled");

        // props.history.push('administrator-dashboard')

        props.onHide();
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="dashboardBtnList-item-active">
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-white">{"Refund"}</span>
          </Modal.Title>

          <button type="button" class="close" onClick={props?.onHide}>
            <span aria-hidden="true" className="text-white">
              ×
            </span>

            <span class="sr-only">
              {t("dashboards.alldashboardkeys.tablekeys.close")}
            </span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">{"Enter Amount"}</span>

                  <Form.Group>
                    <input
                      className="input-login-modal"
                      type="number"
                      {...register("amount", {
                        required: {
                          value: true,

                          message: "this field is required field",
                        },
                      })}
                    />

                    {/* {mediError ? (

                                    <div className="text-error">{mediError}</div>

                                ) : (

                                    ""

                                )} */}
                  </Form.Group>
                </div>

                <input
                  type="submit"
                  value={"Refund"}
                  className="headerBtn-red btn-block mx-auto"
                  style={{ width: "85%", textAlign: "center" }}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddSlotsModal from "../../Components/LayOuts/modals/addSlotsModal/AddSlotsModal";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import ProfileTable from "../patientDashboard/ProfileTable";

const AdministratoDoctor = (props) => {
  const { t } = useTranslation("common")
  const [addSlotModal, setsAddSlotModal] = useState(false);
  const viewData = props.location.state;
  //  let _obj = {friday: {"from": "05:00", "to": "08:30"},
  // thursday: {"from": "08:20", "to": "09:20"}, monday: {"from": "10:00",
  //     "to": "10:30"}, saturday: {"from": "11:00", "to": "11:25"}
  // }
  // Timings That hadto be displayed in table
  let _obj = viewData.timings;
  let _arr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday',
    'saturday', 'sunday']
  let sorted_obj = {}
  for (let i = 0; i < _arr.length; i++) {
    if (_obj.hasOwnProperty(_arr[i])) {
      sorted_obj[_arr[i]] = _obj[_arr[i]]
    }
  }
  // Converting the sorting Object To Array
  const entries = Object.entries(sorted_obj);
  // console.log('U: ' + JSON.stringify(_obj))
  //  console.log('\nS: ' + JSON.stringify(_obj))
  // console.log("objects", (sorted_obj));

  // const item = { key: "addSlot" };
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.addslot")} />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12 ml-0 mr-0">
            <div
              className="btn edit-patient-btn ml-0 mb-2"
              style={{ width: "150px" }}
              onClick={() => {
                props?.history?.push("/administrator");
              }}
            >
              <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens  pb-4">
          <div className="col-xl-8 col-md-6 col-12 containerWithShadow p-4">
            <h5> {t("dashboards.alldashboardkeys.tablekeys.doctordetail")}</h5>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                </span>
                <p>{viewData?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                </span>
                <p>{viewData?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.speciality")}.
                </span>
                {viewData.speciality.map((item) => {
                  return (<>
                    <p>{item.name}</p>
                  </>)
                })}

              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.department")}
                </span>
                <p>{viewData?.departments[0]?.name}</p>
              </li>
            </ul>
            <div className="d-flex justify-content-between">
              <h5>{t("dashboards.alldashboardkeys.tablekeys.slotsdetails")}</h5>
              <div
                className="btn dashboardBtnList-item-active mb-3"
                style={{ width: "150px" }}
                onClick={() => {
                  setsAddSlotModal(true);
                }}
              >
                <span className="text-white">{t("dashboards.alldashboardkeys.tablekeys.addslot")}</span>
              </div>
            </div>
            <ProfileTable
              tableHead={[
                t("dashboards.alldashboardkeys.tablekeys.day")
                ,
                t("dashboards.alldashboardkeys.tablekeys.from")
                ,
                t("dashboards.alldashboardkeys.tablekeys.to")

              ]}
              data={
                entries.map((item) => {
                  // console.log("item", item);
                  // console.log(item[0]);
                  return [
                    // days
                    item[0],
                    // timings
                    item[1].from,
                    item[1].to
                    // viewData?.timings[item].from,
                    // viewData?.timings[item].to,

                  ]
                })
                // sorted_obj.map((item) => {
                //  console.log(item)
                // return [
                // item,
                //  viewData?.timings[item].from,
                //  viewData?.timings[item].to,
                // ];
                // })}
              }
            />
          </div>
        </div>
      </div>
      <AddSlotsModal
        show={addSlotModal}
        viewData={viewData}
        onHide={() => setsAddSlotModal(false)}
        {...props}
      />
    </div>
  );
};

export default AdministratoDoctor;

import React from "react";
import { useTranslation } from "react-i18next";
import HeaderBtn from "./HeaderBtn";

const MobileServiceCard = (props) => {
  const { t } = useTranslation("common");
  return (
    <>
      <div className="row center-childrens ">
        <div className="col-sm-10 col-lg-9 mobile-lab-card text-white">
          <h6 className="mt-2">
            {props?.item?.title ? props?.item?.title : t("home.mobilelabforcovidtest")}
          </h6>
          <div className="mb-3">
            <HeaderBtn
              label={props?.item?.button2 ? props?.item?.button2 : t("home.enquire")}
              className="headerBtn"
              style={{
                padding: "3px 20px 3px 20px",
              }}
              labelStyle={{ fontSize: "14px" }}
              onClick={props?.onCLickEquire}
            />
            &nbsp;&nbsp;
            <HeaderBtn
              label={props?.item?.button1 ? props?.item?.button1 : t("home.location")}
              className="headerBtn"
              style={{
                padding: "3px 20px 3px 20px",
              }}
              labelStyle={{ fontSize: "14px" }}
              onClick={props?.onClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileServiceCard;

import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import ProfileTable from "../patientDashboard/ProfileTable";

export default function ReturnRequestDetail(props) {
  const viewData = props.location.state;
  const { t } = useTranslation("common");
  const tablehead = [
    t("dashboards.alldashboardkeys.tablekeys.type"),
    t("dashboards.alldashboardkeys.tablekeys.name"),
    t("dashboards.alldashboardkeys.tablekeys.quantity"),
    t("dashboards.alldashboardkeys.tablekeys.price"),
  ];
  return (
    <>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <DashBoardMainHading
          title={t("dashboards.alldashboardkeys.tablekeys.orderdetail")}
        // image={orderImage}
        />
        <div className="container ">
          <div className="row center-childrens">
            <div
              className="col-xl-8 col-md-8 col-12 px-0 mb-3
  "
            >
              <div
                className="btn edit-patient-btn "
                style={{ width: "150px" }}
                onClick={() => {
                  props.history.push("/administrator");
                }}
              >
                <i className="fas fa-arrow-left"></i>
                {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
              </div>
            </div>
          </div>
          <div className="row center-childrens">
            <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Product Image"}
                  </span>
                  <p>
                    <img
                      src={viewData?.image}
                      style={{ maxHeight: "500px", maxWidth: "500px" }}
                      alt="No Product Image"
                    />
                  </p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Is Package Intact"}
                  </span>
                  <p>{viewData?.is_packaging_intact ? "Yes" : "No"}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Is Used"}
                  </span>
                  <p>{viewData?.is_used ? "Yes" : "No"}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Assigned Delievery Boy"}
                  </span>
                  <p>{viewData?.order?.delivery_boy?.name}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Patient Name"}
                  </span>
                  <p>{viewData?.order?.ordered_by?.name}</p>
                </li>
                <li>
                  <span
                    className="light-Color-Para"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {"Paid Amount"}
                  </span>
                  <p>{viewData?.order?.paid_amount}</p>
                </li>
              </ul>
              <h5>{t("dashboards.alldashboardkeys.tablekeys.orderdetail")}</h5>
              {
                <div className="px-3 py-1 w-100  mb-5 containerWithShadow table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        {tablehead?.map((item, index) => (
                          <th
                            scope="col"
                            key={index + 1}
                            style={{ background: "#f9f9f9" }}
                          >
                            <span className=" patient-appointment-table-heading">
                              {item}
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{viewData?.order?.medicine?.medicine_type}</td>
                        <td>{viewData?.order?.medicine?.name}</td>
                        <td>{viewData?.quantity}</td>
                        <td>{viewData?.order?.medicine?.sales_price}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../../Store/Actions/loginActions";
const ForgetPasswordModal = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      email_address: data.email,
    };
    dispatch(forgotPassword(apiData));
    props.onHide();
  };
  const {t} = useTranslation("common")
  
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.forgotpassword")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <span className="label-name-login">
            {t("dashboards.alldashboardkeys.tablekeys.emailaddress")}
                    </span>
                  <input
                    className="input-login-modal"
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.email?.message ? (
                    <div className="text-error">{errors?.email?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <input
                type="submit"
                value="Forgot password"
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgetPasswordModal;

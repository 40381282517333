import React, { useEffect, useState } from "react";
import bannerLab from "../../Statics/Images/internal-banner/lab.jpg";
import BannerImg from "../ReUseableComponents/BannerImg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import {
  LabConsultantImg1,
  LabConsultantImg2,
  LabConsultantImg3,
  LabConsultantImg4,
  LabConsultantImg5,
  LabConsultantImg6,
  LabConsultantImg7,
  LabConsultantImg8,
  LabConsultantImg9,
  LabConsultantImg10,
  LabConsultantImg11,
} from "../LayOuts/handlercardData/handlercarddata";
import CardHandler from "../ReUseableComponents/CardHandler";
import Teamcard from "../LayOuts/Cards/teamcardinnerhandler/teamcard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { capitalize, filterComponentData } from "../../Utils/functions";
import moment from "moment";

const Labconsultantinnerpage = (props) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const componetcms = useSelector((state) => state.cms.components);
  const viewData = props?.location?.state;
  const [buttonState, setButtonState] = useState([])
  const lang = useSelector((state) => state?.cms?.language)
  const labTeam = filterComponentData(componetcms, 'labconsultantdetailpage', lang)
  const labconsultantTeamtab = [
    {
      doc1: {
        img: LabConsultantImg1,
        // mainheading: "Hellop",
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc1.head"),

        mainsub: (
          <>
            {t("labconsultantinnerdetail.labconsultantdetaildata.doc1.headsub1")},
            <br /> {t("labconsultantinnerdetail.labconsultantdetaildata.doc1.headsub1-1")}
          </>
        ),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc1.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc1.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc2: {
        img: LabConsultantImg2,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc2.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc2.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc2.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc2.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc3: {
        img: LabConsultantImg3,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc3.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc3.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc3.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc3.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },

      doc4: {
        img: LabConsultantImg4,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc4.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc4.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc4.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc4.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc5: {
        img: LabConsultantImg5,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc5.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc5.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc5.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc5.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc6: {
        img: LabConsultantImg6,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc6.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc6.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc6.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc6.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc7: {
        img: LabConsultantImg7,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc7.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc7.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc7.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc7.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc8: {
        img: LabConsultantImg8,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc8.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc8.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc8.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc8.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc9: {
        img: LabConsultantImg9,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc9.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc9.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc9.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc9.details"), description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
      doc10: {
        img: LabConsultantImg10,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc10.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc10.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc10.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc10.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },

      doc11: {
        img: LabConsultantImg11,
        mainheading: t("labconsultantinnerdetail.labconsultantdetaildata.doc11.head"),
        mainsub: t("labconsultantinnerdetail.labconsultantdetaildata.doc11.headsub1"),
        mainsub1: t("labconsultantinnerdetail.labconsultantdetaildata.doc11.headsub2"),
        description: t("labconsultantinnerdetail.labconsultantdetaildata.doc11.details"),
        description1: <p style={{ marginBottom: "6px", fontSize: "15px" }}></p>,
        linkFacebook: "#",
        linkTwitter: "#",
        linkInsta: "#",
        linkLinkedIn: "#",
      },
    },
  ];
  const functFilter = () => {
    return labTeam?.filter((item) => {
      if (item?.head?.toLowerCase() == viewData?.toLowerCase()) {
        return item
      }
    })
  }
  const populateButtonArray = () => {
    let test = []
    labTeam?.map((item) => {
      test?.push(item?.head)
    })
    setButtonState(test)
  }



  const [selecteddata, setSelecteddata] = useState(
    viewData === "drshaheda"
      ? labconsultantTeamtab[0].doc1
      : viewData === "drfarzan"
        ? labconsultantTeamtab[0].doc2
        : viewData === "drtamina"
          ? labconsultantTeamtab[0].doc3
          : viewData === "drsharminjah"
            ? labconsultantTeamtab[0].doc4
            : viewData === "drfarah"
              ? labconsultantTeamtab[0].doc5
              : viewData === "drrumana"
                ? labconsultantTeamtab[0].doc6
                : viewData === "drsaika"
                  ? labconsultantTeamtab[0].doc7
                  : viewData === "drsharmin"
                    ? labconsultantTeamtab[0].doc8
                    : viewData === "drmizanur"
                      ? labconsultantTeamtab[0].doc9
                      : viewData === "drsonia"
                        ? labconsultantTeamtab[0].doc10
                        : viewData === "drarifa"
                          ? labconsultantTeamtab[0].doc11
                          : labconsultantTeamtab[0].doc1
  );


  const buttonname = [
    "drshaheda",
    "drfarzan",
    "drtamina",
    "drsharminjah",
    "drfarah",
    "drrumana",
    "drsaika",
    "drmizanur",
    "drsonia",
  ];

  const tabHandler = (item) => {
    if (item?.toLowerCase() == labTeam?.filter((item) => item?.head?.toLowerCase())) {
      tabledataHandler(labTeam[0]);
    } else if (item === "drfarzan") {
      tabledataHandler(labconsultantTeamtab[0].doc2);
    } else if (item === "drtamina") {
      tabledataHandler(labconsultantTeamtab[0].doc3);
    } else if (item === "drsharminjah") {
      tabledataHandler(labconsultantTeamtab[0].doc4);
    } else if (item === "drfarah") {
      tabledataHandler(labconsultantTeamtab[0].doc5);
    } else if (item === "drrumana") {
      tabledataHandler(labconsultantTeamtab[0].doc6);
    } else if (item === "drsaika") {
      tabledataHandler(labconsultantTeamtab[0].doc7);
    } else if (item === "drsharmin") {
      tabledataHandler(labconsultantTeamtab[0].doc8);
    } else if (item === "drmizanur") {
      tabledataHandler(labconsultantTeamtab[0].doc9);
    } else if (item === "drsonia") {
      tabledataHandler(labconsultantTeamtab[0].doc10);
    } else if (item === "drarifa") {
      tabledataHandler(labconsultantTeamtab[0].doc11);
    }
  };

  const [selectedTab1, setSelectedTab1] = useState(
    viewData ? viewData : "drshaheda"
  );


  // const [selecteddata, setSelecteddata] = useState(functFilter());
  useEffect(() => {
    populateButtonArray()
    setSelecteddata(labTeam?.length < 1 ? selecteddata : functFilter() ? functFilter() : labconsultantTeamtab[0].doc1);
    // eslint-disable-next-line
  }, [lang,labTeam?.length]);





  const tabledataHandler = async (item) => {
    setSelecteddata(item);
  };


  const tabHandlerTest = (item) => {


    setSelecteddata([{ ...item }])
    setSelectedTab1(item?.head);
  };



  return (
    <>
      <Helmet>
        <title>
          {t("labconsultantinnerdetail.maintitle")}
        </title>
      </Helmet>
      <BannerImg imgSrc={bannerLab} />
      <br />


      <TextAndImageSection
        imgSrc={selecteddata[0]?.image ? selecteddata[0]?.image : selecteddata?.img}
        imgStyle={{ marginTop: "20px" }}
        imgCol="col-lg-3"
        textCol="col-lg-9"
        headingFirst={selecteddata[0]?.head ? selecteddata[0]?.head : selecteddata?.mainheading}
        discription={
          <>
            <h5
              style={{ textTransform: "capitalize" }}
              className="light-Color-Para mt-4"
            >
              {selecteddata[0]?.headsub1 ? selecteddata[0]?.headsub1 : selecteddata?.mainsub}
            </h5>
            <h5
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {selecteddata[0]?.headsub11 ? selecteddata[0]?.headsub11 : ""}
            </h5>
            <h5
              style={{ textTransform: "capitalize" }}
              className="light-Color-Para mb-4"
            >
              {selecteddata[0]?.headsub2 ? selecteddata[0]?.headsub2 : selecteddata?.mainsub1}
            </h5>
          
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {selecteddata[0]?.details ? selecteddata[0]?.details : selecteddata?.description}
            </p>
            <div classNameName="socialmediasIconCovidTeam">
              <span className="light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkFacebook ? selecteddata[0]?.linkFacebook : selecteddata[0]?.linkFacebook}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i
                    className="fab fa-facebook-square"
                    style={{ fontSize: "19px" }}
                  ></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkTwitter ? selecteddata[0]?.linkTwitter : selecteddata?.linkTwitter}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i
                    className="fab fa-linkedin"
                    style={{ fontSize: "19px" }}
                  ></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkInsta ? selecteddata[0]?.linkInsta : selecteddata?.linkInsta}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i
                    className="fab fa-twitter-square"
                    style={{ fontSize: "19px" }}
                  ></i>
                </a>
              </span>
              <span className="ml-1 light-Color-Para">
                <a
                  rel="noreferrer"
                  href={selecteddata[0]?.linkLinkedIn ? selecteddata[0]?.linkLinkedIn : selecteddata?.linkLinkedIn}
                  target="_blank"
                  style={{ color: "#787878" }}
                >
                  <i
                    className="fab fa-dribbble-square"
                    style={{ fontSize: "19px" }}
                  ></i>
                </a>
              </span>
            </div>
          </>
        }
        reverse={true}
      />


      {
        labTeam?.length < 1 ?


          <div className="container mt-5">
            <div className="row mb-5">
              {buttonname.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6"
                  key={index + 1}
                  onClick={() => {
                    tabHandler(item)
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  }}
                >
                  <CardHandler
                    label={
                      item === "drshaheda" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc1?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc1?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc1?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drfarzan" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc2?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc2?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc2?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drtamina" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc3?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc3?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc3?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drsharminjah" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc4?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc4?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc4?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drfarah" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc5?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc5?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc5?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drrumana" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc6?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc6?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc6?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drsaika" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc7?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc7?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc7?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drmizanur" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc9?.img}
                            cardHeading={labconsultantTeamtab[0]?.doc9?.mainheading}
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc9?.mainsub}</>
                            }
                          />
                        </>
                      ) : item === "drsonia" ? (
                        <>
                          <Teamcard
                            imgSrc={labconsultantTeamtab[0]?.doc10?.img}
                            cardHeading={
                              labconsultantTeamtab[0]?.doc10?.mainheading
                            }
                            btnshow={true}
                            description={
                              <>{labconsultantTeamtab[0]?.doc10?.mainsub}</>
                            }
                          />
                        </>
                      ) : (
                        ""
                      )
                    }
                    className={
                      selectedTab1 === item
                        ? "dashboardBtnList-item-doc-active p-0"
                        : "default-color-and-hover-doc p-0"
                    }
                    bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                  />
                </div>
              ))}
            </div>
          </div>


          : <div className="container mt-5">
            <div className="row mb-5">
              {labTeam?.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6"
                  key={index + 1}
                  onClick={() => {

                    tabHandlerTest(item)
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  }}
                >
                  <CardHandler
                    label={
                      item?.head?.toLowerCase() == buttonState[index]?.toLowerCase() ? (
                        <>
                          <Teamcard
                            imgSrc={item?.image}
                            cardHeading={item?.head}
                            btnshow={true}
                            description={
                              <>{item?.headsub1}</>
                            }
                          />
                        </>
                      ) : ""
                    }
                    className={
                      selectedTab1?.toLowerCase() === item?.head?.toLowerCase()
                        ? "dashboardBtnList-item-doc-active p-0"
                        : "default-color-and-hover-doc p-0"
                    }
                    bntStyle={{ fontSize: "15px", borderRadius: "5px" }}
                  />
                </div>
              ))}
            </div>
          </div>

      }

    </>
  );
};

export default Labconsultantinnerpage;

import React from "react";
import "./fixedLeft.css";
import covid19 from "../../../../Statics/Images/covid-19.png"
import hotCall from "../../../../Statics/Images/call.png"
import people from "../../../../Statics/Images/people.png"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../../../Utils/functions";
const FixedLeft = () => {
  const {t,i18n} = useTranslation("common")
  const lang = useSelector((state) => state?.cms?.language)
  const componetcms = useSelector((state) => state.cms.components);
  const fixedleft = filterComponentData(componetcms, 'fixedleft', lang)

  return (
    <div className="row  left-main d-none d-lg-flex">
      <div className="left-covid-info">
          <img src={covid19} alt="Covid For Flight" className="left-img-size"/>
          <span className="left-text">{fixedleft?.flight ? fixedleft?.flight :t("fixedLeft.flight")}</span>
      </div>
      <div className="left-covid-people">
      <img src={people} alt="Covid General People" className="left-img-size"/>
          <span className="left-text">{fixedleft?.people ? fixedleft?.people :t("fixedLeft.people")}</span>
      </div>
      <div className="left-hotcall">
      <img src={hotCall} alt="" className="left-img-size"/>
          <span  style={{color:'red',fontWeight:'900',textTransform:'uppercase'}}>{fixedleft?.hotline ? fixedleft?.hotline : t("fixedLeft.hotline")}</span>
    
      </div>
    </div>
  );
};

export default FixedLeft;

import i18next from "i18next";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { saveUser } from "../../Utils/auth.util";
import { signInConstansts } from "../Constants/loginConstant";
import { patientConstants } from "../Constants/PatientConstants";

export const updatePatient = (data) => async (dispatch) => {

  try {
    const response = await apiServices.updatePatient(data);
    if (response?.response_code === 200) {
      saveUser(response?.response_data?.patient);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: false,
      });
      dispatch({
        type: patientConstants.UPDATE_PROFILE_PATIENT,
        payload: response?.response_data?.patient,
      });
      dispatch({
        type: signInConstansts.SIGN_IN,
        payload: response?.response_data?.patient,
      });
      // toast.success(i18next.t("common:toastmessages.successfullyupdated"))
      toast.success("Successfully Updated")
      return true
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
      return false

    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const patientAppointments = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.patientAppointments(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });

      dispatch({
        type: patientConstants.GET_PATIENT_APPOINTMENTS,
        payload: response?.data?.response_data?.appointment,
      });
      return "true";
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
      return "false";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const getAllDepartment = () => async (dispatch) => {
  try {
    const response = await apiServices.getDepartment();
    // console.log(response);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: patientConstants.GET_DEPARTMENT,
        payload: response?.data?.response_data?.department,
      });
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getDoctorByDepartment = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getDoctorByDepartment(data);
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    if (response?.data?.response_code === 200) {
      dispatch({
        type: patientConstants.GET_DOCTOR_BY_DEPARTMENT_SUCCESS,
        payload: response?.data?.response_data?.doctors,
      });
    } else {
      dispatch({
        type: patientConstants.GET_DOCTOR_BY_DEPARTMENT_SUCCESS,
        payload: [],
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getSlotByDateAndDoctor = (data) => async (dispatch) => {
  try {
    const response = await apiServices.getSlotByDateAndDoctor(data);

    if (response?.data?.response_code === 200) {
      dispatch({
        type: patientConstants.GET_SLOTS_BY_DATE,
        payload: response?.data?.response_data?.slots,
      });
      return true

    }
    else {
      toast.error(response?.data.response_message);
      dispatch({
        type: patientConstants.GET_SLOTS_BY_DATE,
        payload: [],
      });
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
      return false

    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const addAppointment = (data, id) => async (dispatch) => {

  try {
    const response = await apiServices.addAppointment(data);
    if (response?.data?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.appointmentaddedsuccessfully"));
    } else {
      toast.error(response?.data?.response_message);
    }
    dispatch(patientAppointments(id));
  } catch (error) {
    console.log("Login error", error);
  }
};
export const rescheduleModalAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: patientConstants.RESCHEDULE_MODAL,
      payload: data,
    });
  } catch (error) {
    console.log("Login error", error);
  }
};

export const reschedule = (data, id) => async (dispatch) => {


  try {
    const response = await apiServices.reschedule(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.appointmentreschedulesuccess"));
      dispatch(patientAppointments(id));
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getMyDoctors = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.getMyDoctors(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: patientConstants.MY_DOCTORS,
        payload: response?.data?.response_data?.doctor,
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const patientPrescriptions = (data) => async (dispatch) => {
  try {

    const response = await apiServices.patientPrescriptions(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: false,
      });
      dispatch({
        type: patientConstants.GET_PATIENT_PRESCRIPTIONS,
        payload: response?.data?.response_data?.prescription,
      });
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const patientBookOrder = (data, cartPurchase) => async (dispatch) => {
  // const {t} = useTranslation("common")
  try {
    const response = await apiServices.patientBookOrder(data);
    
    if (response?.data?.response_code === 200) {
      if (cartPurchase) {
        localStorage.removeItem("cart");
        toast.info(i18next.t("common:toastmessages.ordercreatedsuccess"));
        return response?.data?.response_data.order;
      } else if (!cartPurchase) {
        toast.info(i18next.t("common:toastmessages.ordercreatedsuccess"));
        return {
          ...response?.data?.response_data?.order,
          gateway_url: response?.data?.response_data?.gateway_url,
        };
      }
    } else {
      toast.error(response?.data?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
      return false
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const payAppointment = (data) => async (dispatch) => {


  try {
    const response = await apiServices.payAppointment(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.paymentsuccess"));
      return "success";
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.response_message,
      });
      return "failed";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const readTestReport = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.readTestReport(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.MY_REPORTS,
        payload: response?.data?.response_data?.test_report,
      });
    } else {
      toast.error(response?.data?.response_message);
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.SET_ERROR,
        payload: response?.data?.response_message,
      });
      return "failed";
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const getPurchasedPackages = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.getPurchasedPackages(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.PURCHASED_PACKAGES,
        payload: response?.data?.response_data?.packages,
      });
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const myPayment = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.myPayment(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.MY_PAYMENT,
        payload: response?.data?.response_data?.payment,
      });
    } else {
      toast.error(response?.response_message);
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const generatePayment = (data) => async (dispatch) => {
  try {
    dispatch({
      type: patientConstants.PAYMENT_LOADER,
      payload: true,
    });
    const response = await apiServices.generateGatewayUrl(data);
    if (response?.data?.response_code === 200 || response?.data?.response_code === 202) {

      dispatch({
        type: patientConstants.PAYMENT_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.GENERATE_PAYMENT,
        payload: response?.data?.response_data?.gateway_url,
      });
      toast.success(response?.data?.response_message)
      return response?.data?.response_data?.gateway_url
    } else {
      dispatch({
        type: patientConstants.PAYMENT_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message, "Error")
      return false
    }
  } catch (error) {
    dispatch({
      type: patientConstants.PAYMENT_LOADER,
      payload: false,
    });
  }
};

export const cancelAppointment = (data) => async (dispatch) => {

  try {

    dispatch({

      type: signInConstansts.SET_LOADER,

      payload: true,

    });

    const response = await apiServices.cancellAppointment(data);

    if (response?.response_code === 200) {


      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });
      toast.success(response?.response_message)

      return true

    } else {

      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });

      toast.error(response?.response_message)

      return false

    }

  } catch (error) { }

};


export const cancelOrder = (data) => async (dispatch) => {

  try {

    dispatch({

      type: signInConstansts.SET_LOADER,

      payload: true,

    });

    const response = await apiServices.cancellOrder(data);

    if (response?.response_code === 200) {


      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });

      toast.success(response?.response_message)


      return true

    } else {

      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });

      toast.error(response?.response_message, "Error")

      return false

    }

  } catch (error) { }

};


export const returnRequest = (data) => async (dispatch) => {

  try {

    dispatch({

      type: signInConstansts.SET_LOADER,

      payload: true,

    });

    const response = await apiServices.returnRequest(data);

    if (response?.data?.response_code === 200) {


      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });

      return true

    } else {

      dispatch({

        type: signInConstansts.SET_LOADER,

        payload: false,

      });

      toast.error(response?.data?.response_message, "Error")

      return false

    }

  } catch (error) { }

};




export const returnRequestPicture = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.uploadReturnRequestPicture(data);
    if (response?.data?.response_code === 200) {

      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.success(response?.data?.response_message)

      return response?.data?.response_data?.url

    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.data?.response_message, "Error")
      return false
    }
  } catch (error) { }
};


export const myMedicalRecords = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.myMedicalRecords(`?id=${data.id}`);
    if (response?.data?.response_code === 200) {

      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: patientConstants.MEDICAL_RECORDS_PATIENT,
        payload: response?.data?.response_data?.medical_records,
      });
    }
    console.log(response);
  } catch (error) { }
};


export const deleteMedicalRecordAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    const response = await apiServices.deleteMedicalRecordReq(data);
    if (response?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.success(response?.response_message);

      return true;
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message, "Error");
      return false;
    }
  } catch (error) {}
};

import React, { useState } from "react";
import "./footer.css";
import securePayment from "../../../Statics/Images/secure-payment.png";
import android from "../../../Statics/Images/android.png";
import ios from "../../../Statics/Images/ios.png";
import qr from "../../../Statics/Images/qr-code.png";
import HeaderBtn from "../../ReUseableComponents/HeaderBtn";
import footerLogo from "../../../Statics/Images/footer-logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../../Utils/functions";
import payp from "../../../Statics/Images/payp1.png";
import { notifyEmail } from "../../../Store/Actions/loginActions";
const Footer = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);
  const dispatch = useDispatch();

  const lang = useSelector((state) => state?.cms?.language);
  const contactus = filterComponentData(componetcms, "contactus", lang);
  const footer = filterComponentData(componetcms, "footer", lang);
  const testCategory = useSelector((state) => state?.static?.testCategory);
  const year = new Date().getFullYear();

  const [email, setEmail] = useState(null);
  console.log("email", email);
  return (
    <>
      {/* footer Top    */}
      <div className="footer-top p-2">
        <div className="container">
          <div className="row py-1 py-md-2 px-lg-0">
            <div className="col-lg-3 footer-col1">
              <div className="row flex-column flex-md-row flex-lg-column">
                <div className="col-md col-lg-auto">
                  <div className="footer-logo">
                    <img
                      src={footer?.logo ? footer?.logo : footerLogo}
                      alt="logo"
                      className="img-fluid"
                      style={{ width: "200px", height: "59px" }}
                    />
                  </div>
                  <div className="mt-2 mt-lg-0"></div>
                  <div className="footer-social d-none d-md-block d-lg-none">
                    <a
                      href="http://www.facebook.com/dmfrmolecularlab.bd"
                      target="blank"
                      className="hovicon"
                    >
                      <i className="icon-facebook-logo"></i>
                    </a>
                    <a
                      href="https://twitter.com/LabDmfr"
                      target="blank"
                      className="hovicon"
                    >
                      <i className="icon-twitter-logo"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC94K-bFePSkz1ZKD9b1isDA"
                      target="blank"
                      className="hovicon"
                    >
                      <i className="icon-youtube-logo"></i>
                    </a>
                    <a
                      href="http://www.instagram.com/dmfr_molecular_lab_diagnostics"
                      target="blank"
                      className="hovicon"
                    >
                      <i className="icon-instagram"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md mb-3">
                  <div className="footer-text mt-1 mt-lg-2">
                    <p
                      className="light-Color-Para"
                      style={{ textAlign: "left !important" }}
                    >
                      {footer?.subscribe
                        ? footer?.subscribe
                        : t("footer.text1")}
                    </p>
                    <div
                      action="subscribe_action.php"
                      className="footer-subscribe"
                    >
                      <div className="input-group">
                        <input
                          name="subscribe_mail"
                          type="text"
                          value={email}
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder={
                            footer?.emailPlaceholder
                              ? footer?.emailPlaceholder
                              : t("footer.emailplaceholder")
                          }
                        />
                        <span>
                          <i
                            className="fas fa-envelope footer-input-icon"
                            onClick={async () => {
                              let data = { email_address: email };
                              let res = await dispatch(notifyEmail(data));
                              // if (res) {
                              //   setEmail(null);
                              // }
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="footer-social d-md-none d-lg-block mb-3">
                    <span className="footer-social-links ">
                      <div
                        className="footer-icon-item cursor-pointer "
                        onClick={() =>
                          window.open(
                            "http://www.facebook.com/dmfrmolecularlab.bd"
                          )
                        }
                      >
                        <i className="fab fa-facebook-f"></i>
                      </div>
                      <div
                        className="footer-icon-item cursor-pointer "
                        onClick={() =>
                          window.open(
                            "http://www.instagram.com/dmfr_molecular_lab_diagnostics"
                          )
                        }
                      >
                        <i className="fab fa-instagram"></i>
                      </div>
                      <div
                        className="footer-icon-item cursor-pointer "
                        onClick={() =>
                          window.open("https://twitter.com/LabDmfr")
                        }
                      >
                        <i className="fab fa-twitter"></i>
                      </div>
                      <div
                        className="footer-icon-item cursor-pointer "
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UC94K-bFePSkz1ZKD9b1isDA"
                          )
                        }
                      >
                        <i className="fab fa-youtube"></i>
                      </div>
                    </span>
                  </div>

                  <a href="tel:09606213233" className="headerBtn py-2 ">
                    {footer?.call ? footer?.call : t("footer.callyoubtn")}
                  </a>
                  <p
                    className="light-Color-Para mt-3"
                    style={{ textAlign: "left !important" }}
                  >
                    {footer?.release ? footer?.release : "Release v1.0.0"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 links">
              <div className="row">
                <div className="col-12 col-md-12">
                  <h4 className="gray-headin">
                    {footer?.testcatlog
                      ? footer?.testcatlog
                      : t("footer.colhead1")}
                  </h4>
                  <div className="h-decor"></div>
                  <ul id="linone">
                    {testCategory?.map((item, index) => (
                      <li key={index + 1}>
                        <i className="fas fa-chevron-right"></i>
                        <Link
                          to={{
                            pathname: `/testcatalog-details`,
                            state: { data: item },
                          }}
                          style={{ fontSize: "14px" }}
                          className="footer-nav-links"
                        >
                          {item?.name === "Molecular Diagnostics"
                            ? t("footer.api.data1")
                            : item?.name === "Flowcytometry"
                            ? t("footer.api.data2")
                            : item?.name === "Microbiology & Serology"
                            ? t("footer.api.data3")
                            : item?.name === "Histopathology"
                            ? t("footer.api.data4")
                            : item?.name === "Clinical Pathology"
                            ? t("footer.api.data5")
                            : item?.name === "X-Ray"
                            ? t("footer.api.data6")
                            : item?.name === "Cardiac Imaging"
                            ? t("footer.api.data7")
                            : item?.name === "Cytopathology"
                            ? t("footer.api.data8")
                            : item?.name === "Ultrasonogram (USG)"
                            ? t("footer.api.data9")
                            : item?.name === "Pharmacogenetics (PGx)"
                            ? t("footer.api.data10")
                            : item?.name === "Biochemistry & Immunology"
                            ? t("footer.api.data11")
                            : item?.name === "Hematology"
                            ? t("footer.api.data12")
                            : item?.name === "clinical pharmacy"
                            ? t("footer.api.data13")
                            : item?.name}
                        </Link>
                      </li>
                    ))}
                    {/* <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/flowcytometry'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Flowcytometry
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/pharmacogenetics'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Pharmacogenetics (PGx)
                      </Link>
                    </li>

                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/microbiology-serology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Microbiology & Serology
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/histopathology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Histopathology
                      </Link>
                    </li>

                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/cytopathology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Cytopathology
                      </Link>
                    </li>

                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/biochemistry-immunology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Biochemistry & Immunology
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/clinical-pathology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Clinical Pathology
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/hematology'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Hematology
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/ultrasonogram'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Ultrasonogram (USG)
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/x-ray'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        X-Ray
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-chevron-right"></i>
                      <Link
                        to='/cardiac-imaging'
                        style={{ fontSize: "14px" }}
                        className="footer-nav-links"
                      >
                        Cardiac Imaging
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <h4 className="gray-headin mb-3">
                {footer?.usefullinks ? footer?.usefullinks : "Useful Links"}
              </h4>
              {/* <span><Link to="/payment-refund-policy">Payment And Refund Policy</Link></span><br/>
                    <span><Link to="/privacy-confidentiality">Privacy Confidentiality</Link></span><br/>
                    <span><Link to="/terms-conditions">Terms And Condition</Link></span> */}
              <ul id="linone" className="st_mp0 st_footer_widget_nav">
                {/* <li className="">
                    <Link to="/privacy-policy">
                      <i className="fas fa-chevron-right"></i> Privacy And Policy
                    </Link>
                  </li> */}
                <li>
                  <Link to="/about-us">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {footer?.aboutus ? footer?.aboutus : "About Us"}
                  </Link>
                </li>
                <li>
                  <Link to="/officials">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {footer?.ourteam ? footer?.ourteam : "Our Team"}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-refund-policy">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {footer?.payment
                      ? footer?.payment
                      : "Payment And Refund Policy"}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {footer?.privacy ? footer?.privacy : "Privacy And Policy"}
                  </Link>
                </li>
                <li>
                  <Link to="/delivery-policy">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {footer?.delivery ? footer?.delivery : "Delivery Policy"}
                  </Link>
                </li>
                <li>
                  <Link to="/terms-conditions">
                    <i className="fas fa-chevron-right"></i>
                    {footer?.terms ? footer?.terms : "Terms And Conditions"}
                  </Link>
                </li>
              </ul>

              <h4 className="gray-headin mt-3">
                {footer?.downloadoption
                  ? footer?.downloadoption
                  : "Download Options"}
              </h4>
              <div className="h-decor"></div>

              <div className="row apps">
                <div className="col-6 col-md-12 mt-2">
                  <img
                    src={footer?.appstoreimage ? footer?.appstoreimage : ios}
                    alt="ios"
                    style={{ width: "140px", height: "39px" }}
                    onClick={() => {
                      // window.open(footer?.appstorelink);
                      window.open(t("footer.appstorelink"));
                      window.focus();
                    }}
                  />
                </div>
                <div className="col-6 col-md-12 mt-2 cursor-pointer">
                  <img
                    src={
                      footer?.googlestoreimage
                        ? footer?.googlestoreimage
                        : android
                    }
                    alt="android"
                    style={{ width: "140px", height: "39px" }}
                    onClick={() => {
                      // window.open(footer?.googlestorelink);
                      window.open(t("footer.googlestorelink"));
                      window.focus();
                    }}
                  />
                </div>
              </div>

              {/* <div className="row qr-code">
                <div className="col-md-12 col-12  mt-2">
                  <h3 className="gray-headin">{t("footer.footertxt2")}</h3>
                  <div className="h-decor"></div>
                  <img
                    src={qr}
                    className="rounded d-block"
                    alt="qr"
                    style={{ width: "75px", height: "75px" }}
                  />
                </div>
              </div> */}
            </div>
            <div className="col-sm-6 col-lg-3">
              <h4 className="gray-headin">
                {footer?.ourcontact
                  ? footer?.ourcontact
                  : t("footer.footertxt3")}
              </h4>
              <div className="h-decor"></div>
              <ul id="linone" className="icn-list">
                <li className="light-Color-Para">
                  <i
                    className="fas fa-map-marker-alt mr-1"
                    style={{ color: "#1a4774" }}
                  ></i>
                  {footer?.address ? footer?.address : t("footer.footertxt4")}
                  <br />
                  <HeaderBtn
                    label={
                      footer?.direction
                        ? footer?.direction
                        : t("footer.footerbtnlabel")
                    }
                    className="headerBtn"
                    onClick={() => window.open(footer?.location)}
                  />
                </li>
                <li className="mt-3">
                  <i className="icon-telephone"></i>
                  <b>
                    <span className="phone">
                      <i className="fas fa-phone-alt mr-1"></i>{" "}
                      <a
                        href={`tel:${footer?.contact}`}
                        className="text-nowrap"
                      >
                        {footer?.contact
                          ? footer?.contact
                          : t("footer.footertxt5")}
                      </a>
                    </span>
                  </b>
                  <br />
                  {footer?.generalenquiry
                    ? footer?.generalenquiry
                    : t("footer.footertxt6")}
                </li>
                <li className="mt-3">
                  <i className="fas fa-envelope phone mr-2"></i>
                  <a href="mailto:info@mdxdmfr.com">
                    {footer?.email ? footer?.email : "footer.footertxt7"}
                  </a>
                </li>

                {/* <span className="d-none d-sm-inline">
                  <img
                    src={securePayment}
                    alt="secure payment"
                    style={{ width: "170px", height: "70px" }}
                  />
                </span> */}
              </ul>
            </div>
          </div>
          <div className="">
            <div className="col-12">
              <img
                src={footer?.paymentoption ? footer?.paymentoption : payp}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* footer Bottom   */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row text-center text-md-left text-white">
            <div className="col-sm">
              {footer?.copyright
                ? footer?.copyright
                : "Copyright © {year} DMFR. All rights reserved."}
            </div>

            <div className="col-sm">
              {footer?.developby ? footer?.developby : t("footer.footertxt9")}
            </div>

            <div className="col-sm-auto ml-auto">
              <span className="d-none d-sm-inline">
                {footer?.emergencycase
                  ? footer?.emergencycase
                  : t("footer.footertxt10")}
                &nbsp;&nbsp;&nbsp;
              </span>
              <i className="icon-telephone"></i>&nbsp;&nbsp;
              <i className="fas fa-phone-alt mr-2"></i>
              <b>
                {" "}
                {footer?.emergencycontact
                  ? footer?.emergencycontact
                  : t("footer.footertxt11")}
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

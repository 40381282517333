import React, { useEffect } from "react";
import contact_us from "../../Statics/Images/banner/contact_us.jpg";
import BannerImg from "../ReUseableComponents/BannerImg";
import "../../Styles/contact.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { conatctUse } from "../../Store/Actions/staticActions";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const Contact = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const contactus = filterComponentData(componetcms, "contact_us_", lang);
  const dispatch = useDispatch();
  const banner = filterComponentData(componetcms, "banners", lang);

  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useForm();
  const onSubmitHandler = (data) => {
    const apiData = {
      email_body: `<Span>Name: ${data?.username}</Span> <br/> <Span>Email: ${data?.user_email}</Span> <br /> 
       <Span>Phone: ${data?.user_phone}</Span> <br /> <Span>Subject: ${data?.subject}</Span> <br /> <Span>Message: ${data?.user_messages}</Span> <br />`,
    };
    dispatch(conatctUse(apiData));
  };
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[20]?.sublink1 ? banner[20]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[20]?.sublink2 ? banner[20]?.sublink2 : "Contact Us",
      subDash: ">",
      subLink: "/contact",
    },
    
  ];
  return (
    <>
      <Helmet>
        <title>{t("contact.maintitle")}</title>
      </Helmet>
      <div className="layout-landing">
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[20]?.image ? banner[20]?.image : contact_us
          })`}
          heading={
            banner[20]?.heading ? banner[20]?.heading : "Contact Us"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

        <div className="container my-5">
          <div className="row">
            <div className="col-xl-7">
              <div className="contact-form-touch">
                <div className="section-title">
                  <h1 className="title" style={{ fontSize: "24px" }}>
                    {" "}
                    {contactus?.head1
                      ? contactus?.head1
                      : t("contact.mainheading1")}
                  </h1>
                </div>
                <div className="contact-form-getin mt-35">
                  <form
                    // action="contact_enquiry.php"
                    id="contact_us_form"
                    // method="post"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="fullname" id="fullname-label">
                            {contactus?.text1
                              ? contactus?.text1
                              : t("contact.contatform.field1")}
                          </label>
                          <input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Name"
                            required="required"
                            {...register("username", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="email" id="email-label">
                            {contactus?.text2
                              ? contactus?.text2
                              : t("contact.contatform.field2")}
                          </label>
                          <input
                            type="email"
                            name="user_email"
                            id="user_email"
                            placeholder="Email"
                            required="required"
                            {...register("user_email", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="fullname" id="fullname-label">
                            {contactus?.text3
                              ? contactus?.text3
                              : t("contact.contatform.field3")}
                          </label>
                          <input
                            type="number"
                            name="user_phone"
                            id="user_phone"
                            placeholder="Phone"
                            required
                            minlenght={5}
                            {...register("user_phone", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="email" id="email-label">
                            {contactus?.text4
                              ? contactus?.text4
                              : t("contact.contatform.field4")}
                          </label>
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            required
                            {...register("subject", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="messages" id="messages-label">
                            {contactus?.text5
                              ? contactus?.text5
                              : t("contact.contatform.field5")}
                          </label>
                          <textarea
                            name="user_messages"
                            id="user_messages"
                            placeholder="Write here"
                            required
                            defaultValue={""}
                            {...register("user_messages", {
                              required: {
                                value: false,
                                message: "this field is required field",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-12 contact_us_alert"
                        style={{ display: "none" }}
                      >
                        <div className="alert alert-success">
                          <b>{t("contact.contatform.alertsuccess")}</b>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          type="Submit"
                          // label={contactus?.text6 ? contactus?.text6:  "Submit"}
                          className="btn headerBtn-red btn-danger"
                          style={{ marginRight: "5px" }}
                        >
                          {contactus?.text6 ? contactus?.text6 : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-5 contact-info-box-wrap">
              <div className="row pr-20">
                <div className="col-lg-12">
                  <div className="contact-info-box">
                    <h4 className="title">
                      {contactus?.head2
                        ? contactus?.head2
                        : t("contact.contactinfo.title")}
                    </h4>
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="content">
                          {contactus?.address
                            ? contactus?.address
                            : t("contact.contactinfo.address")}
                        </div>
                      </li>
                      <li>
                        <a href="tel:01927004000">
                          <span className="icon">
                            <i className="fa fa-phone" />
                          </span>
                          <span className="content">
                            {contactus?.contact
                              ? contactus?.contact
                              : "09606213233"}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@mdxdmfr.com">
                          <span className="icon">
                            <i className="fa fa-envelope" />
                          </span>
                          <span className="content">
                            {contactus?.email
                              ? contactus?.email
                              : "info@mdxdmfr.com"}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@mdxdmfr.com">
                          <span className="icon">
                            <i className="far fa-clock" />
                          </span>
                          <span className="content">
                            {contactus?.timings
                              ? contactus?.timings
                              : t("contact.contactinfo.timeslots")}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

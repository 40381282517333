import React, { useState } from "react";
import ServiceCard from "../../ReUseableComponents/ServiceCard";
import serviceCardImg1 from "../../../Statics/icons/Collection-At-Home.png";
import serviceCardImg2 from "../../../Statics/icons/corporate-collection.png";
import serviceCardImg3 from "../../../Statics/icons/doctor-icon.png";
import serviceCardImg4 from "../../../Statics/icons/Radiology-Services.png";

import "./services.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Services = (props) => {
  const { t, i18n } = useTranslation("common");

  const data = props?.data

  const serviceCardData = [
    {
      img: <i className="fas fa-flask"></i>,
      isImage: false,
      heading: t("home.mainOurService.heading1"),
      details: {
        head: t("home.mainOurService.details1.head"),
        details:t("home.mainOurService.details1.details"),
        route: "/pathology-services",
      },
    },
    {
      img: <i className="fas fa-heartbeat"></i>,
      isImage: false,
      heading: t("home.mainOurService.heading2"),
      details: {
        head: t("home.mainOurService.details2.head"),
        details:t("home.mainOurService.details2.details"),
        route: "/cardiology-services",
      },
    },
    {
      img: serviceCardImg4,
      isImage: true,
      heading: t("home.mainOurService.heading3"),
      details: {
        head: t("home.mainOurService.details3.head"),
        details:t("home.mainOurService.details3.details"),
        route: "/radiology-imaging-services",
      },
    },
    {
      img: serviceCardImg3,
      isImage: true,
      heading: t("home.mainOurService.heading4"),
      details: {
        head: t("home.mainOurService.details4.head"),
        details:t("home.mainOurService.details4.details"),
        route: "/online-consultants",
      },
    },
    {
      img: serviceCardImg1,
      isImage: true,
      heading: t("home.mainOurService.heading5"),
      details: {
        head: t("home.mainOurService.details5.head"),
        details:t("home.mainOurService.details5.details"),
        route: "/collection-home",
      },
    },
    {
      img: serviceCardImg2,
      isImage: true,
      heading: t("home.mainOurService.heading6"),
      details: {
        head: t("home.mainOurService.details6.head"),
        details:t("home.mainOurService.details6.details"),
        route: "/collection-at-corporate",
      },
    },
  ];
  const [viewData, setviewData] = useState(serviceCardData);
  // const [viewData, setviewData] = useState(data[0] ? data[0] : serviceCardData[0]);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-9">
          <div className="row">
            {data?.length < 1 ?  
            serviceCardData?.map((item, index) => (
              <div
                className="col-md-4"
                key={index + 1}
                onClick={() => setviewData(item)}
              >
                <ServiceCard
                  data={item}
                  istrue={false}
                  active={viewData === item}
                  isImage={item.isImage}
                />
              </div>
            )) :
            data?.map((item, index) => (
              <div
                className="col-md-4"
                key={index + 1}
                onClick={() => setviewData(item)}
              >
                <ServiceCard
                  data={item}
                  istrue={true}
                  active={viewData == item}
                  isImage={true}
                />
              </div>
            ))
            }
          </div>
        </div>
        <div className="col-lg-3 p-0">
          <div className="mt-3 ml-3">
            <h3 className="pt-2" style={{ color: "#444" }}>
              {viewData?.detailsheading ? viewData?.detailsheading : viewData?.details?.head}
            </h3>
            <p className="light-Color-Para" style={{ fontWeight: "500" }}>
              {viewData?.detailstext ? viewData?.detailstext : viewData?.details?.details}
            </p>
            <Link
              to={viewData?.route ? viewData?.route : viewData?.details?.route}
              style={{ color: "#e92125", border: "transparent" }}
            >
              {viewData?.button && !viewData?.details?.route ? viewData?.button : ""}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./consultantCard.css";

export const CosultantCard = (props) => {

  const {data} = props;


  const {t,i18n} = useTranslation("common")

  const consultantData = [
    {
      number: t("home.mainconsultants.consultants.number1"),
      heading: t("home.mainconsultants.consultants.heading1"),
      details:t("home.mainconsultants.consultants.details1"),
      link: "/lab-consultants",
    },
    {
      number: t("home.mainconsultants.consultants.number2"),
      heading: t("home.mainconsultants.consultants.heading2"),
      details:t("home.mainconsultants.consultants.details2"),
      link: "/online-consultants",
    },
    {
      number: t("home.mainconsultants.consultants.number3"),
      heading: t("home.mainconsultants.consultants.heading3"),
      details:t("home.mainconsultants.consultants.details3"),
      link: "/officials",
    },
  ];
  return (
    <>
      {data?.length < 1 ? consultantData?.map((item, index) => (
        <div className="row mt-3" key={index + 1}>
          <div
            className="col-12 consultant-card-main"
            
          >
            <div className="consultant-cirlce center-childrens">
              {item?.number}
            </div>
            <div className="consultant-text-side">
              <h5>{item.heading}</h5>
              <p className="light-Color-Para">
                {item?.details}{" "}
                <Link className="hvr-icon-forward" style={{color:'#e92125'}} to={item.link}>
               { t("home.mainconsultants.consultants.btnName")}
                  <i
                    className="fas fa-arrow-right ml-1 hvr-icon "
                    style={{ fontSize: "10px" }}
                  ></i>
                </Link>
              </p>
            </div>
          </div>
        </div>
      ))
    :
    data?.map((item, index) => (
      <div className="row mt-3" key={index + 1}>
        <div
          className="col-12 consultant-card-main"
          
        >
          <div className="consultant-cirlce center-childrens">
            {item?.num}
          </div>
          <div className="consultant-text-side">
            <h5>{item.head}</h5>
            <p className="light-Color-Para">
              {item?.detail}{" "}
              <Link className="hvr-icon-forward" style={{color:'#e92125'}} to={item.route}>
                {item?.btnname}
                <i
                  className="fas fa-arrow-right ml-1 hvr-icon "
                  style={{ fontSize: "10px" }}
                ></i>
              </Link>
            </p>
          </div>
        </div>
      </div>
    ))
    }
    </>
  );
};

import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../../Statics/Images/logo1.png";
import HeaderBtn from "../../ReUseableComponents/HeaderBtn";
import { Link } from "react-router-dom";
import LoginModal from "../modals/loginModal/LoginModal";
import SignUpModal from "../modals/signUpModal/SignUpModal";
import EmailConfirmModal from "../modals/emailConfirmModal/EmailConfirmModal";
import PhoneConfirmModal from "../modals/phoneConfirmModal/PhoneConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import {
  emailModalToggle,
  logoutUser,
  phoneModalToggle,
} from "../../../Store/Actions/loginActions";
import profileLogo from "../../../Statics/Images/profile-logo.png";
import ForgetPasswordModal from "../modals/forgetPasswordModal/ForgetPasswordModal";
import {
  getallNotification,
  testCategory,
} from "../../../Store/Actions/staticActions";
import NotificationModal from "../modals/notificationModal/NotificationModal";
import ChangePasswordModal from "../modals/ChangePasswordModal/ChangePasswordModal";
import { useTranslation } from "react-i18next";
import {
  readLanguageAction,
  SelectedLanguage,
} from "../../../Store/Actions/cmsAction";
// import useMediaQuery from "../../ReUseableComponents/useMediaQueryHook";
// import UploadReportModal from "../modals/uploadReportModal/UploadReportModal";

import ReactFlagsSelect, { Bd } from "react-flags-select";
import useMediaQuery from "../../ReUseableComponents/useMediaQueryHook";
import { isJSON } from "../../../Utils/auth.util";
import { filterComponentData } from "../../../Utils/functions";

const Navbar = (props) => {
  const { t, i18n } = useTranslation("common");

  const [visible, setVisible] = useState(true);

  const [Local, setLocal] = useState([]);
  const [customlocal, setCustomlocal] = useState([]);
  const [selected, setSelected] = useState("US");

  const [customlabel, setCustomlabel] = useState();
  const lang = useSelector((state) => state?.cms?.languages);
  const cartLength = useSelector((state) => state?.static?.cart);
  const componetcms = useSelector((state) => state.cms.components);
  const langs = useSelector((state) => state?.cms?.language);
  const navbar = filterComponentData(componetcms, "navbar", langs);
  let locvar =
    localStorage.getItem("lang") === null
      ? "US"
      : localStorage.getItem("lang").split("-")[1];

  const isMacDesktop = useMediaQuery("(max-width: 768px)");

  useEffect(() => {}, [locvar]);

  const getids = () => {
    let localcode = [];
    let localcodebef = [];
    lang?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      let id0 = firword.split(id)[0];
      localcode?.push(id);
      localcodebef?.push(id0);
    });
    setLocal(localcode);
    setCustomlocal(localcodebef);
  };

  const customlabels = () => {
    let locallabel = {};
    lang?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      locallabel = {
        ...locallabel,
        [id]: item?.name,
      };
    });
    setCustomlabel(locallabel);
  };

  useEffect(() => {
    getids();
    customlabels();
  }, [lang]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 10) {
      setVisible(false);
    } else if (scrolled <= 10) {
      setVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
  }, [toggleVisible, visible]);

  const [loginModal, setLoginModal] = useState(false);
  const [showchangepassword, setshowchangepassword] = useState(false);

  const [signUpModal, setSignUpModal] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const user1 = useSelector((state) => state?.logIn?.user);
  const emailModal = useSelector((state) => state?.logIn?.emailModal);
  const phoneModal = useSelector((state) => state?.logIn?.phoneModal);
  const userRole1 = useSelector((state) => state?.logIn?.userRole);
  const [notificationModal, setNotificationModal] = useState(false);
  const [cartcount, setcartcount] = useState(0);

  // const isMacDesktop = useMediaQuery('(max-width: 1300px)');
  const user = isJSON(user1);
  const userRole = isJSON(userRole1);

  // const testCategoryData = useSelector((state) => state?.static?.testCategory);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logoutUser());
    props?.history?.push("/");
  };
  const profileHandler = () => {
    if (userRole === "doctor") {
      props.history.push("/doctor-dashboard");
    } else if (userRole === "administrator") {
      props.history.push("/administrator");
    } else if (userRole === "pharmacy") {
      props.history.push("/pharmacy");
    } else if (
      userRole === "laboratory-admin" ||
      userRole === "laboratory_admin"
    ) {
      props.history.push("/lab-administrator");
    } else if (userRole === "patient") {
      if (!user?.email_address_is_verified) {
        // alert("Email");
        dispatch(emailModalToggle(true));
      } else if (!user?.phone_number_is_verified) {
        // alert("Password");
        dispatch(phoneModalToggle(true));
      } else if (
        user?.email_address_is_verified &&
        user?.phone_number_is_verified
      ) {
        // alert("Access");
        props.history.push("/patient-dashboard");
      }
    }
  };

  useEffect(() => {
    dispatch(readLanguageAction());

    dispatch(testCategory());
  }, [dispatch]);

  const forgetPasswordHandler = () => {
    setLoginModal(false);
    setForgetPassword(true);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-xl navbar-light bg-light ${
          visible ? "sticky-top" : "st-sticky-header"
        }`}
        
      >
        <div className="container containenavcustome">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt="logo"
              style={{
                // width: isMacDesktop ? "170px" : "190px",
                width: "150px",
                height: "59.3px",
              }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item px-1">
                <Link className="nav-link" to="/home">
                  {navbar?.home ? navbar?.home : t("navbar.navtxt1")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/telemedicine">
                  {navbar?.telemedicine
                    ? navbar?.telemedicine
                    : t("navbar.navtxt2")}
                </Link>
              </li>
              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to=""
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.covid19 ? navbar?.covid19 : t("navbar.navtxt3")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <br />
                  <Link className="dropdown-item" to="/covid-19-info">
                    {navbar?.covid19info
                      ? navbar?.covid19info
                      : t("navbar.navtxt3drop.navtxtdrop1")}
                  </Link>
                  <a
                    className="dropdown-item"
                    target="_blank"
                    rel="noreferrer"
                    href="https://corona.gov.bd/"
                  >
                    {navbar?.covid19live
                      ? navbar?.covid19live
                      : t("navbar.navtxt3drop.navtxtdrop2")}
                  </a>
                  <Link
                    className="dropdown-item"
                    to="/booking-for-covid-19-test"
                  >
                    {navbar?.covid19test
                      ? navbar?.covid19test
                      : t("navbar.navtxt3drop.navtxtdrop3")}
                  </Link>
                  <Link className="dropdown-item" to="/covid-19-team">
                    {navbar?.covid19team
                      ? navbar?.covid19team
                      : t("navbar.navtxt3drop.navtxtdrop4")}
                  </Link>
                </div>
              </li>

              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.aboutus ? navbar?.aboutus : t("navbar.navtxt4")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <br />
                  <Link className="dropdown-item" to="/about-us">
                    {navbar?.whydmfr ? (
                      <span>{navbar?.whydmfr}</span>
                    ) : (
                      <span>
                        {t("navbar.navtxt4drop.navtxtdrop1")}
                        <br /> {t("navbar.navtxt4drop.navtxtdrop1-1")}
                      </span>
                    )}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/managing-director-message"
                  >
                    {navbar?.directormessage
                      ? navbar?.directormessage
                      : t("navbar.navtxt4drop.navtxtdrop2")}
                  </Link>
                  <Link className="dropdown-item" to="/our-mission">
                    {navbar?.mission
                      ? navbar?.mission
                      : t("navbar.navtxt4drop.navtxtdrop3")}
                  </Link>
                </div>
              </li>

              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.ourteam ? navbar?.ourteam : t("navbar.navtxt5")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <br />
                  <Link className="dropdown-item" to="/lab-consultants">
                    {navbar?.labconsultant
                      ? navbar?.labconsultant
                      : t("navbar.navtxt5drop.navtxtdrop1")}
                  </Link>
                  <Link className="dropdown-item" to="/lab-team">
                    {navbar?.labteam
                      ? navbar?.labteam
                      : t("navbar.navtxt5drop.navtxtdrop2")}
                  </Link>
                  <Link className="dropdown-item" to="/officials">
                    {navbar?.official
                      ? navbar?.official
                      : t("navbar.navtxt5drop.navtxtdrop3")}
                  </Link>
                </div>
              </li>

              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.service ? navbar?.service : t("navbar.navtxt6")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <br />
                  <Link className="dropdown-item" to="/pathology-services">
                    {navbar?.pathology
                      ? navbar?.pathology
                      : t("navbar.navtxt6drop.navtxtdrop1")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/radiology-imaging-services"
                  >
                    {navbar?.radiology
                      ? navbar?.radiology
                      : t("navbar.navtxt6drop.navtxtdrop2")}
                  </Link>
                  <Link className="dropdown-item" to="/cardiology-services">
                    {navbar?.cardiology
                      ? navbar?.cardiology
                      : t("navbar.navtxt6drop.navtxtdrop3")}
                  </Link>
                  <Link className="dropdown-item" to="/online-consultants">
                    {navbar?.onlineconsultation
                      ? navbar?.onlineconsultation
                      : t("navbar.navtxt6drop.navtxtdrop4")}
                  </Link>
                  <Link className="dropdown-item" to="/collection-home">
                    {navbar?.collectionathome
                      ? navbar?.collectionathome
                      : t("navbar.navtxt6drop.navtxtdrop5")}
                  </Link>
                  <Link className="dropdown-item" to="/collection-at-corporate">
                    {navbar?.collectionatcoperate
                      ? navbar?.collectionatcoperate
                      : t("navbar.navtxt6drop.navtxtdrop6")}
                  </Link>
                </div>
              </li>

              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.package ? navbar?.package : t("navbar.navtxt7")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/packages-male">
                    {navbar?.male
                      ? navbar?.male
                      : t("navbar.navtxt7drop.navtxtdrop1")}
                  </Link>
                  <Link className="dropdown-item" to="/packages-female">
                    {navbar?.female
                      ? navbar?.female
                      : t("navbar.navtxt7drop.navtxtdrop2")}
                  </Link>
                </div>
              </li>

              <li className="nav-item px-1 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  // to="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {navbar?.newandmedia
                    ? navbar?.newandmedia
                    : t("navbar.navtxt8")}
                </Link>
                <div className="h-100" style={{ cursor: "pointer" }}></div>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/news">
                    {navbar?.news
                      ? navbar?.news
                      : t("navbar.navtxt8drop.navtxtdrop1")}
                  </Link>
                  <Link className="dropdown-item" to="/gallery">
                    {navbar?.gallery
                      ? navbar?.gallery
                      : t("navbar.navtxt8drop.navtxtdrop2")}
                  </Link>
                  <a
                    className="dropdown-item"
                    href="/statics/DMFR-Brochure.pdf"
                    download
                  >
                    {navbar?.brochure
                      ? navbar?.brochure
                      : t("navbar.navtxt8drop.navtxtdrop3")}
                  </a>
                </div>
              </li>

              <li className="nav-item px-1">
                <a
                  className="nav-link"
                  rel="noreferrer"
                  target="_blank"
                  href="https://icare.mdxdmfr.com/medicare-web-client-v2/#/login"
                >
                  {navbar?.reportportal
                    ? navbar?.reportportal
                    : t("navbar.navtxt9")}
                </a>
              </li>
              <li className="nav-item px-1">
                <Link className="nav-link " to="/contact">
                  {navbar?.contact ? navbar?.contact : t("navbar.navtxt10")}
                </Link>
              </li>
            </ul>
            {isMacDesktop ? (
              <div className="">
                <div className="dropdown">
                  <ReactFlagsSelect
                    countries={Local}
                    selected={locvar}
                    optionsSize={13}
                    selectedSize={13}
                    customLabels={customlabel}
                    onSelect={(code) => {
                      localStorage.removeItem("lang");
                      dispatch(
                        SelectedLanguage(
                          lang.filter((item) => item?.locale.includes(code))[0]
                            ?.locale
                        )
                      );
                      localStorage.setItem(
                        "lang",
                        lang.filter((item) => item?.locale.includes(code))[0]
                          ?.locale
                      );
                      setSelected(code);
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {user && userRole ? (
              <>
                <div className="mr-4" id="navbar-list-4">
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown ">
                      <div
                        className="nav-link dropdown-toggle"
                        // to="/patient-dashboard"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={profileLogo}
                          alt="user"
                          width="40"
                          height="40"
                          className="rounded-circle"
                        />
                      </div>
                      <div
                        className="h-100"
                        style={{ cursor: "pointer" }}
                      ></div>
                      <div
                        className="dropdown-menu profile-nav-dropdown"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <div
                          className="dropdown-item-user "
                          style={{ textDecoration: "none", width: "160px" }}
                          // onClick={() => profileHandler()}
                        >
                          <div className=" " style={{ wordBreak: "break-all" }}>
                            {user.name}
                          </div>
                          <div
                            className="px-3"
                            style={{ borderTop: "1px solid" }}
                          ></div>
                          {/* <hr className="my-0 mx-2 p-0" style={{width:'fit-content',color:'red',zIndex:'1'}}/> */}
                        </div>
                        <Link
                          className="dropdown-item"
                          onClick={() => profileHandler()}
                        >
                          <i
                            className="fas fa-id-card mr-2"
                            style={{ fontSize: "14.5px" }}
                          ></i>
                          {t("navbar.navtxt11")}
                        </Link>
                        <div
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setNotificationModal(true);
                            dispatch(getallNotification({ id: user?.id }));
                          }}
                        >
                          <i
                            className="fas fa-bell mr-2"
                            style={{ fontSize: "14.5px" }}
                          ></i>{" "}
                          {t("navbar.navtxt12")}
                        </div>
                        {user?.register_channel?.id == 1 ? (
                          <div
                        style={{ cursor: "pointer" }}

                            className="dropdown-item"
                            onClick={() => {
                              setshowchangepassword(true);
                            }}
                          >
                            <i className="fa fa-key"></i>
                            &nbsp;&nbsp;Change Password
                          </div>
                        ) : null}

                        <Link
                          className="dropdown-item"
                          to="/"
                          onClick={() => logoutHandler()}
                        >
                          <i
                            className="fas fa-sign-out-alt mr-2"
                            style={{ fontSize: "14.5px" }}
                          ></i>
                          {t("navbar.navtxt13")}
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <HeaderBtn
                label={navbar?.login ? navbar?.login : t("navbar.navbtnlogtxt")}
                login={true}
                onClick={() => setLoginModal(!loginModal)}
                // className={"headerBtn-red"}
                className={"headerBtn-red px-2"}
                // className={isMacDesktop ? "headerBtn-red px-2" : "headerBtn-red"}
                style={{ marginRight: "5px" }}
              />
            )}

            <div className="form-inline my-2 my-lg-0">
              <HeaderBtn
                label={navbar?.cart ? navbar?.cart : t("navbar.navbtncarttxt")}
                // className={"headerBtn"}
                className={"headerBtn px-2"}
                // className={isMacDesktop ? "headerBtn px-2" : "headerBtn"}
                cart={true}
                onClick={() => props?.history?.push("/cart")}
              />
              {cartLength ? (
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    zIndex: "999",
                    position: "absolute",
                    marginBottom: "30px",
                    background: "red",
                  }}
                  className="text-white text-center"
                >
                  {cartLength}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </nav>
      {/* Login Modal */}
      <LoginModal
        show={loginModal}
        onHide={() => setLoginModal(!loginModal)}
        showSignUpModal={() => setSignUpModal(true)}
        forgetPasswordHandler={forgetPasswordHandler}
        {...props}
      />
      <SignUpModal
        show={signUpModal}
        onHide={() => setSignUpModal(!signUpModal)}
        {...props}
      />
      <ForgetPasswordModal
        show={forgetPassword}
        onHide={() => setForgetPassword(!forgetPassword)}
        {...props}
      />
      <ChangePasswordModal
        show={showchangepassword}
        onHide={() => setshowchangepassword(false)}
        {...props}
      />
      <EmailConfirmModal
        show={emailModal}
        onHide={() => dispatch(emailModalToggle(false))}
        {...props}
      />
      <PhoneConfirmModal
        show={phoneModal}
        onHide={() => dispatch(phoneModalToggle(false))}
        {...props}
      />
      <NotificationModal
        show={notificationModal}
        onHide={() => setNotificationModal(false)}
        {...props}
      />
      {/* <UploadReportModal 
      show={true}/> */}
    </>
  );
};

export default Navbar;

import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import DirectorMessageBanner from "../../Statics/Images/internal-banner/director.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import messageDirectorImg from "../../Statics/Images/Managing-Director.jpg";
import directorIconImg from "../../Statics/Images/authore.png";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const DirectorMessage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const componetcms = useSelector((state) => state.cms.components);
  
  const lang = useSelector((state) => state?.cms?.language)
  const about = filterComponentData(componetcms, 'directorsmessage', lang)
  const banner = filterComponentData(componetcms, "banners", lang);
  const { t, i18n } = useTranslation("common");
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[5]?.sublink1 ?banner[5]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[5]?.sublink2 ?banner[5]?.sublink2:"About Us",
      subDash: ">",
      subLink: "/managing-director-message",
    },
    {
      subLinkName: banner[5]?.sublink3 ?banner[5]?.sublink3:"Directors Message",
      subDash: ">",
      subLink: "/managing-director-message",
    },
  ]
  return (
    <>
      <Helmet>
        <title>{t("directormessage.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[5]?.image ? banner[5]?.image : DirectorMessageBanner})`}
        heading={banner[5]?.heading ? banner[5]?.heading : "Directors Message"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="container">
        <div className="row mb-5">

          <TextAndImageSection
            imgAlt="Md. Faizur Rahman"
            imgCol={"col-lg-4"}
            textCol={"col-lg-8"}
            imgSrc={about?.userimage ? about?.userimage : messageDirectorImg}
            subHeading={about?.subheading ? about?.subheading : t("directormessage.textrev.subHeading")}
            headingFirst={about?.headfirst ? about?.headfirst : t("directormessage.textrev.headfirst")}
            headingSecond={about?.headsecond ? about?.headsecond : t("directormessage.textrev.headsecond")}
            discription={
              <>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                  className="light-Color-Para"
                >
                  {about?.desc1 ? about?.desc1 : t("directormessage.textrev.discriptiontxt1")}
                </p>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                  }}
                  className="light-Color-Para"
                >
                  {about?.desc2 ? about?.desc2 : t("directormessage.textrev.discriptiontxt2")}
                </p>
                <p
                  style={{
                    fontFamily: "Work Sans', sans-serif",
                    lineHeight: "25px",
                  }}
                  className="light-Color-Para"
                >
                  {about?.desc3 ? about?.desc3 : t("directormessage.textrev.discriptiontxt3")}
                </p>
                <div className="row">
                  <div className=" d-flex flex-row p-3">
                    <div>
                      <img src={about?.usericonimage ? about?.usericonimage : directorIconImg} alt="Md. Faizur Rahman" />
                    </div>
                    <div className="ml-3 mt-2" style={{ width: "300px" }}>
                      <h5 className=" gray-headin">{about?.name ? about?.name : t("directormessage.textrev.name")}</h5>
                      <span style={{ fontWeight: "700", color: "#e92125" }}>
                        {about?.designation ? about?.designation : t("directormessage.textrev.designation")}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            }
            reverse={false}
          />
        </div>
      </div>
    </>
  );
};

export default DirectorMessage;

export const administratorConstants = {
  PATIENTS: "PATIENTS",
  ADMINISTRATOR_LOADER: 'ADMINISTRATOR_LOADER',

  DOCTOR: "DOCTOR",
  DOCTOR_REQUESTS: "DOCTOR_REQUESTS",


  UPDATE_RESCHEDULE_REQUEST: "UPDATE_RESCHEDULE_REQUEST",
  ORDER_BY_EMAIL: "ORDER_BY_EMAIL",
  READ_TARGET: "READ_TARGET",
  READ_BDE: "READ_BDE",
  ASSIGN_TARGET: 'ASSIGN_TARGET',
  UPDATE_TARGET: 'UPDATE_TARGET',





  RETURN_REQUEST: 'RETURN_REQUEST',
  TODAYS_APPOINTMENT: 'TODAYS_APPOINTMENT',
  UPCOMING_APPOINTMENT: 'UPCOMING_APPOINTMENT',
  PAST_APPOINTMENT: 'PAST_APPOINTMENT',

  PARTIAL_PAYEMNT_REQUEST: 'PARTIAL_PAYEMNT_REQUEST'
};

import React from "react";
import Slider from "react-slick";
import "./testemonials.css";
import img1 from "../../../Statics/testimonials/faizalahmed.jpg";
import img2 from "../../../Statics/testimonials/farjana.jpg";
import img3 from "../../../Statics/testimonials/Sumon.jpg";
import { useTranslation } from "react-i18next";


const Testemonials = ({ data, testamonial }) => {
  const { t, i18n } = useTranslation("common")
  const testemonialsData = [
    {
      img: img1,
      review: t("home.maintestimonials.review1"),
      name: t("home.maintestimonials.name1"),
      link: "",
    },
    {
      img: img2,
      review: t("home.maintestimonials.review2"),
      name: t("home.maintestimonials.name2"),
      link: "",
    },
    {
      img: img3,
      review: t("home.maintestimonials.review3"),
      name: t("home.maintestimonials.name3"),
      link: "",
    },
  ];
  var settings = {
    speed: 3000,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    infinite: true,
    autoplay: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="container mb-5" style={{overflowX: "hidden"}}>
      <div style={{ marginTop: "80px" }}>
        <Slider {...settings}>
          {testamonial?.length < 1 ? testemonialsData?.map((item, index) => (
            <div className="center-childrens main-testemonail" key={index + 1}>
              {/* <img src={item.img} alt="testimonials" className="testemonial-img" /> */}
              <p className="testemonial-review mt-4">{item.review}</p>
              <p className="light-Color-Para">-{item.name}</p>
            </div>
          ))
            : testamonial?.map((item, index) => (
              <div className="center-childrens main-testemonail" key={index + 1}>
                <img src={item?.image} alt="testimonials" className="testemonial-img" />
                <p className="testemonial-review mt-4">{item?.review}</p>
                <p className="light-Color-Para">-{item?.heading}</p>
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  );
};

export default Testemonials;

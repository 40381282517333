import React from "react";

const TextAndImageSection = (props) => {
  const ImgDiv = (
    <div className={props.imgCol ? props.imgCol : "col-lg-6"}>
      <img
        src={props.imgSrc}
        // alt={props.subHeading ? props.subHeading : props.headingFirst ? props.headingFirst : "" }
        style={props.imgStyle}
        className="img-fluid"
      />
      {props.imgHtml}
    </div>
  );
  const textDiv = (
    <div className={props.textCol ? props.textCol : "col-lg-6 mt-3"}>
      <div className="heading-wraper">
        <h1  className="subHeading h1customefontsize" style={props.subHeadingStyle}>
          {props?.subHeading}
        </h1>
        <h1 style={{ textTransform: "uppercase" ,fontSize:'24px' }} className="gray-headin">
          {props.headingFirst}
          {props.headingSecond ? (
            <span style={{ color: "#1a4774" }}>
              {" "}
              &nbsp;{props.headingSecond}
            </span>
          ) : null}
        </h1>
      </div>
      <div className="text-wraper">{props.discription}</div>
    </div>
  );
  return (
    <div className="container mt-5">
      <div className="row">
        {props.reverse ? (
          <>
            {ImgDiv}
            {textDiv}
          </>
        ) : (
          <>
            {textDiv}
            {ImgDiv}
          </>
        )}
      </div>
    </div>
  );
};

export default TextAndImageSection;

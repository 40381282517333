import React, { useEffect, useState } from 'react'
import PakagesFemale from '../LayOuts/Cards/pakagesFemale/PakagesFemale'
import BannerWithText from '../ReUseableComponents/BannerWithText'
import BannerImg from "../../Statics/Images/about-breadcrumb-bg.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getPackages } from '../../Store/Actions/staticActions';
import Loader from '../ReUseableComponents/Loader';
import ViewPackageDetails from '../LayOuts/modals/viewpackageDetails/ViewPackageDetails';
import DashboardBtnList from '../ReUseableComponents/DashboardBtnList';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { filterComponentData } from '../../Utils/functions';
import BannerImageWithText from '../ReUseableComponents/BannerImgComponents';
const FemalePackages = (props) => {
  const {t,i18n} = useTranslation("common")
  const dispatch = useDispatch();
  const packages = useSelector((state) => state?.static?.packages);
  const loader = useSelector((state) => state?.logIn?.loader);
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);
  const banner = filterComponentData(componetcms, "banner", lang);
  const [selectedPackage, setSelectedPackage] = useState({
    show: false,
    data: {},
  });
  useEffect(() => {
    dispatch(
      getPackages({
        gender: {
          id: 2,
          name: "female",
        },
      })
    );
  }, [dispatch]);
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[17]?.sublink1 ? banner[17]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[17]?.sublink2 ? banner[17]?.sublink2 : "Packages",
      subDash: ">",
      subLink: "/packages-female",
    },
    {
      subLinkName: banner[17]?.sublink3
        ? banner[17]?.sublink3
        : "Packages Female",
      subDash: ">",
      subLink: "/packages-female",
    },
  ];
  return (
    <>
     <Helmet>
        <title>
        {t("femalepakages.maintitle")}
        </title>
      </Helmet>
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[17]?.image ? banner[17]?.image : BannerImg
          })`}
          heading={
            banner[17]?.heading ? banner[17]?.heading : "Package Female"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
      <div className="container">
        {loader ? (
          <Loader />
        ) : (
          <div className="row mt-5 mb-5">
               <div className="container">
              {[t("femalepakages.labteamhandler.buttonname1"), t("femalepakages.labteamhandler.buttonname2")].map((item, index) => (
                <div
                  className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 mb-4 "
                  key={index + 1}
                >
                  <DashboardBtnList
                    label={item}
                    onClick={
                      item === t("femalepakages.labteamhandler.buttonname1") ? () => props?.history?.push("/packages-male") : ""
                    }
                    labelStyle={
                      item === t("femalepakages.labteamhandler.buttonname2") ? { color: "#fff" } : ""
                    }
                    className={
                      item === t("femalepakages.labteamhandler.buttonname2")
                        ? "dashboardBtnList-item-active py-2"
                        : "default-color-and-hover py-2"
                    }
                  />
                </div>
              ))}
            </div>
            {packages.map((item, index) => (
              <div className="col-lg-3 mb-4" key={index}>
                <PakagesFemale
                  head={item?.name}
                  price={`BDT ${item?.price}`}
                  onClick={() => setSelectedPackage({ show: true, data: item })}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <ViewPackageDetails
        show={selectedPackage?.show}
        data={selectedPackage?.data}
        {...props}
        onHide={() =>
          setSelectedPackage({ show: false, data: selectedPackage?.data })
        }
      />
    </>
  );
}

export default FemalePackages

import React from 'react'
import { Helmet } from 'react-helmet'

const Clinicalpathology = () => {
    return (
        <>
              <Helmet>
        <title>
        Clinical Pathology - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>

            Clinicalpathology
        </>
    )
}

export default Clinicalpathology

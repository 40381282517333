import React, { useEffect, useState } from "react";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import BannerImg from "../../Statics/Images/about-breadcrumb-bg.jpg";
import PakagesFemale from "../LayOuts/Cards/pakagesFemale/PakagesFemale";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../Store/Actions/staticActions";
import Loader from "../ReUseableComponents/Loader";
import ViewPackageDetails from "../LayOuts/modals/viewpackageDetails/ViewPackageDetails";
import DashboardBtnList from "../ReUseableComponents/DashboardBtnList";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const MalePackages = (props) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);

  const packages = useSelector((state) => state?.static?.packages);
  const loader = useSelector((state) => state?.logIn?.loader);
  const [selectedPackage, setSelectedPackage] = useState({
    show: false,
    data: {},
  });
  const banner = filterComponentData(componetcms, "banners", lang);

  useEffect(() => {
    dispatch(
      getPackages({
        gender: {
          id: 1,
          name: "male",
        },
      })
    );
  }, [dispatch]);
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[16]?.sublink1 ? banner[16]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[16]?.sublink2 ? banner[16]?.sublink2 : "Packages",
      subDash: ">",
      subLink: "/packages-male",
    },
    {
      subLinkName: banner[16]?.sublink3
        ? banner[16]?.sublink3
        : "Packages Male",
      subDash: ">",
      subLink: "/packages-male",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("malepakages.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[16]?.image ? banner[16]?.image : BannerImg
          })`}
          heading={
            banner[16]?.heading ? banner[16]?.heading : "Package Male"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
      <div className="container">
        {loader ? (
          <Loader />
        ) : (
          <div className="row mb-5">
            <div className="container">
              {[t("malepakages.labteamhandler.buttonname1"),t("malepakages.labteamhandler.buttonname2")].map((item, index) => (
                <div
                  className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 mb-4 "
                  key={index + 1}
                >
                  <DashboardBtnList
                    label={item}
                    onClick={
                      item === t("malepakages.labteamhandler.buttonname2")
                        ? () => props?.history?.push("/packages-female")
                        : ""
                    }
                    labelStyle={
                      item === t("malepakages.labteamhandler.buttonname1") ? { color: "#fff" } : ""
                    }
                    className={
                      item === t("malepakages.labteamhandler.buttonname1")
                        ? "dashboardBtnList-item-active py-2"
                        : "default-color-and-hover py-2"
                    }
                  />
                </div>
              ))}
            </div>

            {packages.map((item, index) => (
              <div className="col-lg-3 mb-4" key={index}>
                <PakagesFemale
                  head={item?.name}
                  price={`BDT ${item?.price}`}
                  onClick={() => setSelectedPackage({ show: true, data: item })}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <ViewPackageDetails
        show={selectedPackage?.show}
        data={selectedPackage?.data}
        {...props}
        onHide={() =>
          setSelectedPackage({ show: false, data: selectedPackage?.data })
        }
      />
    </>
  );
};
export default MalePackages;

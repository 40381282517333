import { Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import HeaderBtn from "../../../ReUseableComponents/HeaderBtn";
import "./signUpModal.css";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../../Store/Actions/signUpActions";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useTranslation } from "react-i18next";
// import { emailModalToggle } from "../../../../Store/Actions/loginActions";

const SignUpModal = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relativePhoneNumber, setRelativPhoneNumber] = useState("");
  const { onHide } = props;
  // const staticData = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data.password === data.confirmPassword) {
      const apiData = {
        name: data.name,
        phone_number: "+" + phoneNumber,
        gender:
          data.gender === "male"
            ? { id: 1, name: "male" }
            : { id: 2, name: "female" },
        date_of_birth: "1998-08-05",
        registration_type:
          data.registrationType === "patient"
            ? { id: 1, name: "I am a patient" }
            : { id: 2, name: "I am registering on behalf of patient" },
        email_address: data.email,
        relative:
          data.registrationType !== "patient"
            ? {
              name: data?.relativeName || "-",
              relation: data?.relativeRelation || "-",
              contact_number: "+" + relativePhoneNumber || "-",
            }
            : {},
        password: data.password,
        profession: data.profession,
        date_of_birth:data?.date_of_birth
      };
      let res;

      if (phoneNumber?.length >= 12 && phoneNumber?.length <= 15) {
        res = await dispatch(registerUser(apiData));
      } else {
        toast.error("Phone Number is Invalid");
      }
      if (res == "true") {
        onHide();
      }
    } else {
      toast.error(t("toastmessages.passwordnotmatch"));
    }
  };

  const registerationTye = watch("registrationType", props.registrationType);

  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter "
        centered
        style={{ padding: "0px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.registration")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.name")}
                </span>
                <input
                  className="input-login-modal"
                  type="text"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                />
                {errors?.name?.message ? (
                  <div className="text-error">{errors?.name?.message}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.emailaddress")}
                </span>
                <input
                  className="input-login-modal"
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                />
                {errors?.email?.message ? (
                  <div className="text-error">{errors?.email?.message}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {"Date Of Birth"}
                </span>
                <input
                  className="input-login-modal"
                  type="date"
                  {...register("date_of_birth", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                />
                {errors?.email?.message ? (
                  <div className="text-error">{errors?.email?.message}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.gender")}
                </span>
                <Form.Control
                  as="select"
                  className="input-login-modal"
                  custom
                  defaultValue="male"
                  {...register("gender", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                >
                  <option value="male">
                    {t("dashboards.alldashboardkeys.tablekeys.male")}
                  </option>
                  <option value="female">
                    {t("dashboards.alldashboardkeys.tablekeys.female")}
                  </option>
                </Form.Control>
                {errors?.gender?.message ? (
                  <div className="text-error">{errors?.gender?.message}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.phonenumber")}
                </span>
                <PhoneInput
                  className="test"
                  country={"bd"}
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                />
                {errors?.phoneNumber?.message ? (
                  <div className="text-error">
                    {errors?.phoneNumber?.message}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.profession")}
                </span>
                <input
                  className="input-login-modal"
                  type="text"
                  {...register("profession", {})}
                />
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.registerationtype")}
                </span>
                <Form.Control
                  as="select"
                  className="input-login-modal"
                  defaultValue="patient"
                  custom
                  {...register("registrationType", {})}
                >
                  <option value="patient">
                    {t("dashboards.alldashboardkeys.tablekeys.patient")}
                  </option>
                  <option value="relative">
                    {t("dashboards.alldashboardkeys.tablekeys.relative")}
                  </option>
                </Form.Control>
                {errors?.gender?.message ? (
                  <div className="text-error">{errors?.gender?.message}</div>
                ) : (
                  ""
                )}
              </div>
              {registerationTye === "relative" ? (
                <>
                  <div className="col-lg-6 col-12">
                    <span className="label-name-login">
                      {t("dashboards.alldashboardkeys.tablekeys.relativename")}
                    </span>
                    <input
                      className="input-login-modal"
                      type="text"
                      {...register("relativeName", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.relativeName?.message ? (
                      <div className="text-error">
                        {errors?.relativeName?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-6 col-12">
                    <span className="label-name-login">
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.relativewithpatient"
                      )}
                    </span>
                    <input
                      className="input-login-modal"
                      type="text"
                      {...register("relativeRelation", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                    {errors?.relativeRelation?.message ? (
                      <div className="text-error">
                        {errors?.relativeRelation?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-6 col-12">
                    <span className="label-name-login">
                      {t(
                        "dashboards.alldashboardkeys.tablekeys.relativewithmobilenumber"
                      )}
                    </span>
                    <PhoneInput
                      country={"bd"}
                      value={relativePhoneNumber}
                      onChange={(phone) => setRelativPhoneNumber(phone)}
                    />
                    {errors?.relativeNumber?.message ? (
                      <div className="text-error">
                        {errors?.relativeNumber?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : null}
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.password")}
                </span>
                <input
                  className="input-login-modal"
                  type="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                />
                {errors?.password?.message ? (
                  <div className="text-error">{errors?.password?.message}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-12">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.confirmpassword")}
                </span>
                <input
                  className="input-login-modal"
                  type="password"
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "this field is required field",
                    },
                  })}
                />
                {errors?.confirmPassword?.message ? (
                  <div className="text-error">
                    {errors?.confirmPassword?.message}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="submit"
                label={t("dashboards.alldashboardkeys.tablekeys.signup")}
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
              <a
                href="https://accounts.google.com/o/oauth2/v2/auth?client_id=417455767792-i13veq1ohqh7sg1tlg86d0eco536dpd2.apps.googleusercontent.com&redirect_uri=https://www.mdxdmfr.com/auth-signup-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                className="headerBtn btn-block mx-auto"
                target="_blank"
                style={{ width: "85%", textAlign: "center" }}
              >
                <img
                  width="15px"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                  alt="Google login"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                />
                Google Sign Up
              </a>
              <a
                href="https://www.facebook.com/v12.0/dialog/oauth?client_id=541247183991360&redirect_uri=https://www.mdxdmfr.com/auth-signup-verification-facebook&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                className="headerBtn btn-block mx-auto"
                target="_blank"
                style={{ width: "85%", textAlign: "center" }}
              >
                <img
                  width="15px"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                  alt="FB Signup"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                />
                Facebook Sign Up
              </a>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignUpModal;

/* eslint-disable */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, FormLabel, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { acceptAppointmentAction } from "../../../../Store/Actions/administratorActions";
import { getSlotByDateAndDoctor } from "../../../../Store/Actions/patientActions";
import { disablePastDate } from "../../../../Utils/functions";

const SlotsModal = (props) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const [date, setDate] = useState();

  const slots = useSelector((state) => state?.patient?.slotsByDate);

  const [slotId, setslotId] = useState();

  const [selectedSolt, setselectedSolt] = useState();
  const new_date = new Date();
  const acceptReschedule = () => {
    if (date === undefined && selectedSolt === undefined) {
      toast.error(t("dashboards.alldashboardkeys.tablekeys.entervaliddetails"));
      handleClose(true);
    } else {
      let apiData = {
        id: props?.data1,
        slot_id: slotId,
        date: date,
        status: { id: 2, name: "approved" },
      };
      let res = dispatch(acceptAppointmentAction(apiData));
      if (res) {
        props.onHide();
      } else {
        toast.error(t("dashboards.alldashboardkeys.tablekeys.notaccepted"));
      }
    }
  };

  useEffect(() => {
    let apiData = {
      id: props.data,

      date: date,
      timestamp: moment(new_date)
        .add(moment(new_date).utcOffset(), "minutes")
        .utc()
        .unix(),
    };
    if(props?.data){

      dispatch(getSlotByDateAndDoctor(apiData));
    }
  }, [date]);

  useEffect(() => {
    getSlotId();
  }, [selectedSolt]);

  const getSlotId = () => {
    slots?.map((item) =>
      item?.duration === selectedSolt ? setslotId(item?.slot_id) : ""
    );
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.approve")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="date"
            min={disablePastDate()}
            onChange={(e) => 
              setDate(e.target.value)}
            className="form-control"
          />

          <FormLabel>
            {t("dashboards.alldashboardkeys.tablekeys.availableslots")}
          </FormLabel>

          <Form.Control
            as="select"
            className="input-login-modal"
            defaultValue={selectedSolt}
            onChange={(val) => setselectedSolt(val.target.value)}
          >
            <option>Select Slot:</option>
            {slots?.map((item, index) => (
              <option value={item?.duration} key={index + 1}>
                {item?.duration}
              </option>
            ))}
          </Form.Control>

          <input
            type="submit"
            value={t("dashboards.alldashboardkeys.tablekeys.submit")}
            className="headerBtn btn-block mx-auto"
            onClick={acceptReschedule}
            style={{ width: "85%", textAlign: "center" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SlotsModal;

import React, { useEffect } from 'react'
import queryString from "query-string";
import { useDispatch, useSelector } from 'react-redux';
import { addPhoneModalToggle, googleSignIn, phoneModalToggle, sendPhoneOtp } from '../../Store/Actions/loginActions';
import Loader from '../ReUseableComponents/Loader';
import AddPhoneNumber from '../LayOuts/modals/AddPhoneNumberModal/AddPhoneNumber';
import PhoneConfirmModal from '../LayOuts/modals/phoneConfirmModal/PhoneConfirmModal';
import { toast } from 'react-toastify';

const GoogleOtp = (props) => {
  const params = queryString.parse(props.location.search);
  const phoneModal = useSelector((state) => state?.logIn?.addPhoneModal);
  const verifyphoneModal = useSelector((state) => state?.logIn?.phoneModal);
  const user = useSelector((state) => state?.logIn?.user);
  const dispatch = useDispatch();
  const code = params.code;
  const apiData = {
    oauth_code: code,
  };
  useEffect(async () => {
    let success = await dispatch(googleSignIn(apiData))
    let [type, user] = success
    if (success == "invalid") {
      toast.error("Invalid User Name or Password")
      props.history.push('/')
    } else if (success == "fail") {
      props.history.push('/')

    } else {

      if (type == "patient" && user?.phone_number == "") {
        dispatch(addPhoneModalToggle(true))
      } else if (type == "patient" && user?.phone_number_is_verified) {
        props.history.push('/patient-dashboard')
      } else if (type == "patient" && !user?.phone_number_is_verified) {
        dispatch(sendPhoneOtp({ id: user?.id, phone_number: user?.phone_number }));
        dispatch(phoneModalToggle(true))
      } else {

      }
    }
  }, []);
  return (
    <>
      {
        !user ? <div className='text-center'><Loader /></div> : <div></div>
      }

      <AddPhoneNumber
        show={phoneModal}
        onHide={() => dispatch(addPhoneModalToggle(false))}
        {...props}
      />
      <PhoneConfirmModal
        show={verifyphoneModal}
        onHide={() => dispatch(phoneModalToggle(false))}
        {...props}
      />
    </>
  )
}

export default GoogleOtp
import React from "react";
import "./socialmediacard.css";
import img from "../../../../Statics/Images/post.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Socialmediacards2 = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation("common");
  return (
    <div className="col-xl-4 col-lg-6 col-md-12 mt-30">
      <div
        className="newsbg"
        style={{
          background: `url(${
            data?.image ? data?.image : img
          }) top center no-repeat #000`,
        }}
      >
        <div className="maintitle_social">
          {" "}
          <h5 style={{ textTransform: "none", marginBottom: "4px" }}>
            <a
              // href="news-details.php"
              className="ntitles2s"
              style={{ paddingBottom: "12px", color: "#fff" }}
            >
              {data?.heading ? data?.heading : t("news.socialmediacard1.head")}
            </a>
          </h5>
          <div className="news-meta">
            <ul>
              <li style={{ color: "#f9f9f9" }}>
                <i className="far fa-user" />{" "}
                {data?.company ? data?.company : "DMFR"}
              </li>
              <li style={{ color: "#f9f9f9" }}>
                <i className="far fa-calendar-alt" />{" "}
                {data?.date ? data?.date : ""}
              </li>
            </ul>
          </div>
          <div
            className="descriptn_news"
            style={{ textAlign: "left", height: "auto" }}
          >
            {data?.text ? data?.text : t("news.socialmediacard1.description")}
          </div>
          <span className="newsbtn">
            <strong className="newsrtsm_snp">
              <span
                 onClick={() => {
                  props.history.push("/social-media-post#social_media", data);
                }}
                style={{ marginTop: "10px" ,textDecoration:'none',cursor:'pointer'}}
              >
                {data?.button
                  ? data?.button
                  : t("news.socialmediacard1.btnreadmore")}
              </span>
            </strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Socialmediacards2;

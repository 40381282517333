import React from "react";
import bannerLab from "../../Statics/Images/internal-banner/lab.jpg";
import CovidTeamCard from "../LayOuts/Cards/covidTeamCard/CovidTeamCard";
import BannerImg from "../ReUseableComponents/BannerImg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import LabConsultantImg1 from "../../Statics/Images/lap-consul/1.JPG";
import LabConsultantImg2 from "../../Statics/Images/lap-consul/2.JPG";
import LabConsultantImg3 from "../../Statics/Images/lap-consul/3.JPG";
import LabConsultantImg4 from "../../Statics/Images/lap-consul/4.JPG";
import LabConsultantImg5 from "../../Statics/Images/lap-consul/5.JPG";
import LabConsultantImg6 from "../../Statics/Images/lap-consul/6.JPG";
import LabConsultantImg7 from "../../Statics/Images/lap-consul/7.JPG";
// import LabConsultantImg8 from "../../Statics/Images/lap-consul/8.JPG";
import LabConsultantImg9 from "../../Statics/Images/lap-consul/9.JPG";
import LabConsultantImg10 from "../../Statics/Images/lap-consul/10.JPG";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
// import LabConsultantImg11 from "../../Statics/Images/lap-consul/11.JPG";

const LabConsultant = () => {

  const { t, i18n } = useTranslation("common")
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language)
  const labTeam = filterComponentData(componetcms, 'labteam', lang)
  const labTeamHeader = filterComponentData(componetcms, 'team', lang)
  const lab_consultant_header = filterComponentData(componetcms, 'lab_consultant_header', lang)
  const banner = filterComponentData(componetcms, "banners", lang);

  
  const LabConsultantsData = [
    {
      imgSrc: LabConsultantImg1,
      label: t("labconsultants.labconsultantdata.lab1.name"),
      details: t("labconsultants.labconsultantdata.lab1.details"),
      defaultname: 'drshaheda',
    },

    {
      imgSrc: LabConsultantImg2,
      label: t("labconsultants.labconsultantdata.lab2.name"),
      details: t("labconsultants.labconsultantdata.lab2.details"),
      defaultname: 'drfarzan',
    },

    {
      imgSrc: LabConsultantImg3,
      label: t("labconsultants.labconsultantdata.lab3.name"),
      details: t("labconsultants.labconsultantdata.lab3.details"),
      defaultname: 'drtamina',
    },

    {
      imgSrc: LabConsultantImg4,
      label: t("labconsultants.labconsultantdata.lab4.name"),
      details: t("labconsultants.labconsultantdata.lab4.details"),
      defaultname: 'drsharminjah',
    },

    {
      imgSrc: LabConsultantImg5,
      label: t("labconsultants.labconsultantdata.lab5.name"),
      details: t("labconsultants.labconsultantdata.lab5.details"),
      defaultname: 'drfarah',
    },

    {
      imgSrc: LabConsultantImg6,
      label: t("labconsultants.labconsultantdata.lab6.name"),
      details: t("labconsultants.labconsultantdata.lab6.details"),
      defaultname: 'drrumana',
    },

    {
      imgSrc: LabConsultantImg7,
      label: t("labconsultants.labconsultantdata.lab7.name"),
      details: t("labconsultants.labconsultantdata.lab7.details"),
      defaultname: 'drsaika',
    },

    // {
    //   imgSrc: LabConsultantImg8,
    //   label: "DR. SHARMIN CHOWDHURY",
    //   details: "JUNIOR CONSULTANT,MOLECULAR",
    //   defaultname:'drsharmin',
    // },
    {
      imgSrc: LabConsultantImg9,
      label: t("labconsultants.labconsultantdata.lab8.name"),
      details: t("labconsultants.labconsultantdata.lab8.details"),
      defaultname: 'drmizanur',
    },


    {

      imgSrc: LabConsultantImg10,
      label: t("labconsultants.labconsultantdata.lab9.name"),
      details: t("labconsultants.labconsultantdata.lab9.details"),
      defaultname: 'drsonia',
    },
    // {
    //   imgSrc: LabConsultantImg11,
    //   label: "DR.ARIFA AKRAM",
    //   details: "SONOLOGIST",
    //   defaultname:'drarifa',
    // },




  ];
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[7]?.sublink1 ?banner[7]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[7]?.sublink2 ?banner[7]?.sublink2:"Our Team",
      subDash: ">",
      subLink: "/lab-consultants",
    },
    {
      subLinkName: banner[7]?.sublink3 ?banner[7]?.sublink3:"Lab Consultant",
      subDash: ">",
      subLink: "/lab-consultants",
    },
  ]
  return (
    <>
      <Helmet>
        <title>
          {t("labteam.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[7]?.image ? banner[7]?.image : bannerLab})`}
        heading={banner[7]?.heading ? banner[7]?.heading : "Lab Consultant"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="mt-5">
        <BlueHeading heading={lab_consultant_header?.heading ? lab_consultant_header?.heading : t("labteam.mainheading1")} />
      </div>
      <div className="container">
        <div className="row mt-5">

          {
            labTeam?.length < 1 ?
              LabConsultantsData?.map((item, index) => (
                <div className="col-lg-3 col-sm-6 center-childrens" key={index}>
                  <CovidTeamCard
                    btnlinks='/lab-consultants-inner-detail'
                    defaultname={item?.defaultname}
                    imgSrc={item?.imgSrc}
                    imgAlt={item?.imgAlt}
                    label={item?.label}
                    details={item?.details}
                  />
                </div>
              )) : labTeam?.map((item, index) => (
                <div className="col-lg-3 col-sm-6 center-childrens" key={index}>
                  <CovidTeamCard
                    btnlinks='/lab-consultants-inner-detail'
                    defaultname={item?.name}
                    imgSrc={item?.image}
                    imgAlt={item?.name}
                    label={item?.name}
                    details={item?.designation}
                    button={item?.button}
                  />
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default LabConsultant;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import AppointmentDoctor from "./AppointmentDoctor";
import DoctorProfile from "./DoctorProfile";
import MyBoard from "./MyBoard";
import MyPatient from "./MyPatient";
const DoctorDashboard = (props) => {

  const {t,i18n} = useTranslation("common")

  const [selectedTab, setSelectedTab] = useState(t("dashboards.doctordashboard.btntabs.profile"));
  // const userRole = useSelector((state) => state?.logIn?.userRole);
  const tabHandler = (item) => {
    setSelectedTab(item);
  };
  const FromView = props?.location?.state?.key;

  
  useEffect(() => {
    // const userRole = localStorage.getItem("uesrRole");

    if (FromView === "FromAddPrescrition") {
      setSelectedTab(t("dashboards.doctordashboard.btntabs.appointment"));
    }
    if (FromView === "AppointmentDetails") {
      setSelectedTab(t("dashboards.doctordashboard.btntabs.appointment"));
    }
    if (FromView === "FromBoardDetails") {
      setSelectedTab(t("dashboards.doctordashboard.btntabs.board"));
    }
    if (FromView === "PatientDetails") {
      setSelectedTab(t("dashboards.doctordashboard.btntabs.patient"));
    }
    // eslint-disable-next-line
  }, [FromView]);
  return (
    <>
      <Helmet>
        <title>
        {t("dashboards.doctordashboard.maintitle")}
        </title>
      </Helmet>
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading title={t("dashboards.doctordashboard.mainheading1")} />

      <div className="container">
        {[t("dashboards.doctordashboard.btntabs.profile"), t("dashboards.doctordashboard.btntabs.appointment"), t("dashboards.doctordashboard.btntabs.patient"), t("dashboards.doctordashboard.btntabs.board")].map(
          (item, index) => (
            <div
              className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
              key={index + 1}
              onClick={() => tabHandler(item)}
            >
              <DashboardBtnList
                label={item}
                labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                className={
                  selectedTab === item
                    ? "dashboardBtnList-item-active py-2"
                    : "default-color-and-hover py-2"
                }
              />
            </div>
          )
        )}

        {selectedTab === t("dashboards.doctordashboard.btntabs.profile") ? (
          <>
            <div className="pb-5">
              <DoctorProfile />
            </div>
          </>
        ) : selectedTab === t("dashboards.doctordashboard.btntabs.appointment") ? (
          <>
            <AppointmentDoctor {...props} />
          </>
        ) : selectedTab === t("dashboards.doctordashboard.btntabs.patient") ? (
          <>
            <MyPatient {...props} />
          </>
        ) : selectedTab === t("dashboards.doctordashboard.btntabs.board") ? (
          <>
            <MyBoard {...props} />
          </>
        ) : null}
      </div>
    </div>
    </>
  );
};

export default DoctorDashboard;

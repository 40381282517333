import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  emailModalToggle,
  phoneModalToggle,
  resendemailOtp,
  verifyEmail,
} from "../../../../Store/Actions/loginActions";
const EmailConfirmModal = (props) => {
  const {t} = useTranslation("common")
  const user = useSelector((state) => state?.logIn?.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      id: user?.id,
      otp: data.otpMobile,
    };
    let res = await dispatch(verifyEmail(apiData));
    if (res) {
      dispatch(emailModalToggle(false));
      if (!user?.phone_number_is_verified) {
        dispatch(phoneModalToggle(true));
      }
    } else {

    }

  };
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.emailverfication")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3 mb-2">
            <p style={{ fontSize: "13px" }}>
              {t("dashboards.alldashboardkeys.tablekeys.pleaseprovideotpforemail")}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-12">
                  <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.otp")}
                    </span>
                  <input
                    className="input-login-modal w-100"
                    type="number"
                    {...register("otpMobile", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.otpMobile?.message ? (
                    <div className="text-error">
                      {errors?.otpMobile?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.verify")}
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
          <Link
            className="signIn-link"
            to={""}
            style={{ fontWeight: "500", fontSize: "14px" }}
            onClick={() => {
              dispatch(resendemailOtp({ id: user?.id }));
            }}
          >
            Resend Email verification OTP?
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailConfirmModal;

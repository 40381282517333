import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import AddMedicinesPrescriptionModal from "../../Components/LayOuts/modals/addMedicinesPrescription/AddMedicinesPrescriptionModal";
import DoctorBoardModal from "../../Components/LayOuts/modals/addMedicinesPrescription/doctorBoardModal/DoctorBoardModal";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import {
  addprescription,
  createDoctorBoardAction,
  getAllMedicine,
  getAllTests,
} from "../../Store/Actions/docotorActions";
import { patientAppointments } from "../../Store/Actions/patientActions";
import { videoCallToken } from "../../Store/Actions/staticActions";
import ProfileTable from "../patientDashboard/ProfileTable";
import cross from "../../Statics/Images/cross.png";
import Loader from "react-loader-spinner";
import ChatIframe from "../../services/twillioChat/ChatIframe";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const DoctorAddPrescription = (props) => {
  const { t, i18n } = useTranslation("common");
  const [showDiagnosis, setShowDiagnosis] = useState(false);
  const [showMedi, setShowMedi] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [doctorModal, setdoctorModal] = useState(false);
  const [doctorList, setdoctorList] = useState([]);
  const [diagnosis, setDiagnosis] = useState("");
  const [selectedMedi, setSelectedMedi] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const allMedicines = useSelector((state) => state?.doctor?.allMedicines);
  const allTests = useSelector((state) => state?.doctor?.allTests);
  const user = useSelector((state) => state?.logIn?.user);
  const [BoardCreated, setBoardCreated] = useState(false);
  const dispatch = useDispatch();
  const [loadingVideo, setloadingVideo] = useState(false);
  const [showchat, setshowchat] = useState(false);
  const uniqueDoctor = doctorList.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      doctorList.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  // const [loadingChat, setloadingChat] = useState(false);
  const { from } = props?.location?.state;
  const viewData = props?.location?.state?.row;
  const item = { key: "FromAddPrescrition" };

  useEffect(() => {
    if (allMedicines < 1) {
      dispatch(getAllMedicine());
    }
    if (allTests.length < 1) {
      dispatch(getAllTests());
    }
  }, [dispatch, allTests, allMedicines]);
  const modalHandler = (value) => {
    setShowMedi(value);
  };
  const doctorAddHandler = (item) => {
    setdoctorList([...doctorList, ...item]);
  };
  const callHandler = async () => {
    setloadingVideo(true);
    const res = await dispatch(videoCallToken(false, { id: viewData?.id }));
    if (res === "true") {
      setloadingVideo(false);
      const win = window.open(`/call/${viewData?.id}`, "_blank");
      win.focus();
    } else {
      setloadingVideo(false);
    }
  };

  const chatHandler = async () => {
    // setloadingChat(true);
    // const res = await dispatch(joinChatToken(false, { id: viewData?.id }));
    // if (res === "success") {
    //   setloadingChat(false);
    //   localStorage.setItem("ChatUser", viewData?.patient_details?.name);
    // const win = window.open(
    //   `/chat/${viewData?.id}/${user?.id}/${viewData?.doctor_details?.name}/false`,
    //   "_blank"
    // );
    // win.focus();
    // } else {
    //   setloadingChat(false);
    // }
    setshowchat(true);
  };

  const clickHandlerPrescription = async () => {
    if ((selectedMedi?.length < 1 || selectedTest?.length < 1) && !diagnosis) {
      toast.error("Add Medicine Test And Diagnosis To Finish Appointment");
      return;
    }
    const apiData = {
      appointment_id: viewData?.id,
      tests: selectedTest.map((item) => item?.id),
      medicines: selectedMedi,
      diagnosis: diagnosis,
    };
    const response = await dispatch(addprescription(apiData));
    if (response === "success") {
      const apiData = {
        id: user?.id,
      };
      dispatch(patientAppointments(apiData));
      props.history.push("/doctor-dashboard", item);
    }
  };
  const createDoctorBoard = async () => {
    const apiData = {
      appointment_id: viewData?.id,
      doctor_ids: doctorList?.map((item) => item?.id),
    };
    const res = await dispatch(createDoctorBoardAction(apiData));
    if (res === "success") {
      setBoardCreated(true);
    }
  };
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t(
          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.mainbanheading2"
        )}
      />
      {showchat ? (
        <>
        <div className="container">
          <div className="row ml-5">
            <div className="col-xl-8 col-md-8 col-12 ">
              <div
                className="btn edit-patient-btn"
                style={{ width: "150px" }}
                onClick={() => {
                  setshowchat(false);
                }}
              >
                <i className="fas fa-arrow-left"></i>
                {t(
                  "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.backbtn"
                )}
              </div>
            </div>
          </div>
          <ChatIframe
            user_id={user?.id}
            appointment_id={viewData?.id}
            board={false}
            name={user?.name}
          />
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="row center-childrens">
              <div className="col-xl-12 col-md-12 col-12 pb-5">
                <div
                  className="btn edit-patient-btn"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.history.push("/doctor-dashboard", item);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                  {t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.backbtn"
                  )}
                </div>
              </div>
            </div>
            <div className="row center-childrens  pb-5">
              <div className="col-xl-12 col-md-12 col-12 containerWithShadow p-4">
                <h5>
                  {t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.mainheading1"
                  )}
                </h5>
                <div className="row ">
                  <div className="col-6 ">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.datentime"
                          )}
                        </span>
                        <p>
                          {viewData?.date} @ {viewData?.slot.time?.from} -{" "}
                          {viewData?.slot.time?.to}{" "}
                        </p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.patientname"
                          )}
                        </span>
                        <p>{viewData?.patient_details?.name}</p>
                      </li>{" "}
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.patientemail"
                          )}
                        </span>
                        <p>{viewData?.patient_details?.email_address}</p>
                      </li>{" "}
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.patientage"
                          )}
                        </span>
                        <p>{viewData?.patient_details?.age}</p>
                      </li>
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          Condition
                        </span>
                        <p>{viewData?.condition?.name}</p>
                      </li>{" "}
                      <li>
                        <span
                          className="light-Color-Para"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          {t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.description"
                          )}
                        </span>
                        <p>{viewData?.description}</p>
                      </li>
                    </ul>
                  </div>

                  <div className="col-6 "></div>
                </div>

                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {from === "today" && viewData?.status?.name == "confirmed" ? (
                    <div
                      className="btn btn-success"
                      style={{ float: "right" }}
                      onClick={callHandler}
                    >
                      {loadingVideo ? (
                        <Loader
                          height={20}
                          width={20}
                          type="ThreeDots"
                          color="#fff"
                        />
                      ) : (
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.call"
                        )
                      )}
                    </div>
                  ) : null}

                  {from === "today" &&
                  (viewData?.status?.name == "confirmed" ||
                    viewData?.status?.name == "completed") ? (
                    <div
                      className="btn btn-success"
                      style={{ float: "right" }}
                      onClick={chatHandler}
                    >
                      {/* {loadingChat ? (
                    <Loader
                      height={20}
                      width={20}
                      type="ThreeDots"
                      color="#fff"
                    />
                  ) : (
                    "Chat"
                  )} */}
                      {t(
                        "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.chat"
                      )}
                    </div>
                  ) : null}
                </div>
                <br />
                <br />
                <h5>
                  {t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnose1"
                  )}
                </h5>

                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowDiagnosis(true)}
                  className="btn btn-outline-success"
                >
                  {diagnosis.length > 0
                    ? `+ ${t(
                        "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.editdiagnose"
                      )}`
                    : `+ ${t(
                        "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.adddiagnose"
                      )}`}
                </span>
                <br />
                {showDiagnosis ? (
                  <>
                    <div className="row">
                      <div className="col-lg-6 col-md-8 col-12">
                        <textarea
                          className="w-100 p-3"
                          value={diagnosis}
                          style={{ height: "90px" }}
                          onChange={(e) => setDiagnosis(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-6">
                        <input
                          type="submit"
                          className="btn btn-success w-100"
                          value={t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addbtn"
                          )}
                          onClick={() => setShowDiagnosis(false)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="containerWithShadow w-100 p-3 mt-2">
                    <span>{diagnosis}</span>
                  </div>
                )}
                <h5 className="mt-3">
                  {t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.mainbanheading22"
                  )}
                </h5>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => modalHandler(true)}
                  className="btn btn-outline-success mb-2"
                >
                  {`+ ${t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addmedicine"
                  )}`}
                </span>
                <br />
                {selectedMedi.length > 0 ? (
                  <>
                    {" "}
                    <ProfileTable
                      tableHead={[
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.medname"
                        ),
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.morningdose"
                        ),
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.afternoondose"
                        ),
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.nightdose"
                        ),
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosemedicinestableheader.durdose"
                        ),
                        t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.diagnosetesttableheader.action"
                        ),
                      ]}
                      data={selectedMedi.map((item) => {
                        return [
                          item?.name,
                          item?.dose[0],
                          item?.dose[1],
                          item?.dose[2],
                          item?.duration,
                          <>
                            {" "}
                            <div className="row">
                              <div className="col">
                                <DashboardBtnList
                                  label={t(
                                    "dashboards.doctordashboard.appointment.todayappointment.tablecolheader.remove"
                                  )}
                                  className="order-pay-btn"
                                  onClick={() => {
                                    setSelectedMedi(
                                      selectedMedi?.filter(
                                        (_item) => _item !== item
                                      )
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>,
                        ];
                      })}
                    />
                  </>
                ) : null}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTest(true)}
                  className="btn btn-outline-success"
                >
                  {`+ ${t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addtest"
                  )}`}
                </span>

                <br />
                {showTest ? (
                  <>
                    <div className="col-lg-12">
                      <span className="label-name-login">
                        {t(
                          "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.selectedtest"
                        )}
                      </span>
                      <Form.Group>
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple
                          onChange={(selected) => {
                            setSelectedTest(selected);
                          }}
                          options={allTests}
                          placeholder=""
                          selected={selectedTest}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-6">
                        <input
                          type="submit"
                          className="btn btn-success w-100"
                          value={t(
                            "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.addbtn"
                          )}
                          onClick={() => setShowTest(false)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="containerWithShadow w-100 p-3 mt-2">
                    <ol className="listStyleOrder">
                      {selectedTest?.map((item, index) => (
                        <li className="my-2 ml-3" key={index + 1}>
                          {item?.name}
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
                <h5 className="my-3">
                  {t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.doctorboard"
                  )}
                </h5>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setdoctorModal(true)}
                  className="btn btn-outline-success"
                >
                  {`+ ${t(
                    "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.adddocboard"
                  )}`}
                </span>
                {uniqueDoctor.length > 0 ? (
                  <div className="containerWithShadow w-100 p-3 mt-2">
                    <ol className="listStyleOrder">
                      {" "}
                      {uniqueDoctor?.map((item, index) => (
                        <li className="my-2 ml-3">
                          {item?.name}{" "}
                          <span
                            className="ml-2 py-0"
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              const test = [...uniqueDoctor];
                              test.splice(index, 1);
                              await setdoctorList(test);
                            }}
                          >
                            {" "}
                            <img
                              src={cross}
                              alt="cross"
                              style={{ height: "20px", width: "20px" }}
                            />
                          </span>
                        </li>
                      ))}{" "}
                    </ol>
                  </div>
                ) : null}
                {doctorList.length > 0 && !BoardCreated ? (
                  <span
                    className="btn btn-primary mt-2"
                    onClick={() => createDoctorBoard()}
                  >
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.createboard"
                    )}
                  </span>
                ) : null}
                <AddMedicinesPrescriptionModal
                  show={showMedi}
                  onHide={() => modalHandler(!showMedi)}
                  selectedMedi={selectedMedi}
                  setSelectedMedi={setSelectedMedi}
                  {...props}
                />
                <DoctorBoardModal
                  show={doctorModal}
                  onHide={() => setdoctorModal(!doctorModal)}
                  doctorList={doctorList}
                  setdoctorList={doctorAddHandler}
                  {...props}
                />
                {doctorList?.name}
                <div className="col-xl-12 col-md-12 col-12  mt-3 d-flex justify-content-end">
                  <div
                    className="btn edit-patient-btn"
                    onClick={clickHandlerPrescription}
                  >
                    {t(
                      "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.finishappointment"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DoctorAddPrescription;

import React, { useEffect, useState } from "react";
import {
  patientAppointments,
  rescheduleModalAction,
} from "../../Store/Actions/patientActions";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import { useDispatch, useSelector } from "react-redux";
import TodayAppointmetPatient from "./TodayAppointmetPatient";
import UpcommingAppointmentPatient from "./UpcommingAppointmentPatient";
import PastAppointmentPatient from "./PastAppointmentPatient";
import AddAppointments from "../../Components/LayOuts/modals/addAppointment/AddAppointments";
import RescheduleModal from "../../Components/LayOuts/modals/rescheduleModal/RescheduleModal";
import { useTranslation } from "react-i18next";
import { updateModalState } from "../../Store/Actions/staticActions";

const AppointmentProfile = (props) => {
  const { t, i18n } = useTranslation("common");
  const [selectedTab, setSelectedTab] = useState("");
  const [tableData, setTableData] = useState([]);
  const [addAppointment, setAddAppointment] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const appointment = useSelector((state) => state?.patient?.appointments);
  const requestAppointment = useSelector(
    (state) => state.static.requestAppointment
  );

  useEffect(() => {
    if (!user) {
      props?.history?.push("/");
    }
    if (props?.FromView === "fixedRight" && requestAppointment) {
      setAddAppointment(true);
    }
    const apiData = {
      id: user?.id,
    };
    setSelectedTab(t("dashboards.alldashboardkeys.tablekeys.todayappointment"));

    dispatch(patientAppointments(apiData));

    // eslint-disable-next-line
  }, [requestAppointment]);
  const tabHandler = (item) => {
    setSelectedTab(item);
    if (item === t("dashboards.alldashboardkeys.tablekeys.todayappointment")) {
      const finalTableDate = appointment?.today?.map((item) => {
        item = { ...item, from: "TodayAppointment" };
        return [
          item?.date,
          item?.doctor_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/view-appointment", item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    } else if (
      item === t("dashboards.alldashboardkeys.tablekeys.upcomingappointment")
    ) {
      const finalTableDate = appointment?.upcoming?.map((item) => {
        return [
          item?.date,
          item?.doctor_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col pr-0">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/view-appointment", item);
                  }}
                />
              </div>
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className="appointment-reschdule-btn"
                  onClick={() => {
                    dispatch(rescheduleModalAction({ open: true, data: item }));
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });

      setTableData(finalTableDate || []);
    } else if (
      item === t("dashboards.alldashboardkeys.tablekeys.pastappointments")
    ) {
      const finalTableDate = appointment?.past?.map((item) => {
        return [
          item?.date,
          item?.doctor_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col ">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/view-appointment", item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    }
  };
  return (
    <>
      <div>
        {[
          t("dashboards.alldashboardkeys.tablekeys.todayappointment"),
          t("dashboards.alldashboardkeys.tablekeys.upcomingappointment"),
          t("dashboards.alldashboardkeys.tablekeys.pastappointments"),
        ].map((item, index) => (
          <div
            className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
            key={index + 1}
            onClick={() => tabHandler(item)}
          >
            <DashboardBtnList
              label={item}
              labelStyle={selectedTab === item ? { color: "#fff" } : ""}
              className={
                selectedTab === item
                  ? "dashboardBtnList-item-active py-2"
                  : "default-color-and-hover py-2"
              }
            />
          </div>
        ))}
        <div className=" mt-3  d-flex justify-content-end">
          <div
            className="btn btn-danger"
            onClick={() => setAddAppointment(true)}
          >
            Add Appointment
          </div>
        </div>
        {selectedTab ===
        t("dashboards.alldashboardkeys.tablekeys.todayappointment") ? (
          <TodayAppointmetPatient tabledata={tableData} {...props} />
        ) : selectedTab ===
          t("dashboards.alldashboardkeys.tablekeys.upcomingappointment") ? (
          <UpcommingAppointmentPatient tabledata={tableData} {...props} />
        ) : selectedTab ===
          t("dashboards.alldashboardkeys.tablekeys.pastappointments") ? (
          <PastAppointmentPatient tabledata={tableData} {...props} />
        ) : (
          ""
        )}
        <AddAppointments
          show={addAppointment}
          onHide={() => {
            dispatch(updateModalState(false));
            setAddAppointment(!addAppointment);
          }}
          {...props}
        />
      </div>
    </>
  );
};

export default AppointmentProfile;

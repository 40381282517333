import React, { useEffect } from "react";
import HomeCard from "../LayOuts/Cards/homeCard/HomeCard";
import bsl from "../../Statics/icons/bsl.png";
import doctorImg from "../../Statics/icons/doctor.png";
import TestImg from "../../Statics/icons/Reliable-Test.png";
import consultantImg from "../../Statics/icons/doctor-icon.png";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";
import truckImage from "../../Statics/Images/truck.png";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import videoposter from "../../Statics/Images/video-poster.jpg";
import Carousal from "../LayOuts/Carousel/Carousal";
import Services from "../LayOuts/services/Services";
import Pakages from "../LayOuts/pakages/Pakages";
import MobileServiceCard from "../ReUseableComponents/MobileServiceCard";
import ClientSlider from "../LayOuts/clientSlider/ClientSlider";
import Testemonials from "../LayOuts/testimonials/Testemonials";
import ConsultantImg from "../../Statics/Images/why-choose-us.png";
import { CosultantCard } from "../LayOuts/Cards/consultantCard/CosultantCard";
import { Link } from "react-router-dom";
import HomeAddModal from "../LayOuts/modals/homeAddModal/HomeAddModal";
import { useState } from "react";
import Loader from "../LayOuts/loader/Loader";
import HomeVIdeoModal from "../LayOuts/modals/homeVIdeoModal/HomeVIdeoModal";
import HomePageLocationModal from "../LayOuts/modals/homePageLocationModal/HomePageLocationModal";
import MobileLabService from "../LayOuts/modals/mobileLabService/MobileLabService";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { filterComponentData } from "../../Utils/functions";

//import All images from Client folder from static/images/clients
// Note to add any image in client slider add image in client folder and be cautious for image size
function importAll(data) {
  let images = {};
  data.keys().map((item, index) => {
    images[item.replace("./", "")] = data(item);
    return "";
  });
  return images;
}

const images = importAll(
  require.context("../../Statics/Images/clients", false, /\.(png|jpe?g|svg)$/)
);

const Home = (props) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const [homePopUp, setHomePopUp] = useState(false);
  const [showVideo, setshowVideo] = useState(false);
  const [showlocation, setshowlocation] = useState(false);
  
  const [locationUrl, setLocationUrl] = useState(null);

  const [showService, setshowService] = useState(false);
  const [sliderarray, setSliderarray] = [];
  const componetcms = useSelector((state) => state.cms.components);

  useEffect(() => {
    setHomePopUp(true);
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const card = filterComponentData(componetcms, "card_componenet", lang);

  const testamonial = filterComponentData(
    componetcms,
    "testimonial_component",
    lang
  );
  const client = filterComponentData(componetcms, "clientcomponent", lang);
  const slider = filterComponentData(componetcms, "slider_component", lang);
  const about = filterComponentData(componetcms, "about_us_home", lang);
  const all_package_button = filterComponentData(
    componetcms,
    "all_package_button",
    lang
  );
  const ourservices = filterComponentData(
    componetcms,
    "service_component",
    lang
  );
  const consultant = filterComponentData(
    componetcms,
    "consultant_component",
    lang
  );
  const test = filterComponentData(componetcms, "test_validation_com", lang);
  const ourconsultants = filterComponentData(
    componetcms,
    "consultants_doctors",
    lang
  );
  const service_heading_and_description = filterComponentData(
    componetcms,
    "service_heading_and_description",
    lang
  );
  const ourdoctorscard = filterComponentData(
    componetcms,
    "consultants_doctors_cards",
    lang
  );
  const testimonial_header = filterComponentData(
    componetcms,
    "testimonial_header",
    lang
  );
  const client_header = filterComponentData(componetcms, "client_header", lang);
  const mobile_lab_service_header = filterComponentData(
    componetcms,
    "mobile_lab_service_header",
    lang
  );
  const package_header = filterComponentData(
    componetcms,
    "package_header",
    lang
  );
  const mobile_lab_service = filterComponentData(
    componetcms,
    "mobile_lab_service",
    lang
  );
  const mobile_lab_service_image = filterComponentData(
    componetcms,
    "mobile_lab_service_image",
    lang
  )
  // let card = filterComponentData(componetcms,"test_componenet");
  const CardHomeData = [
    {
      img: bsl,
      heading: t("home.mainServiceCard.headingsv1"),
      discription: t("home.mainServiceCard.descriptionsv1"),
    },
    {
      img: TestImg,
      heading: t("home.mainServiceCard.headingsv2"),
      discription: t("home.mainServiceCard.descriptionsv2"),
    },
    {
      img: doctorImg,
      heading: t("home.mainServiceCard.headingsv3"),
      discription: t("home.mainServiceCard.descriptionsv3"),
    },
    {
      img: consultantImg,
      heading: t("home.mainServiceCard.headingsv4"),
      discription: t("home.mainServiceCard.descriptionsv4"),
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("home.maintitle")}</title>
      </Helmet>

      {/* slider Start */}
      <Carousal data={slider} />
      {/* slider End */}

      {/* card Home Sction start */}
      <div className="card-section-home ">
        <div className="container">
          <div className="row">
            <Loader />
            {card?.length < 1
              ? CardHomeData.map((item, index) => (
                  <HomeCard
                    key={index + 1}
                    imgSrc={item.img}
                    imgAlt={item.imgAlt}
                    cardHeading={item?.heading}
                    description={item?.discription}
                  />
                ))
              : card?.map((item, index) => (
                  <HomeCard
                    key={index + 1}
                    imgSrc={item.image}
                    cardHeading={item?.heading}
                    description={item?.text}
                  />
                ))}
          </div>
        </div>
      </div>
      {/* card Home Sction End */}

      {/* Text And Image with Video Button Start */}
      <TextAndImageSection
        imgSrc={videoposter}
        imgAlt={"Reliable And Rapid Diagnostics"}
        imgHtml={
          <>
            <div
              className="video-btn"
              onClick={() => {
                setshowVideo(true);
              }}
            >
              <span>
                {about[0]?.vh
                  ? about[0]?.vh
                  : t("home.mainRevVideoText.video-head")}
              </span>
              <span>
                <i className="fas fa-play"></i>
              </span>
            </div>
          </>
        }
        subHeading={
          about[0]?.head ? about[0]?.head : t("home.mainRevVideoText.head-sub")
        }
        headingFirst={
          about[0]?.hf ? about[0]?.hf : t("home.mainRevVideoText.head-first")
        }
        headingSecond={
          about[0]?.hsec
            ? about[0]?.hsec
            : t("home.mainRevVideoText.head-second")
        }
        discription={
          <>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                marginTop: "20px",
              }}
              className="light-Color-Para"
            >
              {about[0]?.des1
                ? about[0]?.des1
                : t("home.mainRevVideoText.des-p1")}
            </p>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
              }}
              className="light-Color-Para"
            >
              {about[0]?.des2
                ? about[0]?.des2
                : t("home.mainRevVideoText.des-p2")}
            </p>
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
              }}
              className="light-Color-Para"
            >
              {about[0]?.des3
                ? about[0]?.des3
                : t("home.mainRevVideoText.des-p3")}
            </p>
            <Link to="/about-us" className="about-para">
              {about[0]?.linkname
                ? about[0]?.linkname
                : t("home.mainRevVideoText.link-name")}
              <i
                className="fas fa-arrow-right"
                style={{ fontSize: "12px" }}
              ></i>
            </Link>
          </>
        }
        reverse={false}
      />

      {/* heading component Start */}
      <HeadingWithBottomBar
        heading={
          service_heading_and_description?.heading
            ? service_heading_and_description?.heading
            : t("home.mainheading1")
        }
        discription={
          service_heading_and_description?.description
            ? service_heading_and_description?.description
            : t("home.mainheadingsub")
        }
      />
      {/* heading component Start */}

      {/* Services Section Start */}
      <Services data={ourservices} />
      {/* Services Section End */}

      {/* Pakages Sections start */}
      <HeadingWithBottomBar
        subHeading={
          package_header?.heading
            ? package_header?.heading
            : t("home.mainheadingsub2")
        }
        heading={
          package_header?.description
            ? package_header?.description
            : t("home.mainheading2")
        }
      />
      <Pakages {...props} all_package_button={all_package_button}/>
      {/* Pakages Sections end */}

      {/* Consultant Section Start */}
      <div className="mt-5">
        <TextAndImageSection
          imgSrc={
            ourconsultants[0]?.image ? ourconsultants[0]?.image : ConsultantImg
          }
          imgAlt={"Our Dedicated Consultants & Doctors"}
          imgStyle={{
            // width: "600px",
            height: "340px",
            marginTop: "100px",
          }}
          // subHeading={"About Us"}
          headingFirst={
            ourconsultants[0]?.head
              ? ourconsultants[0]?.head
              : t("home.mainconsultants.mainhead")
          }
          // headingSecond="RAPID DIAGNOSTICS"
          reverse={true}
          discription={
            <>
              <div className="contianer mt-5">
                <CosultantCard data={ourdoctorscard} />
              </div>
            </>
          }
        />
      </div>
      {/* Consultant Section End */}

      {/* MOBILE LAB SERVICES section Start */}
      <HeadingWithBottomBar
        subHeading={
          mobile_lab_service_header?.description
            ? mobile_lab_service_header?.description
            : t("home.mainheadingsub3")
        }
        heading={
          mobile_lab_service_header?.heading
            ? mobile_lab_service_header?.heading
            : t("home.mainheading3")
        }
      />
      {mobile_lab_service?.length < 1 ? (
        <div className="test">
          <TextAndImageSection
            imgSrc={mobile_lab_service_image?.image ? mobile_lab_service_image?.image: truckImage}
            imgAlt={"Truck"}
            imgStyle={{
              marginTop: "100px",
            }}
            reverse={true}
            discription={
              <>
                <div className="container">
                  <MobileServiceCard
                    onClick={() => {
                      setshowlocation(true);
                    }}
                    onCLickEquire={() => {
                      setshowService(true);
                    }}
                  />
                </div>
                <div className="container mt-3">
                  <MobileServiceCard
                    onClick={() => {
                      setshowlocation(true);
                    }}
                    onCLickEquire={() => {
                      setshowService(true);
                    }}
                  />
                </div>
                <div className="container mt-3">
                  <MobileServiceCard
                    onClick={() => {
                      setshowlocation(true);
                    }}
                    onCLickEquire={() => {
                      setshowService(true);
                    }}
                  />
                </div>
              </>
            }
          />
        </div>
      ) : (
        <div className="test">
          <TextAndImageSection
            imgSrc={mobile_lab_service_image?.image ? mobile_lab_service_image?.image:truckImage}
            imgAlt={"Truck"}
            imgStyle={{
              marginTop: "100px",
            }}
            reverse={true}
            discription={mobile_lab_service?.map((item) => (
              <>
                <div className="container mb-3">
                  <MobileServiceCard
                    onClick={() => {
                      setshowlocation(true);
                      setLocationUrl(item?.locationUrl)
                    }}
                    onCLickEquire={() => {
                      setshowService(true);
                    }}
                    item={item}
                  />
                </div>
              </>
            ))}
          />
        </div>
      )}
      {/* MOBILE LAB SERVICES section End */}

      {/* Clients Coprates Clients Start */}
      <HeadingWithBottomBar
        subHeading={
          client_header?.description
            ? client_header?.description
            : t("home.mainheadingsub4")
        }
        heading={
          client_header?.heading
            ? client_header?.heading
            : t("home.mainheading4")
        }
      />
      <ClientSlider data={images} client={client} />
      {/* Clients Coprates Clients End */}

      {/* Testemonial Section Start */}
      <div style={{ marginTop: "100px" }}>
        <HeadingWithBottomBar
          subHeading={
            testimonial_header?.description
              ? testimonial_header?.description
              : t("home.mainheadingsub5")
          }
          heading={
            testimonial_header?.heading
              ? testimonial_header?.heading
              : t("home.mainheading5")
          }
        />
      </div>
      <Testemonials data={images} testamonial={testamonial} />
      {/* Testemonial Section End */}

      <HomeAddModal show={homePopUp} onHide={() => setHomePopUp(false)} />
      <HomeVIdeoModal
        data={about}
        show={showVideo}
        onHide={() => setshowVideo(false)}
      />
      <HomePageLocationModal
        show={showlocation}
        onHide={() => setshowlocation(false)}
        location={locationUrl}
      />

      <MobileLabService
        show={showService}
        onHide={() => setshowService(false)}
      />
    </>
  );
};

export default Home;

import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../Components/LayOuts/modals/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { rescheduleModalAction } from "../../Store/Actions/patientActions";
import { comaSeperated, emptyString } from "../../Utils/functions";

import { cancelAppointment, patientAppointments } from "../../Store/Actions/patientActions";

import { toast } from "react-toastify";

import RefundModal from "../../Components/LayOuts/modals/refundAmountModal/RefundModal";

import AlertRefund from "../../Components/LayOuts/modals/alertrefund/AlertRefund";
import ActionBtn from "../../Components/ReUseableComponents/ActionBtn";
import RescheduleModal from "../../Components/LayOuts/modals/rescheduleModal/RescheduleModal";

const TodayAppointmetPatient = (props) => {


  const [show, setShow] = useState(false)

  const [showModal, setShowModal] = useState(false)


  const [id, setID] = useState("")
  const user = useSelector((state) => state?.logIn?.user);
  const date = new Date()
  const time = date.getTime()
  const new_date = moment(new Date()).format('YYYY-MM-DD HH:mm');

  const { t, i18n } = useTranslation("common")
  const dispatch = useDispatch()
  const loader = useSelector((state) => state?.logIn?.loader);
  const appointment = useSelector((state) => state?.patient?.appointments);
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });

  const handleChange = async (value, row) => {

    if (value == t("dashboards.alldashboardkeys.tablekeys.viewbtn")) {

      props?.history?.push("/view-appointment", { row, from: "TodayAppointment" });

    }

    else if (value == "Cancel") {

      let res = await dispatch(cancelAppointment({ appointment_id: row?.id, timestamp: new_date }))

      dispatch(patientAppointments({ id: user?.id }));

      if (res) {

        toast.success("Appoitment is Cancelled")

      }

    } else if (value == "Cancel/Refund") {

      let [hour, minutes] = row?.slot?.from?.split(":")


      if ((hour - date.getHours()) < 6) {

        setShowModal(!showModal)

      } else {

        setID(row?.id)

        setShow(!show)

      }

    }

    else if(value == "Reschedule"){
      setShow(true)
      setrescheduleModal({ show: true, data: row })
    }else{

    }
  };
  const bookAnAppointment = () => {

    props.history.push('/book-appointment')

  }




  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.todayappointment")}</h4>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              {
                formatter: (cell, row, index) => {
                  return <span>{index + 1}</span>;
                },
                text: "Sr.",
              },
              {
                formatter: (cell, row) => {
                  return <span>{moment(row.date).format("DD/MM/YYYY")}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.date"),
              },
              { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
              // {
              //   formatter: (cell, row, index) => {
              //     return (
              //       <span>
              //         {emptyString(row.description) ? "N/A" : row.description}
              //       </span>
              //     );
              //   },
              //   text: t("dashboards.alldashboardkeys.tablekeys.description"),
              // },
              { dataField: "condition.name", text: "Condition" },
              {

                formatter: (cell, row) => {

                  return <span>{`${row?.is_refunded ? comaSeperated(row?.refunded_amount) : '0'}`}</span>;

                },

                text: "Refund Amount",

              },
              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#1a4774' }}>{row?.status?.name?.toUpperCase()}</span>;
                },
                text: "Status",
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    row?.status?.name == "booked" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Reschedule", "Cancel"]}
                      handleChange={handleChange}
                      data={row}
                    /> : row?.status?.name == "confirmed" && row?.consultation_type?.name == "online" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Cancel","Reschedule"]}
                      handleChange={handleChange}
                      data={row}
                    /> : row?.status?.name == "confirmed" && row?.consultation_type?.name == "walk-in" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"), "Cancel","Reschedule"]}
                      handleChange={handleChange}
                      data={row}
                    /> : row?.status?.name == "completed" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                      handleChange={handleChange}
                      data={row}
                    /> :row?.status?.name == "booked" ? <ActionBtn
                      options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn"),"Reschedule"]}
                      handleChange={handleChange}
                      data={row}
                    />: <ActionBtn
                    options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                    handleChange={handleChange}
                    data={row}
                  />

                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={appointment?.today?.length > 0 ? appointment?.today : []}
          />
        )}
      </div>
      <RescheduleModal
        show={show}
        data={rescheduleModal?.data}
        onHide={() => setShow(!show)}
        {...props}
      />
      {/* <RefundModal
        show={show}
        id={id}
        onHide={() => setShow(false)}
      /> */}
      <AlertRefund
        show={showModal}
        onHide={() => setShowModal(false)}

      />
    </div>
  );
};

export default TodayAppointmetPatient;

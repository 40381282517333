import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileTable from "../../Dashboards/patientDashboard/ProfileTable";
import { patientBookOrder } from "../../Store/Actions/patientActions";
import { addToCart, removeFromCart } from "../../Store/Actions/staticActions";
import LoginModal from "../LayOuts/modals/loginModal/LoginModal";
import DashBoardMainHading from "../ReUseableComponents/DashBoardMainHading";

const Cart = (props) => {
  const { t } = useTranslation("common");
  const [cartData, setcartData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.logIn?.user);
  const userRole = useSelector((state) => state?.logIn?.userRole);
  const [loginModal, setLoginModal] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart"));
    setcartData(cartData);
  }, []);


  const totalPrice = () => {
    let price = 0;
    // eslint-disable-next-line

    cartData?.map((item) => {
      price = price + item?.price;
    });
    return price;
  };
  const GrandtotalPrice = () => {
    let price = 0;
    // eslint-disable-next-line

    cartData?.map((item) => {
      price = price + item?.price;
    });
    let vat = (15 / 100) * price;
    return price + vat;
  };
  const paymentHandler = async () => {
    if (checkbox) {
      if (!user) {
        setLoginModal(true);
      } else if (userRole === "patient") {
        const apiData = {
          id: user?.id,
          delivery_type: { id: 2, name: "delivery" },
          packages: cartData?.map((item) => {
            return { package: item?.id, quantity: 1 };
          }),
        };
        const response = await dispatch(patientBookOrder(apiData, true));
        // window.open(response);
        // window.focus();
        dispatch(addToCart(0));

        let item = { ...response, from: "patientOrders" };

        props?.history?.push("/Order-details", item);
      } else {
        toast.error(t("cart.pleaseloginaspatient"));
      }
    } else {
      toast.error(t("Please first read and accept the Terms and Policies"));
    }
  };
  return (
    <>
      <Helmet>
        <title>{t("cart.maintitle")}</title>
      </Helmet>

      <div style={{ backgroundColor: "#f9f9f9" }} className="pb-5">
        <DashBoardMainHading title={t("cart.cart")} />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ProfileTable
                tableHead={[
                  "Sr.",
                  t("cart.ordertype"),
                  t("cart.productname"),
                  t("cart.quantity"),
                  t("cart.price"),
                  t("cart.action"),
                ]}
                data={cartData?.map((item, index) => {
                  return [
                    index + 1,
                    "Package",
                    item?.name,
                    1,
                    item?.price,
                    <span
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      className="ml-4"
                      onClick={() => {
                        const test = [...cartData];
                        test.splice(index, 1);
                        localStorage.setItem("cart", JSON.stringify(test));
                        setcartData(test);
                        dispatch(removeFromCart(test?.length));
                      }}
                    >
                      x
                    </span>,
                  ];
                })}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-md-4">
              <h5 className="gray-headin">{t("cart.cattstotal")}</h5>
              <table
                cellpadding="0"
                cellspacing="0"
                width="100%"
                style={{ border: "1px solid lightgray" }}
              >
                <tbody className="cartTable">
                  <tr>
                    <td width="45%">
                      <strong className="patient-appointment-table-heading">
                        {t("cart.subtotal")}
                      </strong>
                    </td>
                    <td>{totalPrice()}</td>
                  </tr>
                  <tr>
                    <td width="45%">
                      <strong className="patient-appointment-table-heading">
                        {t("cart.vat")}
                      </strong>
                    </td>
                    <td>15%</td>
                  </tr>
                  <tr style={{ background: "#f9f9f9" }}>
                    <td width="45%">
                      <strong className="patient-appointment-table-heading">
                        {t("cart.grandtotal")}
                      </strong>
                    </td>
                    <td>{GrandtotalPrice()}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="mt-2 "
                  onChange={() => setCheckbox(!checkbox)}
                />
                &nbsp;
                <span
                  className="text-justify"
                  style={{ fontSize: "14px", fontWeight: "500px" }}
                >
                  I have Read and Agree to the Website's &nbsp;
                  <Link className="coll" target="_blank" to="/terms-conditions">
                    Terms & Conditions
                  </Link>
                  , &nbsp;{" "}
                  <Link className="coll" target="_blank" to="/privacy-policy">
                    Privacy Confidentiality
                  </Link>
                  ,&nbsp; and&nbsp;
                  <Link
                    className="coll"
                    target="_blank"
                    to="/privacy-refund-policy"
                  >
                    Payment & Refund Policy
                  </Link>
                </span>
              </div>
              <button
                className="btn btn-block btn-success py-3 mt-3"
                style={{ width: "100%" }}
                disabled={!checkbox}
                onClick={paymentHandler}
              >
                {t("cart.proceedtocheckout")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        show={loginModal}
        onHide={() => setLoginModal(!loginModal)}
        {...props}
      />
    </>
  );
};

export default Cart;

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  assignDeliveryStaff,
  getAllDeliveryStaff,
  pharmacyOrderDispatch,
} from "../../../../Store/Actions/pharmacyActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
const AssignRiderModal = (props) => {
  const { t } = useTranslation("common")
  const deliveryStaff = useSelector((state) => state?.pharmacy?.deliveryStaff);
  const [singleSelections, setsingleSelections] = useState("");
  const [selectedTime, setselectedTime] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDeliveryStaff());
  }, [dispatch]);
  const deliverassignHandler = async () => {
    if (singleSelections.length > 0) {
      const apiData = {
        order_id: props?.viewData?.id,
        user_id: singleSelections[0].id,
        assigned_time: moment(selectedTime).unix(),
      };

      let res = await dispatch(assignDeliveryStaff(apiData));
      if (res) {
        dispatch(
          pharmacyOrderDispatch({
            order_id: props?.viewData?.id,
            delivery_status: { id: 3, name: "dispatched" },
          })
        );
        props.onHide();
        const item = { key: "fromOrderDetails" };
        
        props.history.push("/pharmacy", item);
      }

    } else {
      toast.error(t("dashboards.alldashboardkeys.tablekeys.pleaseselectriderfirst"));
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.assignorder")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("dashboards.alldashboardkeys.tablekeys.pleaseselectdeliverystaff")}</p>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={setsingleSelections}
                  options={deliveryStaff}
                  placeholder=""
                  selected={singleSelections}
                />
              </Form.Group>
            </div>
            <div className="col-12">
              <p>{t("dashboards.alldashboardkeys.tablekeys.pleaseselectdeliverytime")}</p>
              <input
                type="datetime-local"
                className="w-100"
                onChange={(e) => {
                  setselectedTime(e.target.value);
                }}
                value={selectedTime}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div
              className="btn btn-danger mt-3 mr-3 btn-block"
              onClick={() => {
                props?.onHide();
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.cancel")}
            </div>
            <div
              className="btn btn-success mt-3 btn-block"
              onClick={() => {
                deliverassignHandler();
              }}
            >
              {t("dashboards.alldashboardkeys.tablekeys.dispatch")}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignRiderModal;

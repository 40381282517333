import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { signInConstansts } from "../Constants/loginConstant";
import { pharmacyConstants } from "../Constants/pharmacyConstants";
import i18next from "i18next";
export const getAllMedicines = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getAllMedicines();
    if (response?.data?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.GET_ALL_MEDICINES,
        payload: response?.data?.response_data?.medicine,
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const addMedicine = (data) => async (dispatch) => {
  try {
    const response = await apiServices.addMedicine({ medicines: [data] });
    if (response?.data?.response_code === 200) {
      dispatch(getAllMedicines());
      toast.success(i18next.t("common:toastmessages.medicineaddedsuccess"));
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const deleteMedicine = (data) => async (dispatch) => {
  try {
    const response = await apiServices.updateMedicine(data);
    if (response?.response_code === 200) {
      dispatch(getAllMedicines());
      toast.info(i18next.t("common:toastmessages.medicinedeletedsuccess"));
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const updateMedicine = (data) => async (dispatch) => {
  try {
    const response = await apiServices.updateMedicine(data);
    if (response?.response_code === 200) {
      dispatch(getAllMedicines());
      toast.info(i18next.t("common:toastmessages.medicineupdatedsuccess"));
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const getAllOrders = (data) => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getAllOrders(data);

    if (response?.data?.response_code === 200) {
      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS,
        payload: response?.data?.response_data?.order,
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const pharmacyPay = (data, fromLabAdmin, id) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.pharmacyPay(data);
    if (response?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      if (!fromLabAdmin) {
        dispatch(getAllOrders());
      }
      toast.info(response?.response_message);
      return true;
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast.error(response?.response_message, "Error");
      return false;
    }

    // if (response?.data?.response_code === 200) {
    //   dispatch({
    //     type: pharmacyConstants.GET_ALL_ORDERS,
    //     payload: response?.data?.response_data?.order,
    //   });
    //   dispatch({
    //     type: signInConstansts.SET_LOADER,
    //     payload: false,
    //   });
    // } else {
    //   toast.error(response?.response_message);
    // }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const pharmacyOrderDispatch = (data) => async (dispatch) => {
  // dispatch({
  //   type: signInConstansts.SET_LOADER,
  //   payload: true,
  // });
  try {
    let response = await apiServices.pharmacyOrderDispatch(data);
    dispatch(getAllOrders());
    if (response?.response_code === 200) {
      toast.info(response?.response_message);

      dispatch({
        type: pharmacyConstants.GET_ALL_ORDERS,
        payload: response?.response_data?.order,
      });

    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const SelectedMedicinde = (data) => {
  return {
    type: pharmacyConstants.SET_SELECTED_MEDI,
    payload: data,
  };
};
export const getAllDeliveryStaff = (data) => async (dispatch) => {
  const response = await apiServices.getAllDeliveryStaff();

  dispatch({
    type: pharmacyConstants.GET_ALL_DELIVERYSTAAF,
    payload: response?.data?.response_data?.delivery,
  });
};
export const assignDeliveryStaff = (data) => async (dispatch) => {
  const response = await apiServices.assignDeliveryStaff(data);
  if (response?.response_code == 200) {
    toast.success("Successfully Assigned");
    return true;
  } else {
    toast.error(response?.response_message);
    return false;
  }
};

export const cancelOrder = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,

      payload: true,
    });

    const response = await apiServices.cancellOrder(data);

    if (response?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,

        payload: false,
      });

      toast.success(response?.response_message);

      return true;
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,

        payload: false,
      });

      toast.error(response?.response_message, "Error");

      return false;
    }
  } catch (error) { }
};

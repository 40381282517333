import React, { useEffect } from "react";
import collectionathome from "../../Statics/Images/banner/collectionathome.jpg";
import collectionathome1 from "../../Statics/Images/collectionathome1.png";
import collectionathome2 from "../../Statics/Images/collectionathome2.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import CircleImg from "../ReUseableComponents/CircleImg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const Collectionathome = () => {
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language)
  const collectionCom = filterComponentData(componetcms, 'collectionathome', lang)
  const banner = filterComponentData(componetcms, "banners", lang);

  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[14]?.sublink1 ? banner[14]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[14]?.sublink2 ? banner[14]?.sublink2 : "Services",
      subDash: ">",
      subLink: "/collection-home",
    },
    {
      subLinkName: banner[14]?.sublink3
        ? banner[14]?.sublink3
        : "Collection At Home",
      subDash: ">",
      subLink: "/collection-home",
    },
  ];
  const { t, i18n } = useTranslation("common");
  return (
    <>
      <Helmet>
        <title>
          {t("collectionathome.maintitle")}</title>
      </Helmet>

      {/* img banner */}
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[14]?.image ? banner[14]?.image : collectionathome
          })`}
          heading={
            banner[14]?.heading ? banner[14]?.heading : "Colection At Home"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
      <div className="">
        <TextAndImageSection
          imgSrc={collectionCom?.image ? collectionCom?.image : collectionathome1}
          imgAlt={"Collection At Home"}
          imgStyle={{ marginTop: "40px" }}
          headingFirst={collectionCom?.title ? collectionCom?.title : t("collectionathome.textrev.headfirst")}
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
                className="light-Color-Para"
              >
                {collectionCom?.text ? collectionCom?.text : t("collectionathome.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
      <div className="mt-5">
        <CircleImg imgcircle={collectionCom?.lastimage ? collectionCom?.lastimage : collectionathome2} imgAlt="collectionathome2" />
      </div>
    </>
  );
};

export default Collectionathome;

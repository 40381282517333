import React from 'react'

const CircleImg = (props) => {
    return (
        <>
         <div className="container text-center my-4">
			<h1 className="mb-2"  style={{fontSize:'24px'}}> {props.heading}  </h1>
			<img className="my-3" src={props.imgcircle} alt={props?.heading ? props?.heading : props?.imgAlt} style={{borderRadius:'50%' ,marginTop:'20px'}} />
			<p className="px-4">{props.discription}</p>
		</div>   
        </>
    )
}

export default CircleImg

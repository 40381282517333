import React from 'react'
import { Helmet } from 'react-helmet'

const Cytopathology = () => {
    return (
        <>
        <Helmet>
        <title>
        Cyto Pathology - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>

            Cytopathology
        </>
    )
}

export default Cytopathology

import React from "react";
import { Helmet } from "react-helmet";

const XRay = () => {
  return <>
   <Helmet>
        <title>
        XRay - DMFR Molecular Lab and Diagnostics
        </title>
      </Helmet>
  XRay</>;
};

export default XRay;

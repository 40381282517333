import React, { useEffect, useState } from "react";

import { Form, Modal } from "react-bootstrap";

import { Typeahead } from "react-bootstrap-typeahead";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import { cancelAppointmentAdmin, cancelOrderAdmin, cancelPartialOrderRequest } from "../../../../Store/Actions/administratorActions";

import { getAllOrders } from "../../../../Store/Actions/pharmacyActions";

export default function RefundPartialPackageOrder(props) {

    const { t } = useTranslation("common")

    const dispatch = useDispatch()

    const user = useSelector((state) => state?.logIn?.user);


    const {

        register,

        handleSubmit,

        formState: { errors },

    } = useForm();

    const onSubmit = async (data) => {

        if (Number(data?.amount) < 1) {
            toast.error("Please Enter Valid Amount")
            return;
        }
        else {

            let apiData = {
                refunded_amount: Number(data?.amount),
                id: props?.id,
                status: { id: 2, name: 'approved' }
            }
            let res = await dispatch(cancelPartialOrderRequest(apiData))
            if (res) {
                toast.success("Successfully Cancelled")
                props.onHide()
            }
        }
    };

    return (

        <>

            <Modal

                {...props}

                size="md"

                aria-labelledby="contained-modal-title-vcenter"

                centered

            >

                <Modal.Header className="dashboardBtnList-item-active">

                    <Modal.Title id="contained-modal-title-vcenter">

                        <span className="text-white">{"Refund"}</span>

                    </Modal.Title>

                    <button type="button" class="close" onClick={props?.onHide}>

                        <span aria-hidden="true" className="text-white">

                            ×
                        </span>

                        <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>

                    </button>

                </Modal.Header>

                <Modal.Body>

                    <div className="row px-3">

                        <form onSubmit={handleSubmit(onSubmit)} className="w-100">

                            <div

                                className="row"

                                style={{ display: "flex", justifyContent: "center" }}

                            >

                                <div className="col-lg-12">

                                    <span className="label-name-login">{"Enter Amount"}</span>

                                    <Form.Group>

                                        <input

                                            className="input-login-modal"

                                            type="number"

                                            {...register("amount", {

                                                required: {

                                                    value: true,

                                                    message: "this field is required field",

                                                },

                                            })}

                                        />

                                        {/* {mediError ? (

                                    <div className="text-error">{mediError}</div>

                                ) : (

                                    ""

                                )} */}

                                    </Form.Group>

                                </div>




                                <input

                                    type="submit"

                                    value={"Refund"}

                                    className="headerBtn-red btn-block mx-auto"

                                    style={{ width: "85%", textAlign: "center" }}

                                />

                            </div>

                        </form>

                    </div>

                </Modal.Body>

            </Modal >

        </>

    )

}
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { getAllOrders } from "../../Store/Actions/pharmacyActions";
import { checkPaidOrUnpaid, comaSeperated } from "../../Utils/functions";

const PharmacyOrders = (props) => {
  const { t } = useTranslation("common")

  const loader = useSelector((state) => state?.logIn?.loader);
  const orders = useSelector((state) => state?.pharmacy?.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-9 col-md-8 col-xs-12">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.orders")}</h4>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "ordered_by.name", text: t("dashboards.alldashboardkeys.tablekeys.orderby") },
              { dataField: "date", text: t("dashboards.alldashboardkeys.tablekeys.date") },

              { dataField: "ordered_by.phone_number", text: t("dashboards.alldashboardkeys.tablekeys.contactnumber") },
              { dataField: "delivery_status.name", text: t("dashboards.alldashboardkeys.tablekeys.deliverystatus") },

              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.refunded_amount)}</span>;
                },
                text: "Refund Amount",
              },
              {
                formatter: (cell, row, index) => {
                  return <span>{comaSeperated(row?.total_amount)}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
              },
              {
                formatter: (cell, row, index) => {
                  return (
                    <span
                      style={{
                        color: checkPaidOrUnpaid(row?.paid_status.name),
                      }}
                    >
                      {row?.paid_status.name?.toUpperCase()}
                    </span>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
              },

              {
                formatter: (cell, row) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-lg-12">
                          <DashboardBtnList
                            label={t("dashboards.alldashboardkeys.tablekeys.viewbtn")}
                            className="order-pay-btn"
                            onClick={() => {
                              props?.history?.push(
                                "/pharmacy-Order-details",
                                row
                              );
                            }}
                          />
                        </div>

                      </div>
                    </>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={orders}
          />
        )}
      </div>
    </>
  );
};

export default PharmacyOrders;

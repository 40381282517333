export const staticConstants = {
  FETCH_STATIC_DATA: "  FETCH_STATIC_DATA",
  SET_STATIC_DATA: "SET_STATIC_DATA",
  GET_TEST_CATEGORY: "GET_TEST_CATEGORY",
  GET_TEST_DATA: "GET_TEST_DATA",
  GET_PACKAGES: "GET_PACKAGES",
  NOTIFICATION: "NOTIFICATION",
  SELECTED_APPOINTMENT: "SELECTED_APPOINTMENT",
  ALL_NOTIFICATIONS: "ALL_NOTIFICATIONS",
  NEW_NOTIFICATIONS: "NEW_NOTIFICATIONS",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  CART: "CART",
  FETCH_PUBLIC_DOCTOR: "FETCH_PUBLIC_DOCTOR",
  LOADING: "LOADING",
  APPOINTMENT_MODAL: "APPOINTMENT_MODAL",
};

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";

const TermsAndCondition = () => {
  const componetcms = useSelector((state) => state.cms.components);
// console.log(Quill.version);
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const terms_condition = filterComponentData(
    componetcms,
    "terms_and_condition",
    lang
  );

  return (
    <>
      <Helmet>
        <title>Terms And Condition - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <div className="container" id="set">
        <div className="row">
          {terms_condition.length < 1 ? (
            <div className="col-12 text-justify">
              <p>
                <h1 className="blue-heading text-center">
                  Terms and Conditions
                </h1>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              1. INTRODUCTION
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            Welcome to mdxdmfr.com also hereby known as “we",
                            "us" or "DMFR". We are an online web portal and
                            these are the terms and conditions governing your
                            access and use of DMFR along with its related sites,
                            mobile app, services and tools (the "Site"). By
                            using the Site, you hereby accept these terms and
                            conditions (including the linked information herein)
                            and represent that you agree to comply with these
                            terms and conditions (the "User Agreement"). This
                            User Agreement is deemed effective upon your use of
                            the Site which signifies your acceptance of these
                            terms. If you do not agree to be bound by this User
                            Agreement please do not access, register with or use
                            this Site. This Site is owned and operated by&nbsp;
                            <strong>
                              DMFR Molecular Lab and Diagnostics BD Ltd, a
                              company incorporated under the Companies Act,
                              1994, (Registration No. C-141234 / TL: TRAD/DSCC/233530/2019; BIN: 002738916-0402)
                            </strong>
                            <br />
                            The Site reserves the right to change, modify, add,
                            or remove portions of these Terms and Conditions at
                            any time without any prior notification. Changes
                            will be effective when posted on the Site with no
                            other notice provided. Please check these Terms and
                            Conditions regularly for updates. Your continued use
                            of the Site following the posting of changes to
                            Terms and Conditions of use constitutes your
                            acceptance of those changes.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              2. CONDITIONS OF USE
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              A. YOUR ACCOUNT
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            To access certain services offered by the platform,
                            we may require that you create an account with us or
                            provide personal information to complete the
                            creation of an account. We may at any time in our
                            sole and absolute discretion, invalidate the
                            username and/or password without giving any reason
                            or prior notice and shall not be liable or
                            responsible for any losses suffered by, caused by,
                            arising out of, in connection with or by reason of
                            such request or invalidation.
                            <br />
                            You are responsible for maintaining the
                            confidentiality of your user identification,
                            password, account details and related private
                            information. You agree to accept this responsibility
                            and ensure your account and its related details are
                            maintained securely at all times and all necessary
                            steps are taken to prevent misuse of your account.
                            You should inform us immediately if you have any
                            reason to believe that your password has become
                            known to anyone else, or if the password is being,
                            or is likely to be, used in an unauthorized manner.
                            You agree and acknowledge that any use of the Site
                            and related services offered and/or any access to
                            private information, data or communications using
                            your account and password shall be deemed to be
                            either performed by you or authorized by you as the
                            case may be. You agree to be bound by any access of
                            the Site and/or use of any services offered by the
                            Site (whether such access or use are authorized by
                            you or not). You agree that we shall be entitled
                            (but not obliged) to act upon, rely on or hold you
                            solely responsible and liable in respect thereof as
                            if the same were carried out or transmitted by you.
                            You further agree and acknowledge that you shall be
                            bound by and agree to fully indemnify us against any
                            and all losses arising from the use of or access to
                            the Site through your account.
                            <br />
                            Please ensure that the details you provide us with
                            are correct and complete at all times. You are
                            obligated to update details about your account in
                            real time by accessing your account online. For
                            pieces of information you are not able to update by
                            accessing Your Account on the Site, you must inform
                            us via our customer service communication channels
                            to assist you with these changes. We reserve the
                            right to refuse access to the Site, terminate
                            accounts, remove or edit content at any time without
                            prior notice to you. We may at any time in our sole
                            and absolute discretion, request that you update
                            your Personal Data or forthwith invalidate the
                            account or related details without giving any reason
                            or prior notice and shall not be liable or
                            responsible for any losses suffered by or caused by
                            you or arising out of or in connection with or by
                            reason of such request or invalidation. You hereby
                            agree to change your password from time to time and
                            to keep your account secure and also shall be
                            responsible for the confidentiality of your account
                            and liable for any disclosure or use (whether such
                            use is authorised or not) of the username and/or
                            password.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              B. PRIVACY
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            Please review our Privacy Agreement, which also
                            governs your visit to the Site. The personal
                            information / data provided to us by you or your use
                            of the Site will be treated as strictly
                            confidential, in accordance with the Privacy
                            Agreement and applicable laws and regulations. If
                            you object to your information being transferred or
                            used in the manner specified in the Privacy
                            Agreement, please do not use the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              C. PLATFORM FOR COMMUNICATION
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            You agree, understand and acknowledge that the Site
                            is an online platform that enables you to purchase
                            medicine and services listed at the price indicated
                            therein at any time from any location using a
                            payment method of your choice. You further agree and
                            acknowledge that we are only a facilitator and
                            cannot be a party to or control in any manner any
                            transactions on the Site or on a payment gateway as
                            made available to you by an independent service
                            provider. Accordingly, the contract of sale of
                            products &amp; services on the Site shall be a
                            strictly bipartite contract between you and the
                            sellers on our Site while the payment processing
                            occurs between you, the service provider and in case
                            of prepayments with electronic cards your issuer
                            bank. Accordingly, the contract of payment on the
                            Site shall be strictly a bipartite contract between
                            you and the service provider as listed on our Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              D. CONTINUED AVAILABILITY OF THE SITE
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We will do our utmost to ensure that access to the
                            Site is consistently available and is uninterrupted
                            and error-free. However, due to the nature of the
                            Internet and the nature of the Site, this cannot be
                            guaranteed. Additionally, your access to the Site
                            may also be occasionally suspended or restricted to
                            allow for repairs, maintenance, or the introduction
                            of new facilities or services at any time without
                            prior notice. We will attempt to limit the frequency
                            and duration of any such suspension or restriction.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <br />
                &nbsp;
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              E. LICENSE TO ACCESS THE SITE
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We require that by accessing the Site, you confirm
                            that you can form legally binding contracts and
                            therefore you confirm that you are at least 18 years
                            of age or are accessing the Site under the
                            supervision of a parent or legal guardian. We grant
                            you a non-transferable, revocable and non-exclusive
                            license to use the Site, in accordance with the
                            Terms and Conditions described herein. Commercial
                            use or use on behalf of any third party is
                            prohibited, except as explicitly permitted by us in
                            advance. If you are registering as a business
                            entity, you represent that you have the authority to
                            bind that entity to this User Agreement and that you
                            and the business entity will comply with all
                            applicable laws relating to online trading. No
                            person or business entity may register as a member
                            of the Site more than once. Any breach of these
                            Terms and Conditions shall result in the immediate
                            revocation of the license granted in this paragraph
                            without notice to you.
                            <br />
                            Content provided on this Site is solely for
                            informational purposes.{" "}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We grant you a limited license to access and make
                            personal use of this Site, but not to download
                            (excluding page caches) or modify the Site or any
                            portion of it in any manner. This license does not
                            include any resale or commercial use of this Site or
                            its contents; any collection and use of any product
                            listings, descriptions, or prices; any derivative
                            use of this Site or its contents; any downloading or
                            copying of account information for the benefit of
                            another seller; or any use of data mining, robots,
                            or similar data gathering and extraction tools.
                            <br />
                            This Site or any portion of it (including but not
                            limited to any copyrighted material, trademarks, or
                            other proprietary information) may not be
                            reproduced, duplicated, copied, sold, resold,
                            visited, distributed or otherwise exploited for any
                            commercial purpose without express written consent
                            by us as may be applicable.
                            <br />
                            You may not frame or use framing techniques to
                            enclose any trademark, logo, or other proprietary
                            information (including images, text, page layout, or
                            form) without our express written consent. You may
                            not use any meta tags or any other text utilizing
                            our name or trademark without our express written
                            consent, as applicable. Any unauthorized use
                            terminates the permission or license granted by us
                            to you for access to the Site with no prior notice.
                            You may not use our logo or other proprietary
                            graphic or trademark as part of an external link for
                            commercial or other purposes without our express
                            written consent, as may be applicable.
                            <br />
                            You agree and undertake not to perform restricted
                            activities listed within this section; undertaking
                            these activities will result in an immediate
                            cancellation of your account, services, reviews,
                            orders or any existing incomplete transaction with
                            us and in severe cases may also result in legal
                            action:
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <ul id="someselector">
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Refusal to comply with the Terms and Conditions
                              described herein or any other guidelines and
                              policies related to the use of the Site as
                              available on the Site at all times.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Impersonate any person or entity or to falsely
                              state or otherwise misrepresent your affiliation
                              with any person or entity.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Use the Site for illegal purposes.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Attempt to gain unauthorized access to or
                              otherwise interfere or disrupt other computer
                              systems or networks connected to the Platform or
                              Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Interfere with another’s utilization and enjoyment
                              of the Site;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Post, promote or transmit through the Site any
                              prohibited materials as deemed illegal by The
                              People's Republic of Bangladesh.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ backgroundColor: "white" }}>
                      <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontSize: "10.5pt" }}>
                          <span
                            style={{ fontFamily: '"Helvetica",sans-serif' }}
                          >
                            <span style={{ color: "#333333" }}>
                              Use or upload, in any way, any software or
                              material that contains, or which you have reason
                              to suspect that contains, viruses, damaging
                              components, malicious code or harmful components
                              which may impair or corrupt the Site’s data or
                              damage or interfere with the operation of another
                              Customer’s computer or mobile device or the Site
                              and use the Site other than in conformance with
                              the acceptable use policies of any connected
                              computer networks, any applicable Internet
                              standards and any other applicable laws.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              F. YOUR CONDUCT
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: "Symbol" }}>
                          <span style={{ color: "#333333" }}>·</span>
                        </span>
                      </span>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            &nbsp;for fraudulent purposes, or in connection with
                            a criminal offense or other unlawful activity
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: "Symbol" }}>
                          <span style={{ color: "#333333" }}>·</span>
                        </span>
                      </span>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            &nbsp;to send, use or reuse any material that does
                            not belong to you; or is illegal, offensive
                            (including but not limited to material that is
                            sexually explicit content or which promotes racism,
                            bigotry, hatred or physical harm), deceptive,
                            misleading, abusive, indecent, harassing,
                            blasphemous, defamatory, libellous, obscene,
                            pornographic, paedophilic or menacing; ethnically
                            objectionable, disparaging or in breach of
                            copyright, trademark, confidentiality, privacy or
                            any other proprietary information or right; or is
                            otherwise injurious to third parties; or relates to
                            or promotes money laundering or gambling; or is
                            harmful to minors in any way; or impersonates
                            another person; or threatens the unity, integrity,
                            security or sovereignty of Bangladesh or friendly
                            relations with foreign States; or objectionable or
                            otherwise unlawful in any manner whatsoever; or
                            which consists of or contains software viruses,
                            political campaigning, commercial solicitation,
                            chain letters, mass mailings or any "spam”
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>

              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: "Symbol" }}>
                          <span style={{ color: "#333333" }}>·</span>
                        </span>
                      </span>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            &nbsp; to cause annoyance, inconvenience or needless
                            anxiety
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: "Symbol" }}>
                          <span style={{ color: "#333333" }}>·</span>
                        </span>
                      </span>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            &nbsp; for any other purposes that is other than
                            what is intended by us
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              G. YOUR SUBMISSION
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            Anything that you submit to the Site and/or provide
                            to us, including but not limited to, questions,
                            reviews, comments, and suggestions (collectively,
                            "Submissions") will become our sole and exclusive
                            property and shall not be returned to you. In
                            addition to the rights applicable to any Submission,
                            when you post comments or reviews to the Site, you
                            also grant us the right to use the name that you
                            submit, in connection with such review, comment, or
                            other content. You shall not use a false e-mail
                            address, pretend to be someone other than yourself
                            or otherwise mislead us or third parties as to the
                            origin of any Submissions. We may, but shall not be
                            obligated to, remove or edit any Submissions without
                            any notice or legal course applicable to us in this
                            regard.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              H. CLAIMS AGAINST OBJECTIONABLE CONTENT
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            If you believe that any content on the Site is
                            illegal, offensive (including but not limited to
                            material that is sexually explicit content or which
                            promotes racism, bigotry, hatred or physical harm),
                            deceptive, misleading, abusive, indecent, harassing,
                            blasphemous, defamatory, libellous, obscene,
                            pornographic, paedophilic or menacing; ethnically
                            objectionable, disparaging; or is otherwise
                            injurious to third parties; or relates to or
                            promotes money laundering or gambling; or is harmful
                            to minors in any way; or impersonates another
                            person; or threatens the unity, integrity, security
                            or sovereignty of Bangladesh or friendly relations
                            with foreign States; or objectionable or otherwise
                            unlawful in any manner whatsoever; or which consists
                            of or contains software viruses, (" objectionable
                            content "), please notify us immediately by
                            following by writing to us on info@mdxdmfr.com. We
                            will make all practical endeavours to investigate
                            and remove valid objectionable content complained
                            about within a reasonable amount of time.
                            <br />
                            Please ensure to provide your name, address, contact
                            information and as many relevant details of the
                            claim including name of objectionable content party,
                            instances of objection, proof of objection amongst
                            other. Please note that providing incomplete details
                            will render your claim invalid and unusable for
                            legal purposes.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              I. CLAIMS AGAINST INFRINGING CONTENT
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We respect the intellectual property of others. If
                            you believe that your intellectual property rights
                            have been used in a way that gives rise to concerns
                            of infringement, please write to us at
                            info@mdxdmfr.com and we will make all reasonable
                            efforts to address your concern within a reasonable
                            amount of time. Please ensure to provide your name,
                            address, contact information and as many relevant
                            details of the claim including name of infringing
                            party, instances of infringement, proof of
                            infringement amongst other. Please note that
                            providing incomplete details will render your claim
                            invalid and unusable for legal purposes. In
                            addition, providing false or misleading information
                            may be considered a legal offense and may be
                            followed by legal proceedings.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              J. TRADEMARKS AND COPYRIGHTS
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            mdxdmfr.com, DMFR logo, and other marks indicated on
                            our Site are trademarks or registered trademarks in
                            the relevant jurisdiction(s). Our graphics, logos,
                            page headers, button icons, scripts and service
                            names are the trademarks .All other trademarks that
                            appear on this Site are the property of their
                            respective owners, who may or may not be affiliated
                            with, connected to, or sponsored by us.
                            <br />
                            All intellectual property rights, whether registered
                            or unregistered, in the Site, information content on
                            the Site and all the website design, including, but
                            not limited to text, graphics, software, photos,
                            video, music, sound, and their selection and
                            arrangement, and all software compilations,
                            underlying source code and software shall remain our
                            property. The entire contents of the Site also are
                            protected by copyright as a collective work under
                            Bangladeshi copyright laws and international
                            conventions. All rights are reserved.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              K. DISCLAIMER
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            You acknowledge and undertake that you are accessing
                            the services on the Site and transacting at your own
                            risk and are using your best and prudent judgment
                            before entering into any transactions through the
                            Site. We shall neither be liable nor responsible for
                            any actions or inactions.
                            <br />
                            We shall neither be liable or responsible for any
                            actions or inactions of any other service provider
                            as listed on our Site which includes but is not
                            limited to payment providers, instalment offerings,
                            nor warranty services amongst others.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              L. COMMUNICATING WITH US
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            When you visit the Site, or send e-mails to us, you
                            are communicating with us electronically. You will
                            be required to provide a valid phone number while
                            placing an order with us. We may communicate with
                            you by e-mail, SMS, phone call or by posting notices
                            on the Site or by any other mode of communication we
                            choose to employ. For contractual purposes, you
                            consent to receive communications (including
                            transactional, promotional and/or commercial
                            messages), from us with respect to your use of the
                            website (and/or placement of your order) and agree
                            to{" "}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              M. AMENDMENTS TO CONDITIONS OR ALTERATIONS OF
                              SERVICE AND RELATED PROMISE
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We reserve the right to make changes to the Site,
                            its policies, these terms and conditions and any
                            other publicly displayed condition or service
                            promise at any time. You will be subject to the
                            policies and terms and conditions in force at the
                            time you used the Site unless any change to those
                            policies or these conditions is required to be made
                            by law or government authority (in which case it
                            will apply to orders previously placed by you). If
                            any of these conditions is deemed invalid, void, or
                            for any reason unenforceable, that condition will be
                            deemed severable and will not affect the validity
                            and enforceability of any remaining condition.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              N. EVENTS BEYOND OUR CONTROL
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            We will not be held responsible for any delay or
                            failure to comply with our obligations under these
                            conditions if the delay or failure arises from any
                            cause which is beyond our reasonable control. This
                            condition does not affect your statutory rights.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              O. WAIVER
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            You acknowledge and recognize that we are a private
                            commercial enterprise and reserve the right to
                            conduct business to achieve our objectives in a
                            manner we deem fit. You also acknowledge that if you
                            breach the conditions stated on our Site and we take
                            no action, we are still entitled to use our rights
                            and remedies in any other situation where you breach
                            these conditions.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              P. TERMINATION
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            In addition to any other legal or equitable
                            remedies, we may, without prior notice to you,
                            immediately terminate the Terms and Conditions or
                            revoke any or all of your rights granted under the
                            Terms and Conditions. Upon any termination of this
                            Agreement, you shall immediately cease all access to
                            and use of the Site and we shall, in addition to any
                            other legal or equitable remedies, immediately
                            revoke all password(s) and account identification
                            issued to you and deny your access to and use of
                            this Site in whole or in part. Any termination of
                            this agreement shall not affect the respective
                            rights and obligations (including without
                            limitation, payment obligations) of the parties
                            arising before the date of termination. You
                            furthermore agree that the Site shall not be liable
                            to you or to any other person as a result of any
                            such suspension or termination. If you are
                            dissatisfied with the Site or with any terms,
                            conditions, rules, policies, guidelines, or
                            practices in operating the Site, your sole and
                            exclusive remedy is to discontinue using the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              Q. GOVERNING LAW AND JURISDICTION
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            These terms and conditions are governed by and
                            construed in accordance with the laws of The
                            People's Republic of Bangladesh. You agree that the
                            courts, tribunals and/or quasi-judicial bodies
                            located in Dhaka, Bangladesh shall have the
                            exclusive jurisdiction on any dispute arising inside
                            Bangladesh under this Agreement.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              R. OUR SOFTWARE
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            Our software includes any software (including any
                            updates or upgrades to the software and any related
                            documentation) that we make available to you from
                            time to time for your use in connection with the
                            Site (the "Software").
                            <br />
                            You may use the software solely for purposes of
                            enabling you to use and enjoy our services as
                            permitted by the Terms and Conditions and any
                            related applicable terms as available on the Site.
                            You may not incorporate any portion of the Software
                            into your own programs or compile any portion of it
                            in combination with your own programs, transfer it
                            for use with another service, or sell, rent, lease,
                            lend, loan, distribute or sub-license the Software
                            or otherwise assign any rights to the Software in
                            whole or in part. You may not use the Software for
                            any illegal purpose. We may cease providing you
                            service and we may terminate your right to use the
                            Software at any time. Your rights to use the
                            Software will automatically terminate without notice
                            from us if you fail to comply with any of the Terms
                            and Conditions listed here or across the Site.
                            Additional third party terms contained within the
                            Site or distributed as such that are specifically
                            identified in related documentation may apply and
                            will govern the use of such software in the event of
                            a conflict with these Terms and Conditions. All
                            software used in any of our services is our property
                            and/or our affiliates or its software suppliers and
                            protected by the laws of Bangladesh including but
                            not limited to any other applicable copyright laws.
                            <br />
                            When you use the Site, you may also be using the
                            services of one or more third parties, such as a
                            wireless carrier or a mobile platform provider. Your
                            use of these third party services may be subject to
                            separate policies, terms of use, and fees of these
                            third parties.
                            <br />
                            You may not, and you will not encourage, assist or
                            authorize any other person to copy, modify, reverse
                            engineer, decompile or disassemble, or otherwise
                            tamper with our software whether in whole or in
                            part, or create any derivative works from or of the
                            Software.
                            <br />
                            In order to keep the Software up-to-date, we may
                            offer automatic or manual updates at any time and
                            without notice to you.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ backgroundColor: "white" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "12.0pt" }}>
                          <span style={{ fontFamily: '"inherit",serif' }}>
                            <span
                              className="blue-heading"
                              style={{ color: "#333333" }}
                            >
                              3. RETURNS
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Arial Narrow",sans-serif' }}>
                      <span style={{ fontSize: "10.5pt" }}>
                        <span style={{ fontFamily: '"Helvetica",sans-serif' }}>
                          <span style={{ color: "#333333" }}>
                            Please review our Returns Policy
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: terms_condition?.description,
              }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;

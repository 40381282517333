import React, { useEffect, useState } from "react";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import BannerImg from "../../Statics/Images/about-breadcrumb-bg.jpg";
import PakagesFemale from "../LayOuts/Cards/pakagesFemale/PakagesFemale";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../Store/Actions/staticActions";
import Loader from "../ReUseableComponents/Loader";
import ViewPackageDetails from "../LayOuts/modals/viewpackageDetails/ViewPackageDetails";
import { Helmet } from "react-helmet";
const AllPackages = (props) => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state?.static?.packages);
  const loader = useSelector((state) => state?.logIn?.loader);
  const [selectedPackage, setSelectedPackage] = useState({
    show: false,
    data: {},
  });
  useEffect(() => {
    dispatch(
      getPackages({
        gender: {
          id: 3,
          name: "male/female",
        },
      })
    );
  }, [dispatch]);
  return (
    <>
       <Helmet>
        <title>All Packages - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <BannerWithText
        imgSrc={BannerImg}
        heading={"All Packages"}
        subHeading={"Home | All Packages"}
      />
      <div className="container">
        {loader ? (
          <Loader />
        ) : (
          <div className="row mb-5">
            {packages.map((item, index) => (
              <div className="col-lg-3 mb-4" key={index}>
                <PakagesFemale
                  head={item?.name}
                  price={`BDT ${item?.price}`}
                  onClick={() => setSelectedPackage({ show: true, data: item })}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <ViewPackageDetails
        show={selectedPackage?.show}
        data={selectedPackage?.data}
        {...props}
        onHide={() =>
          setSelectedPackage({ show: false, data: selectedPackage?.data })
        }
      />
    </>
  );
};
export default AllPackages;

import React from "react";
import { useTranslation } from "react-i18next";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import ProfileTable from "../patientDashboard/ProfileTable";

const DoctorDetails = (props) => {
  const { t } = useTranslation("common")
  const viewData = props.location.state;
  const item = { key: "doctorDetails" };

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.doctordetail")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12 ">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/patient-dashboard", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>{" "}
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3 pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>{t("dashboards.alldashboardkeys.tablekeys.doctordetail")}</h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                </span>
                <p>{viewData?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                </span>
                <p>{viewData?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctorphone")}
                </span>
                <p>{viewData?.phone_number}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctorspeciality")}
                </span>

                {viewData.speciality.map((item) => { return (<><p>{item?.name}</p></>) })}
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctordesignation")}
                </span>
                <p>{viewData?.designation}</p>
              </li>{" "}
            </ul>

            <div className="w-100">
              {viewData?.appointment_details?.length > 0 ? (
                <div>
                  <h5>
                    {t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}

                  </h5>

                  {viewData?.appointment_details?.length > 0 ? (
                    <>
                      <ProfileTable
                        tableHead={[
                          t("dashboards.alldashboardkeys.tablekeys.date")

                          ,
                          t("dashboards.alldashboardkeys.tablekeys.description")

                          ,
                          t("dashboards.alldashboardkeys.tablekeys.status")

                        ]}
                        data={viewData?.appointment_details?.map((item) => {
                          return [item?.date, item?.description, <span style={{ color: item?.status == "booked" ? 'red' : item?.status == "confirmed" ? 'green' : '#1a4774' }}>{item?.status?.toUpperCase()}</span>];
                        })}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetails;

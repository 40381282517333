import { labAdminConstants } from "../Constants/labAdminConstants";
import { signInConstansts } from "../Constants/loginConstant";

const initialState = {
  tests: [],
};
export const labAdminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case labAdminConstants.GET_ALL_TESTS:
      return { ...state, tests: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

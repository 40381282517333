import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MobileLabService = (props) => {
  const {t} = useTranslation("common")
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="popUpModal"
      >
        <Modal.Header closeButton style={{ background: "#fff" }}>

        <div className="row p-2" style={{ width: "500px" }}>
            <div className="col-12 mt-3">
              <h4
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  lineHeight: "1em",
                  marginBottom: "20px",
                  fontWeight: 600,
                  color: "#444",
                }}
              >
                {t("home.mobilelabservices")}
              </h4>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-user" style={{color:'#1a4774'}} aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon10"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-envelope" style={{color:'#1a4774'}} aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Email*"
                  aria-label="Your Email*"
                  aria-describedby="basic-addon10"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{color:'#1a4774'}} id="basic-addon1">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Phone"
                  aria-label="Your Phone"
                  aria-describedby="basic-addon10"
                />
              </div>
              <div className="input-group mb-3">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Your Comment"
                  aria-label="Your Comment"
                  aria-describedby="basic-addon10"
                  style={{ minHeight: "100px" }}
                />
              </div>
              <div className="w-100 d-flex justify-content-end">

                <div>
                  {" "}
                  <input
                    type="submit"
                    value={t("home.booknow")}
                    className="headerBtn-red"
                    style={{ marginRight: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        {/* <Modal.Body style={{ background: "#fff" }}>
     
        </Modal.Body> */}
      </Modal>
    </>
  );
};

export default MobileLabService;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStaticData,
  getPackages,
} from "../../../Store/Actions/staticActions";
import HeaderBtn from "../../ReUseableComponents/HeaderBtn";
import PakagesFemale from "../Cards/pakagesFemale/PakagesFemale";
import ViewPackageDetails from "../modals/viewpackageDetails/ViewPackageDetails";
import "./pakages.css";
const Pakages = (props) => {
  const { t, i18n } = useTranslation("common");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [packageFilter, setPackageFilter] = useState("");

  const packages = useSelector((state) => state?.static?.packages);
  const dispatch = useDispatch();
  const [selectedPackage, setSelectedPackage] = useState({
    show: false,
    data: {},
  });
  useEffect(() => {
    // dispatch(fetchStaticData())
    dispatch(
      getPackages({
        gender: {
          id: 1,
          name: "male",
        },
      })
    );
  }, [dispatch]);
  const optionHandler = (gender, ageGroup, category) => {
    dispatch(
      getPackages({
        gender: gender,
        age_group: ageGroup,
        package_category: category,
      })
    );
    // if (item === "male") {
    //   dispatch(
    //     getPackages({
    //       gender: {
    //         id: 1,
    //         name: "male",
    //       },
    //     })
    //   );
    // }
    // if (item === "female") {
    //   dispatch(
    //     getPackages({
    //       gender: {
    //         id: 2,
    //         name: "female",
    //       },
    //     })
    //   );
    // }
  };
  return (
    <div className="pakages-section ">
      <div className="container">
        <div className="row ">
          <div className="col-lg-3 col-md-4 sidebar mb-3">
            <ul>
              <h5>{t("home.mainpakages.gender")}</h5>
              <li>
                <input
                  type="radio"
                  id="tab1"
                  name="tab01"
                  value={gender}
                  onClick={() => {
                    setGender({ id: 1, name: "male" });
                    optionHandler({ id: 1, name: "male" },age,packageFilter);
                  }}
                />
                <label htmlFor="tab1">{t("home.mainpakages.category1")}</label>
                <div className="check"></div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab2"
                  name="tab01"
                  value={gender}
                  onClick={() => {
                    setGender({ id: 2, name: "female" });
                    optionHandler({ id: 2, name: "female" },age,packageFilter);
                  }}
                />
                <label htmlFor="tab2">{t("home.mainpakages.category2")}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
              <li>
                <input
                  type="radio"
                  id="tab3"
                  name="tab01"
                  value={gender}
                  onClick={() => {
                    setGender({ id: 3, name: "other" });
                    optionHandler({ id: 2, name: "other" },age,packageFilter);
                  }}
                />
                <label htmlFor="tab3">{"other"}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
            </ul>

            <ul>
              <h5>{t("home.mainpakages.age")}</h5>

              <li>
                <input
                  type="radio"
                  id="tab5"
                  name="tab03"
                  value={age}
                  onClick={() => {
                    setAge({ id: 3, name: "child" });
                    optionHandler(gender, { id: 3, name: "child" },packageFilter);
                  }}
                />
                <label htmlFor="tab5">{t("home.mainpakages.category3")}</label>
                <div className="check"></div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab12"
                  name="tab03"
                  value={age}
                  onClick={() => {
                    setAge({ id: 2, name: "above 40" });
                    optionHandler(gender, { id: 2, name: "above 40" },packageFilter);
                  }}
                />
                <label htmlFor="tab12">{t("home.mainpakages.category4")}</label>
                <div className="check"></div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab4"
                  name="tab03"
                  value={age}
                  onClick={() => {
                    setAge({ id: 1, name: "below 40" });
                    optionHandler(gender, { id: 1, name: "below 40" },packageFilter);
                  }}
                />
                <label htmlFor="tab4">{t("home.mainpakages.category5")}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab6"
                  name="tab03"
                  value={age}
                  onClick={() => {
                    setAge({ id: 4, name: "senior citizen" });
                    optionHandler(gender, { id: 4, name: "senior citizen" },packageFilter);
                  }}
                />
                <label htmlFor="tab6">{t("home.mainpakages.category6")}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
              <li>
                <input
                  type="radio"
                  id="tab7"
                  name="tab03"
                  value={age}
                  onClick={() => {
                    setAge({ id: 5, name: "other" });
                    optionHandler(gender, { id: 5, name: "other" },packageFilter);
                  }}
                />
                <label htmlFor="tab7">{"other"}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
            </ul>

            <ul>
              <h5>{t("home.mainpakages.pakages")}</h5>
              <li>
                <input
                  type="radio"
                  id="tab13"
                  name="tab07"
                  value={packageFilter}
                  onClick={() => {
                    setPackageFilter({ id: 1, name: "basic" });
                    optionHandler(gender, age, { id: 1, name: "basic" });
                  }}
                />
                <label htmlFor="tab13">{t("home.mainpakages.type1")}</label>
                <div className="check"></div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab8"
                  name="tab07"
                  value={packageFilter}
                  onClick={() => {
                    setPackageFilter({ id: 2, name: "gold" });
                    optionHandler(gender, age, { id: 2, name: "gold" });
                  }}
                />
                <label htmlFor="tab8">{t("home.mainpakages.type2")}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab9"
                  name="tab07"
                  value={packageFilter}
                  onClick={() => {
                    setPackageFilter({ id: 3, name: "platinum" });
                    optionHandler(gender, age, { id: 3, name: "platinum" });
                  }}
                />
                <label htmlFor="tab9">{t("home.mainpakages.type3")}</label>
                <div className="check"></div>
              </li>

              <li>
                <input
                  type="radio"
                  id="tab10"
                  name="tab07"
                  value={packageFilter}
                  onClick={() => {
                    setPackageFilter({ id: 4, name: "extensive" });
                    optionHandler(gender, age, { id: 4, name: "extensive" });
                  }}
                />
                <label htmlFor="tab10">{t("home.mainpakages.type4")}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
              <li>
                <input
                  type="radio"
                  id="tab11"
                  name="tab07"
                  value={packageFilter}
                  onClick={() => {
                    setPackageFilter({ id: 5, name: "other" });
                    optionHandler(gender, age, { id: 5, name: "other" });
                  }}
                />
                <label htmlFor="tab11">{"other"}</label>
                <div className="check">
                  <div className="inside"></div>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-9
           col-md-7 col-sm-12"
            style={{ marginTop: "-15px" }}
          >
            <div className="row">
              {/* eslint-disable-next-line */}
              {packages.map((item, index) => {
                if (index < 6) {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 mt-3 "
                      key={index + 1}
                    >
                      <PakagesFemale
                        head={item?.name}
                        price={item?.price}
                        onClick={() =>
                          setSelectedPackage({ show: true, data: item })
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="center-childrens mt-5">
          <div>
            {" "}
            <HeaderBtn
              label={
                props?.all_package_button?.button
                  ? props?.all_package_button?.button
                  : t("home.mainpakages.btnname")
              }
              className="headerBtn-red"
              style={{ marginRight: "5px" }}
              onClick={() => props.history?.push("/all-packages")}
            />
          </div>
        </div>
      </div>
      <ViewPackageDetails
        show={selectedPackage?.show}
        data={selectedPackage?.data}
        {...props}
        onHide={() =>
          setSelectedPackage({ show: false, data: selectedPackage?.data })
        }
      />
    </div>
  );
};

export default Pakages;

import React from "react";
import "./CovidTeamCard.css";
import { Link } from "react-router-dom";
import heartIcon from "../../../../Statics/icons/heart-icon.png";
import { useTranslation } from "react-i18next";
const CovidTeamCard = ({ imgSrc, details, label, btnlinks,defaultname ,button}) => {
  const {t,i18n} = useTranslation("common")
  return (
    <>
      <div
        className="card center-childrens mb-5"
        style={{ width: "100%", height: "480px" }}
      >

      

        <img className="covidTeamCardImg mt-3" src={imgSrc} alt="CovidTeamCard" />
        <span className="covidTeamImgIcon">
          <img src={heartIcon} alt={label} />
        </span>
        <div className=" text-center mt-4" >
          <h5 className="card-title covidTEamHeadingCard gray-headin" >
            {label}
          </h5>
          <p className="card-text covidTeamCardText" >{details}</p>
          <div classNameName="socialmediasIconCovidTeam">
            <span className="light-Color-Para">
              <i className="fab fa-facebook-square"></i>
            </span>
            <span className="ml-2 light-Color-Para">
              <i className="fab fa-linkedin"></i>
            </span>
            <span className="ml-2 light-Color-Para">
              <i className="fab fa-twitter-square"></i>
            </span>
            <span className="ml-2 light-Color-Para">
              <i className="fab fa-dribbble-square"></i>
            </span>
          </div>
          <div className="card-footer w-100 d-flex justify-content-center p-2 text-muted" style={{background:"transparent",border:"none"}}>
            <Link
              // to={{
              //   pathname:'/covid-19-team-innerpage',
              //   state:btnselecttabname
              // }}
              to={{ pathname: btnlinks,state:defaultname }}
              className="CovidTeamCardBtn text-cente mt-4"
            >
              {button ? button :t("covidteam.btndetail")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CovidTeamCard;

import { signInConstansts } from "../Constants/loginConstant";
import { signUpConstansts } from "../Constants/signUpConstants";
const initialState = {
  showSignUpModal: false,
  userResponse : {}
};
export const signUpReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case signUpConstansts.SHOW_MODAL:
      return { ...state, showSignUpModal: payload };
      case signUpConstansts.REGISTER_PATIENT:
      return { ...state, userResponse: payload };
      case signInConstansts.LOG_OUT_USER:
        return initialState;
    default:
      return state;
  }
};

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { conatctUse } from "../../../Store/Actions/staticActions";
import { disablePastDate, filterComponentData } from "../../../Utils/functions";

const BookCovidTest = () => {
  const { t, i18n } = useTranslation("common");
  const [forign, setsetForign] = useState(false);
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);
  const covid19test = filterComponentData(componetcms, "covid19test", lang);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useForm();
  const onSubmitHandler = (data) => {
    const apiData = {
      email_body: `<Span>Name: ${
        data?.firstName + data?.lastName
      }</Span> <br/> <Span>Email: ${data?.email}</Span> <br />
       <Span>Phone: ${
         data?.phone
       }</Span> <br /> <Span>Subject:Covid Test Registration</Span> <br /> <Span>Address: ${
        data?.address
      }</Span> <br />  <Span>Gender: ${
        data?.gender
      }</Span> <br/> <Span>Sample Collection: ${
        data?.sampleCollection
      }</Span> <br/> <Span>Date Of Birth: ${data?.dob}</Span> `,
    };
    // console.log(data,"Testing")
    dispatch(conatctUse(apiData));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="row">
          <div className="col-lg-2 mb-4">
            <label className="bookCovidTestLabel" htmlFor="Salutattion">
              {covid19test?.field10
                ? covid19test?.field10
                : t("covid19booking.bookingform.field1")}
            </label>
            <input
              type="text"
              className="bookCovidTestText"
              name="Salutattion"
              required="required"
              {...register("Salutattion", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-5 mb-4">
            <label className="bookCovidTestLabel" htmlFor="firstName">
              {covid19test?.field3
                ? covid19test?.field3
                : t("covid19booking.bookingform.field2")}
            </label>
            <input
              type="text"
              className="bookCovidTestText"
              name="firstName"
              required="required"
              {...register("firstName", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-5 mb-4">
            <label className="bookCovidTestLabel" htmlFor="lastName">
              {covid19test?.field4
                ? covid19test?.field4
                : t("covid19booking.bookingform.field3")}
            </label>
            <input
              type="text"
              className="bookCovidTestText"
              name="lastName"
              required="required"
              {...register("lastName", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="gender">
              {covid19test?.field5
                ? covid19test?.field5
                : t("covid19booking.bookingform.field4")}
            </label>
            {/* <input
              type="text"
              className="bookCovidTestText"
              name="gender"
              required="required"
              {...register("gender", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            /> */}

            <Form.Control
              as="select"
              // className="input-login-modal"
              className="input-login-modal"
              style={{ height: "55px" }}
              custom
              {...register("gender", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Form.Control>
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="dob">
              {covid19test?.field11
                ? covid19test?.field11
                : t("covid19booking.bookingform.field5")}
            </label>
            <input
              type="date"
              className="bookCovidTestText"
              name="dob"
              required="required"
              {...register("dob", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="email">
              {covid19test?.field6
                ? covid19test?.field6
                : t("covid19booking.bookingform.field6")}
            </label>
            <input
              type="tel"
              className="bookCovidTestText"
              name="phone"
              required="required"
              {...register("phone", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="phone">
              {covid19test?.field7
                ? covid19test?.field7
                : t("covid19booking.bookingform.field7")}
            </label>
            <input
              type="email"
              className="bookCovidTestText"
              name="email"
              required="required"
              {...register("email", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-12 mb-4">
            <label className="bookCovidTestLabel" htmlFor="address">
              {covid19test?.field8
                ? covid19test?.field8
                : t("covid19booking.bookingform.field8")}
            </label>
            <input
              type="text"
              className="bookCovidTestText"
              name="address"
              required="required"
              {...register("address", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="sampleCollection">
              {covid19test?.field9
                ? covid19test?.field9
                : t("covid19booking.bookingform.field9")}
            </label>
            <input
              type="date"
              className="bookCovidTestText"
              name="sampleCollection"
              min={disablePastDate()}
              required="required"
              {...register("sampleCollection", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-6 mb-4">
            <label className="bookCovidTestLabel" htmlFor="reportDelivery">
              {covid19test?.mainheading1
                ? covid19test?.mainheading1
                : t("covid19booking.bookingform.field10")}
            </label>
            <input
              type="date"
              className="bookCovidTestText"
              name="reportDelivery"
              min={disablePastDate()}
              required="required"
              {...register("reportDelivery", {
                required: {
                  value: false,
                  message: "this field is required field",
                },
              })}
            />
          </div>
          <div className="col-lg-12 mb-4 d-flex flex-row">
            <input
              type="checkbox"
              name="reportDelivery"
              onChange={() => {
                setsetForign(!forign);
              }}
            />
            <span
              style={{ marginTop: "-5px", marginLeft: "10px" }}
              htmlFor="reportDelivery"
            >
              {covid19test?.field12
                ? covid19test?.field12
                : t("covid19booking.bookingform.field11")}
            </span>
          </div>
          {forign ? (
            <>
              <div className="col-lg-6 mb-4">
                <label className="bookCovidTestLabel" htmlFor="Passport">
                  {covid19test?.field13
                    ? covid19test?.field13
                    : t("covid19booking.bookingform.field12")}
                </label>
                <input
                  type="text"
                  className="bookCovidTestText"
                  name="Passport"
                />
              </div>
              <div className="col-lg-6 mb-4">
                <label className="bookCovidTestLabel" htmlFor="visitDate">
                  {covid19test?.field14
                    ? covid19test?.field14
                    : t("covid19booking.bookingform.field13")}
                </label>
                <input
                  type="date"
                  min={disablePastDate()}
                  className="bookCovidTestText"
                  name="visitDate"
                />
              </div>
            </>
          ) : null}
          <div className="col-12 px-3">
            <button type="submit" className="btn bookCovidTestBtn  btn-block " >{covid19test?.field1 ? covid19test?.field1 : "Submit"}</button>
            <div className="d-flex flex-row ml-5 mt-2">
              <strong className="bookCovidTestLabel ">
                {" "}
                {covid19test?.field2
                  ? covid19test?.field2
                  : t("covid19booking.bookingform.field14")}
              </strong>
              <span style={{ color: "red", marginLeft: "10px" }}>
                {covid19test?.field15
                  ? covid19test?.field15
                  : t("covid19booking.bookingform.field15")}
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BookCovidTest;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Components/LayOuts/footer/Footer";
import Header from "./Components/LayOuts/header/Header";
import Navbar from "./Components/LayOuts/navbar/Navbar";
import Contact from "./Components/Pages/Contact";
import Home from "./Components/Pages/Home";
import PatientDashboard from "./Dashboards/patientDashboard/PatientDashboard";
import { getUser, resendPhoneOtp } from "./Store/Actions/loginActions";
import DoctorDashboard from "./Dashboards/doctorDashboard/DoctorDashboard";
import ResetPassword from "./Components/Pages/ResetPassword";
import Telemedicine from "./Components/Pages/Telemedicine";
import FixedRight from "./Components/LayOuts/FixedSideMenu/FixedRight/FixedRight";
import FixedLeft from "./Components/LayOuts/FixedSideMenu/FixedLeft/FixedLeft";
import Covid19Info from "./Components/Pages/Covid19Info";
import CovidBookingTest from "./Components/Pages/CovidBookingTest";
import CovidTeam from "./Components/Pages/CovidTeam";
import About from "./Components/Pages/About";
import DirectorMessage from "./Components/Pages/DirectorMessage";
import OurMission from "./Components/Pages/OurMission";
import LabConsultant from "./Components/Pages/LabConsultant";
import LabTeam from "./Components/Pages/LabTeam";
import Officials from "./Components/Pages/Officials";
import MalePackages from "./Components/Pages/MalePackages";
import FemalePackages from "./Components/Pages/FemalePackages";
import ScrollTop from "./Components/ReUseableComponents/ScrollTop";
import ViewAppointment from "./Dashboards/patientDashboard/ViewAppointment";
import Moleculardiagnostics from "./Components/Pages/Moleculardiagnostics";
import Flowcytometry from "./Components/Pages/Flowcytometry";
import Pharmacogenetics from "./Components/Pages/Pharmacogenetics";
import MicrobiologynSerology from "./Components/Pages/MicrobiologynSerology";
import Histopathology from "./Components/Pages/Histopathology";
import Cytopathology from "./Components/Pages/Cytopathology";
import Biochemistryimmunology from "./Components/Pages/Biochemistryimmunology";
import Clinicalpathology from "./Components/Pages/Clinicalpathology";
import Hematology from "./Components/Pages/Hematology";
import Ultrasonogram from "./Components/Pages/Ultrasonogram";
import XRay from "./Components/Pages/XRay";
import Cardiacimaging from "./Components/Pages/Cardiacimaging";
import Pathology from "./Components/Pages/Pathology";
import Radiologyimaging from "./Components/Pages/Radiologyimaging";
import Cardiology from "./Components/Pages/Cardiology";
import Onlineconsultation from "./Components/Pages/Onlineconsultation";
import Collectionatcoperate from "./Components/Pages/Collectionatcoperate";
import Collectionathome from "./Components/Pages/Collectionathome";
import News from "./Components/Pages/News";
import Gallery from "./Components/Pages/Gallery";
import LabAdmin from "./Dashboards/labAdministrator/LabAdministrator";
import Paharmacy from "./Dashboards/pharmacy/Pharmacy";
import Administration from "./Dashboards/administration/Administration";
import AdministratoDoctor from "./Dashboards/administration/AdministratoDoctor";
import DoctorAddPrescription from "./Dashboards/doctorDashboard/DoctorAddPrescription";
import PaharmacyOrderDetail from "./Dashboards/pharmacy/PaharmacyOrderDetail";
import ViewPrescriptionDetails from "./Dashboards/patientDashboard/ViewPrescriptionDetails";
import FixedRightEndIcon from "./Components/LayOuts/FixedSideMenu/FixedRight/FixedRightEndIcon";
import ViewTestDetails from "./Dashboards/labAdministrator/ViewTestDetails";
import Cart from "./Components/Pages/Cart";
import ViewOrderDetails from "./Dashboards/patientDashboard/ViewOrderDetails";
import { messaging, test } from "./init-fcm";
import VideoCall from "./Components/ReUseableComponents/VideoCall";
import PaymentSuccess from "./Components/Pages/PymentSuccess";
import PaymentCancelled from "./Components/Pages/PaymentCancelled";

import PaymentFaild from "./Components/Pages/PaymentFaild";
import AppointmentDetails from "./Dashboards/doctorDashboard/AppointmentDetails";
import PatientDetails from "./Dashboards/doctorDashboard/PatientDetails";
import BoardDetails from "./Dashboards/doctorDashboard/BoardDetails";
import ManageReportDetails from "./Dashboards/administration/ManageReportDetails";
import DoctorDetails from "./Dashboards/patientDashboard/DoctorDetails";
import PrivateRoute from "./Utils/PrivateRoute";
import NotFound from "./Components/LayOuts/errorPage/NotFound";
import ChatIframe from "./services/twillioChat/ChatIframe";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import AllPackages from "./Components/Pages/AllPackages";
import CovidTeamInnerDetails from "./Components/Pages/CovidTeamInnerDetails";
import Labconsultantinnerpage from "./Components/Pages/labconsultantinnerpage";
import OnlineConsultantDetailPage from "./Components/Pages/OnlineConsultantDetailPage";
import NewsInnerPages from "./Components/Pages/NewsInnerPages";
import { useTranslation } from "react-i18next";
import AssignTarget from "./Dashboards/administration/AssignTarget";
import TargetDetails from "./Dashboards/administration/TargetDetails";
import {
  getCmscomponent,
  readLanguageAction,
  SelectedLanguage,
} from "./Store/Actions/cmsAction";
import PaymentAndRefundPolicy from "./Components/Pages/PaymentAndRefundPolicy";
import PrivacyConfidentiality from "./Components/Pages/PrivacyConfidentiality";
import TermsAndCondition from "./Components/Pages/TermsAndCondition";

import { addToCart } from "./Store/Actions/staticActions";
import PatientOrderDetail from "./Dashboards/administration/OrderDetail";
import GoogleOtp from "./Components/Pages/GoogleOtp";
import GoogleSignup from "./Components/Pages/GoogleSignUp";
import FaceBookOpt from "./Components/Pages/FaceBookSignIn";
import FaceBookSignup from "./Components/Pages/FaceBookSignUp";
import ReturnRequestDetail from "./Dashboards/administration/ReturnRequestDetail";
import DoctorsPatientMedicalRecords from "./Dashboards/doctorDashboard/PatientMedicalRecord";
import DeliveryPolicy from "./Components/Pages/DeliveryPolicy";
import SocialMediaInnerPage from "./Components/Pages/SocialMediaInnerPage";
function App() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const langs = useSelector((state) => state?.cms?.language);
  const user = useSelector((state) => state?.logIn?.user);

  useEffect(() => {
    // dispatch(SelectedLanguage("en-US"));

    dispatch(getCmscomponent());

    // window.onbeforeunload = () => {
    //   localStorage.removeItem('lang');
    // }
  }, [langs]);
  useEffect(() => {
    dispatch(getUser());
    const cartData = JSON.parse(localStorage.getItem("cart"));
    dispatch(addToCart(cartData?.length));

    localStorage.setItem("lang", "en-US");
    // dispatch(readLanguageAction())

    if ("serviceWorker" in navigator) {
      navigator?.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    if ("Notification" in window && "serviceWorker" in navigator) {
      Notification.requestPermission()
        .then(async function () {
          const token = await test?.getToken(messaging);
          localStorage.setItem("fcm", token);
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });

      navigator?.serviceWorker?.addEventListener("message", (message) => {
        if (!("Notification" in window)) {
          toast.error("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
          const noti = new Notification(
            message?.data?.["firebase-messaging-msg-data"]?.notification?.title,
            {
              icon: message?.data?.["firebase-messaging-msg-data"]?.notification
                ?.image,
              body: message?.data?.["firebase-messaging-msg-data"]?.notification
                ?.body,
              data: message?.data?.["firebase-messaging-msg-data"]?.data,
            }
          );

          noti.onclick = (event) => {
            console.log(event, "Notification clicked.");
            // if (event?.currentTarget?.data?.url) {
            //   window.location.href = event?.currentTarget?.data?.url;
            // }
          };
        }
      });
    }

    window.scrollTo(0, 0);
  }, [dispatch]);
  return (
    <div className="App">
      {/* <h1>{t("welcome.title", { framework: "React" })}</h1>
      <button onClick={() => i18n.changeLanguage("ban")}>ban</button>
      <button onClick={() => i18n.changeLanguage("en")}>en</button> */}

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <ScrollTop />
        <Route exact path="/" render={(props) => <Header {...props} />} />
        <Route exact path="/:page" render={(props) => <Header {...props} />} />

        <Route exact path="/" render={(props) => <Navbar {...props} />} />
        <Route exact path="/:page" render={(props) => <Navbar {...props} />} />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route
            exact
            path="/payment/:id/success"
            render={(props) => <PaymentSuccess {...props} />}
          />
          <Route
            exact
            path="/payment/:id/fail"
            render={(props) => <PaymentFaild {...props} />}
          />
          <Route
            exact
            path="/payment/:id/cancel"
            render={(props) => <PaymentCancelled {...props} />}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/view-appointment"
            component={ViewAppointment}
          />
          {/* <Route exact path="/view-appointment" component={ViewAppointment} /> */}
          <PrivateRoute
            routelink={"doctor"}
            path="/appointment-details"
            component={AppointmentDetails}
          />
          <PrivateRoute
            routelink={"patient"}
            path="/patient-dashboard"
            component={PatientDashboard}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/patient-details"
            component={PatientDetails}
          />
          {/* <Route exact path="/patient-details" component={PatientDetails} /> */}
          <PrivateRoute
            routelink={"patient"}
            path="/patient-doctot-details"
            component={DoctorDetails}
          />
          {/* <Route exact path="/patient-doctot-details" component={DoctorDetails} /> */}
          <PrivateRoute
            routelink={"doctor"}
            path="/boards-details"
            component={BoardDetails}
          />
          {/* <Route exact path="/boards-details" component={BoardDetails} /> */}
          <PrivateRoute
            routelink={"administrator"}
            path="/manage-reports"
            component={ManageReportDetails}
          />
          {/* <Route exact path="/manage-reports" component={ManageReportDetails} /> */}

          <Route
            exact
            path="/doctor-add-prescriptions"
            component={DoctorAddPrescription}
          />

          {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
          <Route
            exact
            path="/privacy-refund-policy"
            component={PaymentAndRefundPolicy}
          />
          <Route
            exact
            path="/privacy-policy"
            component={PrivacyConfidentiality}
          />
          <Route exact path="/delivery-policy" component={DeliveryPolicy} />
          <Route exact path="/terms-conditions" component={TermsAndCondition} />
          <Route
            exact
            path="/chat/:id/:user_id/:name/:board"
            component={ChatIframe}
          />
          <Route exact path="/call/:id" component={VideoCall} />
          <Route
            exact
            path="/audiocall/:id"
            render={(props) => <VideoCall {...props} audiocall={true} />}
          />
          {/* <Route
          exact
          path="/patient-prescription-details"
          component={ViewPrescriptionDetails}
        /> */}
          <PrivateRoute
            routelink={"patient"}
            path="/patient-prescription-details"
            component={ViewPrescriptionDetails}
          />
          <Route
            exact
            path="/reset-password/:id/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/auth-number-verification"
            render={(props) => <GoogleOtp {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-signup-verification"
            render={(props) => <GoogleSignup {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-number-verification-facebook"
            render={(props) => <FaceBookOpt {...props} user={user} />}
          />
          <Route
            exact
            path="/auth-signup-verification-facebook"
            render={(props) => <FaceBookSignup {...props} user={user} />}
          />
          <Route exact path="/telemedicine" component={Telemedicine} />
          <Route exact path="/covid-19-info" component={Covid19Info} />
          <Route
            exact
            path="/booking-for-covid-19-test"
            component={CovidBookingTest}
          />
          <Route exact path="/covid-19-team" component={CovidTeam} />

          {/* Covid Inner Page Route*/}
          <Route
            exact
            path="/covid-19-team-innerpage"
            component={CovidTeamInnerDetails}
          />
          <Route
            exact
            path="/social-media-post"
            component={SocialMediaInnerPage}
          />

          <Route exact path="/about-us" component={About} />
          <Route
            exact
            path="/managing-director-message"
            component={DirectorMessage}
          />
          <Route exact path="/our-mission" component={OurMission} />
          <Route exact path="/lab-team" component={LabTeam} />
          <Route exact path="/lab-consultants" component={LabConsultant} />
          <Route
            exact
            path="/lab-consultants-inner-detail"
            component={Labconsultantinnerpage}
          />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/officials" component={Officials} />
          <Route
            exact
            path="/packages-male"
            render={(props) => <MalePackages {...props} />}
          />
          <Route
            exact
            path="/all-packages"
            render={(props) => <AllPackages {...props} />}
          />
          <Route
            exact
            path="/packages-female"
            render={(props) => <FemalePackages {...props} />}
          />

          {/* News & Media Subpages */}
          <Route exact path="/news" component={News} />
          <Route exact path="/news-details" component={NewsInnerPages} />

          <Route exact path="/gallery" component={Gallery} />

          {/* Sevices Subpages */}
          <Route exact path="/pathology-services" component={Pathology} />
          <Route
            exact
            path="/radiology-imaging-services"
            component={Radiologyimaging}
          />
          <Route exact path="/cardiology-services" component={Cardiology} />
          <Route
            exact
            path="/online-consultants"
            component={Onlineconsultation}
          />
          <Route
            exact
            path="/online-consultant-detail"
            component={OnlineConsultantDetailPage}
          />
          <Route exact path="/collection-home" component={Collectionathome} />
          <Route
            exact
            path="/collection-at-corporate"
            component={Collectionatcoperate}
          />
          <Route exact path="/cart" component={Cart} />
          {/* <Route exact path="/patient-dashboard" component={PatientDashboard} /> */}
          <PrivateRoute
            routelink={"doctor"}
            path="/doctor-dashboard"
            component={DoctorDashboard}
          />
          <PrivateRoute
            routelink={"doctor"}
            path="/patient-medical-records"
            component={DoctorsPatientMedicalRecords}
          />
          {/* <Route exact path="/doctor-dashboard" component={DoctorDashboard} /> */}
          <PrivateRoute
            routelink={"laboratory_admin"}
            path="/lab-administrator"
            component={LabAdmin}
          />
          {/* <Route exact path="/lab-administrator" component={LabAdmin} /> */}
          <PrivateRoute
            routelink={"laboratory_admin"}
            path="/lab-administrator-test-details"
            component={ViewTestDetails}
          />
          {/* <Route
          exact
          path="/lab-administrator-test-details"
          component={ViewTestDetails}
        /> */}
          <PrivateRoute
            routelink={"administrator"}
            path="/test-details"
            component={ViewTestDetails}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/assign-target"
            component={AssignTarget}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/target-details"
            component={TargetDetails}
          />

          {/* <Route exact path="/test-details" component={ViewTestDetails} /> */}
          <PrivateRoute
            routelink={"administrator"}
            path="/administrator"
            component={Administration}
          />
          {/* <Route exact path="/administrator" component={Administration} /> */}
          <PrivateRoute
            routelink={"administrator"}
            path="/administrator-doctor"
            component={AdministratoDoctor}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/patient-order-details"
            component={PatientOrderDetail}
          />
          <PrivateRoute
            routelink={"administrator"}
            path="/return-request-details"
            component={ReturnRequestDetail}
          />
          {/* <Route
          exact
          path="/administrator-doctor"
          component={AdministratoDoctor}
        /> */}
          <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy"
            component={Paharmacy}
          />
          {/* <Route exact path="/pharmacy" component={Paharmacy} /> */}
          <PrivateRoute
            routelink={"pharmacy"}
            path="/pharmacy-Order-details"
            component={PaharmacyOrderDetail}
          />
          {/* <Route
          exact
          path="/pharmacy-Order-details"
          component={PaharmacyOrderDetail}
        /> */}
          <PrivateRoute
            routelink={"patient"}
            path="/Order-details"
            component={ViewOrderDetails}
          />
          {/* <Route exact path="/Order-details" component={ViewOrderDetails} /> */}
          <Route
            exact
            path="/testcatalog-details"
            component={Moleculardiagnostics}
          />
          <Route exact path="/flowcytometry" component={Flowcytometry} />
          <Route exact path="/pharmacogenetics" component={Pharmacogenetics} />
          <Route
            exact
            path="/microbiology-serology"
            component={MicrobiologynSerology}
          />
          <Route exact path="/histopathology" component={Histopathology} />
          <Route exact path="/cytopathology" component={Cytopathology} />
          <Route
            exact
            path="/biochemistry-immunology"
            component={Biochemistryimmunology}
          />
          <Route exact path="/hematology" component={Hematology} />
          <Route
            exact
            path="/clinical-pathology"
            component={Clinicalpathology}
          />
          <Route exact path="/ultrasonogram" component={Ultrasonogram} />
          <Route exact path="/x-ray" component={XRay} />
          <Route exact path="/cardiac-imaging" component={Cardiacimaging} />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Route exact path="/:page" component={Footer} />
        <Route exact path="/" component={Footer} />

        <Route exact path="*" render={(props) => <FixedRight {...props} />} />
      </Router>
      <FixedLeft />
      <FixedRightEndIcon />
    </div>
  );
}

export default App;
// YT4YGKZDANBGgQxn78KE

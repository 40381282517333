import React from "react";
import "./homeCard.css";
const HomeCard = (props) => {
  return (
    <div className="col-md-6 col-lg-3 mt-5 text-center card-size-home">
      <div className="icn-text-alt h-100">
        <div className="icon-text-alt-icon">
          <img src={props.imgSrc} alt={props?.cardHeading} style={{height:'50px'}}/>
        </div>
        <div>
          <h4 className="heading">{props.cardHeading}</h4>
          <p className="text-center mt-3 card-discription">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;

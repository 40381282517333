import React from "react";
import { useTranslation } from "react-i18next";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import { emptyString } from "../../Utils/functions";
import ProfileTable from "../patientDashboard/ProfileTable";

const PatientDetails = (props) => {
  const { t, i18n } = useTranslation("common");
  const viewData = props.location.state;
  const item = { key: "PatientDetails" };

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.doctordashboard.mypatients.mainheading1")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12 ">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/doctor-dashboard", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>{" "}
              {t(
                "dashboards.doctordashboard.appointment.todayappointment.appointmentdetail.backbtn"
              )}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3 pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>{t("dashboards.doctordashboard.mypatients.mainheading1")}</h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.doctordashboard.mypatients.patientname")}
                </span>
                <p>{viewData?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.doctordashboard.mypatients.patientemail")}
                </span>
                <p>{viewData?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.doctordashboard.mypatients.patientnumber")}
                </span>
                <p>{viewData?.phone_number}</p>
              </li>{" "}
            </ul>

            <div>
              {viewData?.appointment_details?.length > 0 ? (
                <div>
                  <h5>
                    {" "}
                    {t("dashboards.doctordashboard.mypatients.appointmentdetail")}
                  </h5>

                  {viewData?.appointment_details?.length > 0 ? (
                    <>
                      <ProfileTable
                        tableHead={[
                          t("dashboards.doctordashboard.mypatients.tablekeys.date")
                          , 
                          t("dashboards.doctordashboard.mypatients.tablekeys.description") 
                          ,
                          t("dashboards.doctordashboard.mypatients.tablekeys.status")
                        ]}
                        data={viewData?.appointment_details?.map((item) => {
                          return [
                            item?.date,
                            emptyString(item?.description)
                              ? "N/A"
                              : item?.description,
                            <span style={{ color: item?.status == "booked" ? 'red' : item?.status == "confirmed" ? 'green' : '#970165' }}>{item?.status?.toUpperCase()}</span>,
                          ];
                        })}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;

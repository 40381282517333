import React, { useEffect, useState } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import LabTeamBanner from "../../Statics/Images/internal-banner/lab.jpg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import molicularLab from "../../Statics/Images/molicular.jpg";
import flomaitry from "../../Statics/Images/flomaitry.jpg";
import microbiology from "../../Statics/Images/microbiology.jpg";
import hospitology from "../../Statics/Images/hospitology.jpg";
import biochemistry from "../../Statics/Images/biochemistry-immonology.jpg";
import clinic_paithology from "../../Statics/Images/clinic_paithology.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const LabTeam = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state?.cms?.language);
  const labTeamHeader = filterComponentData(
    componetcms,
    "labteamtabcomponent",
    lang
  );
  const lab_admin_header = filterComponentData(
    componetcms,
    "lab_admin_header",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);

  useEffect(() => {
    if (labTeamHeader?.length > 1) {
      setSelectedTab(labTeamHeader[0]?.heading);
    }
    // eslint-disable-next-line
  }, [labTeamHeader]);

  const [selectedTab, setSelectedTab] = useState(
    t("labteam.labteamhandler.buttonname1")
  );
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[8]?.sublink1 ?banner[8]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[8]?.sublink2 ?banner[8]?.sublink2:"Our Team",
      subDash: ">",
      subLink: "/lab-team",
    },
    {
      subLinkName: banner[8]?.sublink3 ?banner[8]?.sublink3:"Lab Team",
      subDash: ">",
      subLink: "/lab-team",
    },
  ]
  return (
    <>
      <Helmet>
        <title>{t("labteam.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[8]?.image ? banner[8]?.image : LabTeamBanner})`}
        heading={banner[8]?.heading ? banner[8]?.heading : "Lab Team"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="mt-5">
        <BlueHeading
          heading={
            lab_admin_header?.heading
              ? lab_admin_header?.heading
              : t("labteam.mainheading1")
          }
        />
      </div>
      {labTeamHeader?.length < 1 ? (
        <div className="container">
          {[
            t("labteam.labteamhandler.buttonname1"),
            t("labteam.labteamhandler.buttonname2"),
            t("labteam.labteamhandler.buttonname3"),
            t("labteam.labteamhandler.buttonname4"),
            t("labteam.labteamhandler.buttonname5"),
            t("labteam.labteamhandler.buttonname6"),
          ].map((item, index) => (
            <div
              className="d-flex d-inline-flex "
              key={index + 1}
              onClick={() => setSelectedTab(item)}
            >
              <div
                className={
                  selectedTab === item ? "labTeamBtnActive" : "labTeamBtn"
                }
              >
                <span
                  className={selectedTab === item ? "text-white" : ""}
                  style={{ fontSize: "13px" }}
                >
                  {item}
                </span>
              </div>
            </div>
          ))}

          {selectedTab === t("labteam.labteamhandler.buttonname1") ? (
            <>
              <TextAndImageSection
                imgSrc={molicularLab}
                imgAlt={"Molecular Lab Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "labteam.labteamhandler.buttonname1detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "labteam.labteamhandler.buttonname1detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("labteam.labteamhandler.buttonname2") ? (
            <>
              <TextAndImageSection
                imgSrc={flomaitry}
                imgAlt={"Flow Cytometry Lab Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "labteam.labteamhandler.buttonname2detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "labteam.labteamhandler.buttonname2detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("labteam.labteamhandler.buttonname3") ? (
            <>
              <TextAndImageSection
                imgSrc={microbiology}
                imgAlt={"Microbiology & Serology Lab Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "labteam.labteamhandler.buttonname3detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "labteam.labteamhandler.buttonname3detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("labteam.labteamhandler.buttonname4") ? (
            <>
              <div className="mb-5">
                <TextAndImageSection
                  imgSrc={hospitology}
                  imgAlt={"Histopathology Lab Team"}
                  imgCol={"col-lg-5"}
                  textCol={"col-lg-7"}
                  subHeading={t(
                    "labteam.labteamhandler.buttonname4detail.subHeading"
                  )}
                  subHeadingStyle={{ margin: "0px" }}
                  discription={
                    <>
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "0px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "labteam.labteamhandler.buttonname4detail.discriptiontxt1"
                        )}
                      </p>
                    </>
                  }
                  reverse={true}
                />
              </div>
            </>
          ) : selectedTab === t("labteam.labteamhandler.buttonname5") ? (
            <>
              <div className="mb-5">
                <TextAndImageSection
                  imgSrc={biochemistry}
                  imgAlt={"Biochemistry & Immunology Lab Team"}
                  imgCol={"col-lg-5"}
                  textCol={"col-lg-7"}
                  subHeading={t(
                    "labteam.labteamhandler.buttonname5detail.subHeading"
                  )}
                  subHeadingStyle={{ margin: "0px" }}
                  discription={
                    <>
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "0px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "labteam.labteamhandler.buttonname5detail.discriptiontxt1"
                        )}
                      </p>
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "-15px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "labteam.labteamhandler.buttonname5detail.discriptiontxt2"
                        )}
                      </p>
                    </>
                  }
                  reverse={true}
                />
              </div>
            </>
          ) : selectedTab === t("labteam.labteamhandler.buttonname6") ? (
            <>
              <div className="mb-5">
                <TextAndImageSection
                  imgSrc={clinic_paithology}
                  imgAlt={"Clinical Paithology Lab Team"}
                  imgCol={"col-lg-5"}
                  textCol={"col-lg-7"}
                  subHeading={t(
                    "labteam.labteamhandler.buttonname6detail.subHeading"
                  )}
                  subHeadingStyle={{ margin: "0px" }}
                  discription={
                    <>
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "0px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "labteam.labteamhandler.buttonname6detail.discriptiontxt1"
                        )}
                      </p>
                    </>
                  }
                  reverse={true}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="container">
          {labTeamHeader?.map((item, index) => (
            <div
              className="d-flex d-inline-flex "
              key={index + 1}
              onClick={() => setSelectedTab(item?.tab)}
            >
              <div
                className={
                  selectedTab === item?.tab ? "labTeamBtnActive" : "labTeamBtn"
                }
              >
                <span
                  className={selectedTab === item?.tab ? "text-white" : ""}
                  style={{ fontSize: "13px" }}
                >
                  {item?.tab}
                </span>
              </div>
            </div>
          ))}

          {labTeamHeader?.map((item) =>
            item?.tab == selectedTab ? (
              <TextAndImageSection
                imgSrc={item?.image}
                imgAlt={"Molecular Lab Team"}
                imgCol={"col-lg-5 mb-5"}
                textCol={"col-lg-7 mb-5"}
                subHeading={item?.heading}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {item?.desc1}
                      <br />
                      {item?.desc2}
                    </p>
                  </>
                }
                reverse={true}
              />
            ) : null
          )}
        </div>
      )}
    </>
  );
};

export default LabTeam;

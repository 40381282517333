import React from "react";

const ServiceCard = ({ data, active, isImage ,istrue }) => {
  return (
    <div
      className={
        active
          ? " serviceCard-Active center-childrens mb-3"
          : "serviceCard center-childrens mb-3"
      }
    >
      <div className="serviceCard-circle text-white center-childrens ">
        {isImage ? (
          <img
            src={istrue ? data?.image : data?.img} 
            alt="service card"
            style={{ width: "45px", height: "45px" }}
          />
        ) : (
          <>{istrue ? data?.image : data?.img}</>
        )}
      </div>
      <div className="serviceCard-heading mt-3">{istrue ? data?.title : data?.heading}</div>
    </div>
  );
};

export default ServiceCard;

import React, { useEffect, useState } from "react";
import PastAppointments from "./PastAppointments";
import TodayAppointments from "./TodayAppointments";
import UpComingAppointments from "./UpComingAppointments";
import { patientAppointments } from "../../Store/Actions/patientActions";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import { useDispatch, useSelector } from "react-redux";
import DoctorAppointmentReschedule from "../../Components/LayOuts/modals/doctorAppointmentReschedule/DoctorAppointmentReschedule";
import { videoCallToken } from "../../Store/Actions/staticActions";
import { useTranslation } from "react-i18next";

const AppointmentDoctor = (props) => {
  const { t, i18n } = useTranslation("common");
  const [selectedTab, setSelectedTab] = useState("");
  const [tableData, setTableData] = useState([]);
  const [rescheduleModal, setrescheduleModal] = useState({
    show: false,
    data: {},
  });
  const dispatch = useDispatch();
  // const loader = useSelector((state) => state?.logIn?.loader);
  const user = useSelector((state) => state?.logIn?.user);
  const appointment = useSelector((state) => state?.patient?.appointments);

  useEffect(() => {
    // const finalTableDate = appointment?.today?.map((_item, index) => {
    //   _item = { ..._item, from: "TodayAppointment" };
    //   return [
    //     index + 1,
    //     _item?.date,
    //     _item?.patient_details?.name,
    //     _item?.description,
    //     <>
    //       <div className="row">
    //         <div className="col">
    //           <DashboardBtnList
    //             label={"Start Appointment"}
    //             className="order-pay-btn"
    //             // routePath="/view-appointment"
    //             onClick={() => {
    //               props?.history?.push("/doctor-add-prescriptions", _item);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </>,
    //   ];
    // });
    // setTableData(finalTableDate || []);

    if (!user) {
      props?.history?.push("/");
    }
    const apiData = {
      id: user?.id,
    };
    setSelectedTab(
      t("dashboards.doctordashboard.appointment.btntabs.todayappointment")
    );

    dispatch(patientAppointments(apiData));

    // eslint-disable-next-line
  }, []);
  const tabHandler = (item) => {
    setSelectedTab(item);
    if (
      item ===
      t("dashboards.doctordashboard.appointment.btntabs.todayappointment")
    ) {
      const finalTableDate = appointment?.today?.map((_item, index) => {
        _item = { ..._item, from: "TodayAppointment" };
        return [
          index + 1,
          _item?.date,
          _item?.patient_details?.name,
          _item?.description,
          <>
            <div className="row">
              <div className="col">
                <DashboardBtnList
                  label={t(
                    "dashboards.doctordashboard.appointment.todayappointment.tablecolheader.startappointment"
                  )}
                  className="order-pay-btn"
                  // routePath="/view-appointment"
                  onClick={() => {
                    dispatch(
                      videoCallToken(false, { appointment_id: _item?.id })
                    );
                    props?.history?.push("/doctor-add-prescriptions", _item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    } else if (
      item ===
      t("dashboards.doctordashboard.appointment.btntabs.upcomingappointment")
    ) {
      const finalTableDate = appointment?.upcoming?.map((item, index) => {
        return [
          index + 1,
          item?.date,
          item?.patient_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col pr-0">
                <DashboardBtnList
                  label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.view")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/appointment-details", item);
                  }}
                />
              </div>
              <div className="col">
                <DashboardBtnList
                  label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.reschedule")}
                  className="appointment-reschdule-btn"
                  onClick={() => setrescheduleModal({ show: true, data: item })}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    } else if (
      item ===
      t("dashboards.doctordashboard.appointment.btntabs.pastappointment")
    ) {
      const finalTableDate = appointment?.past?.map((item, index) => {
        return [
          index + 1,
          item?.date,
          item?.patient_details?.name,
          item?.description,
          <>
            <div className="row">
              <div className="col ">
                <DashboardBtnList
                  label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.view")}
                  className="order-pay-btn"
                  onClick={() => {
                    props.history.push("/appointment-details", item);
                  }}
                />
              </div>
            </div>
          </>,
        ];
      });
      setTableData(finalTableDate || []);
    }
  };
  return (
    <>
      <div>
        {[
          t("dashboards.doctordashboard.appointment.btntabs.todayappointment"),
          t(
            "dashboards.doctordashboard.appointment.btntabs.upcomingappointment"
          ),
          t("dashboards.doctordashboard.appointment.btntabs.pastappointment"),
        ].map((item, index) => (
          <div
            className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
            key={index + 1}
            onClick={() => tabHandler(item)}
          >
            <DashboardBtnList
              label={item}
              labelStyle={selectedTab === item ? { color: "#fff" } : ""}
              className={
                selectedTab === item
                  ? "dashboardBtnList-item-active py-2"
                  : "default-color-and-hover py-2"
              }
            />
          </div>
        ))}
        {selectedTab ===
        t("dashboards.doctordashboard.appointment.btntabs.todayappointment") ? (
          <TodayAppointments tabledata={tableData} {...props} />
        ) : selectedTab ===
          t(
            "dashboards.doctordashboard.appointment.btntabs.upcomingappointment"
          ) ? (
          <UpComingAppointments tabledata={tableData} {...props} />
        ) : selectedTab ===
          t(
            "dashboards.doctordashboard.appointment.btntabs.pastappointment"
          ) ? (
          <PastAppointments tabledata={tableData} {...props} />
        ) : (
          ""
        )}
        <DoctorAppointmentReschedule
          show={rescheduleModal?.show}
          data={rescheduleModal?.data}
          onHide={() => setrescheduleModal({ show: false, data: {} })}
          {...props}
        />
      </div>
    </>
  );
};

export default AppointmentDoctor;

import React from "react";

const DashBoardMainHading = (props) => {
  return (
    <div className="dashboardMainHaeding-container mb-5">
      <div className="py-4">
        <div className="col-12 text-center p-5 ">
          <h1 className="dashboardMainHeading-text" style={{fontSize:'28px'}}>{props.title}</h1>
        </div>
      </div>
    </div>
  );
};

export default DashBoardMainHading;

import React, { useEffect, useState } from "react";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import ProfileTable from "./ProfileTable";
import BookOrderModal from "../../Components/LayOuts/modals/bookOrder/BookOrderModal";
import { useTranslation } from "react-i18next";
const ViewPrescriptionDetails = (props) => {
  const [viewData, setviewData] = useState(props.location.state);
  const item = { key: "fromPrescription" };
  const [orderModalData, setorderModalData] = useState({
    show: false,
    data: {},
    prescriptionId: viewData?.id,
    type: "",
  });
  useEffect(() => {
    setviewData(props.location.state);
    // eslint-disable-next-line
  }, []);
  const { t } = useTranslation("common");
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.prescriptiondetail")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/patient-dashboard", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>{" "}
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3  pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <h5>{t("dashboards.alldashboardkeys.tablekeys.doctordetail")}</h5>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.date")}
                </span>
                <p>{viewData?.date}</p>
              </li>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctorname")}
                </span>
                <p>{viewData?.doctor_details?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.doctoremail")}
                </span>
                <p>{viewData?.doctor_details?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.speciality")}
                </span>
                <p>{viewData?.doctor_details?.speciality?.map((item) => {
                  return (<><p>{item.name}</p></>)
                })}</p>
              </li>
            </ul>
            <div className="d-flex justify-content-between">
              <div className="col-lg-8">
                <h5>{t("dashboards.alldashboardkeys.tablekeys.medicinedetail")}</h5>
              </div>
              <div className="col-lg04">
                {viewData?.medicines?.length > 0 ? (
                  <DashboardBtnList
                    label={t("dashboards.alldashboardkeys.tablekeys.ordermedicinebtn")}
                    className="order-pay-btn"
                    onClick={() => {
                      setorderModalData({
                        show: true,
                        data: viewData?.medicines,
                        type: "medicine",
                      });
                    }}
                  />
                ) : null}
              </div>
            </div>
            {viewData?.medicines?.length > 0 ? (
              <>
                <ProfileTable
                  tableHead={[
                    t("dashboards.alldashboardkeys.tablekeys.name"),
                    t("dashboards.alldashboardkeys.tablekeys.morningdose"),
                    t("dashboards.alldashboardkeys.tablekeys.afternoondose"),
                    t("dashboards.alldashboardkeys.tablekeys.nightdose"),
                    t("dashboards.alldashboardkeys.tablekeys.duration"),
                  ]}
                  data={viewData?.medicines?.map((item) => {
                    return [
                      item?.medicine?.name,
                      item?.dose[0],
                      item?.dose[1],
                      item?.dose[2],
                      item?.duration + " (Days)",
                    ];
                  })}
                />
              </>
            ) : (
              <div className="text-center">{t("dashboards.alldashboardkeys.tablekeys.noprescription")}</div>
            )}

            <div className="d-flex justify-content-between">
              <div className="col-lg-8">
                <h5>{t("dashboards.alldashboardkeys.tablekeys.testdetails")}</h5>
              </div>
              <div className="col-lg04">
                {viewData?.tests?.length > 0 ? (
                  <DashboardBtnList
                    label={t("dashboards.alldashboardkeys.tablekeys.ordertestbtn")}
                    className="order-pay-btn"
                    onClick={() => {
                      setorderModalData({
                        show: true,
                        data: viewData?.tests,
                        prescriptionId: viewData?.id,
                        type: "test",
                      });
                    }}
                  />
                ) : null}
              </div>
            </div>
            {viewData?.tests?.length > 0 ? (
              <>
                <ProfileTable
                  tableHead={["ID",
                    t("dashboards.alldashboardkeys.tablekeys.name")
                    ,
                    t("dashboards.alldashboardkeys.tablekeys.price")
                  ]}
                  data={viewData?.tests?.map((item) => {
                    return [item?.test_id, item?.name, item?.price];
                  })}
                />
              </>
            ) : (
              <div className="text-center">{t("dashboards.alldashboardkeys.tablekeys.noprescriptiontest")}</div>
            )}
          </div>
        </div>
      </div>
      <BookOrderModal
        show={orderModalData?.show}
        viewData={orderModalData?.data}
        prescriptionId={orderModalData?.prescriptionId}
        type={orderModalData?.type}
        onHide={() => setorderModalData(false)}
        {...props}
      />
    </div>
  );
};

export default ViewPrescriptionDetails;

import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img1 from "../../Statics/Images/news/1.png";
import { useTranslation } from "react-i18next";
import img2 from "../../Statics/Images/news/2.jpg";
import img3 from "../../Statics/Images/news/3.jpg";
import img4 from "../../Statics/Images/news/4.jpg";
import img5 from "../../Statics/Images/news/5.png";
import img6 from "../../Statics/Images/news/6.jpg";
import img7 from "../../Statics/Images/news/7.jpg";
import img8 from "../../Statics/Images/news/8.jpg";
import img9 from "../../Statics/Images/news/9.jpg";
import img10 from "../../Statics/Images/news/10.jpg";
import img11 from "../../Statics/Images/news/11.png";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
const NewsInnerPages = (props) => {
  const { t, i18n } = useTranslation("common");

  const [newsdetailpage, setnewsdetailpage] = useState(
    props.location.state?.state
  );
  const lang = localStorage.getItem("lang");
  const componetcms = useSelector((state) => state.cms.components);
  const newscardanddetail = filterComponentData(
    componetcms,
    "news_card_and_detail",
    lang
  );
  const newspage = filterComponentData(
    componetcms,
    "news_and_media_heading",
    lang
  );

  const [newsdetailpageList, setnewsdetailpageList] =
    useState(newscardanddetail);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setnewsdetailpageList(newscardanddetail)
  // }, [dispatch])

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    const newscardanddetail = filterComponentData(
      componetcms,
      "news_card_and_detail",
      lang
    );
    setnewsdetailpageList(newscardanddetail);
  }, [newsdetailpage]);
  const newcarddata = [
    {
      img: img1,
      headingName: t("news.newscarddata.new1.head"),
      headingLink: "/news-details",
      new_date: "21 December 2020",
      listData: ["", ""],
      description:
        "We are pleased to introduce Bio Safety Level 3 facility in Bangladesh for the first time in the private sector.",
    },
    // {
    //   img: img2,
    //   headingName: t("news.newscarddata.new2.head"),
    //   headingLink: "/news-details",
    //   new_date: "20 December 2020",
    //   listData: [
    //     "HBV DNA",
    //     "HLA-B27",
    //     "Tropical Fever Panel",
    //     "MTB/MDR TB, PCR",
    //     "HCV RNA/Genotyping",
    //   ],
    //   description:
    //     "We are pleased to introduce Bio Safety Level 3 facility in Bangladesh for the first time in the private sector.",
    //   description1: (
    //     <p>
    //       Call on our helpline number- 10649
    //       <br />
    //       **Offer Valid Till 31st December, 2020
    //     </p>
    //   ),
    // },
    // {
    //   img: img3,
    //   headingName: t("news.newscarddata.new3.head"),
    //   headingLink: "/news-details",
    //   new_date: "5 August 2019",
    //   listData: ["", ""],
    //   description: (
    //     <>
    //       EW &nbsp; Villa Medica Bangladesh and Concord Pharmaceuticals{" "}
    //       <span>
    //         We are pleased to introduce Bio Safety Level 3 facility in
    //         Bangladesh for the first time in the private sector. DMFR has
    //         invested in state-of-art technology with Laboratory Information
    //         Solution and Enterprise resource programs for all the operations.
    //       </span>
    //       <br />
    //       Hotline for Appointment: +8809606213233
    //     </>
    //   ),
    // },
    {
      img: img4,
      headingName: t("news.newscarddata.new4.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img5,
      headingName: t("news.newscarddata.new5.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img6,
      headingName: t("news.newscarddata.new6.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img7,
      headingName: t("news.newscarddata.new7.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img8,
      headingName: t("news.newscarddata.new8.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img9,
      headingName: t("news.newscarddata.new9.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img10,
      headingName: t("news.newscarddata.new10.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },

    {
      img: img11,
      headingName: t("news.newscarddata.new11.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
  ];
  return (
    <>
      <Helmet>
        <title>News Details - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <div className="container title-wrap text-center">
        <div className="breadcrumb-content my-0">
          <a
            href="/news#news"
            className="mr-2 font-weight-bold"
            style={{
              color:
                window.location?.hash === "#news" ||
                window.location?.hash === ""
                  ? "red"
                  : "black",
            }}
          >
            {newspage?.news ? newspage?.news : t("news.tabheading2")}
          </a>
          <span style={{ color: "#0e5684" }}>|</span>
          <a
            href="/news#social_media"
            style={{ color: "black" }}
            className="ml-2 font-weight-bold"
          >
            {newspage?.socialmedia
              ? newspage?.socialmedia
              : t("news.tabheading1")}
          </a>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-8 ">
            <img
              src={newsdetailpage?.image ? newsdetailpage?.image : img1}
              alt="news"
              width="70%"
            />
            <div style={{ fontSize: "13px" }} className="mt-2">
              <span>
                <i className="fa fa-user" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;{newsdetailpage?.com  ? newsdetailpage?.com:'DMFR'}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <i className="fas fa-calendar-alt"></i> &nbsp;&nbsp;{" "}
                {newsdetailpage?.date ? (
                  newsdetailpage?.date
                ) :newsdetailpage?.new_date}
              </span>
            </div>
            <h5
              className="mt-3 ntitles"
              style={{
                textTransform: "none",
                marginBottom: "10px",
                color: "#0e5684",
              }}
            >
              {newsdetailpage?.detailHeading
                ? newsdetailpage?.detailHeading
                : "DMFR Molecular Lab &amp; Diagnostics"}
            </h5>

            <div>
              <span className="blue-heading" style={{ fontSize: "18px" }}>
                {newsdetailpage?.description ? (
                  newsdetailpage?.description
                ) : (
                  <>
                    DMFR Molecular Lab & Diagnostics, EW Villa Medica Bangladesh
                    and
                    <span className="banglafont">
                      We are pleased to introduce Bio Safety Level 3 facility in
                      Bangladesh for the first time in the private sector. DMFR
                      has invested in state-of-art technology with Laboratory
                      Information Solution and Enterprise resource programs for
                      all the operations. This system offers single-point
                      control for revenue transactions, patient inflow, sample
                      tracking, test volume, processing time, breakdown &
                      delays, lab results, decision intelligence, data
                      validation, turn-around time, dispatch efficiency and
                      more. Also, Molecular diagnostic testing and flow
                      cytometry testing are the most advanced procedures that
                      aid a host of critical investigations which ultimately
                      armed the physicians to take the decision regarding most
                      updated targeted therapies.
                    </span>
                  </>
                )}
              </span>
              {props?.listData || newscardanddetail?.listData? (
                <p className="descriptn_news">
                  {
                    <ul
                      style={{
                        padding: "0px",
                        margin: "0px",
                        paddingLeft: "16px",
                      }}
                    >
                      {newscardanddetail?.listData?.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  }
                  {/* {props.description1} */}
                </p>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: newsdetailpage?.["New Info"],
                  }}
                ></p>
              )}
            </div>
            <div
              style={{ fontSize: "15px", fontWeight: "bold" }}
              className="blue-heading mt-2"
            >
              {newsdetailpage?.hotline
                ? newsdetailpage?.hotline
                : "Hotline for Appointment"}
              <span>
                {newsdetailpage?.contact
                  ? newsdetailpage?.contact
                  : "+8809606213233"}
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="w-100">
              {/* <input
                type="text"
                name="username"
                id="username"
                placeholder="Search"
                required=""
                className="serachsect"
              /> */}
              <h5
                style={{
                  textTransform: "none",
                  borderBottom: "1px #ccc solid",
                  paddingBottom: "12px",
                  marginBottom: "12px",
                }}
              >
                <Link to="/news" style={{ color: "red " }}>
                  {newsdetailpage?.recentnews
                    ? newsdetailpage?.recentnews
                    : "Recent News"}
                </Link>
              </h5>
            </div>
            {newsdetailpageList?.length >1
              ? newsdetailpageList?.map((item, idx) =>
                  idx < 5 ? (
                    <>
                      <div className=" row w-100 pb-5">
                        <div
                          className="col-4"
                          style={{ background: "#f9f9f9", cursor: "pointer" }}
                          onClick={() => {
                            setnewsdetailpage(item);
                          }}
                        >
                          {/* <Link to="/news-details"> */}
                          <img
                            src={item?.image ? item?.image : img1}
                            width={100}
                            height={80}
                            alt="news"
                          />
                          {/* </Link> */}
                        </div>
                        <div
                          className="col-8"
                          style={{ background: "#f9f9f9" }}
                        >
                          <h6 className="blue-heading">
                            {item?.heading
                              ? item?.heading
                              : "DMFR Molecular Lab & Diagnostics"}
                          </h6>
                          <span style={{ fontSize: "13px" }}>
                            <i className="fas fa-calendar-alt"></i> &nbsp;&nbsp;
                            {item?.date ? item?.date : "2 Augest 2019"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null
                )
              : newcarddata?.map((item, idx) =>
                  idx < 5 ? (
                    <>
                      <div className=" row w-100 pb-5">
                        <div
                          className="col-4"
                          style={{ background: "#f9f9f9", cursor: "pointer" }}
                          onClick={() => {
                            setnewsdetailpage(item);
                          }}
                        >
                          {/* <Link to="/news-details"> */}
                          <img
                            src={item?.image ? item?.image : img1}
                            width={100}
                            height={80}
                            alt="news"
                          />
                          {/* </Link> */}
                        </div>
                        <div
                          className="col-8"
                          style={{ background: "#f9f9f9" }}
                        >
                          <h6 className="blue-heading">
                            {item?.headingName
}
                          </h6>
                          <span style={{ fontSize: "13px" }}>
                            <i className="fas fa-calendar-alt"></i> &nbsp;&nbsp;
                            {item?.new_date}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null
                )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsInnerPages;

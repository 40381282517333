import React, { useEffect, useState } from "react";
import "./header.css";
import bnFlag from "../../../Statics/icons/Flag-Bangladesh.jpg";
import saFlag from "../../../Statics/icons/Saudi_Arabia.png";
import pakFlag from "../../../Statics/icons/pakflag.png";

import enFlag from "../../../Statics/icons/uk.png";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import ReactFlagsSelect, { Bd } from "react-flags-select";

import {
  readLanguageAction,
  SelectedLanguage,
} from "../../../Store/Actions/cmsAction";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../../Utils/functions";
import { allarray } from "../navbar/NavMenuData";
import { Link } from "react-router-dom";
const HEader = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  // eslint-disable-next-line
  const [selected, setSelected] = useState("US");
  const [Local, setLocal] = useState([]);
  const [customlocal, setCustomlocal] = useState([]);
  const [customlabel, setCustomlabel] = useState();
  const lang = useSelector((state) => state?.cms?.languages);
  const componetcms = useSelector((state) => state.cms.components);
  const langs = useSelector((state) => state?.cms?.language);
  const navbar = filterComponentData(componetcms, "navbar", langs);

  let locvar =
    localStorage.getItem("lang") === null
      ? "US"
      : localStorage.getItem("lang").split("-")[1];
  useEffect(() => {}, [locvar]);

  const getids = () => {
    let localcode = [];
    let localcodebef = [];
    lang?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      let id0 = firword.split(id)[0];
      localcode?.push(id);
      localcodebef?.push(id0);
    });
    setLocal(localcode);
    setCustomlocal(localcodebef);
  };

  const customlabels = () => {
    let locallabel = {};
    lang?.map((item) => {
      const firword = item?.locale;
      let id = firword.split("-")[1];
      locallabel = {
        ...locallabel,
        [id]: item?.name,
      };
    });
    setCustomlabel(locallabel);
  };

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");
  const [langtoggle, setLangtoggle] = useState("en");
  useEffect(() => {
    dispatch(readLanguageAction());
    // dispatch(SelectedLanguage("en"))
  }, []);
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    const newFilter = allarray.filter((value) => {
      return value?.subLinkName
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  useEffect(() => {
    getids();
    customlabels();
  }, [lang]);
  useEffect(() => {
    setWordEntered('')
    setFilteredData([])
  }, [window.location.href])
  
  return (
    <>
      <div className="header d-none d-lg-flex">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-auto d-flex align-items-center">
              <div className="header-info">
                <i className="fas fa-map-marker-alt mr-1"></i>
                {navbar?.address ? navbar?.address : t("header.address")}
              </div>
              <a href="tel:09606213233" className="header-info cursor-pointer">
                <i className="fas fa-phone-alt mr-1"></i>
                {navbar?.contactnumber ? navbar?.contactnumber : "09606213233"}
              </a>

              <a
                href="mailto:info@mdxdmfr.com"
                className="header-info cursor-pointer"
              >
                <i className="fas fa-envelope mr-1"></i>
                {navbar?.email ? navbar?.email : "info@mdxdmfr.com"}
              </a>
              <div className="header-search">
                <i className="fas fa-search search-icon"></i>
                <input
                  type="text"
                  placeholder="Search"
                  value={wordEntered}
                  onChange={handleFilter}
                />
                <button type="submit">
                  <i className="fas fa-search search-icon"></i>
                </button>
                <div className="input-group-append">
                  {filteredData.length === 0 ? <></> : <></>}
                </div>
              </div>
              {filteredData.length !== 0 && (
                <div className="dataResult">
                  {filteredData?.map((value, key) => {
                    if (value.subLinkName === "Report Portal") {
                      return (
                        <a
                          className="dataItem"
                          href={value.subLink}
                          target="blank"
                        >
                          <p>{value?.subLinkName}</p>
                        </a>
                      );
                    } else if (value.subLinkName === "Download Brochure")
                      return (
                        <a className="dataItem" href={value.subLink} download>
                          <p>{value?.subLinkName}</p>
                        </a>
                      );
                    else if (value.subLinkName === "Covid 19 Live Updates")
                      return (
                        <a
                          className="dataItem"
                          style={{ textDecoration: "none" }}
                          href={value.subLink}
                          target="blank"
                        >
                          <p>{value?.subLinkName}</p>
                        </a>
                      );
                    else {
                      return (
                        <Link
                          to={value.subLink}
                          style={{ textDecoration: "none" }}
                          className="dataItem"
                        >
                          <p>{value.subLinkName}</p>
                        </Link>
                      );
                    }
                  })}
                </div>
              )}
            </div>
            <div className="country-drop">
              <div className="dropdown mt-1">
                <ReactFlagsSelect
                  countries={Local}
                  selected={locvar}
                  optionsSize={13}
                  selectedSize={13}
                  customLabels={customlabel}
                  onSelect={(code) => {
                    localStorage.removeItem("lang");
                    dispatch(
                      SelectedLanguage(
                        lang.filter((item) => item?.locale.includes(code))[0]
                          ?.locale
                      )
                    );
                    localStorage.setItem(
                      "lang",
                      lang.filter((item) => item?.locale.includes(code))[0]
                        ?.locale
                    );
                    setSelected(code);
                  }}
                />
              </div>
            </div>

            {/* <span className="header-social d-flex flex-row  ml-0">
              <a
                className="header-icon-item d-none d-xl-flex mr-1"
                href="https://www.facebook.com/dmfrmolecularlab.bd/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "black" }}
              >
                <i className="fab fa-facebook-f"></i>
              </a>

              <div className="header-icon-item  d-none d-xl-flex mr-1">
                <i className="fab fa-instagram"></i>
              </div>
              <div className="header-icon-item  d-none d-xl-flex mr-1">
                <i className="fab fa-twitter"></i>
              </div>
              <div className="header-icon-item  d-none d-xl-flex mr-1">
                <i className="fab fa-youtube"></i>
              </div>
            </span> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default HEader;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ActionBtn from "../../Components/ReUseableComponents/ActionBtn";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtn from "../../Components/ReUseableComponents/DashboardBtn";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import Loader from "../../Components/ReUseableComponents/Loader";
import { myMedicalRecords } from "../../Store/Actions/patientActions";
const DoctorsPatientMedicalRecords = (props) => {
    const id = props.location.state
    const { t } = useTranslation("common")
    const [selectedTab, setselectedTab] = useState("Prescription");
    const loader = useSelector((state) => state?.patient?.patientLoader);
    const medicalRecords = useSelector((state) => state?.patient?.medicalRecords);
    const [tableData, settableData] = useState([]);
    const dispatch = useDispatch();
    let tableDataFormatPrescription = [];
    medicalRecords?.prescriptions?.map((item, index) =>
        tableDataFormatPrescription.push({
            sr: index,
            medical_record_id: item?.medical_record_id,
            created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
            prescription_url: item?.prescription_url
        })
    );
    let tableDataFormatReports = [];
    medicalRecords?.reports?.map((item, index) =>
        tableDataFormatReports.push({
            sr: index,
            medical_record_id: item?.medical_record_id,
            created_at: moment.unix(item?.created_at)?.format("YYYY-MM-DD"),
            report_url: item?.report_url
        })
    );
    useEffect(() => {
        dispatch(myMedicalRecords({ id: id }));
        settableData(tableDataFormatPrescription);
        // eslint-disable-next-line
    }, [dispatch]);
    useEffect(() => {
        settableData(tableDataFormatPrescription);

    }, [medicalRecords])
    const tabHandler = (item) => {
        setselectedTab(item);
        if (item === "Prescription") {
            settableData([])
            settableData(tableDataFormatPrescription);
        } else {
            settableData([])
            settableData(tableDataFormatReports);
        }
    };

    const handleChange = (value, data) => {
        if (value == "View") {

            if (selectedTab === "Prescription") {
                window.open(data?.prescription_url);
                window.focus();
            }
            if (selectedTab === "Report") {
                window.open(data?.report_url);
                window.focus();
            }
        }
    };
    // "Report"
    return (
        <>
            <div style={{ backgroundColor: "#f9f9f9" }}>
                <DashBoardMainHading title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")} />
                <div className="container"> <div className="row ">
                    <div className="col-xl-8 col-md-8 col-12 px-0 mx-5">
                        <div
                            className="btn edit-patient-btn"
                            style={{ width: "150px" }}
                            onClick={() => {
                                props.history.push("/doctor-dashboard");
                            }}
                        >
                            <i className="fas fa-arrow-left"></i> {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
                        </div>
                    </div>
                </div></div>
                <div className="row center-childrens mt-3 pb-5 center-childrens">
                    <div className="col-xl-8 col-md-8 col-12  p-4">
                        <div className="w-100">
                            <div className="">
                                <h4 className="patient-dashboard-heading">{"Medical Records"}</h4>
                            </div>
                            <div className="row px-3 mb-3">
                                {["Prescription", "Report"]?.map((item, index) => (
                                    <div
                                        className="d-flex d-inline-flex col-lg-3 col-12 p-0 pr-3 mt-3 "
                                        key={index + 1}
                                        onClick={() => tabHandler(item)}
                                    >
                                        <DashboardBtnList
                                            label={item}
                                            labelStyle={selectedTab === item ? { color: "#fff" } : ""}
                                            className={
                                                selectedTab === item
                                                    ? "dashboardBtnList-item-active py-2"
                                                    : "default-color-and-hover py-2"
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                            {loader ? (
                                <Loader />
                            ) : (
                                <>
                                    <BootstrapCustomTable
                                        columns={[
                                            {
                                                formatter: (cell, row, index) => {
                                                    return <span>{index + 1}</span>;
                                                },
                                                dataField: "sr.",
                                                text: "Sr.",
                                            },
                                            { dataField: "medical_record_id", text: "Record Id" },
                                            { dataField: "created_at", text: t("dashboards.alldashboardkeys.tablekeys.date") },
                                            {
                                                formatter: (cell, row, index) => {
                                                    return (
                                                        <ActionBtn
                                                            options={[t("dashboards.alldashboardkeys.tablekeys.viewbtn")]}
                                                            handleChange={handleChange}
                                                            data={row}
                                                        />

                                                    );
                                                },
                                                text: t("dashboards.alldashboardkeys.tablekeys.action"),

                                            },
                                        ]}
                                        tabledata={tableData || []}
                                    />
                                </>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorsPatientMedicalRecords;

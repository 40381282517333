import React from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import covidInfo from "../../Statics/Images/banner/covid_info.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { filterComponentData } from "../../Utils/functions";
import { useSelector } from "react-redux";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const Covid19Info = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language);
  const covid19info = filterComponentData(componetcms, "covid19info", lang);
  const banner = filterComponentData(componetcms, "banners", lang);
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[1]?.sublink1 ?banner[1]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[1]?.sublink2 ?banner[1]?.sublink2:"Covid 19",
      subDash: ">",
      subLink: "/covid-19-info",
    },
    {
      subLinkName: banner[1]?.sublink3 ?banner[1]?.sublink3:"Covid 19 Info",
      subDash: ">",
      subLink: "/covid-19-info",
    },
    
   
  ];
  return (
    <>
      <Helmet>
        <title>{t("covid19info.maintitle")}</title>
      </Helmet>

      {/* img banner */}
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[1]?.image ? banner[1]?.image : BannerImg})`}
        heading={banner[1]?.heading ? banner[1]?.heading : "Covid 19 Info"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {covid19info?.text1 ? covid19info?.text1 : t("covid19info.text1")}
              <br />
              {covid19info?.text2 ? covid19info?.text2 : t("covid19info.text2")}
            </p>
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-lg-6 col-md-12">
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {covid19info?.text3 ? covid19info?.text3 : t("covid19info.text3")}
            </p>
            <div className="covidbox mt-4">
              <strong className="covid-info-list-heading">
                {covid19info?.text4head
                  ? covid19info?.text4head
                  : t("covid19info.text4head")}
              </strong>
              <ul className="covid-info-list light-Color-Para">
                <li>
                  {covid19info?.t4list1
                    ? covid19info?.t4list1
                    : t("covid19info.t4list1")}
                </li>
                <li>
                  {covid19info?.t4list2
                    ? covid19info?.t4list2
                    : t("covid19info.t4list1-2")}
                </li>
                <li>
                  {covid19info?.t4list3
                    ? covid19info?.t4list3
                    : t("covid19info.t4list2")}
                </li>
                <li>
                  {covid19info?.t4list4
                    ? covid19info?.t4list4
                    : t("covid19info.t4list3")}
                </li>
                <li>
                  {covid19info?.t4list5
                    ? covid19info?.t4list5
                    : t("covid19info.t4list4")}
                </li>
                <li>
                  {covid19info?.t4list6
                    ? covid19info?.t4list6
                    : t("covid19info.t4list5")}
                </li>
                <li>
                  {covid19info?.t4list7
                    ? covid19info?.t4lis7
                    : t("covid19info.t4list6")}
                </li>
                <li>
                  {covid19info?.t4list8
                    ? covid19info?.t4list8
                    : t("covid19info.t4list7")}
                </li>
                <li>
                  {covid19info?.t4list9
                    ? covid19info?.t4list9
                    : t("covid19info.t4list8")}
                </li>
                <li>
                  {covid19info?.t4list10
                    ? covid19info?.t4list10
                    : t("covid19info.t4list9")}
                </li>
                <li>
                  {covid19info?.item11
                    ? covid19info?.item11
                    : t("covid19info.t4list10")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {covid19info?.text5 ? covid19info?.text5 : t("covid19info.text5")}
            </p>
            <div className=" covidbox">
              <strong className="covid-info-list-heading">
                {" "}
                {covid19info?.text6head
                  ? covid19info?.text6head
                  : t("covid19info.text6head")}
              </strong>
              <ul className="covid-info-list light-Color-Para">
                <li>
                  {covid19info?.t6list1
                    ? covid19info?.t6list1
                    : t("covid19info.t6list1")}
                </li>
                <li>
                  {covid19info?.t6list2
                    ? covid19info?.t6list2
                    : t("covid19info.t6list2")}
                </li>
                <li>
                  {covid19info?.t6list3
                    ? covid19info?.t6list3
                    : t("covid19info.t6list3")}
                </li>
                <li>
                  {covid19info?.t6list4
                    ? covid19info?.t6list4
                    : t("covid19info.t6list4")}
                </li>
                <li>
                  {covid19info?.t6list5
                    ? covid19info?.t6list5
                    : t("covid19info.t6list5")}
                </li>
                <li>
                  {covid19info?.t6list6
                    ? covid19info?.t6list6
                    : t("covid19info.t6list6")}
                </li>
                <li>
                  {covid19info?.t6list7
                    ? covid19info?.t6list7
                    : t("covid19info.t6list7")}
                </li>
                <li>
                  {covid19info?.t6list8
                    ? covid19info?.t6list8
                    : t("covid19info.t6list8")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p
              style={{
                fontFamily: "Work Sans', sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
                textAlign: "justify",
              }}
              className="light-Color-Para"
            >
              {covid19info?.text7 ? covid19info?.text7 : t("covid19info.text7")}
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-6 col-md-12">
            <div className="covidbox2">
              <p className="covid-info-list-heading">
                <strong className="covid-info-list-heading">
                  {covid19info?.text8head
                    ? covid19info?.text8head
                    : t("covid19info.text8head")}
                </strong>
                <br />
                {covid19info?.text8
                  ? covid19info?.text8
                  : t("covid19info.text8")}
              </p>

              <ul className="covid-info-list light-Color-Para">
                <li>
                  {covid19info?.t8list1
                    ? covid19info?.t8list1
                    : t("covid19info.t8list1")}
                </li>
                <li>
                  {covid19info?.t8list2
                    ? covid19info?.t8list2
                    : t("covid19info.t8list2")}
                </li>
                <li>
                  {covid19info?.t8list3
                    ? covid19info?.t8list3
                    : t("covid19info.t8list3")}
                </li>
                <li>
                  {covid19info?.t8list4
                    ? covid19info?.t8list4
                    : t("covid19info.t8list4")}
                </li>
                <li>
                  {covid19info?.t8list5
                    ? covid19info?.t8list5
                    : t("covid19info.t8list5")}
                </li>
                <li>
                  {covid19info?.t8list6
                    ? covid19info?.t8list6
                    : t("covid19info.t8list6")}
                </li>
                <li>
                  {covid19info?.t8list7
                    ? covid19info?.t8list7
                    : t("covid19info.t8list7")}
                </li>
                <li>
                  {covid19info?.t8list8
                    ? covid19info?.t8list8
                    : t("covid19info.t8list8")}
                </li>
                <li>
                  {covid19info?.t8list9
                    ? covid19info?.t8list9
                    : t("covid19info.t8list9")}
                </li>
                <li>
                  {covid19info?.t8list10
                    ? covid19info?.t8list10
                    : t("covid19info.t8list10")}
                </li>
                <li>
                  {covid19info?.t8list11
                    ? covid19info?.t8list11
                    : t("covid19info.t8list11")}
                </li>
                <li>
                  {covid19info?.t8list12
                    ? covid19info?.t8list12
                    : t("covid19info.t8list12")}
                </li>
                <li>
                  {covid19info?.t8list13
                    ? covid19info?.t8list13
                    : t("covid19info.t8list13")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="covidbox2">
              <p className="covid-info-list-heading">
                <strong>
                  {covid19info?.text9head
                    ? covid19info?.text9head
                    : t("covid19info.text9head")}
                </strong>
                <br />
                {covid19info?.text9
                  ? covid19info?.text9
                  : t("covid19info.text9")}
              </p>

              <ul className="covid-info-list light-Color-Para">
                <li>
                  {covid19info?.t10list1
                    ? covid19info?.t10list1
                    : t("covid19info.t10list1")}
                </li>
                <li>
                  {covid19info?.t10list2
                    ? covid19info?.t10list2
                    : t("covid19info.t10list2")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Covid19Info;

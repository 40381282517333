import React, { useEffect } from "react";
import onlineconsbanner from "../../Statics/Images/banner/onlineconsbanner.jpg";
import CovidTeamCard from "../LayOuts/Cards/covidTeamCard/CovidTeamCard";
import BannerImg from "../ReUseableComponents/BannerImg";

import OnlineconsultantImg1 from "../../Statics/Images/doctors/Dr-Chowdhury-Muhammad-Fuad-Galib.jpg";
import OnlineconsultantImg3 from "../../Statics/Images/doctors/Dr-Faizunnessa-Irin.jpg";
import OnlineconsultantImg6 from "../../Statics/Images/doctors/Dr-Mahbub-Hasan-Mehedi.jpg";
import OnlineconsultantImg7 from "../../Statics/Images/doctors/Dr-Maria-Islam.jpg";
import OnlineconsultantImg8 from "../../Statics/Images/doctors/Dr-Mohammad-Waheduzzaman.jpg";
import OnlineconsultantImg9 from "../../Statics/Images/doctors/Dr-Muhibbur-Rahman-Rafe.jpg";
import OnlineconsultantImg10 from "../../Statics/Images/doctors/Dr-Narwana-Khaleque.jpg";
import OnlineconsultantImg11 from "../../Statics/Images/doctors/Dr-Nuruzzaman2.jpg";
import OnlineconsultantImg12 from "../../Statics/Images/doctors/Dr-Samia-Tasneem.jpg";
import OnlineconsultantImg13 from "../../Statics/Images/doctors/Dr-Saroat-Sultana-1.jpg";
import OnlineconsultantImg14 from "../../Statics/Images/doctors/Md-Jamil-Reza-Choudhury.jpg";
import OnlineconsultantImg15 from "../../Statics/Images/doctors/Md-Mahbubur.jpg";
import OnlineconsultantImg16 from "../../Statics/Images/doctors/Prof-Dr-M-A-Khan.jpg";
import OnlineconsultantImg17 from "../../Statics/Images/doctors/Prof-Dr-Shahjahan.jpg";
import OnlineconsultantImg18 from "../../Statics/Images/doctors/Rashed-Jahangir-Kabir.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import { fetchPublicDoctor } from "../../Store/Actions/staticActions";
import OnlineConsultantCard from "../LayOuts/Cards/onlineConsultantCard/OnlineConsultantCard";
import Loader from "../ReUseableComponents/Loader";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const Onlineconsultation = () => {
  const componetcms = useSelector((state) => state.cms.components);
  const { publicDoctor } = useSelector((state) => state.static);
  const loading = useSelector((state) => state?.static?.loader);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPublicDoctor());
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const consultantCom = filterComponentData(
    componetcms,
    "onlineconsultation",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);

  const { t, i18n } = useTranslation("common");
  const OnlineconsultantsData = [
    {
      imgSrc: OnlineconsultantImg16,
      defaultname: "drma",
      label: t("onlineconsultants.onlineconsultantdata.consultant1.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant1.details"),
    },

    {
      imgSrc: OnlineconsultantImg15,
      defaultname: "drmdmah",
      label: t("onlineconsultants.onlineconsultantdata.consultant2.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant2.details"),
    },

    {
      imgSrc: OnlineconsultantImg13,
      defaultname: "drSaroat",
      label: t("onlineconsultants.onlineconsultantdata.consultant3.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant3.details"),
    },

    {
      imgSrc: OnlineconsultantImg6,
      defaultname: "drmahbub",
      label: t("onlineconsultants.onlineconsultantdata.consultant4.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant4.details"),
    },

    {
      imgSrc: OnlineconsultantImg17,
      defaultname: "drshahjahan",
      label: t("onlineconsultants.onlineconsultantdata.consultant5.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant5.details"),
    },
    //
    {
      imgSrc: OnlineconsultantImg18,
      defaultname: "drjhangir",
      label: t("onlineconsultants.onlineconsultantdata.consultant6.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant6.details"),
    },
    //
    {
      imgSrc: OnlineconsultantImg14,
      defaultname: "drchoudary",
      label: t("onlineconsultants.onlineconsultantdata.consultant7.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant7.details"),
    },

    //
    {
      imgSrc: OnlineconsultantImg8,
      defaultname: "drmahammadwahed",
      label: t("onlineconsultants.onlineconsultantdata.consultant8.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant8.details"),
    },
    //

    {
      imgSrc: OnlineconsultantImg7,
      defaultname: "drmaria",
      label: t("onlineconsultants.onlineconsultantdata.consultant9.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant9.details"),
    },
    //
    {
      imgSrc: OnlineconsultantImg9,
      defaultname: "drmahibburrahman",
      label: t("onlineconsultants.onlineconsultantdata.consultant10.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant10.details"),
    },
    {
      imgSrc: OnlineconsultantImg10,
      defaultname: "drnarwana",
      label: t("onlineconsultants.onlineconsultantdata.consultant11.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant11.details"),
    },

    //

    {
      imgSrc: OnlineconsultantImg1,
      defaultname: "drchowdhurymuhammad",
      label: t("onlineconsultants.onlineconsultantdata.consultant12.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant12.details"),
    },
    //

    {
      imgSrc: OnlineconsultantImg12,
      defaultname: "drsaima",
      label: t("onlineconsultants.onlineconsultantdata.consultant13.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant13.details"),
    },

    {
      imgSrc: OnlineconsultantImg11,
      defaultname: "drnuruzzaman",
      label: t("onlineconsultants.onlineconsultantdata.consultant14.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant14.details"),
    },

    {
      imgSrc: OnlineconsultantImg3,
      defaultname: "drfaizun",
      label: t("onlineconsultants.onlineconsultantdata.consultant15.name"),
      details: t("onlineconsultants.onlineconsultantdata.consultant15.details"),
    },
  ];
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[13]?.sublink1 ? banner[13]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[13]?.sublink2 ? banner[13]?.sublink2 : "Services",
      subDash: ">",
      subLink: "/online-consultants",
    },
    {
      subLinkName: banner[13]?.sublink3
        ? banner[13]?.sublink3
        : "Online Consultation",
      subDash: ">",
      subLink: "/online-consultants",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("onlineconsultants.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[13]?.image ? banner[13]?.image : onlineconsbanner
          })`}
          heading={
            banner[13]?.heading ? banner[13]?.heading : "Online Consultant"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

      <div className="container">
        <div className="row mt-5">
          {loading ? (
            <Loader />
          ) : (
            publicDoctor?.map((item, index) => (
              <div className="col-lg-3 col-sm-6 center-childrens" key={index}>
                <OnlineConsultantCard
                  imgSrc={item?.display_picture}
                  label={item?.name}
                  defaultname={item?.name}
                  details={item?.designation}
                  data={item}
                  btnlinks={"/online-consultant-detail"}
                />
              </div>
            ))
          )}
          {}
        </div>
      </div>
    </>
  );
};

export default Onlineconsultation;

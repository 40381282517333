import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { getMyPatient } from "../../Store/Actions/docotorActions";

const MyPatient = (props) => {
  const { t, i18n } = useTranslation("common")
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.logIn?.loader);
  const user = useSelector((state) => state?.logIn?.user);
  const myPatient = useSelector((state) => state?.doctor?.myPatient);
  useEffect(() => {
    const apiData = {
      id: user?.id,
    };
    dispatch(getMyPatient(apiData));

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">My Patients</h4>
        </div>
      </div>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "name", text: t("dashboards.doctordashboard.mypatients.tablekeys.name") },
              { dataField: "phone_number", text: t("dashboards.doctordashboard.mypatients.tablekeys.phone") },
              { dataField: "email_address", text: t("dashboards.doctordashboard.mypatients.tablekeys.email") },
              {
                formatter: (cell, row) => {
                  return (
                    <div className="row">
                      <div className="col-lg-6">
                        <DashboardBtnList
                          label={t("dashboards.doctordashboard.appointment.todayappointment.tablecolheader.viewdetail")}
                          className="order-pay-btn"
                          routePath="/view-appointment"
                          onClick={() => {
                            props?.history?.push("/patient-details", row);
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <DashboardBtnList
                          label={"Record"}
                          className="order-pay-btn"
                          routePath="/view-appointment"
                          onClick={() => {
                            props?.history?.push("/patient-medical-records", row?.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                },
                text: t("dashboards.doctordashboard.mypatients.tablekeys.action"),
              },
            ]}
            tabledata={myPatient?.length > 0 ? myPatient : []}
          />
        )}
      </div>
    </div>
  );
};

export default MyPatient;

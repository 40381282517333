import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { administratorConstants } from "../Constants/administratorConstants";
import { signInConstansts } from "../Constants/loginConstant";
import i18next from "i18next";


export const getadministratorPatients = (data) => async (dispatch) => {

  try {
    const response = await apiServices.getadministratorPatients(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: administratorConstants.PATIENTS,
        payload: response?.data?.response_data?.patient,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const getadministratorDoctor = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    const response = await apiServices.getadministratorDoctor(data);
    if (response?.data?.response_code === 200) {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      dispatch({
        type: administratorConstants.DOCTOR,
        payload: response?.data?.response_data?.doctor,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};
export const removerDoctotByAdmin = (data, id) => async (dispatch) => {

  try {
    const response = await apiServices.removerDoctotByAdmin(data);
    if (response?.data?.response_code === 200) {
      dispatch(getadministratorDoctor());
      toast.info(i18next.t("common:toastmessages.doctordeletedrequestsent"));
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const AdministratorAddDoctorSlots = (data) => async (dispatch) => {

  try {
    const response = await apiServices.AdministratorAddDoctorSlots(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.slotaddedsuccess"));
      dispatch(getadministratorDoctor());
    } else {
      toast.error(response?.response_message)
    }
  } catch (error) {
    console.log("Slots Added", error);
  }
};

export const getdoctorRescheduleRequest = () => async (dispatch) => {
  const response = await apiServices.getdoctorRescheduleRequest({});
  if (response?.data?.response_code === 200) {
    let data = response?.data?.response_data?.request?.filter((item) => item?.action === "reschedule")
    dispatch({
      type: administratorConstants.DOCTOR_REQUESTS,
      payload: data,
    });
  }
};

export const acceptAppointmentAction = (data) => async (dispatch) => {
  let res = await apiServices.requestApproval(data);
  if (res?.response_code === 200) {
    dispatch({
      type: administratorConstants.UPDATE_RESCHEDULE_REQUEST,
      payload: res?.response_data?.request,
    });
    toast.info("Successfully Rescheduled", "Success");
    dispatch(getdoctorRescheduleRequest());

  } else {
    toast.error(res?.response_message, "Error");
  }
};
export const rejectAppointmentAction = (data) => async (dispatch) => {
  let res = await apiServices.requestApproval(data);
  if (res?.response_code === 200) {
    dispatch({
      type: administratorConstants.UPDATE_RESCHEDULE_REQUEST,
      payload: res?.response_data?.request,
    });
    toast.info("Successfully Rejected", "Success");
    dispatch(getdoctorRescheduleRequest());

  } else {
    toast.error(res?.response_message, "Error");
  }
};

export const getOrdersByEmail = (data) => async (dispatch) => {
  try {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: true,
    });
    let res = await apiServices.getOrdersByEmail(data);
    if (res?.data?.response_code === 200) {
      dispatch({
        type: administratorConstants.ORDER_BY_EMAIL,
        payload: res?.data?.response_data?.order,
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    } else {
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
      toast?.error(res?.data?.response_message);
    }
  } catch (error) {
    console.log(error);
  }
  // if (res?.response_code === 200) {
  //   dispatch({
  //     type: administratorConstants.UPDATE_RESCHEDULE_REQUEST,
  //     payload: res?.response_data?.request,
  //   });
  //   toast.info("Accepted Successfully");
  //   dispatch(getdoctorRescheduleRequest());
  // } else {
  //   toast.error(res?.response_message, "Error");
  // }
};


export const readTargetAction = () => async (dispatch) => {
  let res = await apiServices.readTarget();
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  if (res?.data?.response_code === 200) {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    dispatch({
      type: administratorConstants.READ_TARGET,
      payload: res?.data?.response_data?.target,
    });

  } else {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    toast.error(res?.data?.response_message, "Error")
  }
};
export const readBdeAction = () => async (dispatch) => {
  let res = await apiServices.readBDE();
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  if (res?.data?.response_code === 200) {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    dispatch({
      type: administratorConstants.READ_BDE,
      payload: res?.data?.response_data?.business_development_executive,
    });

  } else {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    toast.error(res?.data?.response_message, "Error")
  }
};

export const AssignBdeTargetAction = (data) => async (dispatch) => {
  let res = await apiServices.AssignBDETarget(data);
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  if (res?.data?.response_code === 200) {
    dispatch(readTargetAction())
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    dispatch({
      type: administratorConstants.ASSIGN_TARGET,
      payload: res?.data?.response_data?.target,
    });
    return true
  } else {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    toast.error(res?.data?.response_message, "Error")
    return false

  }
};
export const UpdateBdeTargetAction = (data) => async (dispatch) => {
  let res = await apiServices.UpdateBDETarget(data);
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  if (res?.response_code === 200) {
    dispatch(readTargetAction())
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    dispatch({
      type: administratorConstants.UPDATE_TARGET,
      payload: res?.response_data?.target,
    });
    return true
  } else {
    dispatch({
      type: signInConstansts.SET_LOADER,
      payload: false,
    });
    toast.error(res?.response_message, "Error")
    return false

  }
};

export const getreturnRequest = () => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.readreturnRequest();

    if (response?.data?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      dispatch({

        type: administratorConstants.RETURN_REQUEST,

        payload: response?.data?.response_data?.request,

      });

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

    }

  } catch (error) {

    console.log("Login error", error);

  }

};

export const approvereturnRequest = (data) => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.updatereturnRequest(data);

    if (response?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      return true

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(response?.response_message)

      return false


    }

  } catch (error) {

    console.log("Login error", error);

  }

};





export const readTodaysAppointmentAction = () => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    let res = await apiServices.getTodaysAppoinments();


    if (res?.data?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      dispatch({

        type: administratorConstants.TODAYS_APPOINTMENT,

        payload: res?.data?.response_data?.appointments,

      });


    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(res?.data?.response_message, "Error")

    }

  } catch (e) {


  }


};

export const readUpcomingAppointmentAction = () => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    let res = await apiServices.getUpcomingAppoinments();


    if (res?.data?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      dispatch({

        type: administratorConstants.UPCOMING_APPOINTMENT,

        payload: res?.data?.response_data?.appointments,

      });


    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(res?.data?.response_message, "Error")

    }

  } catch (e) {


  }


};

export const readPastAppointmentAction = () => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    let res = await apiServices.getPastAppoinments();


    if (res?.data?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      dispatch({

        type: administratorConstants.PAST_APPOINTMENT,

        payload: res?.data?.response_data?.appointments,

      });


    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(res?.data?.response_message, "Error")

    }

  } catch (e) {


  }


};



export const cancelAppointmentAdmin = (data) => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.cancellAppointment(data);

    if (response?.response_code === 200) {


      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      return true

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(response?.response_message, "Error")

      return false

    }

  } catch (error) { }

};

export const cancelOrderAdmin = (data) => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.cancellOrder(data);

    if (response?.response_code === 200) {


      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });
      toast.success(response?.response_message)

      return true

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(response?.response_message, "Error")

      return false

    }

  } catch (error) { }

};




export const getPartialPaymentRequest = () => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.readPartialPaymentRequest();

    if (response?.data?.response_code === 200) {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      dispatch({

        type: administratorConstants.PARTIAL_PAYEMNT_REQUEST,

        payload: response?.data?.response_data?.request,

      });

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

    }

  } catch (error) {

    console.log("Login error", error);

  }

};




export const cancelPartialOrderRequest = (data) => async (dispatch) => {

  try {

    dispatch({

      type: administratorConstants.ADMINISTRATOR_LOADER,

      payload: true,

    });

    const response = await apiServices.updatePartialPaymentRequest(data);

    if (response?.response_code === 200) {
      dispatch(getPartialPaymentRequest())


      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      return true

    } else {

      dispatch({

        type: administratorConstants.ADMINISTRATOR_LOADER,

        payload: false,

      });

      toast.error(response?.response_message, "Error")

      return false

    }

  } catch (error) { }

};
import React from "react";
import "./fixedRight.css";
const FixedRightEndIcon = () => {
  return (
    <div className="row no-gutters right-main d-none d-lg-block">
      <div className="avatar-whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=01958316369&amp;text=Say%20Hello!&amp;source=&amp;data="
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>
      <div
        className="backToTop js-backToTop visible"
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
      >
        <i className="fas fa-arrow-up" style={{fontSize:'14px'}}></i>
      </div>
    </div>
  );
};

export default FixedRightEndIcon;

import React from "react";
import "./clientSlider.css";
import Slider from "react-slick";
const ClientSlider = ({ data, client }) => {
  var settings = {
    speed: 3000,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    infinite: true,
    autoplay: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const imagesData = Object.keys(data);
  return (
    <div className="container" style={{overflowX: "hidden"}}>
      <div className="mt-5 py-5 clientSliderParent ">
        <Slider {...settings}>
          {client?.length < 1 ? imagesData.map((item, index) => (
            <div className="client-card center-childrens mx-2" key={index + 1}>
              <img
                src={data[item].default}
                alt="Client Slider"
                style={{ width: "178px", height: "126px" }}
              />
            </div>
          )) : client?.map((item, index) => (
            <div className="client-card center-childrens mx-2" key={index + 1}>
              <img
                src={item?.image}
                alt="Client Slider"
                style={{ width: "178px", height: "126px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSlider;

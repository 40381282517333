import { t } from 'i18next'

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'
import RefundPartialPackageOrder from '../../Components/LayOuts/modals/refundPartialPackage/refundPartialPackageOrder'

import ActionBtn from '../../Components/ReUseableComponents/ActionBtn'

import BootstrapCustomTable from '../../Components/ReUseableComponents/BootstrapCustomTable'

import LoderContainer from '../../Components/ReUseableComponents/Loader'

import { approvereturnRequest, cancelPartialOrderRequest, getPartialPaymentRequest, getreturnRequest } from '../../Store/Actions/administratorActions'


export default function AdministratorPartialPaymentReq(props) {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const [id, setID] = useState("")
    const loader = useSelector((state) => state.administrator.administrator_loader)
    const request = useSelector((state) => state.administrator.partialPaymentReq);
    const filteredReq = request?.filter((it) => it?.action == "refund");
    useEffect(() => {

        dispatch(getPartialPaymentRequest())

    }, [dispatch])


    const handleChange = async (value, item) => {
        if (value == "Refund") {
            setShow(true)
            setID(item?.id)
        }

        else {
            if (value == "No Refund") {
                let apiData = {
                    refunded_amount: 0,
                    id: item?.id,
                    status: { id: 3, name: 'rejected' }
                }
                let res = await dispatch(cancelPartialOrderRequest(apiData))
                if (res) {
                    toast.success("Successfully Cancelled")

                }
            }
        }

    };

    return (
        <>
            <div>

                <div className="row mt-4">

                    <div className="col">

                        <h4 className="patient-dashboard-heading">{"Request"}</h4>


                    </div>

                </div>

                {loader ? (

                    <LoderContainer />

                ) : (

                    <>

                        <div className="row">

                            <div className="col-12">

                                <BootstrapCustomTable

                                    columns={[

                                        { dataField: "action", text: "Action" },


                                        { dataField: "request_id", text: "Record Id" },

                                        { dataField: "requested_by.name", text: "Request By Name" },


                                        { dataField: "requested_by.email_address", text: "Request By Email" },
                                        { dataField: "record.total_amount", text: "Amount" },
                                        { dataField: "record.remaining_amount", text: "Remaining Amount" },
                                        { dataField: "record.no_of_installments", text: "No Of Installments" },
                                        {

                                            formatter: (cell, row, index) => {

                                                return (

                                                    <span

                                                        style={{

                                                            color: row?.status.name == "pending" ? 'green' : 'red',

                                                        }}

                                                    >

                                                        {row?.status.name}

                                                    </span>

                                                );

                                            },

                                            text: "Status",

                                        },

                                        {

                                            formatter: (cell, row, index) => {


                                                return (


                                                    <ActionBtn


                                                        options={row?.status.name == "pending" ? [

                                                            "Refund",
                                                            "No Refund"

                                                        ] : []}

                                                        handleChange={handleChange}

                                                        data={row}

                                                    />


                                                );

                                            },

                                            text: "Actions",

                                        },


                                    ]}

                                    tabledata={filteredReq}

                                />

                            </div>

                        </div>

                    </>

                )}


            </div>
            <RefundPartialPackageOrder

                show={show}

                id={id}

                onHide={() => setShow(false)}

            />
        </>
    )

}


import localStore from "./localstore.util";
import { updateHeaders } from "../services/HttpProvider";

export const getToken = () => localStore.get_data("token");

export const setToken = (token) => localStore.store_data("token", token);
export const setUserRole = (token) => localStore.store_data("userRole", token);
export const getUser = () => localStore.get_data("user");

export const saveUser = (user) => localStore.store_data("user", user);

export const logout = async () => {
  await localStore.remove_data("token");
  await localStore.remove_data("user");
  await localStore.remove_data("userRole");
  await localStore.remove_data("fcm");
  await localStore.remove_data("roomToken");
  await localStore.remove_data("ChatUser");
  await localStore.remove_data("chatToken");
  await updateHeaders();
  return true;
};

class Auth {
  constructor() {
    this.user = {};
  }

  async setUserFromLocal() {
    const user = await getToken();
    this.user = user ? user : {};
  }

  set setUser(user) {
    this.user = user;
  }

  get getUser() {
    return this.user;
  }

  async logout() {
    await logout();
    this.user = {};
  }
}

export function isJSON(str) {
  try {
    return (JSON.parse(str));
  } catch (e) {
    return str;
  }
}
export const authClass = new Auth();


export const envCheck = () => {
  if (window.location.origin === 'https://mdxdmfr.com' || window.location.origin === 'https://www.mdxdmfr.com' || window.location.origin === 'www.mdxdmfr.com') return 'PROD'
  else if (window.location.origin === 'https://dmfr-prod.herokuapp.com' || window.location.origin === 'https://www.dmfr-prod.herokuapp.com/' || window.location.origin === 'www.dmfr-prod.herokuapp.com/') return 'TEST'
  else return 'DEV';
}
import React, { useEffect } from "react";
import collectionathome from "../../Statics/Images/banner/collectionathome.jpg";
import collectionatcoperate1 from "../../Statics/Images/collectionatcoperate1.jpg";
import collectionatcoperate2 from "../../Statics/Images/collectionatcoperate2.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import CircleImg from "../ReUseableComponents/CircleImg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const Collectionatcoperate = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const banner = filterComponentData(componetcms, "banners", lang);

  const collectionCom = filterComponentData(
    componetcms,
    "collectionatcoperate",
    lang
  );
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[15]?.sublink1 ? banner[15]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[15]?.sublink2 ? banner[15]?.sublink2 : "Services",
      subDash: ">",
      subLink: "/collection-at-corporate",
    },
    {
      subLinkName: banner[15]?.sublink3
        ? banner[15]?.sublink3
        : "Collection At Coporate",
      subDash: ">",
      subLink: "/collection-at-corporate",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("collectionathome.maintitle")}</title>
      </Helmet>

      {/* img banner */}
      <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[15]?.image ? banner[15]?.image : collectionathome
          })`}
          heading={
            banner[15]?.heading ? banner[15]?.heading : "Colection At Coporate"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />

      <div className="">
        <TextAndImageSection
          imgSrc={
            collectionCom?.image ? collectionCom?.image : collectionatcoperate1
          }
          imgAlt={"Corporate Wellness Program"}
          imgStyle={{ marginTop: "40px" }}
          headingFirst={
            collectionCom?.title
              ? collectionCom?.title
              : t("collectionathome.textrev.headfirst")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
                className="light-Color-Para"
              >
                {collectionCom?.text
                  ? collectionCom?.text
                  : t("collectionathome.textrev.discriptiontxt1")}
              </p>
            </>
          }
          reverse={false}
        />
      </div>
      <div className="mt-5">
        <CircleImg
          imgcircle={
            collectionCom?.iconimage
              ? collectionCom?.iconimage
              : collectionatcoperate2
          }
          imgAlt="collectionatcoperate2"
        />
      </div>
    </>
  );
};

export default Collectionatcoperate;

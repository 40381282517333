import React from 'react'
import { Helmet } from 'react-helmet'

const Ultrasonogram = () => {
    return (
        <>
         <Helmet>
        <title>
        Ultrasonogram - DMFR Molecular Lab and Diagnostics
        </title>
      </Helmet>
            Ultrasonogram
        </>
    )
}

export default Ultrasonogram

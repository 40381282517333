import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removerDoctotByAdmin } from "../../../../Store/Actions/administratorActions";
import DashboardBtnList from "../../../ReUseableComponents/DashboardBtnList";

const RemoveDoctorAdministratorModal = (props) => {
  const { t } = useTranslation("common");
  const [reason, setReason] = useState(null);
  const dispatch = useDispatch();
  const removerDoctorHandler = () => {
    const apiData = {
      id: props.selecteddoctor?.id,
      description: reason,
    };
    dispatch(removerDoctotByAdmin(apiData));
    props?.onHide();
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="dashboardBtnList-item-active" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-white">
            {t("dashboards.alldashboardkeys.tablekeys.confirmation")}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("dashboards.alldashboardkeys.tablekeys.areyousureyouwantto")}</p>

        <textarea className="w-100 p-2" value={reason} onChange={(e) => setReason(e.target.value)} />
        <div className="row">
          <div className="col-6">
            <DashboardBtnList
              label={t("dashboards.alldashboardkeys.tablekeys.remove")}
              className="appointment-reschdule-btn"
              onClick={removerDoctorHandler}
            />
          </div>
          <div className="col-6">
            <DashboardBtnList
              label={t("dashboards.alldashboardkeys.tablekeys.cancel")}
              className="order-pay-btn"
              onClick={props.onHide}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveDoctorAdministratorModal;

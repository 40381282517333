import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import pathology from "../../Statics/Images/banner/pathology.jpg";
import pathology1 from "../../Statics/Images/pathology1.jpg";
import pathology2 from "../../Statics/Images/pathology2.jpg";
import pathology3 from "../../Statics/Images/pathology3.jpg";
import pathology4 from "../../Statics/Images/pathology4.jpg";
import pathology5 from "../../Statics/Images/pathology5.jpg";
import pathology6 from "../../Statics/Images/pathology6.jpg";
import pathology7 from "../../Statics/Images/pathology7.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";
import useMediaQuery from "../ReUseableComponents/useMediaQueryHook";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const Pathology = () => {
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const pathologyCom = filterComponentData(componetcms, "pathology", lang);
  const pathologyHeader = filterComponentData(
    componetcms,
    "pathologyheader",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);

  const isMobile = useMediaQuery("(max-width: 480px)");

  const { t, i18n } = useTranslation("common");
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[10]?.sublink1 ?banner[10]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[10]?.sublink2 ?banner[10]?.sublink2:"Services",
      subDash: ">",
      subLink: "/pathology-services",
    },
    {
      subLinkName: banner[10]?.sublink3 ?banner[10]?.sublink3:"Pathology",
      subDash: ">",
      subLink: "/pathology-services",
    },
  ]
  return (
    <>
      <Helmet>
        <title>{t("pathology.maintitle")}</title>
      </Helmet>
      <div className="mb-5">
        {/* img banner */}
        <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[10]?.image ? banner[10]?.image : pathology})`}
        heading={banner[10]?.heading ? banner[10]?.heading : "Pathology"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
        {/* img and text section */}
        <HeadingWithBottomBar
          heading={
            pathologyHeader?.heading
              ? pathologyHeader?.heading
              : t("pathology.mainheading1")
          }
          discription={
            <>
              <div>
                {pathologyHeader?.desc1
                  ? pathologyHeader?.desc1
                  : t("pathology.mainheadingsub")}
              </div>
              <div>
                {pathologyHeader?.desc2
                  ? pathologyHeader?.desc2
                  : t("pathology.mainheadingsub1")}
              </div>
            </>
          }
        />

        {pathologyCom?.length < 1 ? (
          <>
            <div className="">
              <TextAndImageSection
                imgSrc={pathology1}
                imgAlt={"Molecular Diagonotics"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev.headfirst")}
                headingSecond={t("pathology.textrev.headsecond")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>
            <div className="">
              <TextAndImageSection
                imgSrc={pathology2}
                imgAlt={"Flow Cytometry"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev1.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={true}
              />
            </div>
            <div className="">
              <TextAndImageSection
                imgSrc={pathology3}
                imgAlt={"Microbiology & Serology"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev2.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev2.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={pathology4}
                imgAlt={"Histopathology"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev3.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev3.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={true}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={pathology5}
                imgAlt={"Biochemistry & Immunology"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev4.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev4.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={pathology6}
                imgAlt={"Clinical Pathology"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev5.headfirst")}
                headingSecond={t("pathology.textrev5.headsecond")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev5.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={true}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={pathology7}
                imgAlt={"Hematology & Coagulation"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("pathology.textrev6.headfirst")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("pathology.textrev6.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>
          </>
        ) : (
          pathologyCom?.map((item, index) => (
            <div className="">
              <TextAndImageSection
                imgSrc={item?.image}
                imgAlt={"Hematology & Coagulation"}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={item?.heading}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: isMobile ? "" : "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {item?.text}
                    </p>
                  </>
                }
                reverse={index % 2 == 1 ? true : false}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Pathology;

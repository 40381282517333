export const signInConstansts = {
  SIGN_IN: "SIGN_IN",
  GET_USER_FROM_LOCAL: "GET_USER_LOCAL",
  LOG_OUT_USER: "LOG_OUT_USER",
  EMAIL_VERIFY: "EMAIL_VERIFY",
  Number_VERIFY: "Number_VERIFY",
  SET_ERROR: "SET_ERROR",
  SET_LOADER: "SET_LOADER",
  EMAIL_MODAL: "EMAIL_MODAL",
  PHONE_MODAL: "PHONE_MODAL",
  USER_TYPE: "USER_TYPE",
  GOOGLE_USER_LOGIN:"GOOGLE_USER_LOGIN",
  ADD_PHONE_MODAL: "ADD_PHONE_MODAL",
  USER_PHONE_NUMBER :"USER_PHONE_NUMBER"
};

import { signInConstansts } from "../Constants/loginConstant";
import { staticConstants } from "../Constants/staticConstants";
const initialState = {
  staticData: [],
  testData: [],
  testCategory: [],
  packages: [],
  selectedAppointmet: [],
  allnotification: [],
  newnotification: [],
  notificationcount: 0,
  cart: 0,
  publicDoctor: [],
  loader: false,
  requestAppointment: false,
};
export const staticReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case staticConstants.CART:
      return { ...state, cart: payload };
    case staticConstants.SET_STATIC_DATA:
      return { ...state, staticData: payload };
    case staticConstants.FETCH_STATIC_DATA:
      return { ...state, staticData: payload };
    case staticConstants.GET_TEST_CATEGORY:
      return { ...state, testCategory: payload };
    case staticConstants.GET_TEST_DATA:
      return { ...state, testData: payload };
    case staticConstants.GET_PACKAGES:
      return { ...state, packages: payload };
    case staticConstants.ALL_NOTIFICATIONS:
      return { ...state, allnotification: payload };
    case staticConstants.NEW_NOTIFICATIONS:
      return { ...state, newnotification: payload };
    case staticConstants.NOTIFICATION_COUNT:
      return { ...state, notificationcount: payload };
    case staticConstants.SELECTED_APPOINTMENT:
      return { ...state, selectedAppointmet: payload };
    case staticConstants.LOADING:
      return { ...state, loader: payload };
    case staticConstants.FETCH_PUBLIC_DOCTOR:
      return { ...state, publicDoctor: payload };
    case signInConstansts.LOG_OUT_USER:
      return initialState;
    case staticConstants.APPOINTMENT_MODAL:
      return { ...state, requestAppointment: payload };
    default:
      return state;
  }
};

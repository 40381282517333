import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import telemedicne from "../../Statics/Images/banner/telemedicne.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import telimedicineDoc from "../../Statics/Images/telimedicine.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";
import { useDispatch } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const Telemedicine = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const componetcms = useSelector((state) => state.cms.components);

  const lang = useSelector((state) => state?.cms?.language);
  const telemedicine = filterComponentData(componetcms, "telemedicine", lang);
  const banner = filterComponentData(componetcms, "banners", lang);
  const { t, i18n } = useTranslation("common");

  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[0]?.sublink1 ?banner[0]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[0]?.sublink2 ?banner[0]?.sublink2:"Telemedicine",
      subDash: ">",
      subLink: "/telemedicine",
    },
    
   
  ];
  return (
    <>
      <Helmet>
        <title>{t("telemedicine.maintitle")}</title>
      </Helmet>
      {/* img banner */}
     
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[0]?.image ? banner[0]?.image : telemedicne})`}
        heading={banner[0]?.heading ? banner[0]?.heading : "Telemedicine"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      {/* img and text section */}
      <div className="p-3">
        <TextAndImageSection
          imgSrc={
            telemedicine?.serviceimage
              ? telemedicine?.serviceimage
              : telimedicineDoc
          }
          subHeading={
            telemedicine?.heading
              ? telemedicine?.heading
              : t("telemedicine.textrev.subHeading")
          }
          discription={
            <>
              <p
                style={{
                  fontFamily: "Work Sans', sans-serif",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
                className="light-Color-Para"
              >
                {telemedicine?.discription
                  ? telemedicine?.discription
                  : t("telemedicine.textrev.discription")}
              </p>
            </>
          }
          reverse={true}
        />
      </div>
      <div className="container mt-2 mb-5">
        <div className="row mb-5">
          <div className="col-lg-6 col-md-12">
            <h5 className="telemedicine-list-heading">
              {" "}
              {telemedicine?.head
                ? telemedicine?.head
                : t("telemedicine.telesevices.head")}
            </h5>
            <ul className="telemedicine-list ">
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list1
                  ? telemedicine?.list1
                  : t("telemedicine.telesevices.list1")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list2
                  ? telemedicine?.list2
                  : t("telemedicine.telesevices.list2")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list3
                  ? telemedicine?.list3
                  : t("telemedicine.telesevices.list3")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list4
                  ? telemedicine?.list4
                  : t("telemedicine.telesevices.list4")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list5
                  ? telemedicine?.list5
                  : t("telemedicine.telesevices.list5")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list6
                  ? telemedicine?.list6
                  : t("telemedicine.telesevices.list6")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list7
                  ? telemedicine?.list7
                  : t("telemedicine.telesevices.list7")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list8
                  ? telemedicine?.list8
                  : t("telemedicine.telesevices.list8")}
              </li>
              <li className="light-Color-Para">
                »{" "}
                {telemedicine?.list9
                  ? telemedicine?.list9
                  : t("telemedicine.telesevices.list9")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Telemedicine;

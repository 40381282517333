import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddPharmacyMedicine from "../../Components/LayOuts/modals/addPharmacyMedicine/AddPharmacyMedicine";
import UploadMedicineModal from "../../Components/LayOuts/modals/uploadMedicine/UploadMedicineModal";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import {
  deleteMedicine,
  getAllMedicines,
  SelectedMedicinde,
} from "../../Store/Actions/pharmacyActions";
import MedicineAlertModal from '../../Components/LayOuts/modals/MedicineAlertModal/MedicineAlertModal'
import EditPharmacyMedicine from "../../Components/LayOuts/modals/addPharmacyMedicine/EditMedicineModal";

const PharmacyMedicines = (props) => {
  const {t} = useTranslation("common");
  const selectedMedi = useSelector((state) => state?.pharmacy?.selectedMedi);

  const loader = useSelector((state) => state?.logIn?.loader);
  const medicines = useSelector((state) => state?.pharmacy?.medicines);
  const [selectedMedicine, setSelectedMedi] = useState({});

  const [addmedicinemodal, setaddmedicinemodal] = useState(false);
  const [addBul, setaddBul] = useState(false);
  const [delteMedi, setDeleteMedi] = useState(false);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMedicines());
  }, [dispatch]);

  const editHandler = async (item) => {
    await dispatch(SelectedMedicinde({ show: true, data: item }));
  };
  return (
    <>
      <div className="row  mt-4 ">
        <div className="col-12 ">
          <h4 className=" patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.medicines")}</h4>
        </div>
        <div className="col-lg-12 col-md-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="mr-0 mb-2">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.addmedicinebulk")}
                  className="add-appointment-btn "
                  onClick={() => setaddBul(true)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ml-0">
                <DashboardBtnList
                  label={t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
                  className="add-appointment-btn"
                  onClick={() => {
                    setaddmedicinemodal(true);
                  }}
                />
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <BootstrapCustomTable
            columns={[
              { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.medname") },
              { dataField: "manufacturer_name", text: t("dashboards.alldashboardkeys.tablekeys.manufacturername") },

              { dataField: "medicine_type", text: t("dashboards.alldashboardkeys.tablekeys.medtype") },
              { dataField: "quantity", text: t("dashboards.alldashboardkeys.tablekeys.medquantity") },

              { dataField: "sales_price", text: t("dashboards.alldashboardkeys.tablekeys.price") },
              {
                formatter: (cell, row) => {
                  return <span style={{ color: row?.status?.name == "active" ? 'green' : 'red' }}>{row?.status?.name?.toUpperCase()}</span>;
                },
                text: t("dashboards.alldashboardkeys.tablekeys.status"),
              },
              {
                formatter: (cell, row) => {
                  return (
                    <div className="row p-2 gx-2">
                      <div
                        className="btn btn-success col-6 "
                        onClick={() => {
                          setSelectedMedi(row)

                          editHandler(row);
                        }}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.edit")}
                      </div>
                      {/* <DashboardBtnList
                        label={"Edit"}
                        className="order-pay-btn"
                        onClick={() => {
                          editHandler(row);
                        }}
                      /> */}
                      <div
                        className="btn btn-danger col-6"
                        style={{ width: '150px' }}
                        onClick={() => {
                          setSelectedMedi(row)
                          setDeleteMedi(true)
                        }}
                      >
                        {t("dashboards.alldashboardkeys.tablekeys.delete")}
                      </div>
                      {/* <DashboardBtnList
                        label={"Delete"}
                        className="appointment-reschdule-btn"
                        onClick={() => deleteHandler(row)}
                      /> */}
                    </div>
                  );
                },
                text: t("dashboards.alldashboardkeys.tablekeys.action"),
              },
            ]}
            tabledata={medicines}
          />
        )}
      </div>
      <AddPharmacyMedicine
        show={addmedicinemodal}
        onHide={() => setaddmedicinemodal(false)}
        {...props}
      />
      {/* <AddPharmacyMedicine
        show={selectedMedi?.show}
        onHide={() => dispatch(SelectedMedicinde({ show: false, data: {} }))}
        {...props}
      /> */}
      <EditPharmacyMedicine
        show={selectedMedi?.show}
        data={selectedMedicine}
        onHide={() => dispatch(SelectedMedicinde({ show: false, data: {} }))}
        {...props}
      />
      <UploadMedicineModal
        show={addBul}
        onHide={() => setaddBul(false)}
        {...props}
      />
      <MedicineAlertModal
        show={delteMedi}
        onHide={() => setDeleteMedi(false)}
        data={selectedMedicine}
        {...props}
      />
    </>
  );
};

export default PharmacyMedicines;

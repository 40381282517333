import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AssignBdeTargetAction,
  readBdeAction,
  UpdateBdeTargetAction,
} from "../../../../Store/Actions/administratorActions";
import { disablePastDate } from "../../../../Utils/functions";
export default function EditTarget(props) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const currentTarget = props?.bdeTarget;

  const [bdeSelection, setbdeSelection] = useState([
    currentTarget?.assigned_to,
  ]);
  useEffect(() => {
    dispatch(readBdeAction());
  }, [dispatch]);
  useEffect(() => {
    setbdeSelection([currentTarget?.assigned_to]);
  }, [currentTarget]);

  const allMedicines = useSelector((state) => state?.administrator?.bde);
  const loading = useSelector((state) => state?.logIn?.loader);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (bdeSelection?.length === 0) {
      toast.error(
        t("dashboards.alldashboardkeys.tablekeys.pleaseselectbdetoassign"),
        "Error"
      );

      // setmediError("This Is A Required Field");
    } else {
      const bdeData = {
        id: bdeSelection[0]?.id,
        amount: Number(data?.amount),
        number_of_packages: Number(data?.packages),
        number_of_tests: Number(data?.test),
        number_of_doctor_visits: Number(data?.doctor),
        start_date: moment(data?.startdate).unix(),
        end_date: moment(data?.enddate).unix(),
        target_id: currentTarget?.id,
      };

      let res = await dispatch(UpdateBdeTargetAction(bdeData));
      if (res) {
        toast.success(
          t("dashboards.alldashboardkeys.tablekeys.successfullyupdatedtarget"),
          "Success"
        );
        props.onHide();
      } else {
      }
      // props.setBdeTarget([...props?.bdeTarget, bdeData]);
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.editTarget")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-12">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.selectbde")}
                  </span>
                  <Form.Group>
                    <Typeahead
                      // disabled={true}
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(selected) => {
                        setbdeSelection(selected);
                      }}
                      options={allMedicines}
                      placeholder=""
                      selected={
                        bdeSelection[0] === undefined ? [] : bdeSelection
                      }
                    />
                    {/* {mediError ? (
                                            <div className="text-error">{mediError}</div>
                                        ) : (
                                            ""
                                        )} */}
                  </Form.Group>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6">
                  <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enterammount")}</span>
                  <Form.Group>
                    <input
                      defaultValue={currentTarget?.amount}
                      className="input-login-modal"
                      type="number"
                      {...register("amount", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enternumberofpakages")}</span>

                  <Form.Group>
                    <input
                      defaultValue={currentTarget?.number_of_packages}
                      className="input-login-modal"
                      type="number"
                      {...register("packages", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.entertest")}</span>

                  <Form.Group>
                    <input
                      defaultValue={currentTarget?.number_of_tests}
                      className="input-login-modal"
                      type="number"
                      {...register("test", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enternumberofdetails")}</span>

                  <Form.Group>
                    <input
                      defaultValue={currentTarget?.number_of_doctor_visits}
                      className="input-login-modal"
                      type="number"
                      {...register("doctor", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enterstartdate")}</span>

                  <Form.Group>
                    <input
                      defaultValue={moment
                        .unix(currentTarget?.start_date)
                        .format("YYYY-MM-DD")}
                        min={disablePastDate()}

                      className="input-login-modal"
                      type="date"
                      {...register("startdate", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.enterenddate")}</span>

                  <Form.Group>
                    <input
                      defaultValue={moment
                        .unix(currentTarget?.end_date)
                        .format("YYYY-MM-DD")}
                      className="input-login-modal"
                      type="date"
                      min={disablePastDate()}

                      {...register("enddate", {
                        required: {
                          value: true,
                          message: "this field is required field",
                        },
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
              <input
                type="submit"
                value={t("dashboards.alldashboardkeys.tablekeys.addtargets")}
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";

const PrivacyConfidentiality = () => {
  const componetcms = useSelector((state) => state.cms.components);
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language);
  const privacy_policy = filterComponentData(
    componetcms,
    "privacy_policy",
    lang
  );

  return (
    <>
      <Helmet>
        <title>Privacy Policy - DMFR Molecular Lab and Diagnostics</title>
      </Helmet>
      <div className="container" id="setpripol">
        <div className="row">
          {privacy_policy.length < 1 ? (
            <div className="col-12 my-3 text-justify">
              <div>
                <h1
                  className="text-center"
                  style={{ color: "#1a4774", fontWeight: "600" }}
                >
                  Privacy and Policy
                </h1>
                <div className="container mt-3" id="pad">
                  <div className="row">
                    <p className="col-12">
                      The <strong>mdxdmfr.com</strong> website (the "Site")
                      operated by{" "}
                      <strong>DMFR Molecular Lab Diagnostics BD Ltd.,</strong>
                      We respect your privacy and for this want to protect your
                      personal information. To learn more, please read this
                      Privacy Policy.
                    </p>
                  </div>
                </div>
                <ol id="someselector">
                  <li>
                    This Privacy Policy explains how we collect, use and (under
                    certain conditions) disclose your personal information. This
                    Privacy Policy also explains the steps we have taken to
                    secure your personal information. Finally, this Privacy
                    Policy explains your options regarding the collection, use
                    and disclosure of your personal information. By visiting the
                    site directly or through another site, you accept the
                    practices described in this Policy.
                  </li>

                  <li>
                    Data protection is a matter of trust and your privacy is
                    important to us. We shall therefore only use your name and
                    other information which relates to you in the manner set out
                    in this Privacy Policy. We will only collect information
                    where it is necessary for us to do so and we will only
                    collect information if it is relevant to our dealings with
                    you.
                  </li>

                  <li>
                    We will only keep your information for as long as we are
                    either required to by law or as is relevant for the purposes
                    for which it was collected.
                  </li>

                  <li>
                    We will cease to retain your personal data, or remove the
                    means by which the data can be associated with you, as soon
                    as it is reasonable to assume that such retention no longer
                    serves the purposes for which the personal data was
                    collected, and is no longer necessary for any legal or
                    business purpose.
                  </li>

                  <li>
                    You can visit the Site and browse without having to provide
                    personal details. During your visit to the Site you remain
                    anonymous and at no time can we identify you unless you have
                    an account on the Site and log on with your user name and
                    password.
                  </li>

                  <li>
                    <span
                    // className="font-weight-bold"
                    // style={{ color: "#003242" }}
                    >
                      Data that we collect
                    </span>
                    <ol id="someselector" type="a">
                      <li>
                        We may collect various pieces of information if you seek
                        to place an order for a product and service with us on
                        the Site.
                      </li>
                      <li>
                        We collect, store and process your data for processing
                        your purchase on the Site and any possible later claims,
                        and to provide you with our services. We may collect
                        personal information including, but not limited to, your
                        title, name, gender, date of birth, email address,
                        postal address, delivery address (if different),
                        telephone number, mobile number, fax number, payment
                        details, payment card details or bank account details.
                      </li>
                      <li>
                        DMFR shall collect the following information where you
                        are a buyer:
                        <ol id="someselector" type="i">
                          <li>
                            Identity data, such as your name, gender, profile
                            picture, and date of birth;
                          </li>
                          <li>
                            Contact data, such as delivery address, email
                            address and phone numbers;
                          </li>
                        </ol>
                      </li>
                      <li>
                        We will use the information you provide to enable us to
                        process your orders and to provide you with the services
                        and information offered through our website and which
                        you request in the following ways:
                        <ol id="someselector" type="i">
                          <li>
                            If you are a buyer Processing your orders for
                            products;
                          </li>
                          <li>Process orders you submit through the Site;</li>
                          <li>
                            Deliver the products you have purchased through the
                            Site for which we may pass your personal information
                            on to a third party (e.g. our logistics partner) in
                            order to make delivery of the product to you;
                          </li>
                          <li>Update you on the delivery of the products;</li>
                          <li>Provide customer support for your orders;</li>
                          <li>
                            Verify and carry out payment transactions (including
                            any credit card payments, bank transfers, offline
                            payments, remittances, or e-wallet transactions) in
                            relation to payments related to you and/or services
                            used by you. In order to verify and carry out such
                            payment transactions, payment information, which may
                            include personal data, will be transferred to third
                            parties such as our payment service providers;
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Providing services
                            </span>
                            <ol id="someselector" type="i">
                              <li>
                                Facilitate your use of the services or access to
                                the Site;
                              </li>
                              <li>Administer your account with us;</li>
                              <li>
                                Display your name, username or profile on the
                                Site (including on any reviews you may post);
                              </li>
                              <li>
                                Respond to your queries, feedback, claims or
                                disputes, whether directly or through our third
                                party service providers; and
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Marketing and advertising:
                            </span>
                            <ol id="someselector" type="i">
                              <li>
                                Provide you with information we think you may
                                find useful or which you have requested from us
                                (provided you have opted to receive such
                                information);
                              </li>
                              <li>
                                Send you marketing or promotional information
                                about products and services on the Site from
                                time to time (provided you have opted to receive
                                such information); and
                              </li>
                              <li>
                                Help us conduct marketing and advertising;
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Legal and operational purposes:
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                Ascertain your identity in connection with fraud
                                detection purposes;
                              </li>
                              <li>
                                Compare information, and verify with third
                                parties in order to ensure that the information
                                is accurate;
                              </li>
                              <li>
                                Process any complaints, feedback, enforcement
                                action you may have lodged with us;
                              </li>
                              <li>
                                Produce statistics and research for internal and
                                statutory reporting and/or record-keeping
                                requirements;
                              </li>
                              <li>Store, host, back up your personal data;</li>
                              <li>
                                Prevent or investigate any actual or suspected
                                violations of our Terms of Use, Privacy Policy,
                                fraud, unlawful activity, omission or
                                misconduct, whether relating to your use of Site
                                or any other matter arising from your
                                relationship with us;
                              </li>
                              <li>
                                Comply with legal and regulatory requirements
                                (including, where applicable, the display of
                                your name, contact details and company details),
                                including any law enforcement requests, in
                                connection with any legal proceedings, or
                                otherwise deemed necessary by us; and
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Analytics, research, business and development:
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                Understand your user experience on the Site;
                              </li>
                              <li>
                                Improve the layout or content of the pages of
                                the Site and customize them for users;
                              </li>
                              <li>Identify visitors on the Site;</li>
                              <li>
                                Conduct surveys, including carrying out research
                                on our users&rsquo; demographics and behaviour;
                              </li>
                              <li>
                                Derive further attributes relating to you based
                                on personal data provided by you (whether to us
                                or third parties), in order to provide you with
                                more targeted and/or relevant information;
                              </li>
                              <li>
                                Conduct data analysis, testing and research,
                                monitoring and analysing usage and activity
                                trends;
                              </li>
                              <li>
                                Further develop our products and services; and
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Other
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                Any other purpose to which your consent has been
                                obtained; and
                              </li>
                              <li>
                                Conduct automated decision-making processes in
                                accordance with any of the above purposes.
                              </li>
                            </ol>
                          </li>
                          <li>
                            Further, we will use the information you provide to
                            administer your account with us; verify and carry
                            out financial transactions in relation to payments
                            you make; audit the downloading of data from our
                            website; improve the layout and/or content of the
                            pages of our website and customize them for users;
                            identify visitors on our website; carry out research
                            on our users' demographics; send you information we
                            think you may find useful or which you have
                            requested from us, including information about our
                            products and services, provided you have indicated
                            that you have not objected to being contacted for
                            these purposes.
                          </li>
                          <li>
                            Subject to obtaining your consent we may contact you
                            by email with details of other products and
                            services. You may unsubscribe from receiving
                            marketing information at any time in our mobile
                            application settings or by using the unsubscribe
                            function within the electronic marketing material.
                            We may use your contact information to send
                            newsletters from us and from our related companies.
                            If you prefer not to receive any marketing
                            communications from us, you can opt out at any time.
                          </li>
                          <li>
                            We may pass your name and address on to a third
                            party in order to make delivery of the product to
                            you (for example to our courier or supplier). You
                            must only submit to us the Site information which is
                            accurate and not misleading and you must keep it up
                            to date and are responsible for informing us of
                            changes to your personal data, or in the event you
                            believe that the personal data we have about you is
                            inaccurate, incomplete, misleading or out of date.
                            Inform us of changes. You can update your personal
                            data anytime by accessing your account on the Site.
                          </li>
                          <li>
                            Your actual order details may be stored with us but
                            for security reasons cannot be retrieved directly by
                            us. However, you may access this information by
                            logging into your account on the Site. Here you can
                            view the details of your orders that have been
                            completed, those which are open and those which are
                            shortly to be dispatched and administer your address
                            details, bank details ( for refund purposes) and any
                            newsletter to which you may have subscribed. You
                            undertake to treat the personal access data
                            confidentially and not make it available to
                            unauthorized third parties. We cannot assume any
                            liability for misuse of passwords unless this misuse
                            is our fault.
                          </li>
                          <li>
                            Other uses of your Personal Information <br /> We
                            may use your personal information for opinion and
                            market research. Your details are anonymous and will
                            only be used for statistical purposes. You can
                            choose to opt out of this at any time. Any answers
                            to surveys or opinion polls we may ask you to
                            complete will not be forwarded on to third parties.
                            Disclosing your email address is only necessary if
                            you would like to take part in competitions. We save
                            the answers to our surveys separately from your
                            email address.
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Competitions
                            </span>
                            <br />
                            For any competition we use the data to notify
                            winners and advertise our offers. You can find more
                            details where applicable in our participation terms
                            for the respective competition.
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Third Parties and Links
                            </span>
                            <br />
                            We may pass your details to other companies in our
                            group. We may also pass your details to our agents
                            and subcontractors to help us with any of our uses
                            of your data set out in our Privacy Policy. For
                            example, we may use third parties to assist us with
                            delivering products to you, to help us to collect
                            payments from you, to analyse data and to provide us
                            with marketing or customer service assistance. We
                            may also exchange information with third parties for
                            the purposes of fraud protection and credit risk
                            reduction.
                            <br />
                            We may share (or permit the sharing of) your
                            personal data with and/or transfer your personal
                            data to third parties and/or our affiliates for the
                            above-mentioned purposes. These third parties and
                            affiliates, which may be located inside or outside
                            your jurisdiction, include but are not limited to:
                            <br />
                            Service providers (such as agents, vendors,
                            contractors and partners) in areas such as payment
                            services, logistics and shipping, marketing, data
                            analytics, market or consumer research, survey,
                            social media, customer service, installation
                            services, information technology and website
                            hosting;
                            <br />
                            Their service providers and related companies; and
                            other users of the Site.
                            <br />
                            We may transfer our databases containing your
                            personal information if we sell our business or part
                            of it, provided that we satisfy the requirements of
                            applicable data protection law when disclosing your
                            personal data. Other than as set out in this Privacy
                            Policy, we shall NOT sell or disclose your personal
                            data to third parties without obtaining your prior
                            consent unless this is necessary for the purposes
                            set out in this Privacy Policy or unless we are
                            required to do so by law.
                            <br />
                            In disclosing your personal data to third parties,
                            we endeavour to ensure that the third parties and
                            our affiliates keep your personal data secure from
                            unauthorized access, collection, use, disclosure,
                            processing or similar risks and retain your personal
                            data only for as long as your personal data helps
                            with any of the uses of your data as set out in our
                            Privacy Policy.
                            <br />
                            We may transfer or permit the transfer of your
                            personal data outside of Bangladesh for any of the
                            purposes set out in this Privacy Policy. However, we
                            will not transfer or permit any of your personal
                            data to be transferred outside of Bangladesh unless
                            the transfer is in compliance with applicable laws
                            and this Privacy Policy.
                          </li>

                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Cookies
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                We or our authorised service providers may use
                                cookies, web beacons, and other similar
                                technologies in connection with your use of the
                                Site.
                              </li>
                              <li>
                                The acceptance of cookies is not a requirement
                                for visiting the Site. However, we would like to
                                point out that the use of the 'basket'
                                functionality on the Site and ordering is only
                                possible with the activation of cookies. Cookies
                                are small text files (typically made up of
                                letters and numbers) placed in the memory of
                                your browser or device when you visit a website
                                or view a message. They allow us to recognise a
                                particular device or browser. Web beacons are
                                small graphic images that may be included on the
                                Site. They allow us to count users who have
                                viewed these pages so that we can better
                                understand your preference and interests.
                                Cookies are tiny text files which identify your
                                computer to our server as a unique user when you
                                visit certain pages on the Site and they are
                                stored by your Internet browser on your
                                computer's hard drive
                              </li>
                              <li>
                                This website uses Google Analytics, a web
                                analytics service provided by Google, Inc.
                                ("Google"). Google Analytics uses cookies, which
                                are text files placed on your computer, to help
                                the website analyse how users use the site. The
                                information generated by the cookie about your
                                use of the website (including your IP address)
                                will be transmitted to and stored by Google on
                                servers in the United States. Google will use
                                this information for the purpose of evaluating
                                your use of the website, compiling reports on
                                website activity for website operators and
                                providing other services relating to website
                                activity and internet usage. Google may also
                                transfer this information to third parties where
                                required to do so by law, or where such third
                                parties process the information on Google's
                                behalf. Google will not associate your IP
                                address with any other data held by Google. You
                                may refuse the use of cookies by selecting the
                                appropriate settings on your browser, however
                                please note that if you do this you may not be
                                able to use the full functionality of this
                                website. By using this website, you consent to
                                the processing of data about you by Google in
                                the manner and for the purposes set out above.
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Security
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                We have in place appropriate technical and
                                security measures to prevent unauthorized or
                                unlawful access to or accidental loss of or
                                destruction or damage to your information. When
                                we collect data through the Site, we collect
                                your personal details on a secure server. We use
                                firewalls on our servers. Our security
                                procedures mean that we may occasionally request
                                proof of identity before we disclose personal
                                information to you. You are responsible for
                                protecting against unauthorized access to your
                                password and to your computer.
                              </li>
                              <li>
                                You should be aware, however, that no method of
                                transmission over the Internet or method of
                                electronic storage is completely secure. While
                                security cannot be guaranteed, we strive to
                                protect the security of your information and are
                                constantly reviewing and enhancing our
                                information security measures.
                              </li>
                            </ol>
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Your rights
                            </span>
                            <br />
                            If you are concerned about your data, you have the
                            right to request access to the personal data which
                            we may hold or process about you. You have the right
                            to require us to correct any inaccuracies in your
                            data free of charge. At any stage you also have the
                            right to ask us to stop using your personal data for
                            direct marketing purposes.
                            <br />
                            Where permitted by applicable data protection laws,
                            we reserve the right to charge a reasonable
                            administrative fee for retrieving your personal data
                            records. If so, we will inform you of the fee before
                            processing your request.
                            <br />
                            You may communicate the withdrawal of your consent
                            to the continued use, disclosure, storing and/or
                            processing of your personal data by contacting our
                            customer services, subject to the conditions and/or
                            limitations imposed by applicable laws or
                            regulations. Please note that if you communicate
                            your withdrawal of your consent to our use,
                            disclosure, storing or processing of your personal
                            data for the purposes and in the manner as stated
                            above or exercise your other rights as available
                            under applicable local laws, we may not be in a
                            position to continue to provide the Services to you
                            or perform any contract we have with you, and we
                            will not be liable in the event that we do not
                            continue to provide the services to, or perform our
                            contract with you. Our legal rights and remedies are
                            expressly reserved in such an event.
                          </li>
                          <li>
                            <span
                            // className="font-weight-bold"
                            // style={{ color: "#003242" }}
                            >
                              Minors
                            </span>

                            <ol id="someselector" type="i">
                              <li>
                                We do not sell products to minors, i.e.
                                individuals below the age of 18, on the Site and
                                we do not knowingly collect any personal data
                                relating to minors. You hereby confirm and
                                warrant that you are above the age of 18 and are
                                capable of understanding and accepting the terms
                                of this Privacy Policy.
                              </li>
                              <li>
                                If you allow a minor to access the Site and buy
                                products from the Site by using your account,
                                you hereby consent to the processing of the
                                minor&rsquo;s personal data and accept and agree
                                to be bound by this Privacy Policy and take
                                responsibility for his or her actions.
                              </li>
                              <li>
                                We will not be responsible for any unauthorized
                                use of your account on the Site by yourself,
                                users who act on your behalf or any unauthorized
                                users. It is your responsibility to make your
                                own informed decisions about the use of the Site
                                and take necessary steps to prevent any misuse
                                of the Site.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Our Services and related documentation give you the ability
                    to delete certain information about you from within the
                    Service. For example, you can remove content that contains
                    information about you using the key word search and editing
                    tools associated with that content, and you can remove
                    certain profile information within your profile settings.
                    Please note, however, that we may need to retain certain
                    information for record keeping purposes, to complete
                    transactions or to comply with our legal obligations.
                  </li>
                </ol>
              </div>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: privacy_policy?.description,
              }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default PrivacyConfidentiality;

import React, { useEffect } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import cardiology from "../../Statics/Images/banner/cardiology.jpg";
import cadio1 from "../../Statics/Images/cadio1.jpg";
import cadio2 from "../../Statics/Images/cadio2.jpg";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const Cardiology = () => {
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const lang = useSelector((state) => state?.cms?.language)
  const cardiologyCom = filterComponentData(componetcms, 'cardiology', lang)
  const cardiologyHeader = filterComponentData(componetcms, 'cardiologyheader', lang)
  const banner = filterComponentData(componetcms, "banners", lang);

  const { t, i18n } = useTranslation("common");
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[12]?.sublink1 ? banner[12]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[12]?.sublink2 ? banner[12]?.sublink2 : "Services",
      subDash: ">",
      subLink: "/cardiology-services",
    },
    {
      subLinkName: banner[12]?.sublink3
        ? banner[12]?.sublink3
        : "Cardiology",
      subDash: ">",
      subLink: "/cardiology-services",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("cardiology.maintitle")}</title>
      </Helmet>
      <div className="mb-5">
        {/* img banner */}
        <BannerImageWithText
          backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
            banner[12]?.image ? banner[12]?.image : cardiology
          })`}
          heading={
            banner[12]?.heading ? banner[12]?.heading : "Cardiology"
          }
          LinksBan={LinksBanSpecialityAstheticErec}
          height={"400px"}
          container_style={{ paddingTop: "200px" }}
          backgroundSize={"100% 300px"}
        />
        {/* img and text section */}
        <HeadingWithBottomBar
          heading={cardiologyHeader?.heading ? cardiologyHeader?.heading : t("cardiology.mainheading1")}
          discription={
            <>
              <div>{cardiologyHeader?.text1 ? cardiologyHeader?.text1 : t("cardiology.mainheadingsub")}</div>
              <div>{cardiologyHeader?.text2 ? cardiologyHeader?.text2 : t("cardiology.mainheadingsub1")}</div>
              <div>{cardiologyHeader?.text3 ? cardiologyHeader?.text3 : t("cardiology.mainheadingsub2")}</div>
            </>
          }
        />
        {
          cardiologyCom?.length < 1 ? <>
            <div className="">
              <TextAndImageSection
                imgSrc={cadio1}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("cardiology.textrev.headfirst")}
                headingSecond={t("cardiology.textrev.headsecond")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("cardiology.textrev.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={false}
              />
            </div>

            <div className="">
              <TextAndImageSection
                imgSrc={cadio2}
                imgStyle={{ marginTop: "40px" }}
                headingFirst={t("cardiology.textrev1.headfirst")}
                headingSecond={t("cardiology.textrev1.headsecond")}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t("cardiology.textrev1.discriptiontxt1")}
                    </p>
                  </>
                }
                reverse={true}
              />
            </div></> :

            cardiologyCom?.map((item, index) => (

              <div div className="" >
                <TextAndImageSection
                  imgSrc={item?.image}
                  imgStyle={{ marginTop: "40px" }}
                  headingFirst={item?.heading}
                  // headingSecond={t("cardiology.textrev1.headsecond")}
                  discription={
                    <>
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {item?.text}
                      </p>
                    </>
                  }
                  reverse={index % 2 == 1 ? true : false}
                />
              </div>
            ))
        }

      </div>
    </>
  );
};

export default Cardiology;

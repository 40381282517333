import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import PopUp from "../../../../Statics/Images/advertisment.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../../../Store/Actions/cmsAction";
import { filterComponentData } from "../../../../Utils/functions";
const HomeAddModal = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
  }, [dispatch])
  const lang = useSelector((state) => state?.cms?.language)

  const componetcms = useSelector((state) => state?.cms?.components);

  const data = filterComponentData(componetcms, 'modal', lang)
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="popUpModal"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#476b8f", padding: '5px', borderBottom: 'none' }}>
          {/* <Modal.Title id="contained-modal-title-vcenter">Sign In</Modal.Title> */}
          {/* <img src={PopUp} alt="popup" style={{ width: "410px", height: "410px" }} /> */}
          <img src={data[0]?.image ? data[0]?.image : PopUp} alt="popup" style={{ width: "410px", height: "410px" }} />

        </Modal.Header>
        {/* <Modal.Body  >
        </Modal.Body> */}
      </Modal>
    </>
  );
};

export default HomeAddModal;

import React from "react";
import { Link } from "react-router-dom";
import "./newcard.css";
const Newscard = (props) => {
  let obj = {
    list: props?.list,
    state: props?.data,
    listData: props?.listData,
  };
  return (
    <div className="col-lg-6 col-md-12">
      <div className="newsectionm">
        <div className="news_left">
          <div className="newsimagescttn">
            <img
              src={props.img}
              alt="News Card"
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="news_right">
          <h5 style={{ textTransform: "none", marginBottom: "4px" }}>
            <Link
              to={props.headingLink}
              className="ntitles2s"
              style={{ color: "#0e5684" }}
            >
              {props.headingName}
            </Link>
          </h5>
          <div className="news-meta">
            <ul>
              <li>
                <i className="far fa-user"></i>{" "}
                {props?.com ? props?.com : "DMFR"}
              </li>
              <li>
                <i className="far fa-calendar-alt"></i> {props?.new_date}
              </li>
            </ul>
          </div>
          <div style={{ clear: "both" }}></div>
          <p className="descriptn_news">
            <span>{props.description}</span> {/* {props.description1} */}
          </p>
          <span
            onClick={() => {
              if (props?.button) {
                props.history.push("/news-details#news", obj);
              } else {
                props.history.push("/news-details#news", {
                  state: props?.data,
                });
              }
            }}
          >
            <strong
              style={{ fontSize: "15px", color: "#000", cursor: "pointer" }}
            >
              {/* <Link to={{ pathname: props.headingLink, state: obj }}> */}{" "}
              <span className="newsbtn">
                {props?.button ? props?.button : "Read More"}
              </span>
              {/* </Link> */}
            </strong>
          </span>{" "}
        </div>
      </div>
    </div>
  );
};

export default Newscard;

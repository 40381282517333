import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddInstallmentAmount from "../../Components/LayOuts/modals/installmentAmonutModal/AddInstallmentAmount";
import ActionBtn from "../../Components/ReUseableComponents/ActionBtn";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
// import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { generatePayment, myPayment } from "../../Store/Actions/patientActions";
import {
  capitalize,
  checkPaidOrUnpaid,
  comaSeperated,
} from "../../Utils/functions";

const PaymentProfile = (props) => {
  const { t } = useTranslation("common")
  const loader = useSelector((state) => state?.logIn?.loader);
  const payments = useSelector((state) => state?.patient?.payments);
  const user = useSelector((state) => state?.logIn?.user);
  const [showInstallmentModal, setShowInstallmentModal] = useState(false)
  const [data, setdata] = useState(null)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(myPayment({ id: user?.id }));
    // eslint-disable-next-line
  }, [dispatch]);
  const paymentHandler = async (data) => {
    let apiData = {
      id:
        data?.type === "appointment" ? data?.appointment?.id : data?.order?.id,
      type: data?.type,
      partial_payment: false

    };
    const res = await dispatch(generatePayment(apiData))
    if (res == false) {
      return;
    }
    window.open(res);
    window.focus();
  };
  const partialPaymentHandler = async (data) => {
    if (data?.type === "appointment") {
      toast.error(t("dashboards.alldashboardkeys.tablekeys.partialpaymentnotallowed"), "Error")
      return;
    }
    else if (data?.order?.type == "package" && data?.status?.name == "partial") {
      setShowInstallmentModal(true)
    } else if (data?.order?.type == "package" && data?.status?.name == "partial") {
      let apiData = {
        id:
          data?.type === "appointment" ? data?.appointment?.id : data?.order?.id,
        type: data?.type,
        partial_payment: true
      };
      const res = await dispatch(generatePayment(apiData))
      if (res == false) {
        return;
      }
      // dispatch(myPayment({ id: user?.id }))
      window.open(res);
      window.focus();
    } else {

      let apiData = {
        id:
          data?.type === "appointment" ? data?.appointment?.id : data?.order?.id,
        type: data?.type,
        partial_payment: true
      };
      const res = await dispatch(generatePayment(apiData))
      if (res == false) {
        return;
      }
      // dispatch(myPayment({ id: user?.id }))
      window.open(res);
      window.focus();
    };
  }
  const handleChange = (value, data) => {
    setdata(data)
    if (value === "Pay") {
      //paymentpaid
      // t("dashboards.alldashboardkeys.tablekeys.successful")
      data?.status?.name === 'paid' ? toast.error(t("dashboards.alldashboardkeys.tablekeys.paymentpaid"), "Error") : paymentHandler(data)
    } else if (value == "Partial Pay") {
      data?.status?.name === 'paid' ? toast.error("Payment Is Already Paid", "Error") : partialPaymentHandler(data)

    }
    else if (value == "Receipt") {
      data?.receipt_url === "" ? toast.error(t("dashboards.alldashboardkeys.tablekeys.reciptnotavailable"), "Error") : window.open(data?.receipt_url, "_blank")
    } else { }
  }
  return (
    <>
      <div>
        <div className="row mt-4">
          <div className="col">
            <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.mypayment")}</h4>
          </div>
        </div>
        <div className="row">
          {loader ? (
            <Loader />
          ) : (
            <BootstrapCustomTable
              columns={[
                {
                  formatter: (cell, row, index) => {
                    return <span>{capitalize(row?.type)}</span>;
                  },
                  dataField: "type",
                  text: t("dashboards.alldashboardkeys.tablekeys.type"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{row?.order?.type ? row?.order?.type : row?.type}</span>;
                  },
                  dataField: "order.type",
                  text: "Order Type",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.tax)}</span>;
                  },
                  dataField: "tax",
                  text: t("dashboards.alldashboardkeys.tablekeys.tax"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.total_amount)}</span>;
                  },
                  dataField: "total_amount",
                  text: t("dashboards.alldashboardkeys.tablekeys.ammount"),
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.remaining_amount)}</span>;
                  },
                  dataField: "remaining_amount",
                  text: "Remaining Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.installments_amount)}</span>;
                  },
                  dataField: "installments_amount",
                  text: "Installment Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return <span>{comaSeperated(row?.paid_amount)}</span>;
                  },
                  dataField: "paid_amount",
                  text: "Paid Amount",
                },
                {
                  formatter: (cell, row, index) => {
                    return (
                      <span
                        style={{ color: checkPaidOrUnpaid(row?.status?.name) }}
                      >
                        {row?.status?.name?.toUpperCase()}
                      </span>
                    );
                  },
                  dataField: "status.name",
                  text: t("dashboards.alldashboardkeys.tablekeys.paymentstatus"),
                },
                {
                  formatter: (cell, row) => {

                    return (
                      <div className="row">
                        <div className="col">
                          {
                            row?.status?.id == 1 && row?.order?.type == "package" ?
                              <ActionBtn
                                options={[
                                  t("dashboards.alldashboardkeys.tablekeys.paybtn"),
                                  // t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                ]}
                                handleChange={handleChange}
                                data={row}
                              /> : row?.status?.id == 1 && row?.order?.type != "package" ?
                                <ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.paybtn"),
                                    // t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                    // t("dashboards.alldashboardkeys.tablekeys.partiallypay"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : row?.status?.id == 2 ? <ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : row?.status?.id == 4 && row?.order?.type == "package" ? < ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                /> : < ActionBtn
                                  options={[
                                    t("dashboards.alldashboardkeys.tablekeys.paybtn"),

                                    t("dashboards.alldashboardkeys.tablekeys.receipt"),
                                  ]}
                                  handleChange={handleChange}
                                  data={row}
                                />
                          }
                        </div>
                      </div>
                    );
                  },

                  text: t("dashboards.alldashboardkeys.tablekeys.action"),
                },
              ]}
              tabledata={payments}
            />
          )}
        </div>
      </div>
      <AddInstallmentAmount
        show={showInstallmentModal}
        orderData={data}
        onHide={() => setShowInstallmentModal(false)}
        {...props}
      />
    </>
  );
};

export default PaymentProfile;

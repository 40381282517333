import React from "react";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import BannerImg from "../../Statics/Images/about-breadcrumb-bg.jpg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import BookCovidTest from "../LayOuts/bookCovidTest/BookCovidTest";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";
const CovidBookingTest = () => {
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language)
  const covid19test = filterComponentData(componetcms, 'covid19test', lang)
  const {t,i18n} = useTranslation("common")
  const banner = filterComponentData(componetcms, "banners", lang);

  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[2]?.sublink1 ?banner[2]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[2]?.sublink2 ?banner[2]?.sublink2:"Covid 19",
      subDash: ">",
      subLink: "/booking-for-covid-19-test",
    },
    {
      subLinkName: banner[2]?.sublink3 ?banner[2]?.sublink3:"Covid 19 Test",
      subDash: ">",
      subLink: "/booking-for-covid-19-test",
    },
    
   
  ];
  return (
    <>
    <Helmet>
        <title>
        {t("covid19booking.maintitle")}</title>
      </Helmet>
   
       <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[2]?.image ? banner[2]?.image : BannerImg})`}
        heading={banner[2]?.heading ? banner[2]?.heading : "Covid 19 Test"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <BlueHeading heading={covid19test?.tabheading ?covid19test?.tabheading:t("covid19booking.banner.title")} />
      <div className="container mb-5">
        <BookCovidTest />
      </div>
    </>
  );
};

export default CovidBookingTest;

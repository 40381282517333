import React from 'react'
import { Helmet } from 'react-helmet'

const Histopathology = () => {
    return (
        <>
          <Helmet>
        <title>
        Histopathology - DMFR Molecular Lab and Diagnostics </title>
      </Helmet>   
            Histopathology
        </>
    )
}

export default Histopathology

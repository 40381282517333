import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import success from "../../Statics/Images/success.png";
import { getUser } from "../../Store/Actions/loginActions";
const PymentSuccess = (props) => {
  const userRole = JSON.parse(localStorage.getItem("userRole"));
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUser());

  }, [])

  const profileHandler = () => {
    if (userRole == "doctor") {
      props.history.push("/doctor-dashboard");
    } else if (userRole == "administrator") {
      props.history.push("/administrator");
    } else if (userRole == "pharmacy") {
      props.history.push("/pharmacy");
    } else if (userRole == "laboratory-admin") {
      props.history.push("/lab-administrator");
    } else if (userRole == "patient") {
      props.history.push("/patient-dashboard");
    } else {
      props.history.push("/");
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Payment - DMFR Molecular Lab and Diagnostics
        </title>
      </Helmet>
      <div className="center-childrens" style={{ height: "100vh" }}>
        <div
          className="paymentSuccessBackground w-100 d-none d-lg-flex"
          style={{ height: "100vh", display: "flex", alignItems: "center" }}
        >
          <div className="col-lg-6 text-center ml-5 pl-5 d-none d-lg-block">
            <span style={{ color: "#1a4774", fontSize: "70px" }}>
              Payment Successful
            </span>
            <span
              style={{ display: "block", fontSize: "34px", fontWeight: "400" }}
              className="mb-4"
            >
              Thank You For Your Purchase
            </span>
            <span
              style={{ display: "block" }}
              className="btn btn-primary  w-50 m-auto"
              onClick={profileHandler}
            >
              Home
            </span>
          </div>
        </div>
        <div className=" row  w-100 p-3 d-lg-none d-md-flex">
          <div className="col-md-6    containerWithShadow p-4  d-lg-none text-center m-auto ">
            <img
              src={success}
              alt="success"
              style={{ width: "150px", height: "150px" }}
            />
            <span
              style={{ display: "block", color: "#1a4774", fontSize: "30px" }}
            >
              Payment Successful
            </span>
            <span
              style={{ display: "block", color: "#1a4774", fontSize: "16px" }}
              className="py-3"
            >
              Thank You For Your Purchase
            </span>
            <span
              style={{ display: "block" }}
              className="btn btn-primary mt-3  w-50 m-auto"
              onClick={profileHandler}
            >
              Home
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PymentSuccess;

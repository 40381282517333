export const allarray = [
  {
    subLinkName: "Telemedicine",
    subDash: "",
    subLink: "/telemedicine",
  },
  {
    subLinkName: "Covid 19 Info",
    subDash: "",
    subLink: "/covid-19-info",
  },
  {
    subLinkName: "Covid 19 Live Updates",
    subDash: "",
    subLink: "https://corona.gov.bd/",
  },
  {
    subLinkName: "Booking For Covid 19 Test",
    subDash: "",
    subLink: "/booking-for-covid-19-test",
  },
  {
    subLinkName: "Covid 19 Team",
    subDash: "",
    subLink: "/covid-19-team",
  },
  {
    subLinkName: "Why DMFR Is Your Best Choice For Your Healthcare",
    subDash: "",
    subLink: "/about-us",
  },
  {
    subLinkName: "Managing Director Message",
    subDash: "",
    subLink: "/managing-director-message",
  },
  {
    subLinkName: "Mission & Vision",
    subDash: "",
    subLink: "/our-mission",
  },
  {
    subLinkName: "LAB CONSULTANTS",
    subDash: "",
    subLink: "/lab-consultants",
  }, {
    subLinkName: "LAB TEAM",
    subDash: "",
    subLink: "/lab-team",
  }, {
    subLinkName: "OFFICIALS",
    subDash: "",
    subLink: "/officials",
  }, {
    subLinkName: "Pathology",
    subDash: "",
    subLink: "/pathology-services",
  }, {
    subLinkName: "Radiology & Imaging",
    subDash: "",
    subLink: "/radiology-imaging-services",
  }, {
    subLinkName: "Cardiology",
    subDash: "",
    subLink: "/cardiology-services",
  }, {
    subLinkName: "Online Consultation",
    subDash: "",
    subLink: "/online-consultants",
  }, {
    subLinkName: "Collection at Home",
    subDash: "",
    subLink: "/collection-home",
  }, {
    subLinkName: "Collection at Corporate",
    subDash: "",
    subLink: "/collection-at-corporate",
  }, {
    subLinkName: "Male",
    subDash: "",
    subLink: "/packages-male",
  }, {
    subLinkName: "Female",
    subDash: "",
    subLink: "/packages-female",
  }, {
    subLinkName: "News",
    subDash: "",
    subLink: "/news",
  }, {
    subLinkName: "Gallery",
    subDash: "",
    subLink: "/gallery",
  }, {
    subLinkName: "Download Brochure",
    subDash: "",
    subLink: "/statics/DMFR-Brochure.pdf",
  }, {
    subLinkName: "Report Portal",
    subDash: "",
    subLink: "https://icare.mdxdmfr.com/medicare-web-client-v2/#/login",
  }, {
    subLinkName: "Contact Us",
    subDash: "",
    subLink: "/contact",
  },
  
  
];


import React from "react";

import { Modal } from "react-bootstrap";

import { useTranslation } from "react-i18next";


export default function AlertRefund(props) {

    const { t } = useTranslation("common")


    return (

        <>

            <Modal

                {...props}

                size="md"

                aria-labelledby="contained-modal-title-vcenter"

                centered

            >

                <Modal.Header className="dashboardBtnList-item-active">

                    <Modal.Title id="contained-modal-title-vcenter">

                        <span className="text-white">{"Refund"}</span>

                    </Modal.Title>

                    <button type="button" class="close" onClick={props?.onHide}>

                        <span aria-hidden="true" className="text-white">

                            ×

                        </span>

                        <span class="sr-only">{t("dashboards.alldashboardkeys.tablekeys.close")}</span>

                    </button>

                </Modal.Header>

                <Modal.Body>

                    <div className="row px-3">

                        <form className="w-100">

                            <div

                                className="row"

                                style={{ display: "flex", justifyContent: "center" }}

                            >

                                <div className="col-lg-12">

                                    <h6 className="label-name-login">{"You cannot refund this appoitment because appoitment hours left are less then 6"}</h6>


                                </div>




                                <input

                                    value={"CLOSE"}

                                    onClick={props.onHide}

                                    className="headerBtn-red btn-block mx-auto"

                                    style={{ width: "85%", textAlign: "center", cursor: 'pointer' }}

                                />

                            </div>

                        </form>

                    </div>

                </Modal.Body>

            </Modal >

        </>

    )

}
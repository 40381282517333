import React from "react";

const FooterComponentsTable = ({ tableHeaders, tableBodyData }) => {
  return (
    <>
      <div className="table-responsive-md">
        <table className="table  table-bordered">
          <thead style={{ backgroundColor: "#3c77b1", color: "#fff" }}>
            <tr>
              {tableHeaders?.map((item, index) => (
                <th scope={index === 1 ? "col-5" : "col-1"} style={{width: index === 1 ? "700px" :'150px'}} key={index + 1}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ color: "#787878" }}>
            {tableBodyData?.map((item, index) => (
              <tr key={index + 2}>
                {item?.map((_item, index) => (
                  <th scope="row" style={{ color: "#787878" }} key={index + 3}>
                    {_item}
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FooterComponentsTable;

import React, { useEffect, useState } from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import officalBannerImg from "../../Statics/Images/banner/Official-background.jpg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import TextAndImageSection from "../ReUseableComponents/TextAndImageSection";
import itTeam from "../../Statics/Images/it-team.jpg";
import pmdTeam from "../../Statics/Images/PMD-Team.jpg";
import accountantTeam from "../../Statics/Images/accountant_team.jpg";
import marketingTeam from "../../Statics/Images/marketing-team.jpg";
import hrTeam from "../../Statics/Images/hr-team.jpg";
import frontDesk from "../../Statics/Images/front_desk.jpg";
import SupplychainTeam from "../../Statics/Images/Supplychain-team.jpg";
import OperationTeam from "../../Statics/Images/opertaion_team.jpg";
import Phlebo from "../../Statics/Images/phlebo.jpeg";
import HouseKeeping from "../../Statics/Images/house-keeping.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const Officials = () => {
  const { t, i18n } = useTranslation("common");
  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state?.cms?.language);
  const officals = filterComponentData(componetcms, "officials", lang);
  const official_header = filterComponentData(
    componetcms,
    "official_header",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);

  useEffect(() => {
    if (officals?.length > 1) setSelectedTab(officals[0]?.tab);
    // eslint-disable-next-line
  }, [officals]);
  const [selectedTab, setSelectedTab] = useState(
    t("officials.labteamhandler.buttonname1")
  );
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[9]?.sublink1 ?banner[9]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[9]?.sublink2 ?banner[9]?.sublink2:"Our Team",
      subDash: ">",
      subLink: "/officials",
    },
    {
      subLinkName: banner[9]?.sublink3 ?banner[9]?.sublink3:"Officials",
      subDash: ">",
      subLink: "/officials",
    },
  ]
  return (
    <>
      <Helmet>
        <title>{t("officials.maintitle")}</title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[9]?.image ? banner[9]?.image : officalBannerImg})`}
        heading={banner[9]?.heading ? banner[9]?.heading : "Officials"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="mt-5">
        <BlueHeading
          heading={
            official_header?.heading
              ? official_header?.heading
              : t("officials.mainheading1")
          }
        />
      </div>
      {officals?.length < 1 ? (
        <div className="container  mb-5">
          {[
            t("officials.labteamhandler.buttonname1"),
            t("officials.labteamhandler.buttonname2"),
            t("officials.labteamhandler.buttonname3"),
            t("officials.labteamhandler.buttonname4"),
            t("officials.labteamhandler.buttonname5"),
            t("officials.labteamhandler.buttonname6"),
            t("officials.labteamhandler.buttonname7"),
            t("officials.labteamhandler.buttonname8"),
            t("officials.labteamhandler.buttonname9"),
            t("officials.labteamhandler.buttonname10"),
          ].map((item, index) => (
            <div
              className="d-flex d-inline-flex"
              key={index + 1}
              onClick={() => setSelectedTab(item)}
            >
              <div
                className={
                  selectedTab === item ? "labTeamBtnActive" : "labTeamBtn "
                }
                style={{ padding: " 10px 13px 10px 13px" }}
              >
                <span
                  className={selectedTab === item ? "text-white" : ""}
                  style={{ fontSize: "13px" }}
                >
                  {item}
                </span>
              </div>
            </div>
          ))}
          {selectedTab === t("officials.labteamhandler.buttonname1") ? (
            <>
              <TextAndImageSection
                imgSrc={itTeam}
                imgAlt={"Information Technology (IT) Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname1detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname1detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "20px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "officials.labteamhandler.buttonname1detail.discriptiontxt2"
                        )}
                      </p>
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname2") ? (
            <>
              <TextAndImageSection
                imgSrc={pmdTeam}
                imgAlt={"Product Management Department (PMD)"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname2detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname2detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "20px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "officials.labteamhandler.buttonname2detail.discriptiontxt2"
                        )}
                      </p>
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname3") ? (
            <>
              <TextAndImageSection
                imgSrc={accountantTeam}
                imgAlt={"Accounts & Finance Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname3detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname3detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      <p
                        style={{
                          fontFamily: "Work Sans', sans-serif",
                          lineHeight: "25px",
                          marginTop: "20px",
                          textAlign: "justify",
                        }}
                        className="light-Color-Para"
                      >
                        {t(
                          "officials.labteamhandler.buttonname3detail.discriptiontxt2"
                        )}
                      </p>
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname4") ? (
            <>
              <TextAndImageSection
                imgSrc={marketingTeam}
                imgAlt={"Business Development (BD) Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname4detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname4detail.discriptiontxt1"
                      )}
                    </p>

                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname4detail.discriptiontxt2"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname5") ? (
            <>
              <TextAndImageSection
                imgSrc={hrTeam}
                imgAlt={"Human Resource (HR) Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname5detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname5detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname6") ? (
            <>
              <TextAndImageSection
                imgSrc={frontDesk}
                imgAlt={"Frontdesk Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname6detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname6detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : //  : selectedTab === "BD Team" ? (
          //   <>
          //     <TextAndImageSection
          //       imgSrc={marketingTeam}
          //       imgCol={"col-lg-5"}
          //       textCol={"col-lg-7"}
          //       subHeading={"BUSINESS DEVELOPMENT (BD) TEAM"}
          //       subHeadingStyle={{ margin: "0px" }}
          //       discription={
          //         <>
          //           <p
          //             style={{
          //               fontFamily: "Work Sans', sans-serif",
          //               lineHeight: "25px",
          //               margin: "0px",
          //               textAlign: "justify",
          //             }}
          //             className="light-Color-Para"
          //           >
          //             Our BD team works sincerely and honestly for planning and
          //             overseeing new marketing initiatives. We give accurate and
          //             worthwhile information about our lab & Test to our KOL
          //             doctors & Patients. We are increasing the value of current
          //             customers while attracting new ones. Finding and developing
          //             new markets and improving sales.
          //           </p>

          //           <p
          //             style={{
          //               fontFamily: "Work Sans', sans-serif",
          //               lineHeight: "25px",
          //               marginTop: "20px",
          //               textAlign: "justify",
          //             }}
          //             className="light-Color-Para"
          //           >
          //             All Our BD Team members are highly experienced & most of
          //             them are completed post post-graduation in science &
          //             marketing. They have adequate knowledge about our Test &
          //             services especially Molecular, Flow Cytometry and others
          //             specialized tests.
          //           </p>
          //         </>
          //       }
          //       reverse={true}
          //     />
          //   </>
          // )
          // : selectedTab === "HR Team" ? (
          //   <>
          //     <TextAndImageSection
          //       imgSrc={hrTeam}
          //       imgCol={"col-lg-5"}
          //       textCol={"col-lg-7"}
          //       subHeading={"HUMAN RESOURCE (HR) TEAM"}
          //       subHeadingStyle={{ margin: "0px" }}
          //       discription={
          //         <>
          //           <p
          //             style={{
          //               fontFamily: "Work Sans', sans-serif",
          //               lineHeight: "25px",
          //               margin: "0px",
          //               textAlign: "justify",
          //             }}
          //             className="light-Color-Para"
          //           >
          //             With the emerging blooming and transition in Healthcare
          //             Industry, We HR should adapt the latest methods to compete
          //             and sustain in the competitive healthcare sectors. We HR
          //             also extend to monitor the rules & regulations governing the
          //             Health care system in relation to its employees. For
          //             delivering quality healthcare, identifying the root cause of
          //             errors, strategies to reduce chance of further errors, the
          //             gap analysis, incorporating appropriate available skills to
          //             close the gap, Diversifying the work among the available
          //             team, employing quality improvement methodologies to form an
          //             interdisciplinary team for better patient centred
          //             healthcare.
          //           </p>
          //         </>
          //       }
          //       reverse={true}
          //     />
          //   </>
          // )
          selectedTab === t("officials.labteamhandler.buttonname7") ? (
            <>
              <TextAndImageSection
                imgSrc={SupplychainTeam}
                imgAlt={"Supply Chain Management (SCM) Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname7detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname7detail.discriptiontxt1"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname8") ? (
            <>
              <TextAndImageSection
                imgSrc={OperationTeam}
                imgAlt={"Operational Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname8detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname8detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname8detail.discriptiontxt2"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname9") ? (
            <>
              <TextAndImageSection
                imgSrc={Phlebo}
                imgAlt={"Phlebotomy Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname9detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname9detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname9detail.discriptiontxt2"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname9detail.discriptiontxt3"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : selectedTab === t("officials.labteamhandler.buttonname10") ? (
            <>
              <TextAndImageSection
                imgSrc={HouseKeeping}
                imgAlt={"Housekeeping Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={t(
                  "officials.labteamhandler.buttonname10detail.subHeading"
                )}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname10detail.discriptiontxt1"
                      )}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {t(
                        "officials.labteamhandler.buttonname10detail.discriptiontxt2"
                      )}
                    </p>
                  </>
                }
                reverse={true}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className="container  mb-5">
          {officals?.map((item, index) => (
            <div
              className="d-flex d-inline-flex"
              key={index + 1}
              onClick={() => setSelectedTab(item?.tab)}
            >
              <div
                className={
                  selectedTab === item?.tab ? "labTeamBtnActive" : "labTeamBtn "
                }
                style={{ padding: " 10px 13px 10px 13px" }}
              >
                <span
                  className={selectedTab === item?.tab ? "text-white" : ""}
                  style={{ fontSize: "13px" }}
                >
                  {item?.tab}
                </span>
              </div>
            </div>
          ))}
          {officals?.map((item) =>
            item?.tab == selectedTab ? (
              <TextAndImageSection
                imgSrc={item?.image}
                imgAlt={"Housekeeping Team"}
                imgCol={"col-lg-5"}
                textCol={"col-lg-7"}
                subHeading={item?.heading}
                subHeadingStyle={{ margin: "0px" }}
                discription={
                  <>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        margin: "0px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {item?.desc1}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {item?.desc2}
                    </p>
                    <p
                      style={{
                        fontFamily: "Work Sans', sans-serif",
                        lineHeight: "25px",
                        marginTop: "20px",
                        textAlign: "justify",
                      }}
                      className="light-Color-Para"
                    >
                      {item?.desc3}
                    </p>
                  </>
                }
                reverse={true}
              />
            ) : null
          )}
        </div>
      )}
    </>
  );
};

export default Officials;

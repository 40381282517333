import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashBoardMainHading from "../../Components/ReUseableComponents/DashBoardMainHading";
import { checkPaidOrUnpaid, comaSeperated } from "../../Utils/functions";
import { getOrdersByEmail } from "../../Store/Actions/administratorActions";
import ProfileTable from "../patientDashboard/ProfileTable";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/ReUseableComponents/Loader";

const ManageReportDetails = (props) => {
  const { t } = useTranslation("common");
  const viewData = props.location.state;
  const loader = useSelector((state) => state?.logIn?.loader);
  const item = { key: "AppointmentDetails" };
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.administrator.orders);
  useEffect(() => {
    dispatch(getOrdersByEmail({ email_address: viewData?.email_address }));
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <DashBoardMainHading
        title={t("dashboards.alldashboardkeys.tablekeys.appointmentdetail")}
      />
      <div className="container">
        <div className="row center-childrens">
          <div className="col-xl-8 col-md-8 col-12 ">
            <div
              className="btn edit-patient-btn"
              style={{ width: "150px" }}
              onClick={() => {
                props.history.push("/administrator", item);
              }}
            >
              <i className="fas fa-arrow-left"></i>
              {t("dashboards.alldashboardkeys.tablekeys.backbtn")}
            </div>
          </div>
        </div>
        <div className="row center-childrens mt-3 pb-5">
          <div className="col-xl-8 col-md-8 col-12 containerWithShadow p-4">
            <div className="d-flex justify-content-between">
              <h5>
                {t("dashboards.alldashboardkeys.tablekeys.patientdetail")}
              </h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientname")}
                </span>
                <p>{viewData?.name}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientemail")}
                </span>
                <p>{viewData?.email_address}</p>
              </li>{" "}
              <li>
                <span
                  className="light-Color-Para"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {t("dashboards.alldashboardkeys.tablekeys.patientphone")}
                </span>
                <p>{viewData?.phone_number}</p>
              </li>{" "}
            </ul>
            {loader ? (
              <Loader />
            ) : (
              <div>
                <h5>Patient Test Orders</h5>
                {orders?.length > 0 ? (
                  <>
                    <ProfileTable
                      tableHead={[
                        "ID",
                        // "Date",
                        t("dashboards.alldashboardkeys.tablekeys.price"),
                        t(
                          "dashboards.alldashboardkeys.tablekeys.deliverystatus"
                        ),
                        t(
                          "dashboards.alldashboardkeys.tablekeys.paymentstatus"
                        ),
                        t("dashboards.alldashboardkeys.tablekeys.action"),
                      ]}
                      data={orders?.map((item) => {
                        return [
                          item?.order_id,
                          //   item?.date,
                          comaSeperated(item?.total_amount),
                          item?.delivery_status?.name,
                          <span
                            style={{
                              color: checkPaidOrUnpaid(item?.paid_status?.name),
                            }}
                          >
                            {item?.paid_status?.name}
                          </span>,
                          // eslint-disable-next-line
                          <div
                            className="btn btn-success"
                            onClick={() => {
                              props?.history?.push("/test-details", {
                                ...item,
                                from: "administrator",
                              });
                            }}
                          >
                            {t(
                              "dashboards.alldashboardkeys.tablekeys.viewtest"
                            )}
                          </div>,
                        ];
                      })}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReportDetails;

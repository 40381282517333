import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";



import RescheduleModal from "../../Components/LayOuts/modals/rescheduleModal/RescheduleModal";

import ActionBtn from "../../Components/ReUseableComponents/ActionBtn";

import { useTranslation } from "react-i18next";

import { cancelAppointment, patientAppointments } from "../../Store/Actions/patientActions";

import { readUpcomingAppointmentAction } from "../../Store/Actions/administratorActions";

import { toast } from "react-toastify";

import RefundModal from "../../Components/LayOuts/modals/refundAmountModal/RefundModal";

import AlertRefund from "../../Components/LayOuts/modals/alertrefund/AlertRefund";


export default function AdministratorUpcomingAppointment(props) {

    const [showModal, setShowModal] = useState(false)

    const date = new Date()


    const { t } = useTranslation("common")

    const [show, setShow] = useState(false)

    const [id, setID] = useState("")

    const user = useSelector((state) => state?.logIn?.user);

    const dispatch = useDispatch()


    const appointments = useSelector(

        (state) => state?.administrator?.upcoming

    );

    const handleChange = async (value, row) => {

        if (value === "View") {

            props?.history?.push("/patient-dashboard-appointment-details", { row, from: "upcoming" });

        }

        else if (value == "Cancel") {

            let res = await dispatch(cancelAppointment({ appointment_id: row?.id }))

            dispatch(readUpcomingAppointmentAction());

            if (res) {

                toast.success("Appoitment is Cancelled")

            }

        } else if (value == "Cancel/Refund") {

            setID(row?.id)

            setShow(!show)

        }

        else {



        }

    };


    const defaultSorting = [{

        dataField: "date",

        order: "asc",

    },]

    return (

        <>

            <div className="w-100">

                <div className="row">

                    <div className="col-6">

                        <div className="p-3">

                            <span className="dashboardHeadingText">{t("dashboards.alldashboardkeys.tablekeys.upcomingappointment")}</span>

                        </div>

                    </div>



                </div>


                <BootstrapCustomTable

                    defaultsort={defaultSorting}


                    columns={[

                        {

                            formatter: (cell, row, index) => {

                                return <span>{index + 1}</span>;

                            },

                            text: "Sr.",

                        },

                        {


                            dataField: "date", text: t("dashboards.alldashboardkeys.tablekeys.date"),

                            sort: true,


                        },

                        {

                            formatter: (cell, row) => {

                                return <span>{`${row?.slot?.from} - ${row?.slot?.to}`}</span>;

                            },

                            text: t("dashboards.alldashboardkeys.tablekeys.time"),

                        },


                        { dataField: "doctor_details.name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },


                        { dataField: "consultation_type.name", text: t("dashboards.alldashboardkeys.tablekeys.consultationtype") },

                        { dataField: "condition.name", text: "Condition" },


                        {

                            formatter: (cell, row) => {

                                return <span style={{ color: row?.status?.name == "booked" ? 'red' : row?.status?.name == "confirmed" ? 'green' : '#970165' }}>{row?.status?.name?.toUpperCase()}</span>;

                            },

                            text: t("dashboards.alldashboardkeys.tablekeys.status"),

                        },

                        {

                            formatter: (cell, row, index) => {

                                return (

                                    row?.status?.name == "booked" && row?.consultation_type?.name == "online"  ?

                                        <ActionBtn

                                            options={["Cancel"]}

                                            handleChange={handleChange}

                                            data={row}

                                        />

                                        : row?.status?.name == "confirmed" && row?.consultation_type?.name == "online" ? <ActionBtn

                                            options={["Cancel", "Cancel/Refund"]}

                                            handleChange={handleChange}

                                            data={row}

                                        /> : row?.status?.name == "confirmed" && row?.consultation_type?.name == "walk-in" ? <ActionBtn

                                            options={["Cancel", "Cancel/Refund"]}

                                            handleChange={handleChange}

                                            data={row}

                                        /> : <ActionBtn

                                            options={[]}

                                            handleChange={handleChange}

                                            data={row}

                                        />

                                );

                            },

                            text: t("dashboards.alldashboardkeys.tablekeys.action"),

                        },

                    ]}

                    tabledata={appointments ? appointments : []}

                />

            </div>

            <RefundModal

                show={show}

                id={id}

                onHide={() => setShow(false)}

            />

            <AlertRefund

                show={showModal}

                onHide={() => setShowModal(false)}


            />

        </>


    )

}
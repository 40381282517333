import React from "react";
import collectionathome from "../../Statics/Images/banner/collectionathome.jpg";
import Newscard from "../LayOuts/Cards/newscard/newscard";
import BannerWithText from "../ReUseableComponents/BannerWithText";
import HeadingWithBottomBar from "../ReUseableComponents/HeadingWithBottomBar";

import img1 from "../../Statics/Images/news/1.png";
import img2 from "../../Statics/Images/news/2.jpg";
import img3 from "../../Statics/Images/news/3.jpg";
import img4 from "../../Statics/Images/news/4.jpg";
import img5 from "../../Statics/Images/news/5.png";
import img6 from "../../Statics/Images/news/6.jpg";
import img7 from "../../Statics/Images/news/7.jpg";
import img8 from "../../Statics/Images/news/8.jpg";
import img9 from "../../Statics/Images/news/9.jpg";
import img10 from "../../Statics/Images/news/10.jpg";
import img11 from "../../Statics/Images/news/11.png";
import Socialmediacards from "../LayOuts/Cards/socialMediaCards/socialmediacards1";
import Socialmediacards2 from "../LayOuts/Cards/socialMediaCards/Socialmediacards2";
import Socialmediacards3 from "../LayOuts/Cards/socialMediaCards/Socialmediacards3";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { filterComponentData } from "../../Utils/functions";
import { useEffect } from "react";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const News = (props) => {
  const { t, i18n } = useTranslation("common");

  const componetcms = useSelector((state) => state.cms.components);

  const dispatch = useDispatch();

  const lang = localStorage.getItem("lang");
  const newspage = filterComponentData(
    componetcms,
    "news_and_media_heading",
    lang
  );
  // const newscard = filterComponentData(componetcms, 'new_media_cards', lang)
  const newscardanddetail = filterComponentData(
    componetcms,
    "news_card_and_detail",
    lang
  );
  const newssocialmediacard = filterComponentData(
    componetcms,
    "new_social_media_post_cards",
    lang
  );
  const banner = filterComponentData(componetcms, "banners", lang);
  useEffect(() => {
    // eslint-disable-next-line
  }, [newscardanddetail]);
  const newcarddata = [
    {
      img: img1,
      headingName: t("news.newscarddata.new1.head"),
      headingLink: "/news-details",
      new_date: "21 December 2020",
      listData: ["", ""],
      description:
        "We are pleased to introduce Bio Safety Level 3 facility in Bangladesh for the first time in the private sector.",
    },
    //     {
    //       img: img2,
    //       headingName: t("news.newscarddata.new2.head"),
    //       headingLink: "/news-details",
    //       new_date: "20 December 2020",
    //       listData: [
    //         "HBV DNA",
    //         "HLA-B27",
    //         "Tropical Fever Panel",
    //         "MTB/MDR TB, PCR",
    //         "HCV RNA/Genotyping",
    //       ],
    //       description:
    //       "We are pleased to introduce Bio Safety Level 3 facility in Bangladesh for the first time in the private sector.",
    //       description1: (
    //         <p>
    //           Call on our helpline number- 10649
    //           <br />
    //           **Offer Valid Till 31st December, 2020
    //         </p>
    //       ),
    //     },
    //     {
    //       img: img3,
    //       headingName: t("news.newscarddata.new3.head"),
    //       headingLink: "/news-details",
    //       new_date: "5 August 2019",
    //       listData: ["", ""],
    //       description: (
    //         <>
    //           EW &nbsp;{" "}
    //           Villa Medica Bangladesh and Concord Pharmaceuticals{" "}
    //           <span >
    //           We are pleased to introduce Bio Safety Level 3 facility in Bangladesh for the first time in the private sector.
    // DMFR has invested in state-of-art technology with Laboratory Information Solution and Enterprise resource programs for all the operations.
    //           </span>
    //           <br />
    //           Hotline for Appointment: +8809606213233
    //         </>
    //       ),
    //     },
    {
      img: img4,
      headingName: t("news.newscarddata.new4.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img5,
      headingName: t("news.newscarddata.new5.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img6,
      headingName: t("news.newscarddata.new6.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img7,
      headingName: t("news.newscarddata.new7.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img8,
      headingName: t("news.newscarddata.new8.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img9,
      headingName: t("news.newscarddata.new9.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
    {
      img: img10,
      headingName: t("news.newscarddata.new10.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },

    {
      img: img11,
      headingName: t("news.newscarddata.new11.head"),
      headingLink: "/news-details",
      new_date: "Date goes here..",
      listData: ["", ""],
      description: "Description Goes here...",
    },
  ];
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName: banner[18]?.sublink1 ? banner[18]?.sublink1 : "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[18]?.sublink2 ? banner[18]?.sublink2 : "News & Media",
      subDash: ">",
      subLink: "/news",
    },
    {
      subLinkName: banner[18]?.sublink3 ? banner[18]?.sublink3 : "News",
      subDash: ">",
      subLink: "/news",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("news.maintitle")}</title>
      </Helmet>
      {/* img banner */}
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${
          banner[18]?.image ? banner[18]?.image : collectionathome
        })`}
        heading={banner[18]?.heading ? banner[18]?.heading : "News & Media"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <div className="container title-wrap text-center">
        <div className="breadcrumb-content my-0">
          <a
            href="#news"
            style={{
              color:
                window.location?.hash === "#news" ||
                window.location?.hash === ""
                  ? "red"
                  : "black",
            }}
            className="mr-2 font-weight-bold"
          >
            {newspage?.news ? newspage?.news : t("news.tabheading2")}
          </a>
          <span>|</span>
          <a
            href="#social_media"
            // style={{ color: "#0e5684" }}
            style={{
              color:
                window.location?.hash === "#social_media" ? "red" : "black",
            }}
            className="ml-2 font-weight-bold"
          >
            {newspage?.socialmedia
              ? newspage?.socialmedia
              : t("news.tabheading1")}
          </a>
        </div>
      </div>
      <HeadingWithBottomBar
        heading={
          newspage?.latestpost ? newspage?.latestpost : t("news.mainheading1")
        }
        margStyle={{ margin: "0px" }}
      />
      <div className="container" id="news">
        <div className="row">
          {newscardanddetail?.length < 1
            ? newcarddata?.map((item, index) => (
                <Newscard
                  headingName={item?.headingName}
                  description={item?.description}
                  description1={item?.description1}
                  headingLink={item?.headingLink}
                  new_date={item?.new_date}
                  listData={item?.listData}
                  img={item?.img}
                  list={[...newcarddata]}
                  data={item}
                  {...props}
                />
              ))
            : newscardanddetail?.map((item, index) => {
                return (
                  <Newscard
                    headingName={item?.heading}
                    description={item?.desc}
                    // description1={item?.description}
                    headingLink={"/news-details"}
                    new_date={item?.date}
                    // listData={item['New Info']}
                    img={item?.image}
                    data={item}
                    list={[...newscardanddetail]}
                    com={item?.company}
                    button={item?.readMore}
                    {...props}
                  />
                );
              })}
        </div>
      </div>

      <div className="container title-wrap text-center">
        <div className="breadcrumb-content my-0">
          <a
            href="#news"
            style={{
              color:
                window.location?.hash === "#news" ||
                window.location?.hash === ""
                  ? "red"
                  : "black",
            }}
            className="mr-2 font-weight-bold"
          >
            {newspage?.news ? newspage?.news : t("news.tabheading2")}
          </a>
          <span style={{ color: "#0e5684" }}>|</span>
          <a
            href="#social_media"
            style={{
              color:
                window.location?.hash === "#social_media" ? "red" : "black",
            }}
            className="ml-2 font-weight-bold"
          >
            {newspage?.socialmedia
              ? newspage?.socialmedia
              : t("news.tabheading1")}
          </a>
        </div>
      </div>
      <HeadingWithBottomBar
        heading={
          newspage?.socialmediapost
            ? newspage?.socialmediapost
            : t("news.mainheading2")
        }
        margStyle={{ margin: "0px" }}
      />

      <div className="container mb-5" id="social_media">
        <div className="row">
          <Socialmediacards2 data={newssocialmediacard[0]} {...props} />
          <Socialmediacards2 data={newssocialmediacard[1]} {...props} />
          <Socialmediacards2 data={newssocialmediacard[2]} {...props} />
          {/* <Socialmediacards3 data={newssocialmediacard[2]} {...props} /> */}
          {/* <Socialmediacards data={newssocialmediacard[1]} {...props} /> */}
        </div>
      </div>
    </>
  );
};

export default News;

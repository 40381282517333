// import axios from "axios";
import i18next from "i18next";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
// import { getToken } from "../../Utils/auth.util";
import { labAdminConstants } from "../Constants/labAdminConstants";
import { signInConstansts } from "../Constants/loginConstant";
export const getLabTests = () => async (dispatch) => {
  dispatch({
    type: signInConstansts.SET_LOADER,
    payload: true,
  });
  try {
    const response = await apiServices.getAllOrders();
    if (response?.data?.response_code === 200) {
      dispatch({
        type: labAdminConstants.GET_ALL_TESTS,
        payload: response?.data?.response_data?.order,
      });
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    } else {
      toast.error(response?.data?.response_message);
      dispatch({
        type: signInConstansts.SET_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("Login error", error);
  }
};

export const changeResportStatus = (data, id) => async (dispatch) => {
  
  
  try {
    const response = await apiServices.changeResportStatus(data);
    if (response?.response_code === 200) {
      toast.info(i18next.t("common:toastmessages.actioncompleted"));
      if (id) {
        return response?.response_data?.report;
      }
    } else {
      toast.error(response?.response_message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTestResports = () => async (dispatch) => {
  await apiServices.getTestResports();
};


import React from "react";
import { Link } from "react-router-dom";
import "./loginModal.css";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../../Store/Actions/loginActions";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
const LoginModal = (props) => {
  const loader = useSelector((state) => state?.logIn?.loader);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const apiData = {
      email_address: data.email,
      password: data.password,
      fcm_token: localStorage.getItem("fcm"),
    };
    const type = await dispatch(loginUser(apiData));
    if (type === "doctor") {
      props.history.push("/doctor-dashboard");
      props.onHide();
    } else if (type === "patient") {
      props.history.push("/patient-dashboard");
      props.onHide();
    } else if (type === "administrator") {
      props.history.push("/administrator");
      props.onHide();
    } else if (type === "laboratory_admin") {
      props.history.push("/lab-administrator");
      props.onHide();
    } else if (type === "pharmacy") {
      props.history.push("/pharmacy");
      props.onHide();
    }
  };
  const { t } = useTranslation("common");
  const onSuccess = (response) => console.log(response);
  const onFailure = (response) => console.error(response);
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("dashboards.alldashboardkeys.tablekeys.signin")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.emailaddress")}
                  </span>
                  <input
                    className="input-login-modal"
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.email?.message ? (
                    <div className="text-error">{errors?.email?.message}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <span className="label-name-login">
                    {t("dashboards.alldashboardkeys.tablekeys.password")}
                  </span>
                  <input
                    className="input-login-modal"
                    type="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "this field is required field",
                      },
                    })}
                  />
                  {errors?.password?.message ? (
                    <div className="text-error">
                      {errors?.password?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              >
                {loader ? (
                  <Loader
                    height={20}
                    width={20}
                    type="ThreeDots"
                    color="#fff"
                  />
                ) : (
                  t("dashboards.alldashboardkeys.tablekeys.signin")
                )}
              </button>
              <a
                href="https://accounts.google.com/o/oauth2/v2/auth?client_id=417455767792-i13veq1ohqh7sg1tlg86d0eco536dpd2.apps.googleusercontent.com&redirect_uri=https://www.mdxdmfr.com/auth-number-verification&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
                className="headerBtn btn-block mx-auto"
                target="_blank"
                style={{ width: "85%", textAlign: "center" }}
              >
                <img
                  width="15px"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                  alt="Google login"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                />
                Google Sign In
              </a>
              <a
                href="https://www.facebook.com/v12.0/dialog/oauth?client_id=541247183991360&redirect_uri=https://www.mdxdmfr.com/auth-number-verification-facebook&scope=email,user_friends&response_type=token&auth_type=rerequest&display=popup"
                className="headerBtn btn-block mx-auto"
                target="_blank"
                style={{ width: "85%", textAlign: "center" }}
              >
                <img
                  width="15px"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                  alt="FB login"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                />
                Facebook Sign In
              </a>

              {/* <HeaderBtn
                label="Sign In"
                link="/patient-dashboard"
                className="headerBtn-red btn-block mx-auto"
                style={{ width: "85%", textAlign: "center" }}
              /> */}
            </form>
            <div className="mt-3">
              <Link
                className="signIn-link "
                onClick={props.forgetPasswordHandler}
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {`${t(
                  "dashboards.alldashboardkeys.tablekeys.forgotpassword"
                )}?`}
              </Link>
              <br />
              <span style={{ fontSize: "14px" }}>
                {`${t(
                  "dashboards.alldashboardkeys.tablekeys.donthaveanaccount"
                )}?`}
              </span>{" "}
              <span
                className="signIn-link"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.showSignUpModal();
                  props.onHide();
                }}
              >
                {t("dashboards.alldashboardkeys.tablekeys.registerwithus")}
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addMedicine,
  updateMedicine,
} from "../../../../Store/Actions/pharmacyActions";
import { fetchStaticData } from "../../../../Store/Actions/staticActions";

const AddPharmacyMedicine = (props) => {
  const {t} = useTranslation("common");
  const selectedMedi = useSelector((state) => state?.pharmacy?.selectedMedi);
  const [stateMedi, setstateMedi] = useState({});
  const [checkBox, setcheckBox] = useState(false);
  const dispatch = useDispatch();
  const mediTypeStaticData = useSelector((state) => state?.static?.staticData?.medicine_type);
  const mediRouteStaticData = useSelector((state) => state?.static?.staticData?.medicine_route);
  const mediUnitStaticData = useSelector((state) => state?.static?.staticData?.medicine_unit_of_measure);

  useEffect(() => {
    dispatch(fetchStaticData());

    if (selectedMedi?.show) {
      setstateMedi(selectedMedi?.data);
      setcheckBox(selectedMedi?.data?.discountable);
    } else {
      setstateMedi({});
      setcheckBox(false);
    }
  }, [selectedMedi]);

  const onSubmit = (data) => {
    const apiData = {
      name: stateMedi?.name,
      formula: stateMedi?.formula,
      manufacturer_name:stateMedi?.manufacturer_name,
      currency: { id: 1, name: "bdt" },
      quantity: Number(stateMedi?.quantity),
      item_id: stateMedi?.item_id,
      unit_of_measure: stateMedi?.unit_of_measure[0]?.name,
      route: stateMedi?.route[0]?.name,
      medicine_type: stateMedi?.medicine_type[0]?.name,
      sales_price: Number(stateMedi?.sales_price),
      purchase_price: Number(stateMedi?.purchase_price),
      discountable: checkBox,
    };  
    if (selectedMedi?.show) {
      setstateMedi({});
      dispatch(updateMedicine({ ...apiData, id: stateMedi?.id }));
    } else if (!selectedMedi?.show) {
      setstateMedi({});
      
      dispatch(addMedicine(apiData)); 
    }
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedMedi?.show ? "Update Medicine" : "Add Medicine"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            <div className="row">
              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medname")}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.name || ""}
                  onChange={(e) =>
                    setstateMedi({ ...stateMedi, name: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.manufacturername")}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.manufacturer_name || ""}
                  onChange={(e) =>
                    setstateMedi({ ...stateMedi, manufacturer_name: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-12">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medformulaname")}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.formula || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      formula: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.medquantity")}</span>
                <input
                  className="input-login-modal"
                  type="number"
                  value={stateMedi?.quantity || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      quantity: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.medtypetablet")}
                </span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.medicine_type || ""}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      medicine_type: selected,
                    });
                  }}
                  options={mediTypeStaticData}
                />
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">
                {t("dashboards.alldashboardkeys.tablekeys.purchaseprice")}
                  
                  </span>
                <input
                  className="input-login-modal"
                  value={stateMedi?.purchase_price || ""}
                  type="number"
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      purchase_price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.sellprice")}</span>
                <input
                  className="input-login-modal"
                  type="number"
                  value={stateMedi?.sales_price || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      sales_price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-12">
                <span className="label-name-login">{t("dashboards.alldashboardkeys.tablekeys.routeformedicine")}</span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.route || ""}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      route: selected,
                    })
                  }}
                  options={mediRouteStaticData}
                />

              </div>
              <div className="col-lg-6 mt-3">
                <span className="label-name-login">
                  {t("dashboards.alldashboardkeys.tablekeys.unitofmeasure")}
                </span>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  placeholder=""
                  selected={stateMedi?.unit_of_measure || ""}
                  onChange={(selected) => {
                    setstateMedi({
                      ...stateMedi,
                      unit_of_measure: selected,
                    })
                  }}
                  options={mediUnitStaticData}
                />
              </div>
              <div className="col-lg-6 mt-3">
                <span className="label-name-login">{"ID"}</span>
                <input
                  className="input-login-modal"
                  type="text"
                  value={stateMedi?.item_id || ""}
                  onChange={(e) =>
                    setstateMedi({
                      ...stateMedi,
                      item_id: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row px-3">
            <div className="form-check">
              <input
                type="checkbox"
                checked={checkBox}
                value={checkBox}
                onChange={() => setcheckBox(!checkBox)}
                className="form-check-input"
                id="exampleCheck1"
                style={{ width: "15px", height: "15px" }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
              {t("dashboards.alldashboardkeys.tablekeys.discountable")}
              </label>
            </div>
          </div>
          <input
            type="submit"
            value={selectedMedi?.show ? t("dashboards.alldashboardkeys.tablekeys.updatemedicine") : t("dashboards.alldashboardkeys.tablekeys.addmedicine")}
            className="headerBtn-red btn-block mx-auto"
            style={{ width: "85%", textAlign: "center" }}
            onClick={onSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPharmacyMedicine;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RemoveDoctorAdministratorModal from "../../Components/LayOuts/modals/removeDoctorAdministratorModal/RemoveDoctorAdministratorModal";
import BootstrapCustomTable from "../../Components/ReUseableComponents/BootstrapCustomTable";
import DashboardBtnList from "../../Components/ReUseableComponents/DashboardBtnList";
import Loader from "../../Components/ReUseableComponents/Loader";
import { getadministratorDoctor } from "../../Store/Actions/administratorActions";
// import ProfileTable from "../patientDashboard/ProfileTable";

const AdministrationDoctorsList = (props) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch();
  const [removeDoctorModal, setRemoveDoctorModal] = useState(false);
  const [selectedDoctor, setselectedDoctor] = useState({});
  const administratorDoctor = useSelector(
    (state) => state?.administrator?.doctor
  );
  const loader = useSelector((state) => state?.logIn?.loader);
  useEffect(() => {
    if (administratorDoctor.length < 1) {
      dispatch(getadministratorDoctor());
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <h4 className="patient-dashboard-heading">{t("dashboards.alldashboardkeys.tablekeys.alldoctors")}</h4>
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <BootstrapCustomTable
                columns={[
                  {
                    formatter: (cell, row, index) => {
                      return <span>{index + 1}</span>;
                    },
                    text: "Sr.",
                  },

                  { dataField: "name", text: t("dashboards.alldashboardkeys.tablekeys.doctorname") },
                  { dataField: "date_of_birth", text: t("dashboards.alldashboardkeys.tablekeys.dateofbirth") },
                  { dataField: "gender.name", text: t("dashboards.alldashboardkeys.tablekeys.gender") },




                  {
                    formatter: (cell, row) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col pr-0">
                              <DashboardBtnList
                                label={t("dashboards.alldashboardkeys.tablekeys.addslot")}
                                className="order-pay-btn"
                                onClick={() => {
                                  props?.history?.push(
                                    "/administrator-doctor",
                                    row
                                  );
                                }}
                              />
                            </div>
                            <div className="col">
                              <DashboardBtnList
                                label={t("dashboards.alldashboardkeys.tablekeys.remove")}
                                className="appointment-reschdule-btn"
                                onClick={() => {
                                  setRemoveDoctorModal(true);
                                  setselectedDoctor(row);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      );
                    },
                    text: "Actions",
                  },
                ]}
                tabledata={administratorDoctor}
              />
            </div>
          </div>
        </>
      )}

      <RemoveDoctorAdministratorModal
        show={removeDoctorModal}
        onHide={() => setRemoveDoctorModal(false)}
        selecteddoctor={selectedDoctor}
        {...props}
      />
    </div>
  );
};

export default AdministrationDoctorsList;

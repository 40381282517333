import React from "react";
import BannerImg from "../ReUseableComponents/BannerImg";
import covidTeamBaner from "../../Statics/Images/internal-banner/covid-team.jpg";
import BlueHeading from "../ReUseableComponents/BlueHeading";
import CovidTeamCard from "../LayOuts/Cards/covidTeamCard/CovidTeamCard";
import covidTeamImg1 from "../../Statics/Images/covid-team/1.jpg";
import covidTeamImg2 from "../../Statics/Images/covid-team/2.jpg";
import covidTeamImg3 from "../../Statics/Images/doctors/no_image.jpg";
import covidTeamImg4 from "../../Statics/Images/covid-team/4.jpg";
import covidTeamImg5 from "../../Statics/Images/covid-team/5.jpg";
import covidTeamImg6 from "../../Statics/Images/covid-team/6.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCmscomponent } from "../../Store/Actions/cmsAction";
import { useEffect } from "react";
import { filterComponentData } from "../../Utils/functions";
import { useSelector } from "react-redux";
import BannerImageWithText from "../ReUseableComponents/BannerImgComponents";

const CovidTeam = () => {
  const {t,i18} = useTranslation("common");


  
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const componetcms = useSelector((state) => state.cms.components);
  const lang = useSelector((state) => state?.cms?.language)
  const CovidTeam = filterComponentData(componetcms, 'covid_card_teams', lang)
  const CovidTeamHeader = filterComponentData(componetcms, 'covid_team_page', lang)
  const banner = filterComponentData(componetcms, "banners", lang);

  const CovidTeamData = [
    {
      imgSrc: covidTeamImg1,
      label: t("covidteam.covidcarddata.label1"),
      defaultname:'drkaniz',
      details: t("covidteam.covidcarddata.details1"),
    },
    {
      imgSrc: covidTeamImg2,
      label: t("covidteam.covidcarddata.label2"),
      defaultname:'drtasnim',
      details: t("covidteam.covidcarddata.details2"),
    },
    {
      imgSrc: covidTeamImg3,
      label: t("covidteam.covidcarddata.label3"),
      defaultname:'drAmirul',
      details: t("covidteam.covidcarddata.details3"),
    },
    {
      imgSrc: covidTeamImg4,
      label: t("covidteam.covidcarddata.label4"),
      defaultname:'drAsish',
      details: t("covidteam.covidcarddata.details4"),
    },
    {
      imgSrc: covidTeamImg5,
      label: t("covidteam.covidcarddata.label5"),
      defaultname:'drmdmaruf',
      details: t("covidteam.covidcarddata.details5"),
    },
    {
      imgSrc: covidTeamImg6,
      label: t("covidteam.covidcarddata.label6"),
      defaultname:'drmahmuda',
      details: t("covidteam.covidcarddata.details6"),
    },
  ];
  const LinksBanSpecialityAstheticErec = [
    {
      subLinkName:banner[3]?.sublink1 ?banner[3]?.sublink1:  "Home",
      subDash: ">",
      subLink: "/",
    },
    {
      subLinkName: banner[3]?.sublink2 ?banner[3]?.sublink2:"Covid 19",
      subDash: ">",
      subLink: "/covid-19-team",
    },
    {
      subLinkName: banner[3]?.sublink3 ?banner[3]?.sublink3:"Covid 19 Team",
      subDash: ">",
      subLink: "/covid-19-team",
    },
    
   
  ];
  return (
    <>
        <Helmet>
        <title>
        {t("covidteam.maintitle")}
        </title>
      </Helmet>
      <BannerImageWithText
        backimg={` linear-gradient(rgba(26, 71, 116, 0.8), rgba(26, 71, 116, 0.8)),url(${banner[3]?.image ? banner[3]?.image : BannerImg})`}
        heading={banner[3]?.heading ? banner[3]?.heading : "Covid 19 Team"}
        LinksBan={LinksBanSpecialityAstheticErec}
        height={"400px"}
        container_style={{ paddingTop: "200px" }}
        backgroundSize={"100% 300px"}
      />
      <br />
      <BlueHeading heading={ CovidTeamHeader[0]?.heading ? CovidTeamHeader[0]?.heading : t("covidteam.mainheading1")} />
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p className="light-Color-Para">
            {CovidTeamHeader[0]?.text ? CovidTeamHeader[0]?.text :t("covidteam.text1")}
            </p>
          </div>
        </div>
        <div className="row mt-5">
          {
          CovidTeam?.length < 1 ?
          
          CovidTeamData?.map((item, index) => (
            <div className="col-lg-3 col-sm-6 center-childrens" key={index}>
              <CovidTeamCard
                btnlinks='/covid-19-team-innerpage'
                imgSrc={item?.imgSrc}
                imgAlt={item?.imgAlt}
                defaultname={item?.defaultname}
                label={item?.label}
                details={item?.details}
              />
            </div>
          )):
          CovidTeam?.map((item, index) => (
            <div className="col-lg-3 col-sm-6 center-childrens" key={index}>
            <CovidTeamCard
              btnlinks='/covid-19-team-innerpage'
              imgSrc={item?.image}
              imgAlt={item?.name}
              defaultname={item?.name}
              label={item?.name}
              details={item?.desig}
              button={item?.button}
            />
          </div>
          ))

         
          }
        </div>
      </div>
    </>
  );
};

export default CovidTeam;
